// @Vendors
import { put, call, select } from 'redux-saga/effects';
import * as Sentry from '@sentry/browser';

import AuthActions from '../redux/AuthRedux';
import UserActions from '../redux/UserRedux';
import { ACCEPTED } from '../constants/status';
import { ZendeskWebApi } from '../utils/zendesk';
import { isKycRequired } from '../utils/kyc';
import HeapBridge from '../utils/heap';
import LocalStorageService from '../services/LocalStorageService';

export const getToken = state => state.auth.token;

export function* login(api, action) {
  const { email, password, history } = action;
  const token = yield select(getToken);
  const response = yield call(api.login, token, email, password);
  HeapBridge.track({ event: 'AccountLogin' });
  if (response.ok) {
    yield put(AuthActions.loginSuccess(response.data));
    history.push('/');
  } else {
    yield put(AuthActions.loginFailure(response));
  }
}

export function* signUp(api, action) {
  const { email, history } = action;
  const token = yield select(getToken);
  const response = yield call(api.signUp, token, email);

  if (response.ok) {
    yield put(AuthActions.signUpSuccess(response.data));
    yield put(UserActions.updateUser({ email: response.data.user.email }));
  } else {
    try {
      const { code, asset, country } = JSON.parse(response.data.message);
      if (code === 2) {
        history.push(`/error/00002?asset=${asset}&country=${country}`);
      }
      yield put(AuthActions.finishRequesting());
    } catch {
      yield put(AuthActions.signUpFailure(response));
    }
  }
}

export function* continueAsAGuest(api, action) {
  const { email, history } = action;
  const token = yield select(getToken);
  const response = yield call(api.continueAsAGuest, token, email);

  HeapBridge.track({ event: 'GuestLogin' });
  if (response.ok) {
    yield put(AuthActions.continueAsAGuestSuccess(response.data));
    yield put(UserActions.updateUser({ email: response.data.user.email }));
    history.push('/');
  } else {
    try {
      const { code, asset, country } = JSON.parse(response.data.message);
      if (code === 2) {
        history.push(`/error/00002?asset=${asset}&country=${country}`);
      }
      yield put(AuthActions.finishRequesting());
    } catch {
      yield put(AuthActions.continueAsAGuestFailure(response));
    }
  }
}

export function* changePassword(api, action) {
  const { password, history, checkVerificationCodeMode, verificationCode } = action;
  const token = yield select(getToken);
  const response = yield call(api.changePassword, token, password, checkVerificationCodeMode, verificationCode);

  if (response.ok) {
    yield put(AuthActions.changePasswordSuccess(response.data));
    yield put(UserActions.updateUser({ email: response.data.user.email }));
    history.push('/auth/login');
  } else {
    yield put(AuthActions.changePasswordFailure(response));
  }
}

export function* checkAuth(api, action) {
  const { token } = action;
  const response = yield call(api.checkAuth, token || LocalStorageService.getToken() || '');

  if (response.ok) {
    yield put(AuthActions.checkAuthSuccess(response.data));
    if (!token && response.data.isValid) {
      // This means token from local storage was used.
      // In this case we need to update local storage with the updated token.
      LocalStorageService.setToken(response.data.token);
      LocalStorageService.setTokenEmail(response.data.user.email);
    }
    const { consumer, countryCode, originAsset, destinationAsset } = response.data.user;
    HeapBridge.addUserProperties({ consumer, countryCode, originAsset, destinationAsset });
  } else {
    yield put(AuthActions.checkAuthFailure(response));
  }
}

export function* tokenExchange(api, action) {
  const { token } = action;
  const response = yield call(api.checkCrossAuth, token);

  if (response.ok) {
    yield put(AuthActions.tokenExchangeSuccess(response.data));
    yield put(UserActions.setUser(response.data.user));
  } else {
    yield put(AuthActions.tokenExchangeFailure(response));
  }
}

export function* userInfo(api, { data: { history } }) {
  const token = yield select(getToken);
  const response = yield call(api.userInfo, token);

  if (response.ok) {
    yield put(UserActions.setUser(response.data.user));
    yield put(AuthActions.userInfoSuccess(response));
    if (response.data.consumerConfig) {
      yield put(AuthActions.setConsumerConfig(response.data.consumerConfig));
    }
    HeapBridge.identify(response.data.userId);
    const { _id, consumer, countryCode, originAsset, destinationAsset } = response.data.user;
    HeapBridge.addUserProperties({ checkoutId: _id, consumer, countryCode, originAsset, destinationAsset });
    const {
      data: { kyc }
    } = response;
    if (kyc) {
      ZendeskWebApi('webWidget', 'identify', {
        name: `${kyc.name} ${kyc.lastname}`,
        email: kyc.email
      });
      const { data: limitEnforcementCheck } = yield call(api.limitEnforcementCheck, token, {});
      if (limitEnforcementCheck) {
        yield put(UserActions.limitEnforcementData(limitEnforcementCheck));
        if (!isKycRequired(kyc, limitEnforcementCheck) && limitEnforcementCheck.status !== ACCEPTED) {
          history.replace('/limit-enforcement');
        }
      }
      yield put(UserActions.setKyc(kyc));
    } else {
      ZendeskWebApi('webWidget', 'identify', {});
    }
  } else {
    yield put(AuthActions.userInfoFailure(response));
    Sentry.captureException(response);
    history.replace('/error/00003');
  }
}

export function* sendVerificationCode(api, action) {
  const { checkVerificationCodeMode } = action;
  const token = yield select(getToken);
  const response = yield call(api.sendVerificationCode, token, checkVerificationCodeMode);

  if (response.ok) {
    yield put(AuthActions.sendVerificationCodeSuccess());
  } else {
    yield put(AuthActions.sendVerificationCodeFailure());
  }
}

export function* checkVerificationCode(api, action) {
  const token = yield select(getToken);
  const { route, verificationCode } = action;
  const response = yield call(api.checkVerificationCode, token, route, verificationCode);

  if (response.ok) {
    yield put(AuthActions.checkVerificationCodeSuccess({ ...response.data, verificationCode }));
    yield put(UserActions.updateUser({ email: response.data.user.email }));
  } else {
    yield put(AuthActions.checkVerificationCodeFailure(response));
  }
}

export function* passwordRecovery(api, action) {
  const { email } = action;
  const token = yield select(getToken);
  const response = yield call(api.passwordRecovery, token, email);

  if (response.ok) {
    yield put(AuthActions.passwordRecoverySuccess(response.data));
    if (response.data.user) {
      yield put(UserActions.updateUser({ email: response.data.user.email }));
    } else {
      yield put(UserActions.updateUser({ email: response.data.email }));
    }
  } else {
    yield put(AuthActions.passwordRecoveryFailure(response));
  }
}
