import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import PaymentMethodComponent from '../../components/PaymentMethod';

import DepositActions from '../../redux/DepositRedux';
import UserActions from '../../redux/UserRedux';

const PaymentMethod = ({
  history,
  user,
  paymentMethods,
  setPaymentMethod,
  paymentMethod,
  quote,
  getAvailablePaymentMethods,
  setUser
}) => {
  useEffect(() => {
    getAvailablePaymentMethods();
  }, [user, getAvailablePaymentMethods]);

  return (
    <>
      {!user && <Redirect to="/" />}
      {user && (
        <PaymentMethodComponent
          {...{
            history,
            user,
            paymentMethods,
            quote,
            setPaymentMethod,
            paymentMethod,
            setUser
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    user: state.user.user,
    quote: state.deposit.quote,
    paymentMethods: state.deposit.paymentMethods,
    paymentMethod: state.deposit.paymentMethodSelected
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAvailablePaymentMethods: data => dispatch(DepositActions.getAvailablePaymentMethods(data)),
    setUser: checkout => dispatch(UserActions.setUser(checkout)),
    setPaymentMethod: paymentMethod => dispatch(DepositActions.setPaymentMethodSelected(paymentMethod))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethod);
