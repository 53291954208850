// @Vendors
import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import LocalStorageService from '../services/LocalStorageService';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  loginRequest: ['email', 'password', 'history'],
  loginSuccess: ['response'],
  loginFailure: ['error'],

  logout: ['callbackUrl'],

  signUpRequest: ['email', 'history'],
  signUpSuccess: ['data'],
  signUpFailure: ['error'],

  continueAsAGuestRequest: ['email', 'history'],
  continueAsAGuestSuccess: ['data'],
  continueAsAGuestFailure: ['error'],

  checkAuthRequest: ['token'],
  checkAuthWontRequest: [],
  resetCheckAuthRequesting: [],
  checkAuthSuccess: ['data'],
  checkAuthFailure: ['error'],

  tokenExchangeRequest: ['token'],
  tokenExchangeSuccess: ['data'],
  tokenExchangeFailure: ['error'],
  saveTokenHeader: ['token'],

  userInfo: ['data'],
  userInfoSuccess: ['data'],
  userInfoFailure: ['error'],

  setConsumerConfig: ['consumerConfig'],

  sendVerificationCodeRequest: ['checkVerificationCodeMode'],
  sendVerificationCodeSuccess: [],
  sendVerificationCodeFailure: [],

  checkVerificationCodeRequest: ['route', 'verificationCode'],
  checkVerificationCodeSuccess: ['data'],
  checkVerificationCodeFailure: ['error'],

  changePasswordRequest: ['password', 'history', 'checkVerificationCodeMode', 'verificationCode'],
  changePasswordSuccess: ['data'],
  changePasswordFailure: ['error'],

  passwordRecoveryRequest: ['email'],
  passwordRecoverySuccess: ['data'],
  passwordRecoveryFailure: ['error'],

  passwordRecovery: [],

  resetCheckVerificationCodeMode: [],

  cleanError: [],

  finishRequesting: [],

  cleanPendingsInSignUp: []
});

export const AuthTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  authenticated: null,
  requesting: false,
  token: null,
  user: null,
  depositIntent: null,
  error: '',
  sendVerificationCodeRequesting: false,
  checkVerificationCodeRequesting: false,
  checkVerificationCodeError: '',
  checkVerificationCodeValid: false,
  pendingEmailCode: false,
  pendingPassword: false,
  checkVerificationCodeMode: '',
  properVerificationCode: '',
  checkAuthRequesting: null
});

/* ------------- Reducers ------------- */

export const loginRequest = state => {
  return state.merge({
    requesting: true,
    error: ''
  });
};

export const loginSuccess = (state, { response }) => {
  LocalStorageService.setToken(response.token);
  LocalStorageService.setTokenEmail(response.data.email);

  return state.merge({
    requesting: false,
    token: response.token,
    user: response.data,
    authenticated: true
  });
};

export const loginFailure = (state, { error }) => {
  return state.merge({
    requesting: false,
    authenticated: false,
    error: `Something went wrong. ${error}`
  });
};

export const logout = (state, { callbackUrl }) => {
  LocalStorageService.removeToken();
  LocalStorageService.removeTokenEmail();

  window.parent.location.href = callbackUrl;
  return state;
};

export const signUpRequest = state => {
  return state.merge({
    requesting: true,
    error: ''
  });
};

export const signUpSuccess = (state, { data }) => {
  return state.merge({
    requesting: false,
    token: data.token,
    user: data.user,
    pendingEmailCode: true,
    checkVerificationCodeMode: 'sign-up'
  });
};

export const signUpFailure = (state, { error }) => {
  return state.merge({
    requesting: false,
    error: `Something went wrong. ${error}`
  });
};

export const continueAsAGuestRequest = state => {
  return state.merge({
    requesting: true,
    error: ''
  });
};

export const continueAsAGuestSuccess = (state, { data }) => {
  return state.merge({
    requesting: false,
    token: data.token,
    user: data.user,
    pendingEmailCode: false,
    checkVerificationCodeMode: '',
    guestUser: true
  });
};

export const continueAsAGuestFailure = (state, { error }) => {
  return state.merge({
    requesting: false,
    error: `Something went wrong. ${error}`
  });
};

export const checkAuthRequest = state => {
  return state.merge({
    checkAuthRequesting: true
  });
};

export const checkAuthWontRequest = state => {
  return state.merge({
    checkAuthRequesting: false
  });
};

export const resetCheckAuthRequesting = state => {
  return state.merge({
    checkAuthRequesting: null
  });
};

export const resetCheckVerificationCodeMode = state => {
  return state.merge({
    checkVerificationCodeMode: ''
  });
};

export const checkAuthSuccess = (state, { data }) => {
  return state.merge({
    authenticated: data.isValid,
    token: data.token,
    checkAuthRequesting: false
  });
};

// DEPRECATED
export const tokenExchangeSuccess = (state, { data }) => {
  return state.merge({
    authenticated: data.isValid,
    depositIntent: data.data,
    user: data.user,
    token: data.token
  });
};

export const saveTokenHeader = (state, { token }) => {
  return state.merge({
    token
  });
};

export const checkAuthFailure = state => {
  LocalStorageService.removeToken();
  LocalStorageService.removeTokenEmail();

  return state.merge({
    authenticated: false,
    checkAuthRequesting: false
  });
};

export const tokenExchangeFailure = (state, { error }) => {
  return state.merge({
    authenticated: false,
    error: error.data.message
  });
};

export const userInfo = state => {
  return state.merge({
    requesting: true,
    error: ''
  });
};

export const userInfoSuccess = (state, { data }) => {
  return state.merge({
    requesting: false,
    user: data.user
  });
};

export const setConsumerConfig = (state, { consumerConfig }) => {
  return state.merge({
    consumerConfig
  });
};

export const userInfoFailure = (state, { error }) => {
  return state.merge({
    requesting: false,
    error: `Something went wrong. ${error}`
  });
};

export const sendVerificationCodeRequest = state => {
  return state.merge({
    sendVerificationCodeRequesting: true
  });
};

export const sendVerificationCodeSuccess = state => {
  return state.merge({
    sendVerificationCodeRequesting: false
  });
};

export const sendVerificationCodeFailure = state => {
  return state.merge({
    sendVerificationCodeRequesting: false
  });
};

export const checkVerificationCodeRequest = state => {
  return state.merge({
    checkVerificationCodeRequesting: true,
    checkVerificationCodeError: '',
    checkVerificationCodeValid: false,
    pendingEmailCode: true
  });
};

export const checkVerificationCodeSuccess = (state, { data }) => {
  return state.merge({
    checkVerificationCodeRequesting: false,
    checkVerificationCodeError: '',
    checkVerificationCodeValid: true,
    token: data.token,
    pendingEmailCode: false,
    pendingPassword: true,
    user: data.user,
    properVerificationCode: data.verificationCode
  });
};

export const checkVerificationCodeFailure = (state, { error }) => {
  return state.merge({
    checkVerificationCodeRequesting: false,
    checkVerificationCodeError: error.data.message,
    checkVerificationCodeValid: false,
    pendingEmailCode: true
  });
};

export const changePasswordRequest = state => {
  return state.merge({
    requesting: true,
    error: '',
    pendingPassword: true
  });
};

export const changePasswordSuccess = (state, { data }) => {
  sessionStorage.removeItem('checkVerificationCodeMode');

  return state.merge({
    requesting: false,
    error: '',
    token: data.token,
    pendingPassword: false,
    user: data.user
  });
};

export const changePasswordFailure = (state, { error }) => {
  return state.merge({
    requesting: false,
    error: error.data.message,
    pendingPassword: true
  });
};

export const passwordRecoveryRequest = state => {
  return state.merge({
    requesting: true,
    pendingEmailCode: false,
    error: ''
  });
};

export const passwordRecoverySuccess = (state, { data }) => {
  return state.merge({
    token: data.token,
    user: data.user ? data.user : state.user,
    pendingEmailCode: true,
    requesting: false,
    error: ''
  });
};

export const passwordRecoveryFailure = (state, { error }) => {
  return state.merge({
    pendingEmailCode: false,
    requesting: false,
    error: error.data.message
  });
};

export const passwordRecovery = state => {
  // This is to know flow type so when user refreshes it is sent to the corresponding route
  const stringValue = JSON.stringify('password-recovery');
  const encodedValue = window.btoa(stringValue);
  sessionStorage.setItem('checkVerificationCodeMode', encodedValue);

  return state.merge({
    checkVerificationCodeMode: 'password-recovery'
  });
};

export const cleanError = state => {
  return state.merge({
    error: ''
  });
};

export const finishRequesting = state => {
  return state.merge({
    requesting: false
  });
};

export const cleanPendingsInSignUp = state => {
  return state.merge({
    pendingEmailCode: false,
    pendingPassword: false
  });
};

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.LOGIN_REQUEST]: loginRequest,
  [Types.LOGIN_SUCCESS]: loginSuccess,
  [Types.LOGIN_FAILURE]: loginFailure,

  [Types.LOGOUT]: logout,

  [Types.SIGN_UP_REQUEST]: signUpRequest,
  [Types.SIGN_UP_SUCCESS]: signUpSuccess,
  [Types.SIGN_UP_FAILURE]: signUpFailure,

  [Types.CONTINUE_AS_A_GUEST_REQUEST]: continueAsAGuestRequest,
  [Types.CONTINUE_AS_A_GUEST_SUCCESS]: continueAsAGuestSuccess,
  [Types.CONTINUE_AS_A_GUEST_FAILURE]: continueAsAGuestFailure,

  [Types.CHECK_AUTH_REQUEST]: checkAuthRequest,
  [Types.CHECK_AUTH_WONT_REQUEST]: checkAuthWontRequest,
  [Types.RESET_CHECK_AUTH_REQUESTING]: resetCheckAuthRequesting,
  [Types.CHECK_AUTH_SUCCESS]: checkAuthSuccess,
  [Types.CHECK_AUTH_FAILURE]: checkAuthFailure,

  [Types.TOKEN_EXCHANGE_REQUEST]: checkAuthRequest,
  [Types.TOKEN_EXCHANGE_SUCCESS]: tokenExchangeSuccess,
  [Types.TOKEN_EXCHANGE_FAILURE]: tokenExchangeFailure,
  [Types.SAVE_TOKEN_HEADER]: saveTokenHeader,

  [Types.USER_INFO]: userInfo,
  [Types.USER_INFO_SUCCESS]: userInfoSuccess,
  [Types.USER_INFO_FAILURE]: userInfoFailure,

  [Types.SET_CONSUMER_CONFIG]: setConsumerConfig,

  [Types.SEND_VERIFICATION_CODE_REQUEST]: sendVerificationCodeRequest,
  [Types.SEND_VERIFICATION_CODE_SUCCESS]: sendVerificationCodeSuccess,
  [Types.SEND_VERIFICATION_CODE_FAILURE]: sendVerificationCodeFailure,

  [Types.CHECK_VERIFICATION_CODE_REQUEST]: checkVerificationCodeRequest,
  [Types.CHECK_VERIFICATION_CODE_SUCCESS]: checkVerificationCodeSuccess,
  [Types.CHECK_VERIFICATION_CODE_FAILURE]: checkVerificationCodeFailure,

  [Types.CHANGE_PASSWORD_REQUEST]: changePasswordRequest,
  [Types.CHANGE_PASSWORD_SUCCESS]: changePasswordSuccess,
  [Types.CHANGE_PASSWORD_FAILURE]: changePasswordFailure,

  [Types.PASSWORD_RECOVERY]: passwordRecovery,

  [Types.PASSWORD_RECOVERY_REQUEST]: passwordRecoveryRequest,
  [Types.PASSWORD_RECOVERY_SUCCESS]: passwordRecoverySuccess,
  [Types.PASSWORD_RECOVERY_FAILURE]: passwordRecoveryFailure,

  [Types.RESET_CHECK_VERIFICATION_CODE_MODE]: resetCheckVerificationCodeMode,

  [Types.CLEAN_ERROR]: cleanError,

  [Types.FINISH_REQUESTING]: finishRequesting,

  [Types.CLEAN_PENDINGS_IN_SIGN_UP]: cleanPendingsInSignUp
});
