import React, { useState, useEffect } from 'react';
import { Col, Row, Input as StrapInput } from 'reactstrap';

import i18n from '../../i18n';

import CustomInput from '../Commons/CustomInput/CustomInput';
import CustomButton from '../Commons/CustomButton/CustomButton';
import CheckoutStep from '../Layout/CheckoutStep';

import { GetSupportUrl } from '../../utils/consumerConfig';

import './index.scss';

const CheckStatusComponent = ({ status, requestCheckoutStatus, error, requesting, t, orderNumberParam, langParam }) => {
  const [orderNumber, setOrderNumber] = useState(
    Number.isInteger(parseInt(orderNumberParam, 10)) ? orderNumberParam : ''
  );
  const [nationalId, setNationalId] = useState('');
  const [type, setType] = useState('Deposit');

  const onlyNumbers = (string, func) => (/^[0-9]*$/.test(string) ? func(string) : null);

  const handleCheckStatus = () => {
    if (!orderNumber || !nationalId) return;
    requestCheckoutStatus({ type, id: orderNumber, nationalId });
  };

  const getFinalStatus = () => {
    if (!status || !status.status) return null;
    if (!status.fullyProcessed || status.fullyProcessed === 'false') {
      return status.status === 'Expired' ? 'expired' : 'pending';
    }
    return status.status === 'Accepted' ? 'executed' : 'rejected';
  };

  const curpValidation = curp => {
    const regex =
      '^([A-Z][AEIOUX][A-Z]{2}\\d{2}(?:0\\d|1[0-2])(?:[0-2]\\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\\d])(\\d)$';
    const matched = curp.match(regex);
    if (!matched) return false;
    if (matched[2] != verificationDigit(matched[1])) return false;
    return true;
  };

  const verificationDigit = curp17 => {
    // Fuente https://consultas.curp.gob.mx/CurpSP/
    const diccionario = '0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ';
    let lngSuma = 0.0;
    let lngDigito = 0.0;
    for (let i = 0; i < 17; i++) lngSuma += diccionario.indexOf(curp17.charAt(i)) * (18 - i);
    lngDigito = 10 - (lngSuma % 10);
    if (lngDigito == 10) return 0;
    return lngDigito;
  };

  if (langParam && langParam !== i18n.language) {
    i18n.changeLanguage(langParam);
  }

  useEffect(() => {
    i18n.changeLanguage(langParam);
  }, [langParam]);

  const finalStatus = getFinalStatus();

  const isMEX = i18n.language === 'mx';
  const validNationalId = isMEX ? curpValidation(nationalId) : nationalId !== '';
  const supportLang = isMEX ? 'es' : i18n.language; // The zendesk page is only distinguishing between es and pt for now.
  const supportUrl = GetSupportUrl(supportLang);

  const hrefIncludesWithdrawWord = String(window.location.href).includes('withdraw');

  console.log(i18n.language);
  return (
    <div className="d-flex flex-column align-items-center">
      <CheckoutStep
        title={t('status.checkStatus.title')}
        hideGoBack
        hideNextButton
        classNameContainer="margin-container-bottom-12"
      >
        <p className="description check-description mt-1 ">{t('status.checkStatus.description')}</p>
        <Row className="description margin-b-100">
          <Col md={{ size: 6 }} className="check-form">
            <StrapInput
              className="origin-asset"
              type="select"
              onChange={({ target: { value } }) => setType(value)}
              name="type"
              value={type}
            >
              {hrefIncludesWithdrawWord ? (
                <>
                  <option value="Withdraw">{t('status.checkStatus.Withdraw')}</option>
                  <option value="Deposit">{t('status.checkStatus.Deposit')}</option>
                </>
              ) : (
                <>
                  <option value="Deposit">{t('status.checkStatus.Deposit')}</option>
                  <option value="Withdraw">{t('status.checkStatus.Withdraw')}</option>
                </>
              )}
            </StrapInput>

            <CustomInput
              className="mt-0 mb-0"
              label={t('status.checkStatus.orderNumber.label')}
              outline
              onChange={({ target }) => onlyNumbers(target.value, setOrderNumber)}
              value={orderNumber}
            />
            <CustomInput
              label={t('status.checkStatus.documentId.label')}
              onChange={({ target }) =>
                i18n.language === 'mx'
                  ? setNationalId(target.value.toUpperCase())
                  : onlyNumbers(target.value, setNationalId)
              }
              value={nationalId}
              outline
            />
            <CustomButton disabled={!orderNumber || !validNationalId || requesting} onClick={handleCheckStatus}>
              {t('status.checkStatus.checkButton')}
            </CustomButton>
          </Col>
          <Col md={{ size: 6 }}>
            <div className="transfer-detail folded status-check" style={{ height: '97%' }}>
              <p className="description mt-4 mb-4"> {t('status.checkStatus.status.title')}</p>
              <p className={`status mt-4 mb-4 ${finalStatus ? `${finalStatus}_color` : ''}`}>
                {!finalStatus ? '--' : t(`limitEnforcement.orderStatus.${finalStatus}`)}
              </p>
            </div>
          </Col>
        </Row>
        {error && <p className="status-description mt-4 mb-4 error">{t('status.checkStatus.errorMessage')}</p>}
        <p className="status-description mt-4">
          {t('status.checkStatus.footer')}{' '}
          <a href={supportUrl} target="_blank" rel="noopener noreferrer">
            {t('status.checkStatus.footer.link')}
          </a>
        </p>
      </CheckoutStep>
    </div>
  );
};

export default CheckStatusComponent;
