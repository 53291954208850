/* eslint-disable jsx-a11y/iframe-has-title */
// @Vendors
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { ZendeskWebApi } from '../../utils/zendesk';

// @Actions
import AuthActions from '../../redux/AuthRedux';
import DepositActions from '../../redux/DepositRedux';
import CheckoutActions from '../../redux/CheckoutRedux';

// @Theme

// @Styles
import '../../components/Layout/LayoutStyles.scss';

// @Components
import KycWebappContainer from '../../components/Kyc/KycWebappContainer';

import { langMapping } from '../../constants/countryMap';
import { isKycRequired } from '../../utils/kyc';

class DepositBridgePage extends Component {
  componentDidMount() {
    this.requesting = true;
    const { userInfo, history, validateCheckout } = this.props;
    validateCheckout(history, () => {
      this.requesting = false;
    });
    userInfo({ history });
  }

  componentDidUpdate() {
    const { user } = this.props;
    if (user && !this.props.requestingQuote && !this.props.quote.quote && user.destinationAsset && user.originAsset) {
      if (user.type === 'Deposit') {
        this.props.getQuote(user.destinationAsset, user.originAsset, 'Sell', user.pid);
      } else {
        this.props.getQuote(user.originAsset, user.destinationAsset, 'Buy', user.pid);
      }
    }

    if (user) {
      if ((user.originAsset === 'ARS' || user.destinationAsset === 'ARS') && i18n.language !== 'es') {
        i18n.changeLanguage('es');
        ZendeskWebApi('webWidget', 'setLocale', 'es');
      }

      if ((user.originAsset === 'BRL' || user.destinationAsset === 'BRL') && i18n.language !== 'pt') {
        i18n.changeLanguage('pt');
        ZendeskWebApi('webWidget', 'setLocale', 'pt');
      }

      if ((user.originAsset === 'MXN' || user.destinationAsset === 'MXN') && i18n.language !== 'mx') {
        i18n.changeLanguage('mx');
        ZendeskWebApi('webWidget', 'setLocale', 'es');
      }
    }
  }

  setUserKycChecked = () => {
    const { userInfo, history } = this.props;
    userInfo({ history });
  };

  render() {
    const { user, kyc, limitEnforcementCheck, t, token } = this.props;

    if (!user || !limitEnforcementCheck || limitEnforcementCheck.status === 'Rejected' || this.requesting) {
      return null;
    }

    if (kyc && !isKycRequired(kyc, limitEnforcementCheck)) {
      if (user.type === 'Deposit') {
        return <Redirect to="/wallet-address" />;
      }
      return <Redirect to="/general/summary" />;
    }

    return (
      <KycWebappContainer
        user={{
          ...user,
          country: user.countryCode,
          kycTypeName: limitEnforcementCheck.kycType.name,
          kycTypeLevel: limitEnforcementCheck.kycType.level
        }}
        token={token}
        onFinish={this.setUserKycChecked}
        hideWelcomeStep
        overrideTitleWith={t('kycTitle')}
        lang={langMapping[user.countryCode]}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user.user,
    quote: state.deposit.quote,
    requestingQuote: state.deposit.requestingQuote,
    kyc: state.user.kyc,
    limitEnforcementCheck: state.user.limitEnforcementCheck,
    token: state.auth.token
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getQuote: (fromAsset, toAsset, type, pid) => dispatch(DepositActions.getQuote(fromAsset, toAsset, type, pid)),
    userInfo: data => dispatch(AuthActions.userInfo(data)),
    validateCheckout: (history, cb) => dispatch(CheckoutActions.validateCheckoutRequest(history, cb))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DepositBridgePage));
