// @Vendors
import { put, call, select } from 'redux-saga/effects';
import FastcashActions from '../redux/FastcashRedux';

export const getToken = state => state.auth.token;

export function* createDepositAps(api, action) {
  const { data } = action;
  const token = yield select(getToken);
  const response = yield call(api.createDepositAps, token, data);

  if (response.ok) {
    yield put(FastcashActions.createDepositSuccess(response.data.data));
  } else {
    yield put(FastcashActions.createDepositFailure(response));
  }
}

export function* validateDepositAps(api, action) {
  const { data } = action;
  const response = yield call(api.validateDepositAps, data);

  if (response.ok) {
    yield put(FastcashActions.validateDepositSuccess(response.data));
  } else {
    yield put(FastcashActions.validateDepositFailure(response));
  }
}

export function* getSubPaymentMethodsAps(api, action) {
  const { data } = action;

  const response = yield call(api.getFcSubpaymentMethods, data);

  if (response.ok) {
    yield put(FastcashActions.getSubPaymentMethodsSuccess(response.data));
  } else {
    yield put(FastcashActions.getSubPaymentMethodsFailure(response));
  }
}
