/* eslint-disable global-require */

const BankName = [
  { id: '005', name: 'The Royal Bank of Scotland N.V.' },
  { id: '007', name: 'Banco de Galicia y Buenos Aires S.A.' },
  { id: '011', name: 'Banco de la Nación Argentina' },
  { id: '014', name: 'Banco de la Provincia de Buenos Aires' },
  { id: '015', name: 'Industrial and Commercial Bank of China S.A.' },
  { id: '016', name: 'Citibank N.A.' },
  { id: '017', name: 'BBVA Banco Francés S.A.' },
  { id: '018', name: 'The Bank of Tokyo-Mitsubishi UFJ, LTD.' },
  { id: '020', name: 'Banco de la Provincia de Córdoba S.A.' },
  { id: '027', name: 'Banco Supervielle S.A.' },
  { id: '029', name: 'Banco de la Ciudad de Buenos Aires' },
  { id: '030', name: 'Central de la República Argentina' },
  { id: '034', name: 'Banco Patagonia S.A.' },
  { id: '044', name: 'Banco Hipotecario S.A.' },
  { id: '045', name: 'Banco de San Juan S.A.' },
  { id: '046', name: 'Banco do Brasil S.A.' },
  { id: '060', name: 'Banco de Tucumán S.A.' },
  { id: '065', name: 'Banco Municipal de Rosario' },
  { id: '072', name: 'Banco Santander Río S.A.' },
  { id: '083', name: 'Banco del Chubut S.A.' },
  { id: '086', name: 'Banco de Santa Cruz S.A.' },
  { id: '093', name: 'Banco de la Pampa Sociedad de Economía Mixta' },
  { id: '094', name: 'Banco de Corrientes S.A.' },
  { id: '097', name: 'Banco Provincia del Neuquén S.A.' },
  { id: '143', name: 'Brubank S.A.U.' },
  { id: '147', name: 'Banco Interfinanzas S.A.' },
  { id: '150', name: 'HSBC Bank Argentina S.A.' },
  { id: '165', name: 'JP Morgan Chase Bank NA (Sucursal Buenos Aires)' },
  { id: '191', name: 'Banco Credicoop Cooperativo Limitado' },
  { id: '198', name: 'Banco de Valores S.A.' },
  { id: '247', name: 'Banco Roela S.A.' },
  { id: '254', name: 'Banco Mariva S.A.ī' },
  { id: '259', name: 'Banco Itaú Argentina S.A.' },
  { id: '262', name: 'Bank of America National Association' },
  { id: '266', name: 'BNP Paribas' },
  { id: '268', name: 'Banco Provincia de Tierra del Fuego' },
  { id: '269', name: 'Banco de la República Oriental del Uruguay' },
  { id: '277', name: 'Banco Sáenz S.A.' },
  { id: '281', name: 'Banco Meridian S.A.' },
  { id: '285', name: 'Banco Macro S.A.' },
  { id: '295', name: 'American Express Bank LTD. S.A.' },
  { id: '299', name: 'Banco Comafi S.A.' },
  { id: '300', name: 'Banco de Inversión y Comercio Exterior S.A.' },
  { id: '301', name: 'Banco Piano S.A.' },
  { id: '305', name: 'Banco Julio S.A.' },
  { id: '309', name: 'Nuevo Banco de la Rioja S.A.' },
  { id: '310', name: 'Banco del Sol S.A.' },
  { id: '311', name: 'Nuevo Banco del Chaco S.A.' },
  { id: '312', name: 'MBA Lazard Banco de Inversiones S.A.' },
  { id: '315', name: 'Banco de Formosa S.A.' },
  { id: '319', name: 'Banco CMF S.A.' },
  { id: '321', name: 'Banco de Santiago del Estero S.A.' },
  { id: '322', name: 'Banco Industrial S.A.' },
  { id: '325', name: 'Deutsche Bank S.A.' },
  { id: '330', name: 'Nuevo Banco de Santa Fe S.A.' },
  { id: '331', name: 'Banco Cetelem Argentina S.A.' },
  { id: '332', name: 'Banco de Servicios Financieros S.A.' },
  { id: '336', name: 'Banco Bradesco Argentina S.A.' },
  { id: '338', name: 'Banco de Servicios y Transacciones S.A.' },
  { id: '339', name: 'RCI Banque S.A.' },
  { id: '340', name: 'BACS Banco de Crédito y Securitización S.A.' },
  { id: '341', name: 'Más Ventas S.A.' },
  { id: '384', name: 'Wilobank S.A.' },
  { id: '386', name: 'Nuevo Banco de Entre Ríos S.A.' },
  { id: '389', name: 'Banco Columbia S.A.' },
  { id: '405', name: 'Ford Credit Compañía Financiera S.A.' },
  { id: '406', name: 'Metrópolis Compañía Financiera S.A.' },
  { id: '408', name: 'Compañía Financiera Argentina S.A.' },
  { id: '413', name: 'Montemar Compañía Financiera S.A.' },
  { id: '415', name: 'Multifinanzas Compañía Financiera S.A.' },
  { id: '428', name: 'Caja de Crédito Coop. La Capital del Plata LTDA.' },
  { id: '431', name: 'Banco Coinag S.A.' },
  { id: '432', name: 'Banco de Comercio S.A.' },
  { id: '434', name: 'Caja de Crédito Cuenca Coop. LTDA.' },
  { id: '437', name: 'Volkswagen Credit Compañía Financiera S.A.' },
  { id: '438', name: 'Cordial Compañía Financiera S.A.' },
  { id: '440', name: 'Fiat Crédito Compañía Financiera S.A.' },
  { id: '441', name: 'GPAT Compañía Financiera S.A.' },
  { id: '442', name: 'Mercedes-Benz Compañía Financiera Argentina S.A.' },
  { id: '443', name: 'Rombo Compañía Financiera S.A.' },
  { id: '444', name: 'John Deere Credit Compañía Financiera S.A.' },
  { id: '445', name: 'PSA Finance Argentina Compañía Financiera S.A.' },
  { id: '446', name: 'Toyota Compañía Financiera de Argentina S.A.' },
  { id: '448', name: 'Finandino Compañía Financiera S.A.' },
  { id: '992', name: 'Provincanje S.A.' }
];

const CBUMap = {
  '011': {
    name: 'Banco de la Nacion Argentina',
    icon: require('../images/Banks/icon-nacion-011.png')
  },
  '191': {
    name: 'Banco Credicoop',
    icon: require('../images/Banks/icon-credicoop-191.png')
  },
  '150': {
    name: 'HSBC Bank Argentina',
    icon: require('../images/Banks/icon-hsbc-150.png')
  },
  '285': {
    name: 'Banco Macro Bansud',
    icon: require('../images/Banks/icon-macro-285.png')
  },
  '016': {
    name: 'CITIBANK',
    icon: require('../images/Banks/icon-citibank-016.png')
  },
  '093': {
    name: 'Banco de la Pampa',
    icon: require('../images/Banks/icon-delapampa-093.png')
  },
  '072': {
    name: 'Banco Santander RIO',
    icon: require('../images/Banks/icon-santander-072.png')
  },
  '017': {
    name: 'Banco Frances BBVA',
    icon: require('../images/Banks/icon-frances-017.png')
  },
  '014': {
    name: 'Banco de la Provincia de Buenos Aires',
    icon: require('../images/Banks/icon-provincia-014.png')
  },
  '007': {
    name: 'Banco GALICIA',
    icon: require('../images/Banks/icon-galicia-007.png')
  },
  '034': {
    name: 'Banco Patagonia',
    icon: require('../images/Banks/icon-patagonia-034.png')
  },
  '015': {
    name: 'Industrial and Commercial Bank of China S.A. (ICBC)',
    icon: require('../images/Banks/icon-icbc-015.png')
  },

  '044': {
    name: 'Banco Hipotecario',
    icon: require('../images/Banks/icon-hipotecario-044.png')
  },
  '027': {
    name: 'Banco Supervielle',
    icon: require('../images/Banks/icon-superville-027.png')
  },
  '389': {
    name: 'Banco Columbia',
    icon: require('../images/Banks/icon-columbia-389.png')
  },
  '325': {
    name: 'Deustche Bank',
    icon: require('../images/Banks/icon-deutsche-325.png')
  },
  '303': {
    name: 'Banco Finansur',
    icon: require('../images/Banks/icon-finansur-303.png')
  },
  '165': {
    name: 'JP Morgan Chase Bank',
    icon: require('../images/Banks/icon-morganchase-165.png')
  },
  '046': {
    name: 'Banco do Brasil',
    icon: require('../images/Banks/icon-dobrasil-046.png')
  },

  '029': {
    name: 'Banco de la Ciudad de Buenos Aires',
    icon: require('../images/Banks/icon-ciudadbsas-029.png')
  },
  '020': {
    name: 'Banco de la Provincia de Cordoba',
    icon: require('../images/Banks/icon-bancor-020.png')
  },
  '330': {
    name: 'Banco de Santa Fé',
    icon: require('../images/Banks/icon-santafe-330.png')
  },
  '322': {
    name: 'Banco Industrial',
    icon: require('../images/Banks/icon-industrial-322.png')
  },
  '266': {
    name: 'BNP Paribas',
    icon: require('../images/Banks/icon-bnp-266.png')
  },
  '097': {
    name: 'Banco de la pronvicia de Neuquen',
    icon: require('../images/Banks/icon-bpn-097.png')
  },
  '259': {
    name: 'Banco Itaú',
    icon: require('../images/Banks/icon-itau-259.png')
  }
};

const validCBUlength = CBU => {
  return CBU.length === 22;
};

const firstBlockValidate = CBU => {
  if (CBU.length < 8) {
    return false;
  }
  const firstBlock = CBU.substr(0, 8);
  const bank = firstBlock.substr(0, 3);
  const sucursal = firstBlock.substr(3, 4);
  const verifDigit1 = firstBlock[7];
  const sum =
    bank[0] * 7 + bank[1] * 1 + bank[2] * 3 + sucursal[0] * 9 + sucursal[1] * 7 + sucursal[2] * 1 + sucursal[3] * 3;
  const differential = 10 - (sum % 10);
  const a_solution = parseInt(verifDigit1, 10) !== 0 && differential === parseInt(verifDigit1, 10);
  const b_solution = parseInt(verifDigit1, 10) === 0 && differential === 10;
  return a_solution || b_solution;
};

const secondBlockValidate = CBU => {
  if (CBU.length < 14) {
    return false;
  }
  const secondBlock = CBU.substr(8, 14);

  const verifDigit = secondBlock[13];
  const sum =
    secondBlock[0] * 3 +
    secondBlock[1] * 9 +
    secondBlock[2] * 7 +
    secondBlock[3] * 1 +
    secondBlock[4] * 3 +
    secondBlock[5] * 9 +
    secondBlock[6] * 7 +
    secondBlock[7] * 1 +
    secondBlock[8] * 3 +
    secondBlock[9] * 9 +
    secondBlock[10] * 7 +
    secondBlock[11] * 1 +
    secondBlock[12] * 3;
  const differential = 10 - (sum % 10);
  const a_solution = parseInt(verifDigit, 10) !== 0 && differential === parseInt(verifDigit, 10);
  const b_solution = parseInt(verifDigit, 10) === 0 && differential === 10;
  return a_solution || b_solution;
};

export default {
  getBankByCBU(CBU = '') {
    const cleanCBU = CBU.replace(/[^0-9]/g, '');
    const prefix = cleanCBU.substr(0, 3);
    return CBUMap[prefix] || {};
  },

  getBankNameByCBU(CBU = '') {
    const cleanCBU = CBU.replace(/[^0-9]/g, '');
    const prefix = cleanCBU.substr(0, 3);
    const bankName = BankName.find(bank => bank.id === prefix);
    return bankName ? bankName.name : {};
  },

  isValidCBU(CBU = '') {
    const cleanCBU = CBU.replace(/[^0-9]/g, '');
    const prefix = cleanCBU.substr(0, 3);
    const bankData = CBUMap[prefix];
    const bankName = BankName.find(bank => bank.id === prefix);
    const validLength = validCBUlength(cleanCBU);
    const validFirstBlock = firstBlockValidate(cleanCBU);
    const validSecondBlock = secondBlockValidate(cleanCBU);

    if (validLength && validFirstBlock && validSecondBlock) {
      return { valid: true, bankData, bankName };
    }

    return { valid: false, bankData, bankName, validLength, validFirstBlock, validSecondBlock };
  }
};
