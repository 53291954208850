import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import DepositActions from '../../redux/DepositRedux';

import DepositSummary from '../../components/DepositSummaryBR';
import DepositFinish from '../../components/DepositFinishBR';

const DepositSummaryPage = ({ history, user, kyc, generateDepositTicket, ticket }) => {
  const [step, setStep] = useState('summary');
  useEffect(() => {
    if (!kyc || !user) return;
    const data = {
      nationalId: kyc.nationalId,
      assetName: user.originAsset,
      amount: user.checkoutAmount,
      feeAmount: user.feeAmount,
      method: 'bank-transfer',
      countryCode: kyc.country,
      userEmail: user.email,
      userName: kyc.name,
      userLastname: kyc.lastname
    };

    generateDepositTicket(data);
  }, []);

  return (
    <React.Fragment>
      {!kyc && <Redirect to="/" />}
      {kyc && step === 'summary' && <DepositSummary setStep={setStep} {...{ history, user, kyc, ticket }} />}
      {ticket && step === 'finish' && (
        <DepositFinish
          onClick={() => (window.parent.location.href = user.callbackUrl)}
          {...{ history, user, kyc, ticket }}
        />
      )}
      {ticket && step === 'checkStatus' && <Redirect to="/status/check" />}
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    user: state.user.user,
    kyc: state.user.kyc,
    ticket: state.deposit.ticket
  };
};

const mapDispatchToProps = dispatch => {
  return {
    generateDepositTicket: data => dispatch(DepositActions.generateDepositTicket(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DepositSummaryPage);
