import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import DepositActions from '../../redux/DepositRedux';

import DepositSummary from '../../components/DepositSummary';
import DepositFinish from '../../components/DepositFinish';

const DepositSummaryPage = ({
  history,
  user,
  isBrokerCheckout,
  kyc,
  generateDepositTicket,
  ticket,
  match,
  t,
  getBanks,
  banks
}) => {
  const [step, setStep] = useState('summary');
  const { paymentMethod: method, provider } = match.params;
  useEffect(() => {
    if (!kyc || !user || !match.params.paymentMethod) return;
    const data = {
      nationalId: kyc.nationalId,
      assetName: user.originAsset,
      amount: user.checkoutAmount,
      feeAmount: user.feeAmount,
      method,
      provider,
      countryCode: kyc.country,
      userEmail: user.email,
      userName: kyc.name,
      userLastname: kyc.lastname,
      providerPSP: user.providerPSP,
      isBrokerCheckout
    };

    generateDepositTicket(data);
  }, []);

  useEffect(() => {
    if (kyc && provider === 'bank') getBanks();
  }, [provider, kyc, getBanks]);

  return (
    <React.Fragment>
      {!kyc && <Redirect to="/" />}
      {kyc && step === 'summary' && (
        <DepositSummary
          setStep={setStep}
          {...{ history, user, kyc, ticket, t, method, provider, bank: banks && banks.length && banks[0] }}
        />
      )}
      {ticket && step === 'finish' && (
        <DepositFinish
          onClick={() => (window.parent.location.href = user.callbackUrl)}
          {...{ history, user, kyc, ticket }}
        />
      )}
      {ticket && step === 'checkStatus' && <Redirect to="/status/check" />}
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    user: state.user.user,
    isBrokerCheckout: state.user.isBrokerCheckout,
    kyc: state.user.kyc,
    ticket: state.deposit.ticket,
    banks: state.deposit.banks
  };
};

const mapDispatchToProps = dispatch => {
  return {
    generateDepositTicket: data => dispatch(DepositActions.generateDepositTicket(data)),
    getBanks: () => dispatch(DepositActions.getAvailableBanksRequest())
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(DepositSummaryPage));
