import React, { useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';

import AuthActions from '../../../redux/AuthRedux';

import './styles.scss';

const LogoutContainer = styled.div`
  .logout {
    color: ${props => props.theme.linkColor};
  }
  .email {
    color: ${props => props.theme.darkTextColor};
  }
`;

const LogoutLogo = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="17" viewBox="0 0 13 17" className="logout ml-2">
    <g fill={color}>
      <path d="M7.5 0c.276 0 .5.224.5.5 0 .245-.177.45-.41.492L7.5 1h-4C2.175 1 1.09 2.032 1.005 3.336L1 3.5v10c0 1.325 1.032 2.41 2.336 2.495L3.5 16h4c.276 0 .5.224.5.5 0 .245-.177.45-.41.492L7.5 17h-4C1.631 17 .105 15.536.005 13.692L0 13.5v-10C0 1.631 1.464.105 3.308.005L3.5 0h4z" />
      <path d="M12.486 7.922c.276 0 .5.224.5.5 0 .246-.177.45-.41.492l-.09.008H4.5c-.276 0-.5-.224-.5-.5 0-.245.177-.45.41-.492l.09-.008h7.986z" />
      <path d="M9.278 5.143c.175-.172.445-.188.638-.051l.069.058 2.859 2.922c.166.17.187.43.062.622l-.062.078-2.86 2.922c-.192.197-.509.2-.706.007-.176-.171-.198-.44-.065-.637l.057-.07 2.516-2.572L9.27 5.85c-.172-.176-.188-.445-.05-.639l.058-.068z" />
    </g>
  </svg>
);

function LogoutButton({ isBrokerCheckout, t }) {
  const dispatch = useDispatch();
  const email = useSelector(state => state.user.user && state.user.user.email);
  const callbackUrl = useSelector(state => state.user.user && state.user.user.callbackUrl) || 'https://www.latamex.com';
  const authenticated = useSelector(state => state.auth.authenticated);
  const guestUser = useSelector(state => state.auth.guestUser);
  const logout = useCallback(() => dispatch(AuthActions.logout(callbackUrl)), [callbackUrl, dispatch]);
  const theme = useTheme();

  return authenticated && email && !isBrokerCheckout && !guestUser ? (
    <LogoutContainer className="logout-container">
      <LogoutLogo className="logout ml-2" onClick={logout} color={theme.linkColor} />
      <span className="logout ml-2" onClick={logout}>
        {callbackUrl === '/#/application/close' ? t('account.returnToAccount') : t('account.logout')}
      </span>
    </LogoutContainer>
  ) : null;
}

export default withTranslation()(LogoutButton);
