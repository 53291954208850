import React from 'react';
import { connect } from 'react-redux';

import FcDepositMethodComponent from '../../../components/Fastcash/FcDepositMethod';

import DepositActions from '../../../redux/DepositRedux';
import FastcashActions from '../../../redux/FastcashRedux';

const FcDepositMethod = ({
  history,
  user,
  paymentMethod,
  setPaymentMethod,
  termsAndConditions,
  setTermsAndConditions
}) => (
  <FcDepositMethodComponent
    {...{
      history,
      user,
      paymentMethod,
      setPaymentMethod,
      termsAndConditions,
      setTermsAndConditions
    }}
  />
);

const mapStateToProps = state => {
  return {
    user: state.user.user,
    paymentMethod: state.fastcash.paymentMethod,
    termsAndConditions: state.fastcash.termsAndConditions
  };
};

const mapDispatchToProps = dispatch => {
  return {
    checkoutRequest: data => dispatch(DepositActions.getDepositStatus(data)),
    setPaymentMethod: paymentMethod => dispatch(FastcashActions.setPaymentMethod(paymentMethod)),
    setTermsAndConditions: agreed => dispatch(FastcashActions.setTermsAndConditions(agreed))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FcDepositMethod);
