export default {
  crypto: {
    NEO: 0,
    ONT: 0,
    TRX: 6,
    BTC: 8,
    LTC: 8,
    ETH: 8,
    BNB: 8,
    XLM: 2,
    XRP: 2,
    EOS: 2,
    USDT: 2,
    TUSD: 2,
    USDC: 2,
    DAI: 2,
    ARST: 2,
    BRLT: 2,
    RIF: 8
  }
};
