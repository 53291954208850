export default {
  'account.cancel': 'Cancelar',
  'account.changePasswordSuccess': 'Senha mudada com sucesso',
  'account.createAccountSuccess': 'Conta criada com sucesso!',
  'account.insertEmail.enterYourEmail': 'Insira seu e-mail',
  'account.insertEmail.error': 'O e-mail já está sendo usado',
  'account.insertEmail.invalidEmail': 'e-mail inválido',
  'account.insertEmail.login': 'Já tem uma conta Settle ID? Iniciar sessão',
  'account.insertEmail.subtitle': 'Para criar sua Settle ID, precisamos verificar seu email.',
  'account.insertEmail.title': 'Crie sua Settle ID para ingressar na {{companyName}}',
  'account.insertEmail.whatIsSettleId': 'O que é Settle ID?',
  'account.insertEmail.settleIdPopup':
    'É uma conta única para acessar diferentes plataformas, como Latamex e Stablex, sem ter que preencher todos os seus dados novamente. Em breve você poderá usá-lo para entrar em outras plataformas parceiras.<br /><br />Agora o acesso à sua conta Latamex e Stablex é feito através do Settle ID. Você não precisa fazer nada novo, se você já tiver uma conta, seu e-mail e senha Latamex ou Stablex se tornarão seu ID do Settle. Se você não tiver uma conta, precisará se registrar, isso levará apenas alguns minutos.',
  'account.insertEmail.OK': 'Entendido',
  'account.createAnAccount': 'Não tem um Settle ID? Criar uma conta',
  'account.insertPassword.checkPassword': 'Verifique sua senha',
  'account.insertPassword.createAPassword': 'Settle ID - Crie uma senha',
  'account.insertPassword.enterPassword': 'Digite sua senha',
  'account.insertPassword.passwordRequirements': 'Sua senha deve ter pelo menos 8 caracteres, uma letra e um número',
  'account.insertPassword.passwordsDoNotMatch': 'As senhas não correspondem',
  'account.insertPassword.repeatedPasswordError': 'A senha foi usada anteriormente',
  'account.insertPassword.repeatPassword': 'Repetir a senha',
  'account.login.enterYourEmail': 'Insira seu e-mail',
  'account.login.subtitle': 'Insira com o seu Settle ID',
  'account.login.enterYourPassword': 'Coloque sua senha',
  'account.login.enterLatamex': 'Entrar na {{companyName}}',
  'account.login.forgetPassword': 'Esqueci minha senha',
  'account.login.invalidCredentials': 'Credenciais inválidas',
  'account.logout': 'Sair da sessão',
  'account.returnToAccount': 'Voltar para minha conta',
  'account.continueAsAGuest': 'Continuar como convidado',
  'account.next': 'Seguinte',
  'account.passwordRecovery.error': 'Conta não encontrada',
  'account.passwordRecovery.login': 'Iniciar sessão',
  'account.passwordRecovery.subtitle': 'Digite seu e-mail, você receberá um código para redefinir sua senha.',
  'account.passwordRecovery.title': 'Settle ID - Redefina sua senha',
  'buttons.addAccount': 'Adicionar Conta',
  'buttons.confirm': 'Confirmw',
  'buttons.deposit': 'Comprar',
  'buttons.done': 'Feito',
  'buttons.next': 'Seguinte',
  'buttons.withdraw': 'Vender',
  'checkoutForm.buyButton': 'Comprar',
  'checkoutForm.sellButton': 'Vender',
  'checkoutForm.checkWalletAddress': 'Verifique o endereço da sua carteira',
  'checkoutForm.checkWalletAddressFederation': 'Verifique o seu endereço federado',
  'checkoutForm.doNotHaveWallet': 'Não tens carteira?',
  'checkoutForm.enterHigherValue': 'O valor mínimo de compra é {{fiatAsset}} {{minAmount}}',
  'checkoutForm.enterLowerValue': 'Entrar um valor inferior',
  'checkoutForm.enterValidEmail': 'O e-mail não é válido',
  'checkoutForm.enterYourAmount': 'Digite o valor',
  'checkoutForm.enterYourEmail': 'Digite seu e-mail',
  'checkoutForm.enterYourWallet': 'Digite sua carteira',
  'checkoutForm.includedFees': 'Taxas incluídas',
  'checkoutForm.minAmountError': 'O valor mínimo de compra é {{fiatAsset}} {{minAmount}}',
  'checkoutForm.minAmountError.withdraw': 'O valor mínimo de venta é {{cryptoAsset}} {{minAmount}}',
  'checkoutForm.mustEnterValue': 'É necessário entrar algum valor',
  'checkoutForm.flatFee': ' + taxa fixa {{fiatAsset}} {{flatFee}}',
  'checkoutForm.minFee': ' (ou {{fiatAsset}} {{minFee}} mín)',
  'checkoutForm.feeAndTermsCondDisclaimer':
    'O preço inclui as despesas do processador de pagamento desde {{fee}}%{{minFee}}{{flatFee}}. Continuando, você aceita os <1>termos e condições</1>.',
  'checkoutForm.termsCondDisclaimer': 'Continuando, você aceita os <1>termos e condições</1>.',
  'checkoutForm.tooltip':
    'Preço com base na cotação atual. A <br/>cotação final pode variar assim que seu <br/>pagamento for creditado. Depois que seu <br/>pagamento for recebido, um e-mail será <br/>enviado a você para confirmar a <br/>transação com a taxa final.',
  'checkoutForm.wantToBuy': 'Quero comprar',
  'checkoutForm.wantToSpend': 'Eu quero gastar',
  'checkoutForm.wantToSell': 'Quero vender',
  'checkoutForm.wantToReceive': 'Quero receber',
  'commons.footer.help': 'Precisa de ajuda?',
  'commons.footer.limits': 'Limites e Comissoes',
  'commons.footer.privacy-policy': 'Política de Privacidade',
  'commons.footer.rates': '',
  'commons.footer.terms-and-conditions': 'Termos e Condições',
  'confirmErrorPage.checkStatus': 'Verifique o status do serviço',
  'confirmErrorPage.description':
    'Nossa equipe foi notificada. Analisaremos seu pedido e enviaremos o link de confirmação para seu e-mail novamente.',
  'confirmErrorPage.dontWorry': 'Não se preocupe!',
  'confirmErrorPage.meantime': 'Enquanto isso, você pode:',
  'confirmErrorPage.visitHelpCenter': 'Visite nossa Central de Ajuda',
  'confirmPage.actualPriceLabel': 'Preço atual',
  'confirmPage.alreadyExecuted.deposit':
    'Seu <strong>{{destinationAssetError}}</strong> foram enviados a sua carteira <strong>{{destinationWalletAddressError}}</strong>',
  'confirmPage.alreadyExecuted.withdraw': 'Seu <strong>{{destinationAssetError}}</strong> foram enviados a sua conta',
  'confirmPage.alreadyExecuted_transferPending.deposit':
    'Seu <strong>{{destinationAssetError}}</strong> serão enviados para o seu endereço em breve <strong>{{destinationWalletAddressError}}</strong>.',
  'confirmPage.alreadyExecuted_transferPending.withdraw':
    'Seu <strong>{{destinationAssetError}}</strong> serão enviados para o sua conta em breve.',
  'confirmPage.btn': 'Confirme',
  'confirmPage.btnNewOrder': 'Criar novo pedido',
  'confirmPage.completedError': 'Ops ... não foi possível processar sua transação',
  'confirmPage.completedSuccess':
    'Seu<br /><strong>{{destinationAsset}} {{cryptoAmount}}</strong><br />Está a caminho do endereço<br /><strong>{{destinationWalletAddress}}</strong>',
  'confirmPage.completedSuccess_transferPending':
    'Recebemos sua confirmação.<br />Seu<br /><strong>{{cryptoAmount}} {{destinationAsset}}</strong><br />Serão enviados para o seu endereço em breve<br /><strong>{{destinationWalletAddress}}</strong>',
  'confirmPage.completedSuccess_userConfirmed':
    'Recebemos sua confirmação.<br />Seu<br /><strong>{{cryptoAmount}} {{destinationAsset}}</strong><br />Serão enviados para o seu endereço em breve<br /><strong>{{destinationWalletAddress}}</strong>',
  'confirmPage.completedSuccess.hash': 'Este é o seu TX ID:',
  'confirmPage.loading': 'Carregando...',
  'confirmPage.payStatusAccepted': 'credenciado',
  'confirmPage.payStatusLabel': 'Status do seu pagamento:',
  'confirmPage.thanks': 'Obrigado!',
  'confirmPage.thanksOperatingWithUs': 'Obrigado por operar conosco :)',
  'confirmPage.title': 'Confirme seu pedido Nº {{confirmId}}',
  'confirmPage.updatingIn': 'Atualização em',
  'confirmPage.yourCryptoAddress': 'Seu endereço {{crypto}}',
  'confirmPage.yourOrderLabel': 'Seu pedido',
  consult: '',
  'country.AR': 'Argentina',
  'country.BR': 'Brasil',
  'country.MX': 'México',
  'currencies.ARS': 'Argentinean Peso',
  'currencies.USD': 'Dólar americano',
  'currency.country.AR': 'ARS',
  'currency.country.BR': 'BRL',
  'currency.country.MX': 'MXN',
  'deposit.addAccount.description': 'Você só pode adicionar uma conta com o mesmo nome que você completou na sua KYC.',
  'deposit.addAccount.placeholder.accountCBU': 'conta CBU',
  'deposit.addAccount.placeholder.holderLastName': 'titular da conta Sobrenome',
  'deposit.addAccount.placeholder.holderName': 'Nome (s) titular da conta',
  'deposit.addAccount.title': 'Adicionar uma conta bancária',
  'deposit.cardConfirmationStep.endingText': '{{CardBrand}} termina em {{last4Digits}}',
  'deposit.cardConfirmationStep.title': 'depósito de cartão de crédito',
  'deposit.cardInfoStep.placeholder.cardNumber': 'Número do cartão',
  'deposit.cardInfoStep.placeholder.expirationMonth': 'Mês de validade',
  'deposit.cardInfoStep.placeholder.expirationYear': 'Vencimento Ano',
  'deposit.cardInfoStep.placeholder.holderName': 'Primeiro e último nome',
  'deposit.cardInfoStep.placeholder.securityCode': 'Código de segurança',
  'deposit.cardInfoStep.title': 'Detalhes do cartão de crédito',
  'deposit.cashInfoStep.description':
    'Por favor, vá a qualquer um dos locais de rede de caixa de varejo e pagar ao caixa usando o código de pagamento followiing.',
  'deposit.cashInfoStep.title': 'detalhes depósito em dinheiro',
  'deposit.cashSelectStep.pagofacil': 'Pago Fácil',
  'deposit.cashSelectStep.rapipago': 'Rapipago',
  'deposit.cashSelectStep.subtitle': 'Selecione a rede dinheiro que você deseja usar.',
  'deposit.cashSelectStep.title': 'detalhes depósito em dinheiro',
  'deposit.finishStep.actionBtn': 'Concluir',
  'deposit.finishStep.question': '',
  'deposit.finishStep.textFour':
    'Seu pedido será válido por 48 horas. Após esse tempo sem receber o pagamento, o pedido será cancelado automaticamente.',
  'deposit.finishStep.textOne':
    'Os detalhes da sua ordem de pagamento foram recebidos corretamente.<br />Enviaremos um detalhe para o seu e-mail.',
  'deposit.finishStep.textThree':
    'Os pagamentos recebidos após o horário do banco serão processadas quando o horário do banco for retomado (de segunda a sexta-feira, das 11h às 16h).',
  'deposit.finishStep.textTwo':
    'Ao verificar o credenciamento do seu pagamento, enviaremos um e-mail para confirmar seu pedido com o preço atual.',
  'deposit.finishStep.title': 'Obrigado, {{name}}!',
  'deposit.identityStep.placeholder': 'CPF #',
  'deposit.identityStep.subtitle': 'Por favor, indique o seu número do CPF completo.',
  'deposit.identityStep.title': 'Detalhes do cartão de crédito',
  'deposit.stepFive.hasAccount':
    'Por favor, confirme que você estará enviando {{currency}} {{amount}} de sua conta seguinte:',
  'deposit.stepFive.title': 'detalhes de depósito',
  'deposit.stepFive.withoutAccount':
    'Não há nenhuma conta bancária vinculada no seu perfil, por favor, adicione pelo menos uma conta bancária, para que possamos identificar suas transferências recebidas',
  'deposit.stepFour.subtitleOne': 'Por favor, faça uma única transferência de',
  'deposit.stepFour.subtitleTwo': 'para a seguinte conta',
  'deposit.stepFour.title': 'Detalhes depósito',
  'deposit.stepOne.radioOne': 'Peso argentino',
  'deposit.stepOne.radioTwo': 'Dólar americano',
  'deposit.stepOne.subtitle': 'Escolha a moeda que deseja depositar',
  'deposit.stepOne.title': 'FIAT Deposit',
  'deposit.stepSix.message':
    'O saldo da conta será atualizado uma vez que recebemos o seu pagamento e iremos notificar por e-mail. Você sempre pode verificar seu status de depósito em sua conta.',
  'deposit.stepSix.subtitle': 'Seus detalhes da transferência foram submetidos com sucesso!',
  'deposit.stepSix.title': 'Obrigado!',
  'deposit.stepThree.subtitle': 'Digite o valor que deseja financiar',
  'deposit.stepThree.title': 'Valor a depositar',
  'deposit.stepTwo.radioOne': 'Conta bancária',
  'deposit.stepTwo.radioThree': 'Cartão de crédito',
  'deposit.stepTwo.radioTwo': 'Depósito em dinheiro',
  'deposit.stepTwo.subtitle': 'Escolha o seu método de pagamento',
  'deposit.stepTwo.title': 'Comprar ZARS Coin Estável.',
  'emailCode.cancel': 'Cancelar',
  'emailCode.correctCode': 'Codigo correto!',
  'emailCode.incorrectCode': 'Código errado',
  'emailCode.next': 'Próxima',
  'emailCode.onlyNumbersError': 'Erro, você só pode digitar números!',
  'emailCode.reSendCode': 'Reenviar código',
  'emailCode.reSendCodeError': 'Erro no envio do código!',
  'emailCode.reSendCodeSuccess': 'O código foi enviado com sucesso!',
  'emailCode.text':
    'Olá <strong>{{email}}</strong>, para continuar por favor introduza o código de 4 dígitos que lhe enviámos para o seu e-mail. (Verifique o seu spam apenas no caso de)',
  'emailCode.recoveryText':
    '<strong>{{email}}</strong> recebemos o seu pedido. Você receberá um código caso o email digitado corresponda a uma conta Settle ID. (Verifique o seu spam apenas no caso de)',
  'emailCode.title': 'Settle ID - Verifique o seu e-mail',
  'errorPage.DepositsExisting.contact': 'Entre em contato com o suporte',
  'errorPage.DepositsExisting.description.deposit':
    'Para fazer um novo pedido você deve fazer o pagamento do seu pedido pendente.<br /><br /> Se você precisar cancelar, entre em <0>contato com o suporte</0>.',
  'errorPage.DepositsExisting.description.withdraw':
    'Para fazer um novo pedido, você deve transferir seu pedido pendente.',
  'errorPage.DepositsExisting.title.deposit': 'Já existe um pedido pendente em seu nome!',
  'errorPage.DepositsExisting.title.withdraw': 'Já existe 1 pedido pendente em seu nome!',
  'errorPage.AmountTooLow.title': 'O valor é muito baixo',
  'errorPage.AmountTooLow.description':
    'O valor digitado está abaixo do mínimo permitido. Crie um novo pedido para pelo menos <strong>{{fiat-asset}} {{fiat-minimum}}</strong>.',
  'errorPage.WrongAsset.title': 'Moeda incorreta',
  'errorPage.WrongAsset.description':
    'A moeda que você selecionou <strong>{{fiat-asset}}</strong> não corresponde ao país com o qual você realizou sua verificação de identidade <strong>{{user-country}}</strong>.',
  'errorPage.SessionConflict.title': 'Erro de sessão',
  'errorPage.SessionConflict.description':
    'Esta sessão encontrou um conflito e não pode continuar. Não se preocupe, se você já criou um pedido, receberá os dados por e-mail, caso não tente criar um novo pedido. Caso você veja esse erro novamente, entre em contato com a equipe de suporte.',
  'errorPage.Cookies.title': 'Erro de cookies',
  'errorPage.Cookies.description':
    'Os cookies do seu navegador estão bloqueados ou não estão disponíveis. Verifique se eles estão ativados e tente novamente. Se você vir esse erro novamente, entre em contato com o suporte.',
  'errorPage.retryOrContact': 'Digite novamente seu pedido ou entre em contato com <0> support </0>',
  'errorPage.somethingWrong': 'Parece que algo deu errado',
  'errorPage.title': 'Oops!',
  'errorPage.return': 'Retorna',
  exit: 'Sair',
  'fastcash.fcDepositBankTransfer.account.label': 'Informe sua conta, com dígito.',
  'fastcash.fcDepositBankTransfer.agency.label': 'Informe sua agência, sem dígito',
  'fastcash.fcDepositBankTransfer.bank.label': 'Escolha o seu banco.',
  'fastcash.fcDepositBankTransfer.description':
    'Realize uma transferência por e-banking, telefone ou caixa eletrônico.',
  'fastcash.fcDepositBankTransfer.error':
    'Ups! Algo correu mal. Por favor, tente novamente e se o problema persistir entre em contato com o suporte.',
  'fastcash.fcDepositBankTransfer.nextButton': 'Próxima',
  'fastcash.fcDepositBankTransfer.title': 'Transferência bancária',
  'fastcash.fcDepositCash.description':
    'Escolha um local para realizar o depósito, Não é necessário ser correntista. Escolha a agência ou terminal de sua preferência.',
  'fastcash.fcDepositCash.error':
    'Ups! Algo correu mal. Por favor, tente novamente e se o problema persistir entre em contato com o suporte.',
  'fastcash.fcDepositCash.nextButton': 'Próxima',
  'fastcash.fcDepositCash.subPaymentMethod.label': 'Escolha o seu local',
  'fastcash.fcDepositCash.title': 'Depósito de dinheiro',
  'fastcash.fcDepositDetails.account.label': 'Conta-Corrente',
  'fastcash.fcDepositDetails.accountHolder.label': 'Favorecido',
  'fastcash.fcDepositDetails.agency.label': 'Agência',
  'fastcash.fcDepositDetails.bank.label': 'Banco',
  'fastcash.fcDepositDetails.bankTransferDescription':
    'Para efetuar o pagamento realize uma transeferência pelo internet banking, celular, phone banking ou caixa eletrônico para:',
  'fastcash.fcDepositDetails.bankTransferTitle': 'Dados para transferência',
  'fastcash.fcDepositDetails.cashDescription':
    'Para efetuar o pagamento deve ir a qualquer agência do banco Lotéricas e fazer um depósito com os seguintes dados:',
  'fastcash.fcDepositDetails.cashTitle': 'Dados para depósito',
  'fastcash.fcDepositDetails.copyButton.notPushed': 'copiar',
  'fastcash.fcDepositDetails.copyButton.pushed': 'copiado',
  'fastcash.fcDepositDetails.homeBankingLegend': 'Acesse seu internet banking',
  'fastcash.fcDepositDetails.nextButton': 'Seguinte',
  'fastcash.fcDepositDetails.total.label': 'Total: ',
  'fastcash.fcDepositFinish.description1':
    'Os dados da sua ordem de pagamento foram recebidos corretamente. Seu pedido será processado assim que recebermos a transferência dos fundos. Após transferir o dinheiro, informe os dados do comprovante.',
  'fastcash.fcDepositFinish.description2':
    'Enviaremos um e-mail para informá-lo sobre qualquer alteração de status em seu pedido.',
  'fastcash.fcDepositFinish.fee.label': 'taxa e impostos',
  'fastcash.fcDepositFinish.from.label': 'De',
  'fastcash.fcDepositFinish.idLabel': 'ID #',
  'fastcash.fcDepositFinish.nextButton': 'Comprovar pagamento',
  'fastcash.fcDepositFinish.order.label': 'Solicitação de pagamento',
  'fastcash.fcDepositFinish.title': 'Obrigado {{name}}!',
  'fastcash.fcDepositFinish.total.from': 'De',
  'fastcash.fcDepositFinish.total.label': 'Total',
  'fastcash.fcDepositMethod.bankTransfer': 'Transferência bancária',
  'fastcash.fcDepositMethod.cash': 'Deposito',
  'fastcash.fcDepositMethod.description1': 'Pague R ',
  'fastcash.fcDepositMethod.description2': ' para finalizar seu pedido',
  'fastcash.fcDepositMethod.footerLegend':
    'Se você tiver dúvidas sobre seu pedido, entre em contato com a equipe de suporte',
  'fastcash.fcDepositMethod.nextButton': 'Próxima',
  'fastcash.fcDepositMethod.termsAndConditionsCheck': 'Aceito os termos e condições',
  'fastcash.fcDepositMethod.title': 'Selecione a forma de pagamento',
  'fastcash.fcDepositVerification.amount.label': 'quantidade',
  'fastcash.fcDepositVerification.description': 'Para comprovar o pagamento do seu pedido insira os dados abaixo',
  'fastcash.fcDepositVerification.error':
    'Ups! Something went wrong. Please re-try again and if the problem persists contact to support.',
  'fastcash.fcDepositVerification.nextButton': 'Comprovar pagamento',
  'fastcash.fcDepositVerification.orderNumber.label': 'Nº do Pedido',
  'fastcash.fcDepositVerification.reference.help':
    'Você pode encontrar o número do seu pedido no correio recebido ao criar o pedido.',
  'fastcash.fcDepositVerification.reference.label': 'Referência #',
  'fastcash.fcDepositVerification.supportContact':
    'Se você tiver dúvidas sobre seu pedido, entre em contato com a equipe de suporte',
  'fastcash.fcDepositVerification.title': 'Comprovar pagamento',
  'fastcash.fcDepositVerificationSuccess.description1': 'Obrigado pela comprovação',
  'fastcash.fcDepositVerificationSuccess.description2':
    'Você receberá um email assim que recebermos do banco a compensação do seu pagamento.',
  'fastcash.fcDepositVerificationSuccess.description3':
    'Atenção: depósitos por envelope demoram até o final do próximo dia util para serem compensados.',
  'fastcash.fcDepositVerificationSuccess.nextButton': 'Verificar status',
  'fastcash.fcDepositVerificationSuccess.supportContact':
    'Se você tiver dúvidas sobre seu pedido, entre em contato com a equipe de suporte',
  'fastcash.fcDepositVerificationSuccess.title': 'Obrigado {{name}}!',
  finalizar: 'Finalizar',
  'instructions.copied': 'copiado',
  'instructions.copy': 'copiar',
  'instructions.description.bank.bankTransfer':
    'Faça uma transferência bancária de uma conta em seu nome <strong> {{name}} {{lastname}}, </strong> no <strong>valor exato</strong> solicitado e inclua o <strong>número de referência</strong> do seu pedido, caso permitido pelo seu banco, para que possamos identificar melhor seu pagamento. Se o seu banco não permitir que você inclua o número de referência, você poderá ignorá-lo.',
  'instructions.description.rapipago.cashNetwork': '',
  'instructions.details.CBU': '',
  'instructions.details.account': 'Conta-Corrente',
  'instructions.details.agency': 'Agência',
  'instructions.details.alias': '',
  'instructions.details.amount': 'Total',
  'instructions.details.bank': 'Banco',
  'instructions.details.banknumber': 'Numero de Banco',
  'instructions.details.cbu': '',
  'instructions.details.clabe': '',
  'instructions.details.favored': 'Favorecido',
  'instructions.details.cnpj': 'Chave PIX - CNPJ',
  'instructions.details.company': '',
  'instructions.details.cuit': '',
  'instructions.details.recipient': 'Favorecido',
  'instructions.details.refnumber': 'Nº Referência',
  'instructions.details.rfc': '',
  'instructions.title.bank.bankTransfer': 'Dados para transferência',
  'instructions.title.rapipago.cashNetwork': '',
  'instructions.tooltip':
    'Inclua o número de referência do seu pedido, caso permitido pelo seu banco. Se o seu banco não permitir, você poderá ignorá-lo.',
  kycTitle: 'Verificação de identidade',
  'limitEnforcement.addDocs.description':
    'Anexe os seguintes comprovantes conforme apropriado para aumentar seu limite e processar seu pedido:',
  'limitEnforcement.addDocs.fileRequestList':
    '- Comprovante de renda: declaração de imposto sobre os rendimentos do último ano, ou contra-cheque de pagamento<br />- Declaração de propriedade pessoal, se aplicável<br />- Origem e licitude do capital',
  'limitEnforcement.addDocs.title': 'Aumente o limite',
  'limitEnforcement.addDocs.uploadText': 'Selecione arquivos ou arraste aqui',
  'limitEnforcement.checkStatus.description': '',
  'limitEnforcement.checkStatus.footer': 'Si voce tiver duvidas ',
  'limitEnforcement.checkStatus.footer.link': 'contacte com a equipe de suporte',
  'limitEnforcement.checkStatus.title': '',
  'limitEnforcement.limitExceeded.availableAnual': 'Limite anual remanescente',
  'limitEnforcement.limitExceeded.availableBiannual': 'Limite semestral remanescente',
  'limitEnforcement.limitExceeded.availableDaily': 'Limite diário remanescente',
  'limitEnforcement.limitExceeded.availableMonthly': 'Limite mensual remanescente',
  'limitEnforcement.limitExceeded.contactSupport': 'Contacte-me para pedir um aumento de limite',
  'limitEnforcement.limitExceeded.contactSupport.link': 'https://{{branding}}.zendesk.com/hc/pt/requests/new',
  'limitEnforcement.limitExceeded.description':
    'Superou o limite anual ou diário. Você pode criar uma nova ordem para uma quantidade inferior ou solicitar um aumento do limite de nossa equipe de suporte',
  'limitEnforcement.limitExceeded.minorOrderButton': 'Criar novo pedido',
  'limitEnforcement.limitExceeded.title': 'Excedeu o seu limite',
  'limitEnforcement.limitExceeded.yourOrder': 'sua encomenda',
  'limitEnforcement.orderStatus.executed': 'CONCLUÍDO',
  'limitEnforcement.orderStatus.expired': 'EXPIRADO',
  'limitEnforcement.orderStatus.pending': 'PENDENTE',
  'limitEnforcement.orderStatus.rejected': 'REJEITADO',
  'limitEnforcement.uploadSuccess.button': 'Voltar para {{companyName}}',
  'limitEnforcement.uploadSuccess.description':
    'Suas informações foram enviadas corretamente.<br />Nossa equipe está iniciando o processo de revisão necessário para aumentar seus limites operacionais.<br /><br />Depois de verificarmos a documentação, enviaremos um e-mail informando sobre o resultado da sua solicitação.',
  'paymentMethod.bankTransfer': 'Transferência bancária',
  'paymentMethod.cash': 'Deposito',
  'paymentMethod.description1': '',
  'paymentMethod.description2': '',
  'paymentMethod.escription1': 'Pague R ',
  'paymentMethod.escription2': ' para finalizar seu pedido',
  'paymentMethod.footerLegend': 'Se você tiver dúvidas sobre seu pedido, entre em contato com a equipe de suporte',
  'paymentMethod.nextButton': 'Próxima',
  'paymentMethod.title': 'Selecione a forma de pagamento',

  'paymentMethod.notEnabled.limit':
    'Límite alcanzado.<br/> Límite Anual = {{annualRemaining}} / {{annualLimit}} <br/> Límite Diario = {{dailyRemaining}} / {{dailyLimit}}',

  send: 'Enviar',
  'status.checkStatus.Deposit': 'Depósito',
  'status.checkStatus.Withdraw': 'Extração',
  'status.checkStatus.checkButton': 'Verificar',
  'status.checkStatus.description':
    'Introduza o número do pedido (verifique nos e-mails que lhe enviamos) e o seu ID para verificar se a seu pedido está pendente, concluído ou rejeitado.',
  'status.checkStatus.documentId.label': 'CPF #',
  'status.checkStatus.errorMessage': 'Por favor, verifique se o seu número do pedido e ID estão corretos.',
  'status.checkStatus.footer': 'Se tiver dúvidas sobre o estado do seu pedido',
  'status.checkStatus.footer.link': 'Entre em contato com a equipe de suporte',
  'status.checkStatus.orderNumber.label': 'Número do pedido',
  'status.checkStatus.status.title': 'Status do pedido',
  'status.checkStatus.title': 'Verifique o status do seu pedido',
  'paymentMethod.termsAndConditionsCheck': 'Aceito os',
  'paymentMethod.termsAndConditionsCheckAnd': 'e',
  'paymentMethod.Rapipago': 'Efectivo - Rapipago',
  'welcome.welcomeScreen.Deposit': 'Resumo da sua ordem de pagamento',
  'welcome.welcomeScreen.Withdraw': 'Resumo do seu pedido de vendas',
  'welcome.title': 'Seu pedido {{companyName}}',
  'welcome.description.Deposit': 'Verifique todos os dados antes de continuar!',
  'welcome.description.Withdraw': 'Verifique todos os dados antes de continuar!',
  'welcome.order.Deposit': 'Ordem de pagamento',
  'welcome.order.Withdraw': 'Pedido total de vendas',
  'welcome.processorCharge': 'Taxa do processador',
  'welcome.flatFee': 'Taxa fija:',
  'welcome.varFee': 'Taxa variável:',
  'welcome.feeWithMin': '(taxa de {{fee}}% ou mínimo {{currency}} {{minFee}})',
  'welcome.fee': '(taxa de {{fee}}%)',
  'status.checkStatus.type': 'Tipo de Transação',
  'welcome.address': 'Seu endereço {{addressType}}',
  'warningInfo.checkText': 'Confirmo que li e compreendi esta informação',
  'warningInfo.disclaimer':
    'Se você não cumprir todas essas indicações, seu pagamento não poderá ser identificado corretamente e poderá atrasar, dificultar ou extraviar sua transação.',
  'warningInfo.title': 'Informações muito importantes sobre o seu pagamento',
  'welcome.addressConfirm': 'Confirmo que as informações da minha carteira estão corretas',
  'welcome.cancelAction': 'Cancelar o pedido',
  'welcome.description': 'Verifique todos os dados antes de continuar',
  'welcome.paymentOrder': 'Ordem de pagamento',
  'welcome.termsAndCond': 'Aceito os <0>termos e condições</0> e <1>política de privacidade</1>',
  'welcome.termsAndCondDesc':
    'Seu pedido será processado por um processador de pagamento local.<br/>Antes de continuar, você deve aceitar os termos e condições.',
  'welcome.startBtn': 'Seguinte',
  'welcome.processorFeeDisclaimer':
    'Comissão de cobro informada pelo processador de pagamento local escolhido em sua jurisdição. O valor da comissão será deduzido do total a receber',
  'welcome.total.Deposit': 'Total a pagar:',
  'welcome.total.Withdraw': 'Total a retirar:',
  'welcome.termsAndCondDesc.withdraw':
    'Seu pagamento será processado por um processador de pagamento local.<br/>Antes de continuar, você deve aceitar os termos e condições.',
  'welcome.withdrawDisclaimer':
    'Comissão de cobrança informada pelo processador de pagamento local escolhido em sua jurisdição. O valor da comissão será descontado do total para receber',
  'welcome.welcomeScreen': 'Resumo da sua ordem de pagamento',
  'welcome.termsAndCond.withdraw':
    'Aceito os <0>termos e condições</0> e <1>política de privacidade</1> do processador de pagamentos',
  'welcome.estimatedFees': 'Taxas estimadas',
  'withdraw.orderNumber': 'Pedido Nº',
  'withdraw.importantInfo':
    '<strong>Importante</strong>: se sua carteira cobrar uma taxa de retirada adicional, adicione-a para que possamos receber o valor total.<br /><br />Assim que recebermos a transferência em nossa carteira, enviaremos um link para seu e-mail para confirmar seu pedido com a cotação final naquele momento.',
  'withdraw.etaTransfer': 'Tempo de credenciamento <br/> 1 a 24 horas úteis após o recebimento da transferência.',
  'withdraw.amount': 'Quantidade',
  'withdraw.memo': 'Memo',
  'withdraw.network': 'Rede',
  'withdraw.wallet': 'Carteira',
  'withdraw.address': 'Direção',
  'withdraw.sendToWallet':
    'Faça uma transferência de <strong>{{cryptoAmount}} {{originAsset}}</strong> para a próxima carteira.',
  'withdraw.termsAndCond.memo':
    'Eu entendo que se eu não concluir a transferência para o <strong>endereço</strong> correto e <strong>MEMO</strong>, meus fundos serão perdidos.',
  'confirmPage.orderComplete': 'Seu pedido foi concluído!',
  'confirmPage.gonnaReceiveSoon': 'Em breve, você receberá o pagamento em sua conta.',
  'confirmPage.confirmDetail':
    'As ordens de venda confirmadas fora dos dias e horários bancários serão processadas quando forem retomadas.',
  finish: 'Finalizar',
  'confirmPage.transferStatusLabel': 'Status da sua transferência',
  'confirmPage.transferStatusReceived': 'Recebido',
  'confirmPage.amountReceived': 'Valor recebido',
  'confirmPage.amountCashout': 'Valor a cobrar',
  'confirmPage.withdrawfeeInfo': 'Comissão de cobrança informada pelo processador de pagamento',
  'confirmPage.totalCashout': 'Total a receber',
  'walletAddress.title': 'Carteira de destino',
  'walletAddressDestination.title': 'Carteira ou endereço de destino',
  'walletAddress.labelPart1': 'Insira o endereço da sua carteira {{cryptoAsset}} correspondente à',
  'walletAddress.labelPart2': ' rede principal ',
  'walletAddress.labelPart3':
    '<strong>({{network}})</strong>. O envio de qualquer criptomoeda para sua carteira usando uma rede que não oferecemos suporte resultará em uma potencial perda de seus fundos.',
  'walletAddress.tooltipText': 'Rede principal suportada por nossa plataforma.',
  'walletAddress.selectLabel': 'Selecione a carteira',
  'walletAddress.addressLabel': 'Endereço da carteira',
  'walletAddress.memoLabel': 'Memo',
  'walletAddress.memoOptional': 'Não requer MEMO / TAG',
  'walletAddress.memoTooltip':
    'Muitas carteiras exigem que você insira um Memo / Tag. Caso tenha certeza de que não requer, marque a opção "não requer Memo / Tag". Se você completar esta opção incorretamente, seus fundos podem ser perdidos. Se você tiver dúvidas, pode ler mais sobre Memo / Tag em nossa central de ajuda.',
  'walletAddressFederation.label':
    'Por favor, insira o endereço de sua carteira ou endereço federado de {{cryptoAsset}} onde você deseja receber suas criptomoedas',
  'walletAddressFederation.title': 'Endereço federado',
  'walletAddressFederation.selection': 'Selecione sua carteira',
  'walletAddressFederation.description':
    'O endereço federado é um identificador semelhante a um e-mail que representa o seu endereço Stellar público de forma amigável. Cada endereço federado é composto de: nome-de-usuário(*)domínio. Você deve conseguir encontrá-lo no perfil ou nas configurações da carteira.'
};
