// @Vendors
import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import _ from 'lodash';
import moment from 'moment';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  createDepositRequest: ['data'],
  createDepositSuccess: ['deposit'],
  createDepositFailure: ['error'],

  getDepositsRequest: ['filter'],
  getDepositsSuccess: ['deposits', 'filter'],
  getDepositsFailure: ['error'],

  getDepositRequest: ['id'],
  getDepositSuccess: ['deposit'],
  getDepositFailure: ['error'],

  updateDepositRequest: ['id', 'data'],
  updateDepositSuccess: ['deposit'],
  updateDepositFailure: ['error'],

  getAvailableBanksRequest: [],
  getAvailableBanksSuccess: ['banks'],
  getAvailableBanksFailure: ['error'],

  generateDepositTicket: ['data'],
  generateDepositTicketSuccess: ['ticket'],
  generateDepositTicketFailure: ['error'],

  getCheckoutStatus: ['data'],
  getCheckoutStatusSuccess: ['status'],
  getCheckoutStatusFailure: ['error'],

  getQuote: ['fromAsset', 'toAsset', '_type', 'pid'],
  getQuoteSuccess: ['quote'],
  getQuoteFailure: ['error'],

  getLimits: ['data'],
  getLimitsSuccess: ['limits'],
  getLimitsFailure: ['error'],

  sendLimitEmail: ['data'],
  sendLimitEmailSuccess: [],
  sendLimitEmailFailure: ['error'],

  getAvailablePaymentMethods: ['data'],
  getAvailablePaymentMethodsSuccess: ['paymentMethods'],
  getAvailablePaymentMethodsFailure: ['error'],

  setPaymentMethodSelected: ['paymentMethod']
});

export const DepositTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  requesting: false,
  requestingQuote: false,
  flagged: [],
  list: [],
  last: {},
  deposit: {
    ticket: { status: null }
  },
  quote: {},
  rate: {},
  confirm: undefined,
  confirmResult: undefined,
  requestingConfirm: undefined,
  provider: undefined,
  error: '',
  updateAction: '',
  banks: [],
  limits: null,
  ticket: null,
  paymentMethods: [],
  paymentMethodSelected: undefined
});

/* ------------- Reducers ------------- */

export const createRequest = state => {
  return state.merge({
    requesting: true,
    error: ''
  });
};

export const createSuccess = (state, { deposit }) => {
  return state.merge({
    requesting: false,
    last: deposit,
    list: [...state.list, deposit]
  });
};

export const getDepositsRequest = state => {
  return state.merge({
    requesting: true,
    error: ''
  });
};

export const getDepositsSuccess = (state, { deposits, filter }) => {
  const newState = {
    requesting: false
  };

  if (filter === 'flagged') {
    newState.flagged = _.orderBy(deposits, deposit => moment(deposit.lastUpdateTimeStamp), ['desc']);
  } else {
    newState.list = _.orderBy(deposits, deposit => moment(deposit.lastUpdateTimeStamp), ['desc']);
  }

  return state.merge(newState);
};

export const getDepositRequest = state => {
  return state.merge({
    requesting: true,
    error: ''
  });
};

export const getDepositSuccess = (state, { deposit }) => {
  return state.merge({
    requesting: false,
    last: deposit
  });
};

export const updateDepositRequest = state => {
  return state.merge({
    updating: true,
    error: ''
  });
};

export const updateDepositSuccess = (state, { deposit }) => {
  const newList = [...state.list];
  const index = _.findIndex(newList, d => d._id === deposit._id);
  newList[index] = deposit;

  return state.merge({
    updating: false,
    list: newList,
    last: deposit
  });
};

export const failure = (state, { error }) => {
  return state.merge({
    requesting: false,
    error: `Something went wrong. ${error}`
  });
};

// TODO: Move to an specific reducer.
export const getAvailableBanksSuccess = (state, { banks }) => {
  return state.merge({
    updating: false,
    banks
  });
};

export const generateDepositTicket = state => {
  return state.merge({
    requesting: true,
    error: ''
  });
};

export const generateDepositTicketSuccess = (state, { ticket }) => {
  return state.merge({
    requesting: false,
    ticket
  });
};

export const getCheckoutStatus = state => {
  return state.merge({
    ticket: { status: null },
    requesting: true,
    error: ''
  });
};

export const getCheckoutStatusSuccess = (state, { status }) => {
  return state.merge({
    requesting: false,
    ticket: { status },
    error: ''
  });
};

export const getCheckoutStatusFailure = (state, { error }) => {
  return state.merge({
    requesting: false,
    ticket: { status: null },
    error: `Something went wrong. ${error}`
  });
};

export const getQuote = state => {
  return state.merge({
    requestingQuote: true,
    error: ''
  });
};

export const getQuoteSuccess = (state, { quote }) => {
  return state.merge({
    requestingQuote: false,
    quote,
    error: ''
  });
};

export const getQuoteFailure = (state, { error }) => {
  return state.merge({
    requestingQuote: false,
    quote: {},
    error: `Something went wrong. ${error}`
  });
};

export const getLimits = state => {
  return state.merge({
    requestingLimit: true,
    error: ''
  });
};

export const getLimitsSuccess = (state, { limits }) => {
  return state.merge({
    requestingLimit: false,
    limits,
    error: ''
  });
};

export const getLimitsFailure = (state, { error }) => {
  return state.merge({
    requestingLimit: false,
    limits: null,
    error: `Something went wrong. ${error}`
  });
};

export const sendLimitEmail = state => {
  return state.merge({
    requestingLimit: true,
    error: ''
  });
};

export const sendLimitEmailSuccess = state => {
  return state.merge({
    requestingLimit: false,
    error: ''
  });
};

export const sendLimitEmailFailure = (state, { error }) => {
  return state.merge({
    requestingLimit: false,
    limits: null,
    error: `Something went wrong. ${error}`
  });
};

export const getAvailablePaymentMethods = state => {
  return state.merge({
    requesting: true,
    error: ''
  });
};

export const getAvailablePaymentMethodsSuccess = (state, { paymentMethods }) => {
  return state.merge({
    requesting: false,
    paymentMethods
  });
};

export const getAvailablePaymentMethodsFailure = (state, { error }) => {
  return state.merge({
    requesting: false,
    error: `Something went wrong. ${error.message || error}`
  });
};

export const setPaymentMethodSelected = (state, { paymentMethod }) => {
  return state.merge({
    paymentMethodSelected: paymentMethod
  });
};

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CREATE_DEPOSIT_REQUEST]: createRequest,
  [Types.CREATE_DEPOSIT_SUCCESS]: createSuccess,
  [Types.CREATE_DEPOSIT_FAILURE]: failure,

  [Types.GET_DEPOSITS_REQUEST]: getDepositsRequest,
  [Types.GET_DEPOSITS_SUCCESS]: getDepositsSuccess,
  [Types.GET_DEPOSITS_FAILURE]: failure,

  [Types.GET_DEPOSIT_REQUEST]: getDepositRequest,
  [Types.GET_DEPOSIT_SUCCESS]: getDepositSuccess,
  [Types.GET_DEPOSIT_FAILURE]: failure,

  [Types.UPDATE_DEPOSIT_REQUEST]: updateDepositRequest,
  [Types.UPDATE_DEPOSIT_SUCCESS]: updateDepositSuccess,
  [Types.UPDATE_DEPOSIT_FAILURE]: failure,

  [Types.GET_AVAILABLE_BANKS_SUCCESS]: getAvailableBanksSuccess,
  [Types.GET_AVAILABLE_BANKS_FAILURE]: failure,

  [Types.GENERATE_DEPOSIT_TICKET]: generateDepositTicket,
  [Types.GENERATE_DEPOSIT_TICKET_SUCCESS]: generateDepositTicketSuccess,
  [Types.GENERATE_DEPOSIT_TICKET_FAILURE]: failure,

  [Types.GET_CHECKOUT_STATUS]: getCheckoutStatus,
  [Types.GET_CHECKOUT_STATUS_SUCCESS]: getCheckoutStatusSuccess,
  [Types.GET_CHECKOUT_STATUS_FAILURE]: getCheckoutStatusFailure,

  [Types.GET_QUOTE]: getQuote,
  [Types.GET_QUOTE_SUCCESS]: getQuoteSuccess,
  [Types.GET_QUOTE_FAILURE]: getQuoteFailure,

  [Types.GET_LIMITS]: getLimits,
  [Types.GET_LIMITS_SUCCESS]: getLimitsSuccess,
  [Types.GET_LIMITS_FAILURE]: getLimitsFailure,

  [Types.SEND_LIMIT_EMAIL]: sendLimitEmail,
  [Types.SEND_LIMIT_EMAIL_SUCCESS]: sendLimitEmailSuccess,
  [Types.SEND_LIMIT_EMAIL_FAILURE]: sendLimitEmailFailure,

  [Types.GET_AVAILABLE_PAYMENT_METHODS]: getAvailablePaymentMethods,
  [Types.GET_AVAILABLE_PAYMENT_METHODS_SUCCESS]: getAvailablePaymentMethodsSuccess,
  [Types.GET_AVAILABLE_PAYMENT_METHODS_FAILURE]: getAvailablePaymentMethodsFailure,

  [Types.SET_PAYMENT_METHOD_SELECTED]: setPaymentMethodSelected
});
