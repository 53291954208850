import React, { Component } from 'react';
import { Col, Row, Spinner } from 'reactstrap';
import { withTranslation, Trans } from 'react-i18next';
import Countdown from 'react-countdown-now';

import CustomButton from '../Commons/CustomButton/CustomButton';

import emailVerificationCode from '../../images/account/account-email@2x.png';
import CustomInput from '../Commons/CustomInput/CustomInput';

import './style.scss';

const onlyNumbers = string => /^[0-9]*$/.test(string);

class EmailCodeVerificationScreen extends Component {
  state = {
    code: [],
    countdownDate: Date.now(),
    resendCodeDisabled: false,
    resendCodeButtonTouched: false
  };

  resendCode = () => {
    const { resendCode } = this.props;
    this.setState(
      {
        countdownDate: Date.now() + 60 * 1000,
        resendCodeDisabled: true,
        resendCodeButtonTouched: true
      },
      resendCode
    );
  };

  // ────────────────────────────────────────────────────────────────────────────────

  checkCodeIsValid = code => {
    const { t } = this.props;
    const parsedCode = code.join('');
    if (String(parsedCode).length === 4) {
      if (!onlyNumbers(parsedCode)) return t('emailCode.onlyNumbersError');
      return 'valid';
    }
    return '';
  };

  updateCode = (value, inputNumber) => {
    const { code } = this.state;
    const newCode = code;
    newCode[inputNumber - 1] = value;
    this.setState({ code: newCode }, () => value && inputNumber < 4 && this[`_input${inputNumber + 1}`].setFocus());
  };

  onKeyDown = event => {
    if (event.keyCode === 8) {
      const { code } = this.state;
      const { _input1, _input2, _input3, _input4 } = this;
      if (_input2.state.isFocused && !code[1]) _input1.setFocus();
      if (_input3.state.isFocused && !code[2]) _input2.setFocus();
      if (_input4.state.isFocused && !code[3]) _input3.setFocus();
    }
  };

  // ────────────────────────────────────────────────────────────────────────────────

  render() {
    const { onClick, user, t, validCode, buttonTouched, loading, resendCodeLoading, isPasswordRecovery } = this.props;
    const { code, countdownDate, resendCodeDisabled, resendCodeButtonTouched } = this.state;

    const codeIsValid = this.checkCodeIsValid(this.state.code);

    return (
      <div className="d-flex flex-column align-items-center">
        <h1 className="title sign-up-title mt-4">{t('emailCode.title')}</h1>

        <img src={emailVerificationCode} alt="email-verification-code-icon" className="mt-4" height="80" />

        <span
          style={{
            fontSize: '16px',
            textAlign: 'center',
            maxWidth: '600px',
            marginTop: '32px',
            marginBottom: '9px',
            lineHeight: '20px'
          }}
        >
          {isPasswordRecovery ? (
            <Trans i18nKey="emailCode.recoveryText">
              <strong>{{ email: user.email }}</strong>
            </Trans>
          ) : (
            <Trans i18nKey="emailCode.text">
              <strong>{{ email: user.email }}</strong>
            </Trans>
          )}
        </span>

        <Row
          className={`d-flex justify-center justify-content-md-center code-validation ${
            buttonTouched
              ? validCode
                ? 'code-valid'
                : 'code-invalid'
              : codeIsValid !== ''
              ? codeIsValid === 'valid'
                ? 'code-valid'
                : 'code-invalid'
              : ''
          }`}
        >
          <Col>
            {[1, 2, 3, 4].map(inputNumber => (
              <CustomInput
                key={inputNumber}
                maxLength={1}
                onChange={({ target }) => this.updateCode(target.value, inputNumber)}
                onKeyDown={this.onKeyDown}
                outline
                reference={ref => (this[`_input${inputNumber}`] = ref)}
                style={{ textAlign: 'center', height: '48px' }}
                type="text"
                value={this.state.code[inputNumber - 1]}
              />
            ))}
          </Col>
        </Row>
        {codeIsValid && codeIsValid !== 'valid' ? (
          <div style={{ color: 'red', fontSize: '12px' }}>{codeIsValid}</div>
        ) : null}

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            fontSize: '14px',
            lineHeight: '18px',
            fontWeight: '500',
            marginBottom: '32px'
          }}
        >
          {resendCodeLoading ? (
            <Spinner size="sm" color="#488dfc" />
          ) : (
            <div style={{ display: 'flex' }}>
              <span
                style={{
                  color: resendCodeDisabled ? '#cccccc' : '#488dfc',
                  marginRight: resendCodeDisabled ? '5px' : 0,
                  cursor: resendCodeDisabled ? 'default' : 'pointer'
                }}
                onClick={resendCodeDisabled ? () => {} : this.resendCode}
              >
                {t('emailCode.reSendCode')}
              </span>
              {resendCodeDisabled ? (
                <Countdown
                  date={countdownDate}
                  renderer={({ seconds, completed }) => {
                    if (completed) {
                      this.setState({ resendCodeDisabled: false, resendCodeButtonTouched: false });
                    }
                    return `${seconds}"`;
                  }}
                />
              ) : null}
            </div>
          )}
          {resendCodeButtonTouched && !resendCodeLoading && (
            <span style={{ color: '#0ddf0e', fontSize: '12px' }}>{t('emailCode.reSendCodeSuccess')}</span>
          )}
        </div>

        {buttonTouched && !loading && (
          <span style={{ color: !validCode ? '#ff0000' : '#0ddf0e', marginBottom: '5px', marginTop: '20px' }}>
            {!validCode ? t('emailCode.incorrectCode') : t('emailCode.correctCode')}
          </span>
        )}

        <CustomButton
          disabled={codeIsValid !== 'valid' || code.join('').length !== 4}
          onClick={() => onClick(code.join(''))}
          loading={loading}
          width="350px"
        >
          {t('emailCode.next')}
        </CustomButton>

        <p
          style={{
            color: '#448aff',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '18px',
            cursor: 'pointer',
            marginBottom: '12px',
            marginTop: '16px'
          }}
          onClick={() => (window.parent.location.href = user.callbackUrl)}
        >
          {t('emailCode.cancel')}
        </p>
      </div>
    );
  }
}

export default withTranslation()(EmailCodeVerificationScreen);
