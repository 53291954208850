import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import Popup from 'reactjs-popup';
import { Col, Row } from 'reactstrap';
import './styles.scss';
import settleIdIcon from '../../images/icons/settle-id.svg';

const PopupWhatIsSettleId = ({ t }) => (
  <Popup trigger={<p className="action-label big">{t('account.insertEmail.whatIsSettleId')}</p>} modal nested>
    {close => (
      <div className="modal-container">
        <div className="d-flex flex-column align-items-center">
          <h3 className="title sign-up-title mb-2">Settle ID</h3>
          <img style={{ height: 120 }} alt="Settle ID" src={settleIdIcon} />
          <Row className="justify-content-center mt-3 mb-4">
            <Col md={9} style={{ textAlign: 'center' }}>
              <p className="align-items-center">
                <strong>{t('account.insertEmail.whatIsSettleId')}</strong>
                <br />
                <Trans i18nKey="account.insertEmail.settleIdPopup">
                  <br />
                  <br />
                </Trans>
              </p>
            </Col>
          </Row>
          <p
            className="action-label big"
            onClick={() => {
              console.log('modal closed ');
              close();
            }}
          >
            {t('account.insertEmail.OK')}
          </p>
        </div>
      </div>
    )}
  </Popup>
);

export default withTranslation()(PopupWhatIsSettleId);
