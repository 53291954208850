// @Vendors
import { put, call, select } from 'redux-saga/effects';
import UserActions from '../redux/UserRedux';

import HeapBridge from '../utils/heap';

export const getToken = state => state.auth.token;

export function* addAccount(api, action) {
  const token = yield select(getToken);
  const { holderName, holderLastName, CBU } = action;
  const response = yield call(api.addAccount, token, { holderName, holderLastName, CBU });

  if (response.ok) {
    yield put(UserActions.addAccountSuccess(response.data));
  } else {
    yield put(UserActions.addAccountFailure(response));
  }
}

export function* getKycVerificationUrlRequest(kycApi, action) {
  const token = yield select(getToken);
  const { userData } = action;
  const response = yield call(kycApi.getKycVerificationUrlRequest, token, userData);

  if (response.ok) {
    yield put(UserActions.getKycVerificationUrlSuccess(response.data));
    HeapBridge.track({ event: 'KycStarted' });
  } else {
    yield put(UserActions.getKycVerificationUrlFailure(response));
  }
}
