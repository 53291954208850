import React from 'react';

import { withTranslation } from 'react-i18next';
import CheckoutStep from '../../Layout/CheckoutStep';
import stepIcon from '../../../images/fastcash/sent.png';

const FcDepositVerificationSuccessComponent = ({ history, kyc: { name }, t }) => (
  <div className="d-flex flex-column align-items-center">
    <CheckoutStep
      title={t('fastcash.fcDepositVerificationSuccess.title', { name })}
      hideGoBack
      buttonContent={t('fastcash.fcDepositVerificationSuccess.nextButton')}
      next={() => history.push('/status/check')}
    >
      <div className="fc-step">
        <img src={stepIcon} />

        <p className="description">
          {t('fastcash.fcDepositVerificationSuccess.description1')}
          <br />
          {t('fastcash.fcDepositVerificationSuccess.description2')}
        </p>

        <p className="description">{t('fastcash.fcDepositVerificationSuccess.description3')}</p>

        <p className="status-description">{t('fastcash.fcDepositVerificationSuccess.supportContact')}</p>
      </div>
    </CheckoutStep>
  </div>
);

export default withTranslation()(FcDepositVerificationSuccessComponent);
