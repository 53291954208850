import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { withTranslation } from 'react-i18next';
import WithdrawActions from '../../redux/WithdrawRedux';
import CheckoutActions from '../../redux/CheckoutRedux';

import WithdrawInput from '../../components/WithdrawInput';
import WithdrawFinish from '../../components/WithdrawFinish';

const WithdrawSummaryPage = ({
  history,
  match,
  user,
  isBrokerCheckout,
  kyc,
  generateWithdrawTicket,
  ticket,
  t,
  cryptoCurrencies,
  getCurrencies
}) => {
  const [step, setStep] = useState('input');

  useEffect(() => {
    setStep(match.params.step);
  }, [match.params.step]);

  const persistWithdraw = bankInfo => {
    const data = {
      nationalId: kyc.nationalId,
      accountHolderName: kyc.name,
      accountHolderLastname: kyc.lastname,
      origin: 'checkout',
      originId: `checkout-${user.consumer}`,
      assetName: user.originAsset,
      amount: user.cryptoAmount,
      method: 'bank-transfer',
      provider: 'bank',
      countryCode: kyc.country,
      feeAmount: user.feeAmount,
      providerPSP: user.providerPSP,
      entity: user.entity,
      isBrokerCheckout,
      ...bankInfo
    };
    generateWithdrawTicket(data);
    history.push('/withdraw/finish');
  };

  useEffect(() => {
    getCurrencies();
  }, [getCurrencies]);

  return (
    <>
      {!kyc && <Redirect to="/" />}
      {kyc && step === 'input' && <WithdrawInput onClick={persistWithdraw} {...{ history, user, kyc, ticket }} />}
      {ticket && step === 'finish' && (
        <WithdrawFinish
          onClick={() => (window.parent.location.href = user.callbackUrl)}
          {...{ history, user, kyc, ticket, t, cryptoCurrencies }}
        />
      )}
      {ticket && step === 'checkStatus' && <Redirect to="/status/check" />}
    </>
  );
};

const mapStateToProps = state => {
  return {
    user: state.user.user,
    isBrokerCheckout: state.user.isBrokerCheckout,
    kyc: state.user.kyc,
    ticket: state.withdraw.last,
    cryptoCurrencies: state.checkout.cryptoCurrencies
  };
};

const mapDispatchToProps = dispatch => {
  return {
    generateWithdrawTicket: data => dispatch(WithdrawActions.createWithdrawRequest(data)),
    getCurrencies: () => dispatch(CheckoutActions.getCurrencies())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(WithdrawSummaryPage));
