import React, { useEffect } from 'react';

const CloseApp = () => {
  useEffect(() => {
    window.parent.postMessage(
      {
        event: 'applicationClosed'
      },
      '*'
    );
    window.close();

    window.location.href = 'https://latamex.com/';
  });
  return <></>;
};

export default CloseApp;
