import CurrencyFormat from 'react-currency-format';
import React from 'react';

import CbuService from '../../services/CBU';
import { toFixed } from '../../utils/numbers';

import CustomButton from '../Commons/CustomButton/CustomButton';
import IconBank from '../../images/deposit/icon-bank-2.png';
import successWithdrawIcon from '../../images/icons/success-withdraw-icon.svg';

const CashoutFinish = ({ ticket, t, user }) => {
  const { accountHandle, accountHandleType, toBank, countryCode } = ticket;
  const { callbackUrl, cryptoAmount, feeAmount, quote, destinationAsset } = user;

  return (
    <div className="d-flex justify-content-center align-items-center flex-column confirm-success">
      <h4 className="mb-3">{t('confirmPage.thanks')}</h4>
      <div className="mb-3">&nbsp;</div>
      <img src={successWithdrawIcon} width="150" className="d-block" />
      <span className="mt-4 mb-4 text-center">
        {t('confirmPage.orderComplete')}
        <br />
        <b>
          {destinationAsset}{' '}
          <CurrencyFormat
            value={toFixed((cryptoAmount - feeAmount) * quote, 2)}
            displayType="text"
            thousandSeparator=","
            decimalSeparator="."
            decimalScale={2}
          />
        </b>
        <br />
        {t('confirmPage.gonnaReceiveSoon')}
        <br />
      </span>

      <div className="bank-detail">
        <div className="icon">
          {countryCode === 'AR' ? (
            <img src={CbuService.getBankByCBU(accountHandle).icon} />
          ) : (
            <img className="confirmBankIcon" src={IconBank} />
          )}
        </div>
        <div className="my-auto">
          <small>{toBank}</small>
          <br />
          <small>
            {destinationAsset !== 'BRL' && (
              <>
                {accountHandleType}: {accountHandle}
              </>
            )}
            {destinationAsset === 'BRL' &&
              accountHandleType.split(' | ').map((brAccountPart, index) => (
                <>
                  {brAccountPart}: {accountHandle.split(' | ')[index]}
                  <br />
                </>
              ))}
          </small>
        </div>
      </div>

      <span className="mt-4 mb-4 text-center" style={{ width: '70%' }}>
        <small>{t('confirmPage.confirmDetail')}</small>
      </span>

      <p className="description mt-2 mb-2">
        <CustomButton onMouseUp={() => (window.parent.location.href = callbackUrl)} width="350px">
          {t('finish')}
        </CustomButton>
      </p>
    </div>
  );
};

export default CashoutFinish;
