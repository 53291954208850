// @Vendors
import React, { Component } from 'react';
import { Form } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'mdbreact';
import { PropagateLoader } from 'react-spinners';
// @Actions
import AuthActions from '../../redux/AuthRedux';

import HeapBridge from '../../utils/heap';

import './styles.scss';
import CustomInput from '../Commons/CustomInput/CustomInput';
import CustomButton from '../Commons/CustomButton/CustomButton';
import profileLogo from '../../images/account/profile@2x.png';

import PopupWhatIsSettleId from '../SettleIDPopup';

class Login extends Component {
  state = {
    password: '',
    email: '',
    emailFormatError: '',
    loadingcheckAuthRequesting: true
  };

  componentDidMount() {
    const { user, cleanPendingsInSignUp } = this.props;
    if (user) {
      this.setState({ email: user.email });
    }
    cleanPendingsInSignUp();
  }

  componentDidUpdate(prevProps) {
    const { authenticated, history, checkAuthRequesting, user } = this.props;
    const { loadingcheckAuthRequesting } = this.state;
    if (authenticated) {
      history.push('/');
    }

    if (loadingcheckAuthRequesting) {
      if (prevProps.checkAuthRequesting && !checkAuthRequesting) {
        this.setState({ loadingcheckAuthRequesting: false }, this.displayToastIfCorrespond); // eslint-disable-line react/no-did-update-set-state
      }
      if (prevProps.checkAuthRequesting === null && checkAuthRequesting === false) {
        this.setState({ loadingcheckAuthRequesting: false }, this.displayToastIfCorrespond); // eslint-disable-line react/no-did-update-set-state
      }
      if (prevProps.checkAuthRequesting === false && checkAuthRequesting === false) {
        this.setState({ loadingcheckAuthRequesting: false }); // eslint-disable-line react/no-did-update-set-state
      }
    }

    if (!prevProps.user && this.props.user) {
      this.setState({ email: user.email }); // eslint-disable-line react/no-did-update-set-state
    }
  }

  onLogin() {
    const { login, history } = this.props;
    const { password, email } = this.state;
    login(email, password, history);
  }

  onForgetPassword() {
    const { history, passwordRecovery, cleanError } = this.props;
    cleanError();
    passwordRecovery();
    history.push('/auth/sign-up');
  }

  onCreateAccount() {
    const { history, cleanError } = this.props;
    cleanError();
    history.push('/auth/sign-up');
  }

  displayToastIfCorrespond() {
    const { checkVerificationCodeMode, t } = this.props;
    if (checkVerificationCodeMode && checkVerificationCodeMode === 'sign-up') {
      toast.success(t('account.createAccountSuccess'));
      HeapBridge.track({ event: 'AccountRegistered' });
    } else if (checkVerificationCodeMode && checkVerificationCodeMode === 'password-recovery') {
      toast.success(t('account.changePasswordSuccess'));
    }
  }

  handleEmailOnchange(event) {
    const VALID_EMAIL_REG_EXP = /^[A-Z0-9._+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const { emailFormatError } = this.state;
    const email = event.target.value ? event.target.value.toLowerCase() : event.target.value;
    this.setState({ email });

    if (!email.match(VALID_EMAIL_REG_EXP)) {
      if (!emailFormatError) {
        this.setState({ emailFormatError: this.props.t('account.insertEmail.invalidEmail') });
      }
    } else if (emailFormatError) {
      this.setState({ emailFormatError: '' });
    }
  }

  render() {
    const { error, authenticated, requesting, user, t, cleanError, loading: initialLoading, iframeUrl } = this.props;
    const { password, loadingcheckAuthRequesting, email, emailFormatError } = this.state;

    if (error && !authenticated) {
      toast.error(t('account.login.invalidCredentials'));
    }

    return (
      <>
        {loadingcheckAuthRequesting && !initialLoading ? (
          <div style={{ minHeight: '583px' }} className="d-flex justify-content-center align-items-center">
            <PropagateLoader sizeUnit="px" size={10} color="#448aff" />
          </div>
        ) : (
          <div className="d-flex flex-column align-items-center">
            {initialLoading ? (
              <div
                style={{ minHeight: '583px' }}
                className="d-flex justify-content-center align-items-center kyc-iframe-container"
              >
                <iframe title="tokenIframe" src={iframeUrl} width="0" height="0" />
                <PropagateLoader sizeUnit="px" size={10} color="#448aff" />
              </div>
            ) : (
              <>
                <h1 className="title enter-latamex">{t('account.login.enterLatamex')}</h1>
                <img className="mt-2" height="80px" src={profileLogo} alt="profileLogo" />
                <p className="text-height-20 mb-1 subtitle">{t(`account.login.subtitle`)}</p>
                <PopupWhatIsSettleId />
                <Form className="pt-1 pt-2">
                  <CustomInput
                    outline
                    label={t('account.login.enterYourEmail')}
                    value={email}
                    className="account-input"
                    onChange={e => {
                      cleanError();
                      this.handleEmailOnchange(e);
                    }}
                    messageType={emailFormatError && 'error'}
                    message={emailFormatError}
                  />
                  <CustomInput
                    outline
                    type="password"
                    label={t('account.login.enterYourPassword')}
                    className="mt-4 account-input"
                    onChange={e => {
                      cleanError();
                      this.setState({ password: e.target.value });
                    }}
                  />
                  <p className="action-label" onClick={() => this.onForgetPassword()}>
                    {t('account.login.forgetPassword')}
                  </p>
                  <div className="d-flex flex-column align-items-center">
                    <CustomButton
                      loading={requesting}
                      disabled={!password || !email || emailFormatError}
                      onClick={() => this.onLogin()}
                      width="350px"
                    >
                      {t('account.next')}
                    </CustomButton>
                  </div>
                </Form>
                <p className="mt-2 action-label big2" onClick={() => (window.parent.location.href = user.callbackUrl)}>
                  {t('account.cancel')}
                </p>
              </>
            )}
            <ToastContainer hideProgressBar={false} newestOnTop autoClose={5000} position="bottom-center" />
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    error: state.auth.error,
    authenticated: state.auth.authenticated,
    requesting: state.auth.requesting,
    user: state.user.user,
    checkAuthRequesting: state.auth.checkAuthRequesting,
    checkVerificationCodeMode: state.auth.checkVerificationCodeMode
  };
};

const mapDispatchToProps = dispatch => {
  return {
    login: (mail, password, history) => dispatch(AuthActions.loginRequest(mail, password, history)),
    passwordRecovery: () => dispatch(AuthActions.passwordRecovery()),
    cleanError: () => dispatch(AuthActions.cleanError()),
    cleanPendingsInSignUp: () => dispatch(AuthActions.cleanPendingsInSignUp())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(Login)));
