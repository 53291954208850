import React, { useState, useEffect } from 'react';
import { Tooltip } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import CheckoutStep from '../../Layout/CheckoutStep';
import stepIcon from '../../../images/fastcash/receipt-icon.png';

const FcDepositVerificationComponent = ({ history, error, requesting, validation, fcValidateDepositRequest, t }) => {
  const [orderNumber, setOrderNumber] = useState('');
  const [reference, setReference] = useState('');
  const [amount, setAmount] = useState('');
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  useEffect(() => {
    if (validation && validation.validated) {
      history.push('/br/deposit-verification-success');
    }
  });

  const handleOnNextButtonClicked = () => {
    fcValidateDepositRequest({ depositId: orderNumber, ValidationCode1: reference, amount });
  };

  return (
    <div className="d-flex flex-column align-items-center">
      <CheckoutStep
        title={t('fastcash.fcDepositVerification.title')}
        hideGoBack
        buttonContent={t('fastcash.fcDepositVerification.nextButton')}
        disabled={!orderNumber || !reference || requesting}
        next={handleOnNextButtonClicked}
      >
        <div className="fc-step">
          <img src={stepIcon} />

          <p className="description">{t('fastcash.fcDepositVerification.description')}</p>

          <input
            className="fc-input"
            type="text"
            placeholder={t('fastcash.fcDepositVerification.orderNumber.label')}
            onChange={e => setOrderNumber(e.target.value)}
            value={orderNumber}
          />

          <div>
            <input
              className="fc-input"
              type="text"
              placeholder={t('fastcash.fcDepositVerification.reference.label')}
              onChange={e => setReference(e.target.value)}
              value={reference}
            />
            <button className="fc-help-btn" id="TooltipExample">
              ?
            </button>
            <Tooltip placement="right" isOpen={tooltipOpen} target="TooltipExample" toggle={toggle}>
              {t('fastcash.fcDepositVerification.reference.help')}
            </Tooltip>
          </div>

          <input
            className="fc-input"
            type="text"
            placeholder={t('fastcash.fcDepositVerification.amount.label')}
            onChange={e => setAmount(e.target.value)}
            value={amount}
          />

          <p className="status-description">{t('fastcash.fcDepositVerification.supportContact')}</p>

          {error && <p className="status-description error">{t('fastcash.fcDepositVerification.error')}</p>}
        </div>
      </CheckoutStep>
    </div>
  );
};

export default withTranslation()(FcDepositVerificationComponent);
