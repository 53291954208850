import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { PropagateLoader } from 'react-spinners';

import UserActions from '../../redux/UserRedux';

const KycWebappContainer = ({
  user,
  token,
  onFinish,
  onCancel,
  onKycChecked,
  hideWelcomeStep,
  overrideTitleWith,
  lang,
  getKycVerificationUrl,
  iframeUrl
}) => {
  useEffect(() => {
    if (!token || !user || user.entity === 'Kyb') return;
    const userData = {
      user,
      token,
      hideWelcomeStep,
      overrideTitleWith,
      lang
    };
    getKycVerificationUrl(userData);
  }, [getKycVerificationUrl, token]);

  useEffect(() => {
    window.addEventListener('message', handleIframeResponse);
  });

  const sourceString = url =>
    url
      .replace('http://', '')
      .replace('https://', '')
      .replace('www.', '')
      .split(/[/?#]/)[0];

  const [iframeHeight, setIframeHeight] = useState(555);

  const handleIframeResponse = e => {
    if (iframeUrl && sourceString(e.origin) === sourceString(iframeUrl)) {
      const {
        data: { event, status }
      } = e;
      switch (event) {
        case 'kycFinished':
          onFinish(status);
          break;
        case 'kycCanceled':
          onCancel();
          break;
        case 'kycChecked':
          onKycChecked();
          break;
        case 'heightChanged':
          setIframeHeight(e.data.height > 555 ? e.data.height : 555);
          break;
        case 'callbackFlow':
          window.location.href = e.data.callbackUrl;
          break;
        default:
          console.log(e.data);
      }
    }
  };
  const iframeStyle = {
    height: `${iframeHeight}px`
  };
  return (
    <React.Fragment>
      {!iframeUrl && (
        <div className="loader-container d-flex justify-content-center align-items-center">
          <PropagateLoader sizeUnit="px" size={10} color="#448aff" />
        </div>
      )}
      {iframeUrl && (
        <div className="kyc-iframe-container" style={iframeStyle}>
          <iframe
            title="kyc-container"
            src={iframeUrl}
            width="100%"
            height="100%"
            allow="camera;fullscreen;accelerometer;gyroscope;magnetometer"
            allowFullScreen
          />
        </div>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    iframeUrl: state.user.iframeUrl
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getKycVerificationUrl: userData => dispatch(UserActions.getKycVerificationUrlRequest(userData))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(KycWebappContainer);
