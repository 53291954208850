// @Vendors
import React, { useState, useCallback, useEffect } from 'react';
import { Form } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import './styles.scss';
import CustomInput from '../Commons/CustomInput/CustomInput';
import CustomButton from '../Commons/CustomButton/CustomButton';
import passwordLogo from '../../images/account/password@2x.png';
import AuthActions from '../../redux/AuthRedux';

function InsertPassword({
  handleSetPasswordOnClick,
  email,
  errorInPasswordFormat,
  loading,
  t,
  samePasswordAsBeforeError,
  updateErrorInPasswordFormat
}) {
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [repeatedPasswordError, setRepeatedPasswordError] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const dispatch = useDispatch();
  const cleanError = useCallback(() => dispatch(AuthActions.cleanError()), [dispatch]);

  useEffect(() => {
    if (password && repeatPassword && password !== repeatPassword) {
      if (!repeatedPasswordError) {
        setRepeatedPasswordError(t('account.insertPassword.passwordsDoNotMatch'));
      }
    } else if (repeatedPasswordError) {
      setRepeatedPasswordError('');
    }
  }, [password, repeatPassword, setRepeatedPasswordError, t]);

  useEffect(() => {
    if (!password || !repeatPassword || password !== repeatPassword) {
      if (!buttonDisabled) {
        setButtonDisabled(true);
      }
    } else if (buttonDisabled) {
      setButtonDisabled(false);
    }
  }, [password, repeatPassword, buttonDisabled, setButtonDisabled]);

  const handlePasswordOnchange = useCallback(
    event => {
      updateErrorInPasswordFormat(false);
      cleanError();
      const passwrd = event.target.value;
      setPassword(passwrd);
    },
    [setPassword]
  );

  const handleRepeatPasswordOnchange = useCallback(
    event => {
      updateErrorInPasswordFormat(false);
      cleanError();
      const passwrd = event.target.value;
      setRepeatPassword(passwrd);
    },
    [setRepeatPassword]
  );

  const handleSetPassword = useCallback(() => handleSetPasswordOnClick(password), [handleSetPasswordOnClick, password]);

  return (
    <div className="d-flex flex-column align-items-center">
      <h1 className="title sign-up-title">{t('account.insertPassword.createAPassword')}</h1>
      <img className="mt-2" height="80px" src={passwordLogo} alt="passwordLogo" />
      <p className="subtitle text-height-20 mt-3 mb-0">{t('account.insertPassword.passwordRequirements')}</p>
      <Form>
        <CustomInput
          outline
          className="mb-0 account-input"
          label=""
          value={email}
          disabled
          style={{ marginBottom: '0px' }}
        />
        <CustomInput
          outline
          className=" mb-0 account-input"
          style={{ marginTop: '35px', marginBottom: '0px' }}
          label={t('account.insertPassword.enterPassword')}
          type="password"
          onChange={handlePasswordOnchange}
          messageType={(errorInPasswordFormat || samePasswordAsBeforeError) && 'error'}
          message={
            (errorInPasswordFormat && !samePasswordAsBeforeError && t('account.insertPassword.checkPassword')) ||
            (samePasswordAsBeforeError && t('account.insertPassword.repeatedPasswordError'))
          }
        />
        <div style={{ paddingTop: errorInPasswordFormat || samePasswordAsBeforeError ? '1px' : null }}>
          <CustomInput
            outline
            className="account-input"
            style={{ marginTop: '35px', marginBottom: '0px' }}
            label={t('account.insertPassword.repeatPassword')}
            type="password"
            onChange={handleRepeatPasswordOnchange}
            messageType={repeatedPasswordError && 'error'}
            message={repeatedPasswordError}
          />
        </div>
        <div className=" d-flex flex-column align-items-center" style={{ marginTop: '16px', marginBottom: '9px' }}>
          <CustomButton width="350px" loading={loading} disabled={buttonDisabled} onClick={handleSetPassword}>
            {t('account.next')}
          </CustomButton>
        </div>
      </Form>
    </div>
  );
}

export default withTranslation()(InsertPassword);
