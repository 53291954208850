/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';

import { Col, Form, Row, Input as StrapInput } from 'reactstrap';
import _ from 'lodash';
import queryString from 'query-string';
import ReactTooltip from 'react-tooltip';

import { connect } from 'react-redux';
import CurrencyFormat from 'react-currency-format';
import { withTranslation, Trans } from 'react-i18next';
import i18n, { setCurrentLangToLocalStorage } from '../../i18n';

import { Button } from '../../components/Form';
import './TriggerForm.scss';
import DepositActions from '../../redux/DepositRedux';
import CheckoutActions from '../../redux/CheckoutRedux';

import { moneyMapping, langMapping } from '../../constants/countryMap';

import { ZendeskWebApi } from '../../utils/zendesk';

const countries = Object.keys(moneyMapping);
const CRYPTO_ASSETS_ENABLED = process.env.REACT_APP_CRYPTO_ASSETS_ENABLED || 'false';
const STABLEX_CRYPTOS = ['ARST', 'BRLT'];
const types = ['Deposit', 'Withdraw'];

const omitCrypto = {
  ARS: ['BRLT', 'MXNT'],
  BRL: ['ARST', 'MXNT'],
  MXN: ['ARST', 'BRLT']
};

const getEnabledCountries = (cntries, query) => {
  if (!query || !query.fiat) {
    return cntries;
  }

  const selectedAssets = _.isString(query.fiat) ? [query.fiat] : query.fiat;
  return cntries.filter(country => selectedAssets.indexOf(country) !== -1);
};

const calculateFee = (amount, quote, type, decimalScale = 8) => {
  if (type === 'Deposit') {
    const fee = Number(((quote.fee * amount) / 100).toFixed(2));
    return quote.flatFee + Math.max(quote.minFee, fee);
  }

  // Withdraw
  const cryptoMinFee = +(quote.minFee / quote.quote).toFixed(decimalScale);
  const cryptoFlatFee = +(quote.flatFee / quote.quote).toFixed(decimalScale);
  const feeAmount = (quote.fee * amount) / 100;
  const fee = +feeAmount.toFixed(decimalScale);

  const withdrawFee = cryptoFlatFee + Math.max(cryptoMinFee, fee);
  return withdrawFee;
};

const RefTooltip = ({ t }) => {
  return (
    <span className="tooltip-container-2">
      <a data-tip={t('checkoutForm.tooltip')} className="question-mark">
        ?
      </a>
      <ReactTooltip effect="solid" place="bottom" multiline />
    </span>
  );
};

const typeMapping = {
  Deposit: 'Sell',
  Withdraw: 'Buy'
};

const TriggerForm = ({
  getQuote,
  getLimits,
  getCurrencies,
  cryptoCurrencies: cryptoCurrenciesProvider,
  loading,
  quoteData,
  limits,
  match,
  t,
  history
}) => {
  window.onbeforeunload = null;
  const minOrderAmount = quoteData.fromAssetMinAmount;
  const query = queryString.parse(history.location.search.replace('?', ''));

  ZendeskWebApi('webWidget', 'hide', 'true');

  const countryOptions = getEnabledCountries(countries, query);
  const [cryptoCurrencies, setCryptoCurrencies] = useState();
  const [touched, setTouched] = useState({
    consumerUserEmail: false
  });
  const [crypto, setCrypto] = useState();
  const [cryptoValue, _setCryptoValue] = useState();
  const [fiatValue, setFiatValue] = useState('0');
  const [checkoutAmount, setCheckoutAmount] = useState();
  const [consumerUserEmail, setConsumerUserEmail] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [country, setCountry] = useState(
    countryOptions.includes(match.params.country) ? match.params.country : countryOptions[0]
  );
  const [countryCurrency, setCountryCurrency] = useState(moneyMapping[country]);
  const [feeAmount, setFeeAmount] = useState(0);
  const [amount, setAmount] = useState(0);
  const [triggering, setTriggering] = useState(false);
  const [decimalScale, setDecimalScale] = useState();
  const [transactionRef] = useState(undefined);
  const [type] = useState(query.type ? query.type : types[0]);

  const fiatMins = () => {
    const isStablex = STABLEX_CRYPTOS.includes(crypto);

    return {
      AR: isStablex ? 100 : 2000,
      BR: isStablex ? 10 : 70,
      MX: isStablex ? 30 : 350
    };
  };

  const setCryptoValue = value => {
    _setCryptoValue(`${value}`);
  };

  const handleFeeAmountChange = (_quoteData, _checkoutAmount, _feeAmount, _amount) => {
    _feeAmount = _feeAmount || calculateFee(_checkoutAmount, _quoteData, type);
    setFeeAmount(_feeAmount);
    setAmount((_amount || _checkoutAmount) - _feeAmount);
  };

  const toFixedDown = (value, digits) => {
    const re = new RegExp(`(\\d+\\.\\d{${digits}})(\\d)`);

    const m = value.toString().match(re);
    return m ? parseFloat(m[1]) : value.valueOf();
  };

  useEffect(() => {
    getCurrencies();
    sessionStorage.removeItem('checkVerificationCodeMode');
  }, []);

  useEffect(() => {
    if (cryptoCurrenciesProvider && countryCurrency) {
      let _cryptoCurrencies = [];

      if (query.currency) {
        _cryptoCurrencies = _.filter(cryptoCurrenciesProvider, { crypto: query.currency });
      } else if (CRYPTO_ASSETS_ENABLED && CRYPTO_ASSETS_ENABLED !== 'false') {
        const cryptAssetsEnabled = CRYPTO_ASSETS_ENABLED.split(',');
        _cryptoCurrencies = _.filter(cryptoCurrenciesProvider, c => cryptAssetsEnabled.indexOf(c.crypto) >= 0);
      } else {
        _cryptoCurrencies = _.filter(cryptoCurrenciesProvider, c => c.crypto !== 'TUSD' && c.crypto !== 'LTC');
      }

      console.log({ _cryptoCurrencies, omit: omitCrypto[countryCurrency] });

      setCryptoCurrencies(_cryptoCurrencies.filter(_crypto => !omitCrypto[countryCurrency].includes(_crypto.crypto)));
    }
  }, [cryptoCurrenciesProvider, query.currency, countryCurrency]);

  useEffect(() => {
    if (cryptoCurrencies) {
      setCrypto(cryptoCurrencies[0].crypto);
      setDecimalScale(cryptoCurrencies[0].decimal);
    }
  }, [cryptoCurrencies]);

  useEffect(() => {
    if (crypto && crypto !== '-') {
      getQuote(crypto, moneyMapping[country], typeMapping[type]);
      getLimits({ countryCode: country });
    }
  }, [crypto]);

  useEffect(() => {
    if (quoteData.quote) {
      const _checkoutAmount =
        checkoutAmount !== undefined
          ? typeof checkoutAmount === 'string'
            ? parseFloat(checkoutAmount.replace(/,/g, ''))
            : parseFloat(checkoutAmount)
          : undefined;

      const _feeAmount = calculateFee(_checkoutAmount || 0, quoteData, type);
      setCheckoutAmount(_checkoutAmount);
      setCryptoValue(
        toFixedDown(_checkoutAmount === undefined ? '' : (_checkoutAmount - _feeAmount) / quoteData.quote, decimalScale)
      );
      handleFeeAmountChange(quoteData, _checkoutAmount || 0, _feeAmount);
    }
    setDisabled(quoteData.quote === undefined);
  }, [quoteData.quote]);

  useEffect(() => {
    if (match.params.country && countryOptions.includes(match.params.country)) {
      handleCountryChange(match.params.country);
    }
  }, [match.params.country]);

  const calcCryptoValue = _checkoutAmount => {
    const _feeAmount = calculateFee(_checkoutAmount.floatValue, quoteData, type);
    setCheckoutAmount(_checkoutAmount.formattedValue);
    setCryptoValue(
      _checkoutAmount.floatValue > 0
        ? toFixedDown((_checkoutAmount.floatValue - _feeAmount) / quoteData.quote, decimalScale)
        : ''
    );
    handleFeeAmountChange(quoteData, _checkoutAmount.floatValue, _feeAmount);
  };

  const calcFiatValue = _checkoutAmount => {
    const _feeAmount = calculateFee(_checkoutAmount.floatValue, quoteData, type);
    setCryptoValue(_checkoutAmount.formattedValue);
    const fiatAmount =
      _checkoutAmount.floatValue > 0
        ? toFixedDown((_checkoutAmount.floatValue - _feeAmount) * quoteData.quote, decimalScale)
        : 0;
    setFiatValue(fiatAmount);
    handleFeeAmountChange(quoteData, _checkoutAmount.floatValue, _feeAmount, null);
  };

  const handleCountryChange = _country => {
    setTriggering(true);
    window.parent.postMessage(
      {
        event: 'countryChanged',
        country: _country
      },
      '*'
    );
    setCountry(_country);
    getLimits({ countryCode: _country });
    setCheckoutAmount(undefined);
    i18n.changeLanguage(langMapping[_country]);
    setCurrentLangToLocalStorage(langMapping[_country]);
    setCountryCurrency(moneyMapping[_country]);
    if (crypto && crypto !== '-') {
      getQuote(crypto, moneyMapping[_country], typeMapping[type]);
    }
    setFiatValue(0);
    setTriggering(false);
  };

  const handleCryptoChange = ({ target: { value: _crypto } }) => {
    setTriggering(true);
    setCrypto(_crypto);
    setCheckoutAmount(undefined);
    const selectedCrypto = cryptoCurrencies.filter(cc => cc.crypto === _crypto)[0];
    setDecimalScale(selectedCrypto.decimal);
    setTriggering(false);
  };

  const post = (path, params, method = 'post') => {
    const form = document.createElement('form');
    form.method = method;
    form.action = path;
    form.target = '_parent';

    _.each(params, (value, key) => {
      const hiddenField = document.createElement('input');
      hiddenField.type = 'hidden';
      hiddenField.name = key;
      hiddenField.value = value;
      form.appendChild(hiddenField);
    });

    document.body.appendChild(form);
    form.submit();
  };

  const submitCheckout = () => {
    const bodyPost = {
      type,
      version: 1,
      transactionRef: type === 'Deposit' ? undefined : transactionRef,
      consumer: 'latamex',
      destinationAsset: type === 'Withdraw' ? countryCurrency : crypto,
      originAsset: type === 'Withdraw' ? crypto : countryCurrency,
      checkoutAmount: amount,
      cryptoAmount: cryptoValue,
      feeAmount,
      consumerUserEmail,
      consumerUserId: '-----',
      quote: quoteData.quote,
      countryCode: country,
      callbackUrl: countryCurrency.toLowerCase() === 'mxn' ? 'https://latamex.mx/' : 'https://latamex.com'
    };
    setDisabled(true);
    post(`${process.env.REACT_APP_API_URL}/checkout/new`, bodyPost);
  };

  const unformattedCheckoutAmount =
    typeof checkoutAmount === 'string' ? parseFloat(checkoutAmount.replace(/,/g, '')) : parseFloat(checkoutAmount);

  const { simulateContainer } = query;
  const validEmail = /^[A-Z0-9._+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(consumerUserEmail);
  const validCheckoutAmount =
    checkoutAmount === undefined ||
    (checkoutAmount !== '' && (limits ? unformattedCheckoutAmount <= limits.hardLimit : true));
  const validCryptoAmount =
    !STABLEX_CRYPTOS.includes(crypto) && cryptoValue !== undefined && cryptoValue !== ''
      ? cryptoValue.replace(/,/g, '') >= minOrderAmount
      : true;
  const validMinCheckoutAmount =
    type === 'Deposit'
      ? checkoutAmount !== undefined && checkoutAmount !== '' && fiatMins()[country] <= unformattedCheckoutAmount
      : cryptoValue !== undefined && cryptoValue !== '';
  const isValid = validEmail && validCheckoutAmount && validCryptoAmount && validMinCheckoutAmount;
  const { fee, minFee, flatFee } = quoteData;
  const bottomTextKey =
    fee !== 0 || minFee !== 0 ? 'checkoutForm.feeAndTermsCondDisclaimer' : 'checkoutForm.termsCondDisclaimer';

  const minFeeString =
    minFee > 0 ? t('checkoutForm.minFee', { minFee: minFee + flatFee, fiatAsset: moneyMapping[country] }) : '';

  const flatFeeString = flatFee > 0 ? t('checkoutForm.flatFee', { fiatAsset: moneyMapping[country], flatFee }) : '';

  return (
    <div className={`container full-width-container ${simulateContainer ? 'simulateContainer' : ''}`}>
      <Row className="justify-content-left trigger-form">
        {type === 'Deposit' ? (
          <Col xs="12">
            <Form>
              <Row>
                <Col xs="7">
                  <div className="col-amount-currency">
                    <span className="quote-value">{t('checkoutForm.wantToSpend')}</span>
                  </div>

                  <div className="col-amount-currency">
                    <div>
                      <CurrencyFormat
                        value={checkoutAmount || ''}
                        decimalScale={2}
                        fixedDecimalScale={false}
                        allowNegative={false}
                        onValueChange={values => calcCryptoValue(values)}
                        thousandSeparator
                        prefix=""
                        disabled={disabled}
                        placeholder={`${t('checkoutForm.enterYourAmount')}`}
                      />
                      <StrapInput
                        className="country"
                        type="select"
                        name="select"
                        disabled={disabled}
                        onChange={e => handleCountryChange(e.target.value)}
                      >
                        {countryOptions.map(countryOpt => (
                          <option key={countryOpt} value={countryOpt} selected={countryOpt === country}>
                            {t(`currency.country.${countryOpt}`)}
                          </option>
                        ))}
                      </StrapInput>
                    </div>
                  </div>

                  <div className={`row-crypto-value${loading || triggering ? ' hide' : ''}`}>
                    {!loading && !validCheckoutAmount && (
                      <small className="error">
                        {limits && unformattedCheckoutAmount > limits.hardLimit
                          ? t('checkoutForm.enterLowerValue')
                          : t('checkoutForm.mustEnterValue')}
                      </small>
                    )}

                    {!loading && !validMinCheckoutAmount && cryptoValue > 0 && (
                      <small className="error">
                        {t('checkoutForm.enterHigherValue', {
                          fiatAsset: countryCurrency,
                          minAmount: fiatMins()[country]
                        })}
                      </small>
                    )}
                    {!loading && !validCryptoAmount && validMinCheckoutAmount && cryptoValue > 0 && (
                      <small className="error">
                        {t('checkoutForm.minAmountError', { minOrderAmount, fromAsset: crypto })}
                      </small>
                    )}
                    {cryptoValue !== undefined && (
                      <>
                        <span className="quote-value">
                          ≈{' '}
                          <CurrencyFormat
                            value={cryptoValue}
                            displayType="text"
                            decimalScale={decimalScale}
                            thousandSeparator
                            fixedDecimalScale
                          />{' '}
                          {crypto} {t('checkoutForm.includedFees')} <RefTooltip t={t} />
                        </span>
                      </>
                    )}
                  </div>
                </Col>
                <Col xs="5">
                  <div>
                    <span className="quote-value">{t('checkoutForm.wantToBuy')}</span>
                  </div>

                  <div className="col-crypto-dropdown">
                    <div className={`crypto-dropdown-container${disabled ? ' disabled' : ''}`}>
                      <div className={`crypto-icon-container${disabled ? ' disabled' : ''}`}>
                        <img
                          disabled={disabled}
                          // crypto-icons from https://cryptoicons.org/api/icon/tusd/200
                          src={require(`../../images/crypto-icons-png/${
                            crypto === undefined ? 'btc' : String(crypto).toLowerCase()
                          }.png`)}
                          width="30"
                          className="d-block"
                        />
                      </div>
                      <StrapInput type="select" onChange={handleCryptoChange} name="select" disabled={disabled}>
                        {cryptoCurrencies &&
                          cryptoCurrencies.map(cc => (
                            <option key={cc.crypto} value={cc.crypto}>
                              {cc.crypto}
                            </option>
                          ))}
                      </StrapInput>
                    </div>
                  </div>

                  <div className={`row-crypto-value${loading || triggering ? ' hide' : ''}`}>
                    {crypto !== undefined && cryptoValue !== undefined && (
                      <>
                        <span className="quote-value">
                          1 {crypto} ={' '}
                          <CurrencyFormat
                            value={quoteData.quote}
                            displayType="text"
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                          />{' '}
                          {countryCurrency}
                        </span>
                      </>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs="7">
                  <input
                    type="email"
                    placeholder={t('checkoutForm.enterYourEmail')}
                    value={consumerUserEmail}
                    name="consumerUserEmail"
                    onChange={e => setConsumerUserEmail(e.target.value)}
                    onBlur={() => setTouched({ ...touched, consumerUserEmail: true })}
                    disabled={disabled}
                  />
                  {touched.consumerUserEmail && !validEmail && (
                    <small className="error">{t('checkoutForm.enterValidEmail')}</small>
                  )}
                </Col>
                <Col xs="5">
                  <Button
                    primary
                    id="btn-buy-landing"
                    size="lg"
                    onClick={submitCheckout}
                    label={t('checkoutForm.buyButton')}
                    disabled={disabled || !isValid}
                  />
                </Col>
              </Row>
              {countryCurrency.toLowerCase() !== 'mxn' && (
                <Row>
                  <Col xs="12" className={`terms-and-condition-disclaimer ${loading || triggering ? 'hide' : ''}`}>
                    <Trans i18nKey={bottomTextKey}>
                      {{ fee, minFee: minFeeString, flatFee: flatFeeString }}
                      <a href="https://latamex.com/terms/" target="_blank" rel="noopener noreferrer">
                        terms and conditions
                      </a>
                    </Trans>
                  </Col>
                </Row>
              )}
            </Form>
          </Col>
        ) : (
          <Col xs="12">
            <Form>
              <Row>
                <Col xs="7" className="col-amount-currency">
                  <span className="quote-value">{t('checkoutForm.wantToSell')}</span>
                  <div className="withdraw-crypto-selector">
                    <CurrencyFormat
                      value={cryptoValue || ''}
                      decimalScale={decimalScale}
                      fixedDecimalScale
                      allowNegative={false}
                      onValueChange={values => calcFiatValue(values)}
                      thousandSeparator
                      prefix=""
                      disabled={disabled}
                      placeholder={`${t('checkoutForm.enterYourAmount')}`}
                    />
                    <div className={`crypto-icon-container${disabled ? ' disabled' : ''}`}>
                      <img
                        disabled={disabled}
                        // crypto-icons from https://cryptoicons.org/api/icon/tusd/200
                        src={require(`../../images/crypto-icons-png/${
                          crypto === undefined ? 'btc' : String(crypto).toLowerCase()
                        }.png`)}
                        width="30"
                        className="d-block"
                      />
                    </div>
                    <StrapInput type="select" onChange={handleCryptoChange} name="select" disabled={disabled}>
                      {cryptoCurrencies &&
                        cryptoCurrencies.map(cc => (
                          <option key={cc.crypto} value={cc.crypto}>
                            {cc.crypto}
                          </option>
                        ))}
                    </StrapInput>
                  </div>

                  {!loading && !validCheckoutAmount && (
                    <small className="error">
                      {limits && unformattedCheckoutAmount > limits.hardLimit
                        ? t('checkoutForm.enterLowerValue')
                        : t('checkoutForm.mustEnterValue')}
                    </small>
                  )}
                  {!loading && !validCryptoAmount && cryptoValue !== 0 && (
                    <small className="error">
                      {t('checkoutForm.minAmountError.withdraw', {
                        minAmount: minOrderAmount,
                        cryptoAsset: crypto
                      })}
                    </small>
                  )}
                  <span className="quote-value">
                    ≈{' '}
                    <CurrencyFormat
                      value={fiatValue}
                      displayType="text"
                      thousandSeparator
                      fixedDecimalScale
                      decimalScale={2}
                    />{' '}
                    {countryCurrency} {t('checkoutForm.includedFees')} <RefTooltip t={t} />
                  </span>
                </Col>
                <Col className="col-crypto-dropdown">
                  <span className="quote-value">{t('checkoutForm.wantToReceive')}</span>

                  <StrapInput
                    className="country"
                    type="select"
                    name="select"
                    disabled={disabled}
                    onChange={e => handleCountryChange(e.target.value)}
                  >
                    {countryOptions.map(countryOpt => (
                      <option key={countryOpt} value={countryOpt} selected={countryOpt === country}>
                        {t(`currency.country.${countryOpt}`)}
                      </option>
                    ))}
                  </StrapInput>
                  <span className="quote-value">
                    1 {crypto} ={' '}
                    <CurrencyFormat
                      value={quoteData.quote}
                      displayType="text"
                      decimalScale={2}
                      thousandSeparator
                      fixedDecimalScale
                    />{' '}
                    {countryCurrency}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col xs="7">
                  <input
                    type="email"
                    placeholder={t('checkoutForm.enterYourEmail')}
                    value={consumerUserEmail}
                    name="consumerUserEmail"
                    onChange={e => setConsumerUserEmail(e.target.value)}
                    onBlur={() => setTouched({ ...touched, consumerUserEmail: true })}
                    disabled={disabled}
                  />
                  {touched.consumerUserEmail && !validEmail && (
                    <small className="error">{t('checkoutForm.enterValidEmail')}</small>
                  )}
                </Col>
                <Col xs="5">
                  <Button
                    primary
                    id="btn-sell-landing"
                    size="lg"
                    onClick={submitCheckout}
                    label={t('checkoutForm.sellButton')}
                    disabled={disabled || !isValid}
                  />
                </Col>
              </Row>
              {countryCurrency.toLowerCase() !== 'mxn' && (
                <Row>
                  <Col xs="12" className={`terms-and-condition-disclaimer ${loading || triggering ? 'hide' : ''}`}>
                    <Trans i18nKey={bottomTextKey}>
                      {{ fee, minFee: minFeeString, flatFee: flatFeeString }}
                      <a href="https://latamex.com/terms/" target="_blank" rel="noopener noreferrer">
                        terms and conditions
                      </a>
                    </Trans>
                  </Col>
                </Row>
              )}
            </Form>
          </Col>
        )}
      </Row>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    error: state.deposit.error,
    loading: state.deposit.requestingQuote || state.deposit.requestingLimit,
    quoteData: state.deposit.quote,
    limits: state.deposit.limits,
    cryptoCurrencies: state.checkout.cryptoCurrencies,
    fiatCurrencies: state.checkout.fiatCurrencies
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getQuote: (fromAsset, toAsset, type) => dispatch(DepositActions.getQuote(fromAsset, toAsset, type)),
    getLimits: data => dispatch(DepositActions.getLimits(data)),
    getCurrencies: () => dispatch(CheckoutActions.getCurrencies())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TriggerForm));
