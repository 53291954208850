import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AuthActions from '../../redux/AuthRedux';
import EmailCodeVerificationScreen from '../EmailCodeVerification/EmailCodeVerificationScreen';
import InsertEmail from './InsertEmail';
import InsertPassword from './InsertPassword';

const SIGN_UP_ROUTE_TO_SEND_CODE = '/auth/verification';
const PASSWORD_RECOVERY_ROUTE_TO_SEND_CODE = '/auth/verify-password';
const VALID_PASSWORD_REG_EXP = /^(?=.*[A-Za-z])(?=.*\d).{8,30}$/;

function SignUpContainer({ loading, iframeUrl }) {
  const [errorInPasswordFormat, setErrorInPasswordFormat] = useState(false);
  const [emailCodeButtonIsTouched, setEmailCodeButtonIsTouched] = useState(false);
  const pendingEmailCode = useSelector(state => state.auth.pendingEmailCode);
  const pendingPassword = useSelector(state => state.auth.pendingPassword);
  const checkVerificationCodeRequesting = useSelector(state => state.auth.checkVerificationCodeRequesting);
  const checkVerificationCodeValid = useSelector(state => state.auth.checkVerificationCodeValid);
  const sendVerificationCodeRequesting = useSelector(state => state.auth.sendVerificationCodeRequesting);
  const user = useSelector(state => state.user.user);
  const requesting = useSelector(state => state.auth.requesting);
  const error = useSelector(state => state.auth.error);
  const properVerificationCode = useSelector(state => state.auth.properVerificationCode);
  const checkVerificationCodeMode = useSelector(state => state.auth.checkVerificationCodeMode);
  const history = useHistory();

  const dispatch = useDispatch();
  const signUp = useCallback(mail => dispatch(AuthActions.signUpRequest(mail, history)), [dispatch, history]);
  const continueAsAGuest = useCallback(mail => dispatch(AuthActions.continueAsAGuestRequest(mail, history)), [
    dispatch,
    history
  ]);
  const checkVerificationCode = useCallback(
    (route, verificationCode) => dispatch(AuthActions.checkVerificationCodeRequest(route, verificationCode)),
    [dispatch]
  );
  const changePassword = useCallback(
    passwrd =>
      dispatch(AuthActions.changePasswordRequest(passwrd, history, checkVerificationCodeMode, properVerificationCode)),
    [dispatch, history, checkVerificationCodeMode, properVerificationCode]
  );
  const sendVerificationCode = useCallback(
    () => dispatch(AuthActions.sendVerificationCodeRequest(checkVerificationCodeMode)),
    [dispatch, checkVerificationCodeMode]
  );
  const resetCheckAuthRequesting = useCallback(() => dispatch(AuthActions.resetCheckAuthRequesting()), [dispatch]);
  const cleanError = useCallback(() => dispatch(AuthActions.cleanError()), [dispatch]);
  const passwordRecoveryRequest = useCallback(mail => dispatch(AuthActions.passwordRecoveryRequest(mail)), [dispatch]);

  const handleSignUpOnClick = useCallback(
    mail => {
      if (checkVerificationCodeMode === 'password-recovery') {
        passwordRecoveryRequest(mail);
      } else {
        signUp(mail);
      }
    },
    [signUp, passwordRecoveryRequest, checkVerificationCodeMode]
  );

  const handleContinueAsAGuestOnClick = useCallback(
    mail => {
      continueAsAGuest(mail);
    },
    [continueAsAGuest]
  );

  const handleEmailVerificationOnClick = useCallback(
    verificationCode => {
      setEmailCodeButtonIsTouched(true);
      if (checkVerificationCodeMode === 'sign-up') {
        checkVerificationCode(SIGN_UP_ROUTE_TO_SEND_CODE, verificationCode);
      } else if (checkVerificationCodeMode === 'password-recovery') {
        checkVerificationCode(PASSWORD_RECOVERY_ROUTE_TO_SEND_CODE, verificationCode);
      }
    },
    [setEmailCodeButtonIsTouched, checkVerificationCode, checkVerificationCodeMode]
  );

  const handleSetPasswordOnClick = useCallback(
    password => {
      if (!password.match(VALID_PASSWORD_REG_EXP)) {
        if (!errorInPasswordFormat) {
          setErrorInPasswordFormat(true);
        }
        if (error) {
          cleanError();
        }
        return;
      }
      setErrorInPasswordFormat(false);
      resetCheckAuthRequesting();
      changePassword(password);
    },
    [changePassword, setErrorInPasswordFormat, resetCheckAuthRequesting, errorInPasswordFormat, error, cleanError]
  );

  if (pendingPassword) {
    return (
      <InsertPassword
        handleSetPasswordOnClick={handleSetPasswordOnClick}
        email={user && user.email}
        loading={requesting}
        errorInPasswordFormat={errorInPasswordFormat}
        samePasswordAsBeforeError={error}
        updateErrorInPasswordFormat={setErrorInPasswordFormat}
      />
    );
  }

  if (pendingEmailCode && user) {
    return (
      <EmailCodeVerificationScreen
        {...{ user }}
        onClick={handleEmailVerificationOnClick}
        loading={checkVerificationCodeRequesting}
        validCode={checkVerificationCodeValid}
        buttonTouched={emailCodeButtonIsTouched}
        resendCode={sendVerificationCode}
        resendCodeLoading={sendVerificationCodeRequesting}
        isPasswordRecovery={checkVerificationCodeMode === 'password-recovery'}
      />
    );
  }

  return (
    <InsertEmail
      handleSignUpOnClick={handleSignUpOnClick}
      handleContinueAsAGuestOnClick={handleContinueAsAGuestOnClick}
      existingEmail={user ? user.email : ''}
      loading={requesting}
      error={error}
      callbackUrl={user && user.callbackUrl}
      checkVerificationCodeMode={checkVerificationCodeMode}
      userAlreadyExisted={user && user.userAlreadyExisted}
      consumer={user && user.consumer}
      initialLoading={loading}
      iframeUrl={iframeUrl}
    />
  );
}

export default SignUpContainer;
