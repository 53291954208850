import React from 'react';
import { connect } from 'react-redux';

import FcDepositVerificationComponent from '../../../components/Fastcash/FcDepositVerification';

import FastcashActions from '../../../redux/FastcashRedux';

const FcDepositVerification = ({ history, error, validation, requesting, fcValidateDepositRequest }) => (
  <FcDepositVerificationComponent
    {...{
      history,
      error,
      validation,
      requesting,
      fcValidateDepositRequest
    }}
  />
);

const mapStateToProps = state => {
  return {
    user: state.user.user,
    error: state.fastcash.error,
    validation: state.fastcash.validation,
    requesting: state.fastcash.requesting
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fcValidateDepositRequest: data => dispatch(FastcashActions.fcValidateDepositRequest(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FcDepositVerification);
