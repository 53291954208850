// @Vendors
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Spinner } from 'reactstrap';
import styled from '@emotion/styled';

// @Styles
import './CustomButtonStyles.scss';

const CustomButton = ({ loading, disabled, color, width, ...props }) => {
  const ButtonColor = styled(Button)`
    background: ${props => props.theme.buttonBackground};
    color: ${props => props.theme.buttonColor};
    width: ${width};
    &:hover {
      color: ${props => props.theme.buttonColor};
    }
    @media (max-width: 450px) {
      width: 90%;
    }
  `;
  return (
    <ButtonColor
      {...props}
      className={`kyc-custom-button ${loading ? 'loading' : ''}`}
      color={color}
      disabled={disabled || loading}
    >
      {loading ? (
        <div className="d-flex align-items-center justify-content-center">
          <div style={{ opacity: 0 }}>{props.children}</div>
          <div style={{ position: 'absolute' }}>
            <Spinner size="sm" color="white" />
          </div>
        </div>
      ) : (
        props.children
      )}
    </ButtonColor>
  );
};

CustomButton.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  color: PropTypes.string
};

CustomButton.defaultProps = {
  color: '#1A73E8',
  loading: false,
  disabled: false
};

export default CustomButton;
