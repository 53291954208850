// @Vendors
import React, { Component } from 'react';
import { Button, MDBIcon } from 'mdbreact';
import classNames from 'classnames';
import _ from 'lodash';

import './ButtonStyles.scss';

const classMap = {
  primary: 'custom-primary',
  secondary: 'custom-secondary'
};

class RadioButton extends Component {
  render() {
    const {
      label,
      onClick,
      href,
      icon,
      className = '',
      id = '',
      type = 'primary',
      fullWidth = false,
      disabled = false
    } = this.props;
    const rootClassName = classNames(classMap[type], { 'full-width': fullWidth }, className);

    return (
      <Button
        disabled={disabled}
        size="lg"
        href={href}
        id={id || _.camelCase(label)}
        className={rootClassName}
        onClick={onClick}
      >
        {icon && <MDBIcon icon={icon} className="mr-1" />}
        {label}
      </Button>
    );
  }
}

export default RadioButton;
