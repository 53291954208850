import React from 'react';

import CurrencyFormat from 'react-currency-format';
import { withTranslation } from 'react-i18next';
import CheckoutStep from '../../Layout/CheckoutStep';

const FcDepositFinishComponent = ({ history, kyc: { name, lastname, email }, deposit, t }) => {
  return (
    <div className="d-flex flex-column align-items-center">
      <CheckoutStep
        title={t('fastcash.fcDepositFinish.title', { name })}
        hideGoBack
        next={() => history.push('/br/deposit-verification')}
        buttonContent={t('fastcash.fcDepositFinish.nextButton')}
      >
        <div className="fc-step">
          <p className="description">{t('fastcash.fcDepositFinish.description1')}</p>
          <p className="description mt-4">{t('fastcash.fcDepositFinish.description2')}</p>

          <div className="transfer-detail folded">
            <small>
              {t('fastcash.fcDepositFinish.idLabel')}
              {deposit ? deposit._id : ''}
            </small>
            <small>
              {t('fastcash.fcDepositFinish.order.label')}:{' '}
              <CurrencyFormat
                value={deposit.amount}
                displayType="text"
                thousandSeparator=","
                decimalSeparator="."
                fixedDecimalScale
                decimalScale={2}
                prefix="$ "
              />
            </small>
            <small>
              {t('fastcash.fcDepositFinish.fee.label')}:{' '}
              <CurrencyFormat
                value={deposit.feeAmount}
                displayType="text"
                thousandSeparator=","
                decimalSeparator="."
                fixedDecimalScale
                decimalScale={2}
                prefix="$ "
              />
            </small>
            <small>
              {t('fastcash.fcDepositFinish.total.label')}:{' '}
              <CurrencyFormat
                value={deposit.amount + deposit.feeAmount}
                displayType="text"
                thousandSeparator=","
                decimalSeparator="."
                fixedDecimalScale
                decimalScale={2}
                prefix="$ "
              />
            </small>
            <small>
              {t('fastcash.fcDepositFinish.from.label')}: {name} {lastname}
            </small>
            <small>{email}</small>
          </div>
        </div>
      </CheckoutStep>
    </div>
  );
};

export default withTranslation()(FcDepositFinishComponent);
