import apisauce from 'apisauce';

const privateRequest = ({ api, method, endpoint, params, options, token, contentType = 'application/json' }) => {
  const headers = options ? options.headers : {};
  return api[method](endpoint, params, {
    ...options,
    headers: {
      ...headers,
      'Content-Type': contentType,
      Authorization: `Token ${token}`
    }
  });
};

const privateGet = (api, token, endpoint, params, options) =>
  privateRequest({ api, method: 'get', endpoint, params, options, token });

const privatePost = (api, token, endpoint, params, options, contentType) =>
  privateRequest({ api, method: 'post', endpoint, params, options, token, contentType });

const privatePatch = (api, token, endpoint, params, options) =>
  privateRequest({ api, method: 'patch', endpoint, params, options, token });

const addQueryParams = (endpoint, params) =>
  Object.keys(params)
    .reduce((ant, paramKey) => (params[paramKey] ? `${ant}${paramKey}=${params[paramKey]}&` : ant), `${endpoint}?`)
    .slice(0, -1);

const create = (baseURL = '') => {
  const api = apisauce.create({
    baseURL,
    headers: {
      'Cache-Control': 'no-cache'
    },
    timeout: 1000000
  });

  const naviMonitor = response => console.log('API MONITOR ACTIVITY:', response);
  api.addMonitor(naviMonitor);

  const getMe = token => privateGet(api, token, '/user/me');

  const login = (token, email, password) => privatePost(api, token, '/auth/user/login', { email, password });

  const signUp = (token, email) => privatePatch(api, token, '/auth/user/register', { email });

  const continueAsAGuest = (token, email) => privatePatch(api, token, '/auth/user/guest', { email });

  const changePassword = (token, password, checkVerificationCodeMode, verificationCode) =>
    privatePatch(api, token, '/auth/user/change-password', {
      password,
      changePasswordMode: checkVerificationCodeMode,
      verificationCode
    });

  const checkAuth = token => privatePost(api, token, '/auth/check', {}, {});

  const checkCrossAuth = token => api.post('/auth/check/cross', { token });

  const createDeposit = (token, data) => privatePost(api, token, '/deposit', data, {});

  const getDeposits = (token, { limit = 20, offset = 0, filter }) =>
    privateGet(api, token, addQueryParams('/deposits', { limit, offset, filter }));
  // privateGet(api, token, '/deposits');

  const getDeposit = (token, id) => privateGet(api, token, `/deposit/${id}`);

  const updateDeposit = (token, id, data) => privatePatch(api, token, `/deposit/${id}`, data, {});

  const createWithdraw = (token, data) => privatePost(api, token, '/withdraw', data, {});

  const getWithdraws = (token, { limit = 20, offset = 0, filter = null }) =>
    privateGet(api, token, addQueryParams('/withdraws', { limit, offset, filter }));

  const getWithdraw = (token, id) => privateGet(api, token, `/withdraw/${id}`);

  const updateWithdraw = (token, id, data) => privatePatch(api, token, `/withdraw/${id}`, data, {});

  const addAccount = (token, data) => privatePatch(api, token, `/user/bank-account`, data, {});

  const getBanks = token => privateGet(api, token, '/payments/banks');

  const userInfo = token => privateGet(api, token, '/checkout/info');

  const getCheckoutConfirmInfo = (token, id, type) => privateGet(api, token, `/checkout/confirm/${type}/${id}`);

  const checkoutConfirm = (token, id, quoteHash, type) =>
    privatePost(api, token, `/checkout/confirm/${type}/${id}`, { quoteHash });

  const checkoutProvider = (token, countryCode) =>
    privateGet(api, token, addQueryParams('/checkout/provider', { countryCode }));

  const getCheckoutStatus = data => api.post('/checkout/status-check', data);

  const getQuote = (fromAsset, toAsset, type, pid) =>
    api.get(addQueryParams('/quotes', { fromAsset, toAsset, type, pid: pid || 'qG6peWvso3' }));

  const getRate = token => privateGet(api, token, '/rates');

  const limitEnforcementCheck = token => privateGet(api, token, '/limitenforcement');

  const getLimits = ({ countryCode }) => api.get(`/limit/${countryCode}`);

  const sendLimitEmail = token => privatePost(api, token, '/kyc/sendAMLLimitNotif', {});

  const createDepositAps = (token, data) => privatePost(api, token, '/aps/deposit', data, {});
  const validateDepositAps = data => api.post(`/aps/deposit/${data.depositId}/fc-validation`, data);
  const getFcSubpaymentMethods = data =>
    api.get(`/aps/fastcash/subpayment-methods?paymentMethod=${data.paymentMethod}`);

  const getAvailablePaymentMethods = token => privateGet(api, token, '/paymentmethods');
  const sendVerificationCode = (token, checkVerificationCodeMode) =>
    privatePatch(api, token, addQueryParams('/auth/user/send-code', { checkVerificationCodeMode }));

  const checkVerificationCode = (token, route, verificationCode) =>
    privatePost(api, token, route, { verificationCode });
  const getFiatCurrencies = () => api.get(`/currencies/fiat`);
  const getCryptoCurrencies = () => api.get(`/currencies/crypto`);

  const passwordRecovery = (token, email) => privatePatch(api, token, '/auth/user/password-recovery', { email });

  const validateCheckout = token => privateGet(api, token, '/checkout/validate');

  const saveWalletAddress = (token, address, memo) =>
    privatePost(api, token, '/checkout/wallet-address', { address, memo }, {});

  const validateStablexWallet = (asset, wallet) =>
    api.get(addQueryParams('/checkout/stablex/validate-wallet', { asset, wallet }));

  return {
    getMe,
    login,
    signUp,
    continueAsAGuest,
    changePassword,
    checkAuth,
    checkCrossAuth,
    addAccount,
    userInfo,
    limitEnforcementCheck,
    passwordRecovery,

    createDeposit,
    getDeposits,
    getDeposit,
    updateDeposit,
    getCheckoutStatus,

    getCheckoutConfirmInfo,
    checkoutConfirm,
    checkoutProvider,
    validateCheckout,

    createWithdraw,
    getWithdraws,
    getWithdraw,
    updateWithdraw,

    getBanks,

    getQuote,

    createDepositAps,
    validateDepositAps,
    getFcSubpaymentMethods,
    getRate,
    getLimits,
    sendLimitEmail,
    getAvailablePaymentMethods,

    sendVerificationCode,
    checkVerificationCode,
    getFiatCurrencies,
    getCryptoCurrencies,

    saveWalletAddress,
    validateStablexWallet
  };
};

export default {
  create
};
