/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState } from 'react';
import { Col, Row, CustomInput as Check } from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import { withTranslation, Trans } from 'react-i18next';
import { PropagateLoader } from 'react-spinners';

// @Constants
import { moneyMapping } from '../../constants/countryMap';

import CheckoutStep from '../Layout/CheckoutStep';

import { getSpecificProviderDisclaimers } from '../../utils/consumerConfig';

import './index.scss';

const TermsAndCond = (countryCode, type) => {
  const providerDisclaimers = getSpecificProviderDisclaimers();
  return (
    <Trans i18nKey={`welcome.termsAndCond${type === 'Withdraw' ? '.withdraw' : ''}`}>
      {providerDisclaimers && providerDisclaimers.termsAndCond && (
        <a
          href={`${providerDisclaimers.termsAndCond}/${countryCode.toLowerCase()}`}
          target="_blank"
          rel="noopener noreferrer"
        />
      )}

      {providerDisclaimers && providerDisclaimers.privacyPolicies && (
        <a
          href={`${providerDisclaimers.privacyPolicies}/${countryCode.toLowerCase()}`}
          target="_blank"
          rel="noopener noreferrer"
        />
      )}
    </Trans>
  );
};

const TermsAndConditions = ({ countryCode, onTermsAndConditionCheck, checked, type }) => (
  <Check
    type="checkbox"
    id="TermsAndCond"
    label={TermsAndCond(countryCode, type)}
    onChange={onTermsAndConditionCheck}
    defaultChecked={checked}
  />
);

const WelcomeScreen = ({ onClick, history, user, t, quote, paymentMethodFee, loading }) => {
  const [termsAndCondCheck, setTermsAndCondCheck] = useState(false);

  const toggleTermsAndCond = event => setTermsAndCondCheck(event.target.checked);
  const countryCode = user && user.countryCode;
  const { consumer } = user;
  const consumerIsBinance = consumer === 'binance';

  const fee = paymentMethodFee ? paymentMethodFee.fee : quote.fee;
  const flatFee = paymentMethodFee ? paymentMethodFee.flatFee || 0 : quote.flatFee || 0;
  const minFee = paymentMethodFee ? paymentMethodFee.minFee : quote.minFee;

  return (
    <div className="d-flex flex-column align-items-center">
      {loading || !quote || Object.keys(quote).length === 0 ? (
        <div style={{ minHeight: '400px' }} className="d-flex justify-content-center align-items-center">
          <PropagateLoader sizeUnit="px" size={10} color="#448aff" />
        </div>
      ) : (
        <>
          <CheckoutStep
            title={t(`welcome.welcomeScreen.${user.type}`)}
            next={onClick}
            prev={history.goBack}
            loading={!countryCode && !user.checkoutAmount}
            buttonContent={t('welcome.startBtn')}
            disabled={!termsAndCondCheck}
            hideGoBack
            cancelActionBtn={() => (window.parent.location.href = user.callbackUrl)}
          >
            <div className="transfer-detail folded">
              <p style={{ fontSize: '20px', textAlign: 'left' }}>{t('welcome.title')} </p>
              <p style={{ textAlign: 'left' }}>{t(`welcome.description.${user.type}`)} </p>
              <p style={{ textAlign: 'left' }} className="gray-text">
                {user.email}
              </p>
              <p style={{ textAlign: 'left' }} className="gray-text">
                {t(`welcome.order.${user.type}`)}
                {': '}
                {user.type === 'Withdraw' ? (
                  <>
                    {user.originAsset} {user.cryptoAmount}
                  </>
                ) : (
                  <CurrencyFormat
                    value={user.checkoutAmount}
                    displayType="text"
                    thousandSeparator=","
                    decimalSeparator="."
                    fixedDecimalScale
                    decimalScale={2}
                    prefix={`${moneyMapping[countryCode]} `}
                  />
                )}{' '}
              </p>
              {user.type === 'Withdraw' && (
                <p style={{ textAlign: 'left' }} className="gray-text">
                  {t('welcome.estimatedFees')}*: {quote.fee}%
                </p>
              )}

              {user.type === 'Deposit' && (
                <p style={{ textAlign: 'left' }} className="gray-text">
                  {t('welcome.processorCharge')}
                  {paymentMethodFee ? '*' : ' '}
                  {': '}
                  <CurrencyFormat
                    value={user.feeAmount}
                    displayType="text"
                    thousandSeparator=","
                    decimalSeparator="."
                    fixedDecimalScale
                    decimalScale={2}
                    prefix={`${moneyMapping[countryCode]} `}
                  />
                </p>
              )}
              {user.type === 'Deposit' && (
                <small style={{ textAlign: 'left' }} className="gray-text">
                  ({flatFee > 0 ? `${t('welcome.flatFee')} ${moneyMapping[countryCode]} ${flatFee}` : ''}
                  {flatFee > 0 && fee > 0 ? ' + ' : ''}
                  {fee > 0 ? `${t('welcome.varFee')} ${fee}%` : ''}
                  {minFee > 0 ? ` (min ${moneyMapping[countryCode]} ${minFee + (flatFee || 0)})` : ''})
                </small>
              )}
              {user.type === 'Withdraw' && (
                <p style={{ textAlign: 'left' }} className="gray-text comision">
                  <small>* {t('welcome.withdrawDisclaimer')}.</small>
                </p>
              )}
              {user.type === 'Deposit' && (
                <>
                  <div style={{ height: '20px' }} />
                  <p style={{ textAlign: 'left', fontSize: '20px' }} className="totalTranfer">
                    {t(`welcome.total.${user.type}`)}{' '}
                    <CurrencyFormat
                      value={user.checkoutAmount + user.feeAmount}
                      displayType="text"
                      thousandSeparator=","
                      decimalSeparator="."
                      fixedDecimalScale
                      decimalScale={2}
                      prefix={`${moneyMapping[countryCode]} `}
                    />
                  </p>
                </>
              )}
            </div>
            {user.type === 'Deposit' && user.originAsset !== 'MXN' && (
              <Row>
                <Col className="mb-0 mt-3" md={{ size: 6, offset: 3 }}>
                  <p className="text-center fee-disclaimer mb-0">{t('welcome.processorFeeDisclaimer')}</p>
                </Col>
              </Row>
            )}
            <Row className="mt-3">
              <Col className="terms-and-conditions-welcome" md={{ size: 6, offset: 3 }}>
                <p className="mb-4">
                  <Trans i18nKey={`welcome.termsAndCondDesc${user.type === 'Withdraw' ? '.withdraw' : ''}`}>
                    <br />
                  </Trans>
                </p>
                <div className={`checks-container${consumerIsBinance ? ' center-items' : ''}`}>
                  <TermsAndConditions
                    {...{ countryCode, t }}
                    checked={termsAndCondCheck}
                    type={user.type}
                    onTermsAndConditionCheck={toggleTermsAndCond}
                  />
                </div>
              </Col>
            </Row>
          </CheckoutStep>
        </>
      )}
    </div>
  );
};

export default withTranslation()(WelcomeScreen);
