import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CurrencyFormat from 'react-currency-format';

import CheckoutStep from '../Layout/CheckoutStep';

// @Images
import IconBank from '../../images/deposit/icon-bank-2.png';

const RefTooltip = ({ visible, setVisible }) => {
  const openTooltip = event => {
    event.stopPropagation();
    setVisible(true);
  };
  return (
    <div className="tooltip-container">
      <a className="question-mark" onClick={openTooltip}>
        ?
      </a>
      {visible && (
        <div className="tooltip-content br" onClick={e => e.stopPropagation()}>
          Inclua o número de referência do seu pedido, caso permitido pelo seu banco. Se o seu banco não permitir, você
          poderá ignorá-lo.
        </div>
      )}
    </div>
  );
};

const DepositSummaryBR = ({
  history,
  kyc: { name, lastname },
  user: { checkoutAmount, feeAmount },
  ticket,
  setStep
}) => {
  const [copied, setCopied] = useState('none');
  const [visible, setVisible] = useState(false);

  const bankToTransfer = (ticket && ticket.bankToTransfer) || { data: {} };
  const {
    bankName = '',
    holderName = '',
    data: { cnpj = '', bankNumber = '', agency = '', account = '' }
  } = bankToTransfer;

  return (
    <div className="d-flex flex-column align-items-center">
      <CheckoutStep
        title="Dados para transferência"
        next={() => setStep('finish')}
        prev={history.goBack}
        buttonContent="Seguinte"
        hideGoBack
      >
        <p className="description mt-4">
          Faça uma transferência bancária de uma conta em seu nome{' '}
          <b>
            {name} {lastname},
          </b>{' '}
          no <b>valor exato</b> solicitado e inclua o <b>número de referência</b> do seu pedido, caso permitido pelo seu
          banco, para que possamos identificar melhor seu pagamento. Se o seu banco não permitir que você inclua o
          número de referência, você poderá ignorá-lo.
        </p>
        <div className="transfer-detail">
          <Row>
            <Col md="3">
              <img src={IconBank} width="60" alt="iconBank" />
            </Col>
            <Col>
              <span className="bank-description">{bankName}</span>
              <span className="bank-description">Numero de Banco: {bankNumber}</span>
              <span className="bank-description">Favorecido: {holderName}</span>
              <span className="bank-description">CNPJ: {cnpj}</span>
            </Col>
          </Row>
          <Row className="mt-4 mb-2">
            <Col md="9" className="align-left">
              <span className="transfer-description">
                Agência: <b>{agency}</b>
              </span>
            </Col>
            <Col md="3">
              <CopyToClipboard text={agency} onCopy={() => setCopied('agency')}>
                <span className="copyButton">{copied === 'agency' ? 'copiado' : 'copiar'}</span>
              </CopyToClipboard>
            </Col>
          </Row>
          <Row className="mt-2 mb-2">
            <Col md="9" className="align-left">
              <span className="transfer-description">
                Conta-Corrente: <b>{account}</b>
              </span>
            </Col>
            <Col md="3">
              <CopyToClipboard text={account} onCopy={() => setCopied('account')}>
                <span className="copyButton">{copied === 'account' ? 'copiado' : 'copiar'}</span>
              </CopyToClipboard>
            </Col>
          </Row>
          <Row className="mt-2 mb-2">
            <Col md="9" className="align-left">
              <span className="transfer-description">
                Nº Referência: <b>{ticket ? ticket._id : ''}</b>
              </span>
              <RefTooltip {...{ visible, setVisible }} />
            </Col>
            <Col md="3">
              <CopyToClipboard text={ticket ? ticket._id : ''} onCopy={() => setCopied('ticket')}>
                <span className="copyButton">{copied === 'ticket' ? 'copiado' : 'copiar'}</span>
              </CopyToClipboard>
            </Col>
          </Row>
          <Row className="mt-2 mb-2">
            <Col md="9" className="align-left">
              <span className="transfer-description">
                Total:{' '}
                <b>
                  <CurrencyFormat
                    value={checkoutAmount + feeAmount}
                    displayType="text"
                    thousandSeparator=","
                    decimalSeparator="."
                    fixedDecimalScale
                    decimalScale={2}
                    prefix="R$ "
                  />
                </b>
              </span>
            </Col>
          </Row>
        </div>
      </CheckoutStep>
    </div>
  );
};

export default DepositSummaryBR;
