import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import LimitCheckStatusComponent from '../../components/LimitEnforcement/LimitCheckStatus';

import DepositActions from '../../redux/DepositRedux';

const LimitEnforcementCheckStatus = ({ checkoutRequest, token, status, error, requesting, history, t, match }) => (
  <LimitCheckStatusComponent
    requestCheckoutStatus={checkoutRequest}
    status={status}
    error={error}
    token={token}
    requesting={requesting}
    langParam={match.params.lang}
    history={history}
    {...{ t }}
  />
);

const mapStateToProps = ({ deposit: { error, requesting }, user: { user }, auth: { token } }) => {
  return {
    user,
    error,
    requesting,
    token
  };
};

const mapDispatchToProps = dispatch => {
  return {
    checkoutRequest: data => dispatch(DepositActions.getCheckoutStatus(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LimitEnforcementCheckStatus));
