export { TicketNotCreated } from './ticket';

const RouteValidator = (list = [], state) => {
  for (let i = 0; i < list.length; i += 1) {
    const validation = list[i];

    const result = validation(state);

    if (result) {
      return result;
    }
  }

  return { status: 'success' };
};

export default RouteValidator;
