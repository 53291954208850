import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import LimitDocUploadSuccessComponent from '../../components/LimitEnforcement/LimitDocUploadSuccess';

const LimitDocUploadSuccess = ({ checkoutRequest, token, status, error, requesting, history, user, kyc, t, match }) => (
  <LimitDocUploadSuccessComponent
    requestCheckoutStatus={checkoutRequest}
    status={status}
    error={error}
    token={token}
    // onClick={() => history.push('/limit-enforcement/check-status')}
    onClick={() => {
      window.location.href = user.callbackUrl;
    }}
    requesting={requesting}
    langParam={match.params.lang}
    history={history}
    kyc={kyc}
    {...{ t }}
  />
);

const mapStateToProps = ({ deposit: { error, requesting }, user: { user, kyc }, auth: { token } }) => {
  return {
    user,
    kyc,
    error,
    requesting,
    token
  };
};

export default connect(mapStateToProps, null)(withTranslation()(LimitDocUploadSuccess));
