import React, { useEffect, useState, useCallback } from 'react';
import { Route } from 'react-router-dom';
import { useDispatch, useSelector, useStore } from 'react-redux';
import AuthActions from '../../../redux/AuthRedux';

import RouteValidator from '../../../utils/RouteValidator';

const DEFAULT_PUBLIC_ROUTE = 'https://www.latamex.com';

function useAuth() {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const dispatch = useDispatch();
  const token = useSelector(state => state.auth.token);
  const authenticated = useSelector(state => state.auth.authenticated);
  const checkAuthRequest = useCallback(() => dispatch(AuthActions.checkAuthRequest(token)), [dispatch, token]);

  useEffect(() => {
    if (isAuthenticated === null) {
      checkAuthRequest();
    }
  }, [checkAuthRequest, isAuthenticated]);

  useEffect(() => {
    setIsAuthenticated(authenticated);
  }, [authenticated]);

  return isAuthenticated;
}

function PrivateRoute({ component: Comp, validators, ...props }) {
  const isAuthenticated = useAuth();
  const store = useStore();
  return (
    <Route
      {...props}
      render={routeProps => {
        const state = store.getState();
        const validatorResult = RouteValidator(validators, state);

        if (validatorResult.status === 'success') {
          if (isAuthenticated === null) return null;
          if (isAuthenticated === false) {
            window.parent.location.href = DEFAULT_PUBLIC_ROUTE;
            return null;
          }

          return <Comp {...routeProps} />;
        }

        if (validatorResult.Redirect) {
          return validatorResult.Redirect;
        }

        return null;
      }}
    />
  );
}

export default PrivateRoute;
