// @Vendors
import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
// @Actions
import AuthActions from '../../redux/AuthRedux';

import Login from '../../components/Login';
import SignUp from '../../components/SignUp';

import { ZendeskWebApi } from '../../utils/zendesk';
import i18n from '../../i18n';
import LocalStorageService from '../../services/LocalStorageService';

class Auth extends Component {
  state = {
    domIsReady: false,
    iframeToken: null,
    iframeUrl: `${process.env.REACT_APP_API_URL.substring(0, process.env.REACT_APP_API_URL.length - 3)}session`
  };

  componentDidMount() {
    const { history, match, saveTokenHeader } = this.props;

    const encodedValue = sessionStorage.getItem('checkVerificationCodeMode');
    const stringValue = encodedValue && window.atob(encodedValue);
    if (((stringValue && JSON.parse(stringValue)) || '') === 'password-recovery') {
      history.push('/auth/login');
      sessionStorage.removeItem('checkVerificationCodeMode');
    }

    const query = queryString.parse(history.location.search.replace('?', ''));
    if (!query.authtoken) {
      window.addEventListener('message', this.handleIframeSessionResponse);
      if (query.token) {
        this.setState({ iframeToken: btoa(JSON.stringify({ token: query.token })) });
      }
    }

    if (query.authtoken) {
      saveTokenHeader(query.authtoken);
      history.push('/');
    }

    global.addEventListener('load', this.enableDomReady);
    if (document.readyState === 'complete') {
      this.enableDomReady();
    }

    if (match.params.type !== 'login') {
      LocalStorageService.removeToken();
      LocalStorageService.removeTokenEmail();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { userInfo, history } = this.props;
    const prevToken = prevState.iframeToken;
    const newToken = this.state.iframeToken;
    const { saveTokenHeader } = this.props;
    if (newToken && newToken === 'notTokenFound') {
      history.push('/error/00004');
    }
    if (newToken && prevToken !== newToken && newToken !== 'notTokenFound') {
      const { token } = JSON.parse(atob(newToken));
      saveTokenHeader(token);
      userInfo({ history });
    }

    const { user, checkAuth, checkAuthWontRequest, match } = this.props;
    if (match.params.type === 'login' && user) {
      const emailInLocalStorage = LocalStorageService.getTokenEmail() || '';
      if (user.email !== emailInLocalStorage) {
        checkAuthWontRequest();
        LocalStorageService.removeToken();
        LocalStorageService.removeTokenEmail();
      } else {
        checkAuth();
      }
    }

    ZendeskWebApi('webWidget', 'show', 'true');
    if (user) {
      if ((user.originAsset === 'ARS' || user.destinationAsset === 'ARS') && i18n.language !== 'es') {
        i18n.changeLanguage('es');
        ZendeskWebApi('webWidget', 'setLocale', 'es');
      }

      if ((user.originAsset === 'BRL' || user.destinationAsset === 'BRL') && i18n.language !== 'pt') {
        i18n.changeLanguage('pt');
        ZendeskWebApi('webWidget', 'setLocale', 'pt');
      }

      if ((user.originAsset === 'MXN' || user.destinationAsset === 'MXN') && i18n.language !== 'mx') {
        i18n.changeLanguage('mx');
        ZendeskWebApi('webWidget', 'setLocale', 'es');
      }
    }
  }

  enableDomReady = () => {
    this.setState({
      domIsReady: true
    });
  };

  handleIframeSessionResponse = ({ origin, data }) => {
    if (this.sourceString(origin) === this.sourceString(this.state.iframeUrl)) {
      if (data.event === 'tokenValue' && data.tokenValue) {
        this.setState({
          iframeToken: data.tokenValue
        });
      }
    }
  };

  sourceString = url =>
    url
      .replace('http://', '')
      .replace('https://', '')
      .replace('www.', '')
      .split(/[/?#]/)[0];

  render() {
    const { domIsReady, iframeToken } = this.state;
    const { match } = this.props;

    if (!['sign-up', 'login'].includes(match.params.type)) {
      window.parent.location.href = 'https://www.latamex.com';
      return null;
    }

    if (!domIsReady) {
      return <div className={`overlay-loading ${!domIsReady ? 'visible' : ''}`} />;
    }

    const loading = !iframeToken;
    switch (match.params.type) {
      case 'login':
        return <Login loading={loading} iframeUrl={this.state.iframeUrl} />;
      case 'sign-up':
        return <SignUp loading={loading} iframeUrl={this.state.iframeUrl} />;
      default:
    }
  }
}

const mapStateToProps = state => {
  return {
    user: state.user.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    saveTokenHeader: token => dispatch(AuthActions.saveTokenHeader(token)),
    userInfo: data => dispatch(AuthActions.userInfo(data)),
    checkAuth: () => dispatch(AuthActions.checkAuthRequest()),
    checkAuthWontRequest: () => dispatch(AuthActions.checkAuthWontRequest())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
