import { takeLatest, takeEvery, all } from 'redux-saga/effects';
import API from '../services/Api';
import KYCAPI from '../services/KycApi';
/* ------------- Types ------------- */
import { DepositTypes } from '../redux/DepositRedux';
import { WithdrawTypes } from '../redux/WithdrawRedux';
import { AuthTypes } from '../redux/AuthRedux';
import { UserTypes } from '../redux/UserRedux';
import { FastcashTypes } from '../redux/FastcashRedux';
import { CheckoutTypes } from '../redux/CheckoutRedux';
/* ------------- Sagas ------------- */
import {
  createDeposit,
  getDeposits,
  getDeposit,
  updateDeposit,
  getBanks,
  generateDepositTicket,
  getCheckoutStatus,
  getQuote,
  getLimits,
  sendLimitEmail,
  getAvailablePaymentMethods
} from './DepositSagas';
import { createWithdraw, getWithdraws, getWithdraw, updateWithdraw } from './WithdrawSagas';
import {
  login,
  signUp,
  continueAsAGuest,
  changePassword,
  checkAuth,
  tokenExchange,
  userInfo,
  sendVerificationCode,
  checkVerificationCode,
  passwordRecovery
} from './AuthSagas';
import { addAccount, getKycVerificationUrlRequest } from './UserSagas';
import {
  getCurrencies,
  getRate,
  checkoutConfirm,
  checkoutProvider,
  getCheckoutConfirmInfo,
  validateCheckout,
  saveWalletAddress
} from './CheckoutSagas';
import { createDepositAps, validateDepositAps, getSubPaymentMethodsAps } from './FastcashSagas';

/* ------------- API ------------- */

const api = API.create(process.env.REACT_APP_API_URL);
const kycApi = KYCAPI.create(process.env.REACT_APP_KYC_URL);

/* ------------- Connect Types To Sagas ------------- */

export default function* root() {
  yield all([
    // AUTH
    takeLatest(AuthTypes.LOGIN_REQUEST, login, api),
    takeLatest(AuthTypes.SIGN_UP_REQUEST, signUp, api),
    takeLatest(AuthTypes.CONTINUE_AS_A_GUEST_REQUEST, continueAsAGuest, api),
    takeLatest(AuthTypes.CHANGE_PASSWORD_REQUEST, changePassword, api),
    takeLatest(AuthTypes.CHECK_AUTH_REQUEST, checkAuth, api),
    takeLatest(AuthTypes.TOKEN_EXCHANGE_REQUEST, tokenExchange, api),
    takeLatest(AuthTypes.USER_INFO, userInfo, api),
    takeLatest(AuthTypes.SEND_VERIFICATION_CODE_REQUEST, sendVerificationCode, api),
    takeLatest(AuthTypes.CHECK_VERIFICATION_CODE_REQUEST, checkVerificationCode, api),
    takeLatest(AuthTypes.PASSWORD_RECOVERY_REQUEST, passwordRecovery, api),
    // USER
    takeLatest(UserTypes.ADD_ACCOUNT_REQUEST, addAccount, api),
    takeLatest(UserTypes.GET_KYC_VERIFICATION_URL_REQUEST, getKycVerificationUrlRequest, kycApi),
    // DEPOSITS
    takeLatest(DepositTypes.CREATE_DEPOSIT_REQUEST, createDeposit, api),
    takeEvery(DepositTypes.GET_DEPOSITS_REQUEST, getDeposits, api),
    takeLatest(DepositTypes.GET_DEPOSIT_REQUEST, getDeposit, api),
    takeLatest(DepositTypes.UPDATE_DEPOSIT_REQUEST, updateDeposit, api),
    takeLatest(DepositTypes.GET_AVAILABLE_BANKS_REQUEST, getBanks, api),
    takeLatest(DepositTypes.GENERATE_DEPOSIT_TICKET, generateDepositTicket, api),
    takeLatest(DepositTypes.GET_CHECKOUT_STATUS, getCheckoutStatus, api),
    takeLatest(DepositTypes.GET_QUOTE, getQuote, api),
    takeLatest(DepositTypes.GET_LIMITS, getLimits, api),
    takeLatest(DepositTypes.SEND_LIMIT_EMAIL, sendLimitEmail, api),
    takeLatest(DepositTypes.GET_AVAILABLE_PAYMENT_METHODS, getAvailablePaymentMethods, api),
    // WITHDRAWS
    takeLatest(WithdrawTypes.CREATE_WITHDRAW_REQUEST, createWithdraw, api),
    takeEvery(WithdrawTypes.GET_WITHDRAWS_REQUEST, getWithdraws, api),
    takeLatest(WithdrawTypes.GET_WITHDRAW_REQUEST, getWithdraw, api),
    takeLatest(WithdrawTypes.UPDATE_WITHDRAW_REQUEST, updateWithdraw, api),
    // FASTCASH
    takeLatest(FastcashTypes.FC_GET_SUB_PAYMENT_METHODS_REQUEST, getSubPaymentMethodsAps, api),
    takeLatest(FastcashTypes.FC_CREATE_DEPOSIT_REQUEST, createDepositAps, api),
    takeLatest(FastcashTypes.FC_VALIDATE_DEPOSIT_REQUEST, validateDepositAps, api),
    // CHECKOUT FORM
    takeLatest(CheckoutTypes.SAVE_WALLET_ADDRESS_REQUEST, saveWalletAddress, api),
    takeLatest(CheckoutTypes.GET_CURRENCIES, getCurrencies, api),
    takeLatest(CheckoutTypes.GET_RATE, getRate, api),
    takeLatest(CheckoutTypes.GET_CHECKOUT_CONFIRM_INFO_REQUEST, getCheckoutConfirmInfo, api),
    takeLatest(CheckoutTypes.CHECKOUT_CONFIRM_REQUEST, checkoutConfirm, api),
    takeLatest(CheckoutTypes.CHECKOUT_PROVIDER_REQUEST, checkoutProvider, api),
    takeLatest(CheckoutTypes.VALIDATE_CHECKOUT_REQUEST, validateCheckout, api)
  ]);
}
