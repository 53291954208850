import React from 'react';
import CheckoutStep from '../../components/Layout/CheckoutStep';
import errorConfirmIcon from '../../images/icons/errorConfirmIcon.png';
import { GetSupportUrl } from '../../utils/consumerConfig';

const statusPageMap = {
  pt: 'https://latamex.com/pt/status/',
  es: 'https://latamex.com/estado',
  mx: 'https://latamex.mx/estado'
};

const ConfirmError = ({ t, lang }) => {
  const checkStatusUrl = statusPageMap[lang];
  const supportLang = lang === 'mx' ? 'es' : lang;
  const supportUrl = GetSupportUrl(supportLang);

  return (
    <CheckoutStep hideGoBack hideNextButton title={t('confirmPage.completedError')}>
      <div className="d-flex justify-content-center align-items-center flex-column">
        <img src={errorConfirmIcon} className="mt-4 mb-4" width="237" />

        <p className="description mt-2 mb-2">
          <b>{t('confirmErrorPage.dontWorry')}</b>
          <br />
          {t('confirmErrorPage.description')}
        </p>
        <p className="description mt-2">
          {t('confirmErrorPage.meantime')} <br />
          <a href={checkStatusUrl} target="_blank" rel="noopener noreferrer">
            {t('confirmErrorPage.checkStatus')}
          </a>{' '}
          <br />
          <a href={supportUrl} target="_blank" rel="noopener noreferrer">
            {t('confirmErrorPage.visitHelpCenter')}
          </a>
        </p>
      </div>
    </CheckoutStep>
  );
};

export default ConfirmError;
