import React from 'react';
import { withTranslation, Trans } from 'react-i18next';

import CheckoutStep from '../Layout/CheckoutStep';

import OrderIcon from '../../images/deposit/order-icon@2x.png';

const DepositFinish = ({ onClick, history, user: { quoteSource }, kyc: { name }, t }) => {
  return (
    <div className="d-flex flex-column align-items-center">
      <CheckoutStep
        title={t('deposit.finishStep.title', { name })}
        next={onClick}
        prev={history.goBack}
        buttonContent={t('deposit.finishStep.actionBtn')}
        hideGoBack
      >
        <div className="d-flex align-items-center">
          <img src={OrderIcon} alt="order icon" className="logo" style={{ marginTop: '30px' }} />
        </div>
        <p className="description mt-4">
          <Trans i18nKey="deposit.finishStep.textOne">
            text
            <br />
            text
          </Trans>
        </p>
        {quoteSource && quoteSource !== 'stablex' && (
          <p className="description mt-4">{t('deposit.finishStep.textTwo')}</p>
        )}
        <p className="description mt-4 mb-4 font-bold">
          <small>{t('deposit.finishStep.textThree')}</small>
        </p>
        <p className="description mt-4 last-text-confirmation-step">
          <small>{t('deposit.finishStep.textFour')}</small>
        </p>
      </CheckoutStep>
    </div>
  );
};

export default withTranslation()(DepositFinish);
