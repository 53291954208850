import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { GetSupportUrl } from '../../utils/consumerConfig';

import CheckoutStep from '../Layout/CheckoutStep';
import CustomInput from '../Commons/CustomInput/CustomInput';

import HandMoneyDoc from '../../images/enforcement/hand-money-doc.svg';

const LimitCheckStatusComponent = ({ history, t, langParam }) => {
  const [email, setEmail] = useState('');
  const [touched, setTouched] = useState(false);
  const validEmail = /^[A-Z0-9._+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
  const error = touched && !validEmail && t('checkoutForm.enterValidEmail');
  const lang = langParam === 'mx' ? 'es' : langParam; // The zendesk page is only distinguishing between es and pt for now.
  const supportUrl = GetSupportUrl(lang);

  return (
    <div className="d-flex flex-column align-items-center">
      <CheckoutStep
        title={t('limitEnforcement.checkStatus.title')}
        next={() => true}
        prev={history.goBack}
        disabled={email === '' || !validEmail}
        buttonContent={t('consult')}
        hideGoBack
      >
        <div className="text-center mt-4">
          <img src={HandMoneyDoc} alt="limit exceeded" />
        </div>
        <Row>
          <Col md={{ size: 8, offset: 2 }}>
            <p className="description mb-4 mt-4 fz-14">{t('limitEnforcement.checkStatus.description')}</p>
          </Col>
        </Row>
        <Row>
          <Col md={{ size: 8, offset: 2 }}>
            <CustomInput
              label="Email"
              outline
              onChange={e => setEmail(e.target.value)}
              onBlur={e => setTouched(e.target.value !== '')}
              value={email}
            />
            {error && (
              <small style={{ position: 'relative', top: '-20px' }} className="error">
                {error}
              </small>
            )}
          </Col>
        </Row>
      </CheckoutStep>
      <Row>
        <Col md={{ size: 8, offset: 2 }}>
          <p className="status-description mt-4 mb-2">
            {t('limitEnforcement.checkStatus.footer')}{' '}
            <a href={supportUrl} target="_blank" rel="noopener noreferrer">
              {t('limitEnforcement.checkStatus.footer.link')}
            </a>
          </p>
        </Col>
      </Row>
    </div>
  );
};

export default LimitCheckStatusComponent;
