export default {
  'account.cancel': 'Cancel',
  'account.changePasswordSuccess': 'Password has been successfully changed',
  'account.createAccountSuccess': 'Account created successfully!',
  'account.insertEmail.enterYourEmail': 'Enter your email',
  'account.insertEmail.error': 'Email already in use',
  'account.insertEmail.invalidEmail': 'Invalid email',
  'account.insertEmail.login': 'Already have an account in Settle ID? Login',
  'account.insertEmail.subtitle': 'To create your Settle ID, we need to verify your email.',
  'account.insertEmail.title': 'Create your Settle ID to login your {{companyName}} account',
  'account.insertEmail.whatIsSettleId': 'What is Settle ID?',
  'account.insertEmail.settleIdPopup': '',
  'account.insertPassword.checkPassword': 'Check your password',
  'account.insertPassword.createAPassword': 'Settle ID - Create a password',
  'account.insertPassword.enterPassword': 'Enter password',
  'account.insertEmail.OK': 'OK',
  'account.insertPassword.passwordRequirements':
    'Your password must be at least 8 characters long and have one letter and one number',
  'account.createAnAccount': 'No Settle ID? Create an account',
  'account.insertPassword.passwordsDoNotMatch': 'Passwords do not match',
  'account.insertPassword.repeatedPasswordError': 'Password was previously used',
  'account.insertPassword.repeatPassword': 'Repeat password',
  'account.login.enterLatamex': 'Enter {{companyName}}',
  'account.login.enterYourEmail': 'Enter your email',
  'account.login.enterYourPassword': 'Enter your password',
  'account.login.forgetPassword': 'I forgot my password',
  'account.login.invalidCredentials': 'Invalid credentials',
  'account.logout': 'Logout',
  'account.returnToAccount': 'Return to my account',
  'account.continueAsAGuest': 'Continue as a guest',
  'account.next': 'Next',
  'account.passwordRecovery.error': 'Account not found',
  'account.passwordRecovery.login': 'Login',
  'account.passwordRecovery.subtitle': 'Enter your email, you will receive a code to reset your password.',
  'account.passwordRecovery.title': 'Settle ID - Reset password',
  'buttons.addAccount': '+ Add account',
  'buttons.confirm': 'Confirm',
  'buttons.deposit': 'Buy',
  'buttons.done': 'Done',
  'buttons.next': 'Next',
  'buttons.withdraw': 'Sell',
  'checkoutForm.buyButton': '',
  'checkoutForm.checkWalletAddress': '',
  'checkoutForm.doNotHaveWallet': '',
  'checkoutForm.enterHigherValue': '',
  'checkoutForm.enterLowerValue': '',
  'checkoutForm.enterValidEmail': '',
  'checkoutForm.enterYourAmount': '',
  'checkoutForm.enterYourEmail': '',
  'checkoutForm.enterYourWallet': '',
  'checkoutForm.includedFees': '',
  'checkoutForm.minAmountError': '',
  'checkoutForm.mustEnterValue': '',
  'checkoutForm.flatFee': ' + flat fee {{fiatAsset}} {{flatFee}}',
  'checkoutForm.minFee': ' ({{fiatAsset}} {{minFee}} min)',
  'checkoutForm.feeAndTermsCondDisclaimer':
    'Price does not include payment processor fees from {{fee}}%{{minFee}}{{flatFee}}. By continuing you are accepting the <1>terms and conditions</1>.',
  'checkoutForm.termsCondDisclaimer': 'By continuing you are accepting the <1>terms and conditions</1>.',
  'checkoutForm.tooltip': '',
  'checkoutForm.wantToBuy': '',
  'checkoutForm.wantToSpend': '',
  'commons.footer.help': '',
  'commons.footer.limits': 'Limits and Commissions',
  'commons.footer.privacy-policy': 'Privacy Policy',
  'commons.footer.rates': '',
  'commons.footer.terms-and-conditions': 'Terms and Conditions',
  'confirmErrorPage.checkStatus': '',
  'confirmErrorPage.description': '',
  'confirmErrorPage.dontWorry': '',
  'confirmErrorPage.meantime': '',
  'confirmErrorPage.visitHelpCenter': '',
  'confirmPage.actualPriceLabel': 'Precio actual',
  'confirmPage.alreadyExecuted.deposit':
    'Your <strong>{{destinationAssetError}}</strong> has been already sent to your <strong>{{destinationWalletAddressError}}</strong> address',
  'confirmPage.alreadyExecuted_transferPending.deposit':
    'Your <strong>{{destinationAssetError}}</strong> will be sentsent to your <strong>{{destinationWalletAddressError}}</strong> address shortly.',
  'confirmPage.btn': 'Confirmar',
  'confirmPage.btnNewOrder': 'Create new order',
  'confirmPage.completedError': 'Tuvimos un problema procesando tu solicitud',
  'confirmPage.completedSuccess':
    'Your<br /><strong>{{destinationAsset}} {{cryptoAmount}}</strong><br />are on their way to your address<br /><strong>{{destinationWalletAddress}}</strong>',
  'confirmPage.completedSuccess_transferPending':
    'Hemos recibido tu confirmacion.<br />Tus<br /><strong>{{cryptoAmount}} {{destinationAsset}}</strong><br />Serán enviados a tu direccion a la brevedad<br /><strong>{{destinationWalletAddress}}</strong>',
  'confirmPage.completedSuccess_userConfirmed':
    'Hemos recibido tu confirmacion.<br />Tus<br /><strong>{{cryptoAmount}} {{destinationAsset}}</strong><br />Serán enviados a tu direccion a la brevedad<br /><strong>{{destinationWalletAddress}}</strong>',
  'confirmPage.completedSuccess.hash': 'Este es tu Tx ID:',
  'confirmPage.loading': 'Cargando...',
  'confirmPage.payStatusAccepted': 'acreditado',
  'confirmPage.payStatusLabel': 'Estado de tu pago:',
  'confirmPage.thanks': 'Thank you!',
  'confirmPage.thanksOperatingWithUs': 'Thanks for operating with us :)',
  'confirmPage.title': 'Confirm your order Nº {{confirmId}}',
  'confirmPage.updatingIn': 'Updating in',
  'confirmPage.yourCryptoAddress': 'Your {{crypto}} address',
  'confirmPage.yourOrderLabel': 'Tu orden',
  consult: '',
  'country.AR': '',
  'country.BR': '',
  'country.MX': '',
  'currencies.ARS': 'Argentinean Peso',
  'currencies.USD': 'US Dollar',
  'currency.country.AR': 'ARS',
  'currency.country.BR': 'BRL',
  'currency.country.MX': '',
  'deposit.addAccount.description': 'You can only add an account under the same name you completed in your KYC.',
  'deposit.addAccount.placeholder.accountCBU': 'Account CBU',
  'deposit.addAccount.placeholder.holderLastName': 'Account holder Last Name',
  'deposit.addAccount.placeholder.holderName': 'Account holder Name(s)',
  'deposit.addAccount.title': 'Add a bank account',
  'deposit.cardConfirmationStep.endingText': '{{cardBrand}} ending in {{last4Digits}}',
  'deposit.cardConfirmationStep.title': 'Credit card deposit',
  'deposit.cardInfoStep.placeholder.cardNumber': 'Card number',
  'deposit.cardInfoStep.placeholder.expirationMonth': 'Expiration Month',
  'deposit.cardInfoStep.placeholder.expirationYear': 'Expiration Year',
  'deposit.cardInfoStep.placeholder.holderName': 'First and Last Name',
  'deposit.cardInfoStep.placeholder.securityCode': 'Security Code',
  'deposit.cardInfoStep.title': 'Credit card details',
  'deposit.cashInfoStep.description':
    'Please go to any of the retail cash network locations and pay to the cashier using the followiing payment code.',
  'deposit.cashInfoStep.title': 'Cash deposit details',
  'deposit.cashSelectStep.pagofacil': 'Pago Fácil',
  'deposit.cashSelectStep.rapipago': 'Rapipago',
  'deposit.cashSelectStep.subtitle': 'Select the cash network you want to use.',
  'deposit.cashSelectStep.title': 'Cash deposit details',
  'deposit.finishStep.actionBtn': 'Finish',
  'deposit.finishStep.question': 'Why do you have to confirm your order?',
  'deposit.finishStep.textFour': '',
  'deposit.finishStep.textOne':
    'The details of your payment order were received correctly. We will send a detail to your email.',
  'deposit.finishStep.textThree':
    'Due to the possible price variation from the time you created your order until we receive payment, we want to make sure you know how much you are paying.',
  'deposit.finishStep.textTwo':
    'When we receive the funds transfer, we will send you an email to confirm your purchase.',
  'deposit.finishStep.title': 'Thank you, {{name}}!',
  'deposit.identityStep.placeholder': 'DNI #',
  'deposit.identityStep.subtitle': 'Please enter your full DNI number.',
  'deposit.identityStep.title': 'Credit card details',
  'deposit.stepFive.hasAccount':
    'Please confirm that you will be sending {{currency}} {{amount}} from your following account:',
  'deposit.stepFive.title': 'Deposit details',
  'deposit.stepFive.withoutAccount':
    'There is no linked bank account in your profile, please add at least one bank account, so we can identify your incoming transfers',
  'deposit.stepFour.subtitleOne': 'Please make one only transfer of',
  'deposit.stepFour.subtitleTwo': 'to the following account',
  'deposit.stepFour.title': 'Deposit Details',
  'deposit.stepOne.radioOne': 'Argentinean Peso',
  'deposit.stepOne.radioTwo': 'US Dollar',
  'deposit.stepOne.subtitle': 'Choose the currency you wish to deposit',
  'deposit.stepOne.title': 'FIAT Deposit',
  'deposit.stepSix.message':
    'Your account balance will be updated once we receive your payment and we will notify via email. You can always check your deposit status in your account.',
  'deposit.stepSix.subtitle': 'Your transfer details were successfully submited!',
  'deposit.stepSix.title': 'Thank you!',
  'deposit.stepThree.subtitle': 'Please enter the amount you want to fund',
  'deposit.stepThree.title': 'Amount to deposit',
  'deposit.stepTwo.radioOne': 'Bank Account',
  'deposit.stepTwo.radioThree': 'Credit card',
  'deposit.stepTwo.radioTwo': 'Cash deposit',
  'deposit.stepTwo.subtitle': 'Choose your payment method',
  'deposit.stepTwo.title': 'Buy ZARS Stable Coin.',
  'emailCode.cancel': 'Cancel',
  'emailCode.correctCode': 'Correct code!',
  'emailCode.incorrectCode': 'Incorrect code!',
  'emailCode.next': 'Next',
  'emailCode.onlyNumbersError': 'Error, you can only enter numbers!',
  'emailCode.reSendCode': 'Forward code',
  'emailCode.reSendCodeError': 'Error in sending the code!',
  'emailCode.reSendCodeSuccess': 'The code was successfully sent!',
  'emailCode.text':
    'Hello <strong>{{email}}</strong>, to continue please enter the 4-digit code we sent you to your email. (Check your spam just in case)',
  'emailCode.recoveryText':
    '<strong>{{email}}</strong> we have received your request. You will receive a code in case the email entered corresponds to a Settle ID account. (Check your spam just in case)',
  'emailCode.title': 'Settle ID - Verify your email',
  'errorPage.DepositsExisting.contact': 'Contact support',
  'errorPage.DepositsExisting.description.deposit':
    'To place a new order you must make payment for your pending order.<br /><br />If you need to cancel, <0>contact support</0>.',
  'errorPage.DepositsExisting.title.deposit': 'There is already a pending order in your name!',
  'errorPage.DepositsExisting.description.withdraw':
    'In order to be able to create a new order, you will need to do the transfer for the order that is pending.',
  'errorPage.DepositsExisting.title.withdraw': 'There is already an open order under your name!',
  'errorPage.AmountTooLow.title': 'Value is too low',
  'errorPage.AmountTooLow.description':
    'The amount you entered is below the minimum allowed. Please create a new order for at least <strong>{{fiat-asset}} {{fiat-minimum}}</strong>.',
  'errorPage.WrongAsset.title': 'Wrong currency',
  'errorPage.WrongAsset.description':
    'The currency you selected <strong>{{fiat-asset}}</strong> does not correspond to the country in which you carried out your identity verification <strong>{{user-country}}</strong>.',
  'errorPage.SessionConflict.title': 'Session error',
  'errorPage.SessionConflict.description':
    "This session has encountered a conflict and cannot continue. Don't worry, if you have already created an order, you will receive the data by email, if not try to create a new order. In case you see this error again, contact support.",
  'errorPage.Cookies.title': 'Cookies error',
  'errorPage.Cookies.description':
    'Cookies in your browser are blocked or not available. Make sure they are enabled and try again. If you see this error again, contact support.',
  'errorPage.retryOrContact': '',
  'errorPage.somethingWrong': '',
  'errorPage.title': 'Oops!',
  'errorPage.return': 'Return',
  exit: 'Exit',
  'fastcash.fcDepositBankTransfer.account.label': 'Inform your account, with digit.',
  'fastcash.fcDepositBankTransfer.agency.label': 'Inform your agency, without digit',
  'fastcash.fcDepositBankTransfer.bank.label': 'Choose your bank.',
  'fastcash.fcDepositBankTransfer.description': 'Make a transfer by e-banking, telephone or ATM.',
  'fastcash.fcDepositBankTransfer.error':
    'Ups! Something went wrong. Please re-try again and if the problem persists contact to support.',
  'fastcash.fcDepositBankTransfer.nextButton': 'Next',
  'fastcash.fcDepositBankTransfer.title': 'Bank transfer',
  'fastcash.fcDepositCash.description':
    'Choose a location to make the deposit, It is not necessary to be an account holder. Choose the agency or terminal of your choice.',
  'fastcash.fcDepositCash.error':
    'Ups! Something went wrong. Please re-try again and if the problem persists contact to support.',
  'fastcash.fcDepositCash.nextButton': 'Next',
  'fastcash.fcDepositCash.subPaymentMethod.label': 'Choose your location',
  'fastcash.fcDepositCash.title': 'Cash Deposit',
  'fastcash.fcDepositDetails.account.label': 'Conta-Corrente',
  'fastcash.fcDepositDetails.accountHolder.label': 'Favorecido',
  'fastcash.fcDepositDetails.agency.label': 'Agência',
  'fastcash.fcDepositDetails.bank.label': 'Banco',
  'fastcash.fcDepositDetails.bankTransferDescription':
    'Para efetuar o pagamento realize uma transeferência pelo internet banking, celular, phone banking ou caixa eletrônico para:',
  'fastcash.fcDepositDetails.bankTransferTitle': 'Dados para transferência',
  'fastcash.fcDepositDetails.cashDescription':
    'Para efetuar o pagamento qualquer agência do banco Lotéricas e faça um depósito com os seguintes dados:',
  'fastcash.fcDepositDetails.cashTitle': 'Dados para depósito',
  'fastcash.fcDepositDetails.copyButton.notPushed': 'copiar',
  'fastcash.fcDepositDetails.copyButton.pushed': 'copiado',
  'fastcash.fcDepositDetails.homeBankingLegend': 'Acesse seu internet banking',
  'fastcash.fcDepositDetails.nextButton': 'Próxima',
  'fastcash.fcDepositDetails.total.label': 'Total: ',
  'fastcash.fcDepositFinish.description1':
    'Os dados da sua ordem de pagamento foram recebidos corretamente. Seu pedido será processado assim que recebermos a transferência dos fundos. Após transferir o dinheiro, informe os dados do comprovante.',
  'fastcash.fcDepositFinish.description2':
    'Enviaremos um e-mail para informá-lo sobre qualquer alteração de status em seu pedido.',
  'fastcash.fcDepositFinish.fee.label': 'taxa e impostos',
  'fastcash.fcDepositFinish.from.label': 'From',
  'fastcash.fcDepositFinish.idLabel': 'ID #',
  'fastcash.fcDepositFinish.nextButton': 'Comprovar pagamento',
  'fastcash.fcDepositFinish.order.label': 'Solicitação de pagamento',
  'fastcash.fcDepositFinish.title': '¡Gracias {{name}}!',
  'fastcash.fcDepositFinish.total.from': 'De',
  'fastcash.fcDepositFinish.total.label': 'Total',
  'fastcash.fcDepositMethod.bankTransfer': 'Transferência bancária',
  'fastcash.fcDepositMethod.cash': 'Cash',
  'fastcash.fcDepositMethod.description1': 'Pay ',
  'fastcash.fcDepositMethod.description2': ' to complete your order',
  'fastcash.fcDepositMethod.footerLegend': 'If you have questions about your order, please contact the support team',
  'fastcash.fcDepositMethod.nextButton': 'Next',
  'fastcash.fcDepositMethod.termsAndConditionsCheck': 'I accept the terms and conditions',
  'fastcash.fcDepositMethod.title': 'Select the payment method',
  'fastcash.fcDepositVerification.amount.label': 'Amount',
  'fastcash.fcDepositVerification.description': 'Para comprovar o pagamento do seu pedido insira os dados abaixo',
  'fastcash.fcDepositVerification.error':
    'Ups! Something went wrong. Please re-try again and if the problem persists contact to support.',
  'fastcash.fcDepositVerification.nextButton': 'Comprovar pagamento',
  'fastcash.fcDepositVerification.orderNumber.label': 'Nº de Orden',
  'fastcash.fcDepositVerification.reference.help':
    'You can find your order number in the mail you received when you created the order.',
  'fastcash.fcDepositVerification.reference.label': 'Referencia #',
  'fastcash.fcDepositVerification.supportContact':
    'Se você tiver dúvidas sobre seu pedido, entre em contato com a equipe de suporte',
  'fastcash.fcDepositVerification.title': 'Comprovar pagamento',
  'fastcash.fcDepositVerificationSuccess.description1': 'Obrigado pela comprovação',
  'fastcash.fcDepositVerificationSuccess.description2':
    'Você receberá um email assim que recebermos do banco a compensação do seu pagamento.',
  'fastcash.fcDepositVerificationSuccess.description3':
    'Atenção: depósitos por envelope demoram até o final do próximo dia util para serem compensados.',
  'fastcash.fcDepositVerificationSuccess.nextButton': 'Verificar status',
  'fastcash.fcDepositVerificationSuccess.supportContact':
    'Se você tiver dúvidas sobre seu pedido, entre em contato com a equipe de suporte',
  'fastcash.fcDepositVerificationSuccess.title': '¡Obrigado {{name}}!',
  finalizar: '',
  'instructions.copied': '',
  'instructions.copy': '',
  'instructions.description.bank.bankTransfer': '',
  'instructions.description.rapipago.cashNetwork': '',
  'instructions.details.CBU': '',
  'instructions.details.account': '',
  'instructions.details.agency': '',
  'instructions.details.alias': '',
  'instructions.details.amount': '',
  'instructions.details.bank': '',
  'instructions.details.banknumber': '',
  'instructions.details.cbu': '',
  'instructions.details.clabe': '',
  'instructions.details.cnpj': '',
  'instructions.details.company': '',
  'instructions.details.cuit': '',
  'instructions.details.recipient': '',
  'instructions.details.refnumber': '',
  'instructions.details.rfc': '',
  'instructions.title.bank.bankTransfer': '',
  'instructions.title.rapipago.cashNetwork': '',
  'instructions.tooltip': '',
  kycTitle: 'ID verification',
  'limitEnforcement.addDocs.description': '',
  'limitEnforcement.addDocs.fileRequestList': '',
  'limitEnforcement.addDocs.title': '',
  'limitEnforcement.addDocs.uploadText': '',
  'limitEnforcement.checkStatus.description': '',
  'limitEnforcement.checkStatus.footer': '',
  'limitEnforcement.checkStatus.footer.link': '',
  'limitEnforcement.checkStatus.title': '',
  'limitEnforcement.limitExceeded.availableAnual': '',
  'limitEnforcement.limitExceeded.availableBiannual': '',
  'limitEnforcement.limitExceeded.availableDaily': '',
  'limitEnforcement.limitExceeded.availableMonthly': '',
  'limitEnforcement.limitExceeded.contactSupport': '',
  'limitEnforcement.limitExceeded.contactSupport.link': '',
  'limitEnforcement.limitExceeded.description': '',
  'limitEnforcement.limitExceeded.minorOrderButton': '',
  'limitEnforcement.limitExceeded.title': 'You have exceeded your limit',
  'limitEnforcement.limitExceeded.yourOrder': '',
  'limitEnforcement.orderStatus.executed': 'EXECUTED',
  'limitEnforcement.orderStatus.expired': 'EXPIRED',
  'limitEnforcement.orderStatus.pending': 'PENDING',
  'limitEnforcement.orderStatus.rejected': 'REJECTED',
  'limitEnforcement.uploadSuccess.button': '',
  'limitEnforcement.uploadSuccess.description': '',
  'paymentMethod.Rapipago': 'Efectivo - Rapipago',
  'paymentMethod.bankTransfer': 'Bank Transfer',
  'paymentMethod.cash': 'Cash',
  'paymentMethod.description1': '',
  'paymentMethod.description2': '',
  'paymentMethod.escription1': 'Pay ',
  'paymentMethod.escription2': ' to complete your order',
  'paymentMethod.footerLegend': 'If you have questions about your order, please contact the support team',
  'paymentMethod.nextButton': 'Next',
  'paymentMethod.termsAndConditionsCheck': 'I accept the terms and conditions',
  'paymentMethod.title': 'Select the payment method',
  'paymentMethod.notEnabled':
    'Para poder operar con {{name}}<br/> deberás crear una nueva orden <br />por un valor {{majorMinor}} a {{amount}} {{currency}}',
  'paymentMethod.notEnabled.major': 'mayor',
  'paymentMethod.notEnabled.minor': 'menor',

  'paymentMethod.notEnabled.limit':
    'Límite alcanzado.<br/> Límite Anual = {{annualRemaining}} / {{annualLimit}} <br/> Límite Diario = {{dailyRemaining}} / {{dailyLimit}}',

  send: '',
  'status.checkStatus.Deposit': 'Deposit',
  'status.checkStatus.Withdraw': 'Withdraw',
  'status.checkStatus.checkButton': 'Check',
  'status.checkStatus.description':
    'Enter the order number (check it in the e-mails we send you) and your ID to check if your order is pending, completed or rejected.',
  'status.checkStatus.documentId.label': 'ID #',
  'status.checkStatus.errorMessage': 'Please check that your order number and ID are correct.',
  'status.checkStatus.footer': 'If you have doubts about the status of your order',
  'status.checkStatus.footer.link': 'contact the support team',
  'status.checkStatus.orderNumber.label': 'Order Number',
  'status.checkStatus.status.title': 'Order status',
  'status.checkStatus.title': 'Check the status of your order',
  'status.checkStatus.type': 'Transaction Type',
  'warningInfo.checkText': '',
  'warningInfo.disclaimer': '',
  'warningInfo.title': '',
  'walletAddress.title': 'Destination wallet',
  'walletAddress.selectLabel': 'Select Wallet',
  'walletAddressDestination.title': 'Wallet or destination address',
  'walletAddress.network': '{{mainNetwork}}',
  'walletAddress.labelPart1': 'Enter your wallet address {{cryptoAsset}} corresponding to the',
  'walletAddress.labelPart2': ' main network ',
  'walletAddress.labelPart3':
    '<strong>({{network}})</strong>. Sending any cryptocurrency to your wallet using a network that we do not support will result in a potential loss of your funds.',
  'walletAddress.tooltipText': 'Main network supported by our platform.',
  'welcome.addressConfirm': 'I confirm that my wallet address is correct',
  'welcome.cancelAction': 'Cancel order',
  'welcome.description': 'Check all the data before continuing, especially the address of your wallet!',
  'welcome.paymentOrder': 'Payment order',
  'welcome.processorCharge': 'Processor Charge',
  'welcome.flatFee': 'Flat fee:',
  'welcome.varFee': 'Variable fee:',
  'welcome.feeWithMin': '({{fee}}% charge or minimum of {{currency}} {{minFee}})',
  'welcome.fee': '({{fee}}% charge)',
  'welcome.address': 'Your {{addressType}} address',
  'welcome.addressTag': 'Memo',
  'welcome.total': 'Total to be paid:',
  'welcome.startBtn': 'Continue',
  'welcome.termsAndCond': 'I accept the <0>terms and conditions</0> and <1>privacy policy</1>',
  'welcome.termsAndCondDesc':
    'Your order will be processed by a local payment processor.<br/> To continue you must accept the terms and conditions.',
  'welcome.title': 'Your {{companyName}} order',
  'welcome.total.Deposit': 'Total to be paid:',
  'welcome.total.Withdraw': 'Total to be withdrawn:',
  'welcome.welcomeScreen': 'Summary of your payment order'
};
