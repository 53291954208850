import React from 'react';
import { withTranslation, Trans } from 'react-i18next';

import CheckoutStep from '../Layout/CheckoutStep';

import OrderIcon from '../../images/deposit/order-icon@2x.png';

import './index.scss';

const DepositFinish = ({ onClick, history, user: { quoteSource }, kyc: { name }, t }) => {
  return (
    <div className="d-flex flex-column align-items-center">
      <CheckoutStep
        title={t('deposit.finishStep.title', { name })}
        next={onClick}
        prev={history.goBack}
        buttonContent={t('finish')}
        hideGoBack
        classNameContainer="margin-container-bottom-12"
      >
        <div className="mt-1 d-flex align-items-center">
          <img src={OrderIcon} alt="order icon" className="logo" width="159" height="84" />
          {/* style={{ marginTop: '30px' }} */}
        </div>

        <p className="description mt-3 desc-lh-20">
          <Trans i18nKey="deposit.finishStep.textOne">
            text
            <br />
            text
          </Trans>
        </p>
        {quoteSource && quoteSource !== 'stablex' && (
          <p className="description mt-4 desc-lh-20">{t('deposit.finishStep.textTwo')}</p>
        )}
        <p className="description mt-4 font-bold desc-lh-16">
          <small>{t('deposit.finishStep.textThree')}</small>
        </p>
        <p className="description mt-4 last-text-confirmation desc-lh-16">
          <small>{t('deposit.finishStep.textFour')}</small>
        </p>
      </CheckoutStep>
    </div>
  );
};

export default withTranslation()(DepositFinish);
