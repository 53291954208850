import { useSelector } from 'react-redux';

export const GetSupportUrl = (supportLang = 'es') => {
  const branding = GetBranding();
  return `https://${branding || 'latamex'}.zendesk.com/hc/${supportLang}/requests/new`;
};

export const GetBranding = () => {
  const consumerConfig = useSelector(state => (state.auth ? state.auth.consumerConfig : {}));
  const storedConsumerConfig = JSON.parse(localStorage.getItem('adjustedTheme'));

  const branding =
    (consumerConfig && consumerConfig.branding) || (storedConsumerConfig && storedConsumerConfig.branding);

  return branding;
};

export const getConsumerConfig = () => ConsumerConfig();

// - Footer tiene que mostrar terms/privacy/lmits globales para todos (menos BRLT)
// - Ocultar terms para BRLT en el widget
// - Footer mostrar links de provider para BRLT
// - BRLT no precisa provider specifics *pero lo duplicamos en al config*
// VER URLS TABLA DRIVE
// https://docs.google.com/spreadsheets/d/1J3E5G6kE03m9iThptnViYYZGbs_FE6-KRNS-UkfFNsQ/edit#gid=0

export const getSpecificProviderDisclaimers = () => {
  const checkout = CurrentCheckout();
  const consumerConfig = getConsumerConfig();
  if (!checkout || !consumerConfig) {
    return {};
  }
  const { type, originAsset, destinationAsset } = checkout;
  const { providerTerms, providerPrivacyPolicy, providerLimits } = consumerConfig;
  const termsAndCond =
    (providerTerms &&
      ((type && providerTerms[type === 'Deposit' ? destinationAsset : originAsset]) || providerTerms.default)) ||
    '';
  const privacyPolicies =
    (providerPrivacyPolicy &&
      ((type && providerPrivacyPolicy[type === 'Deposit' ? destinationAsset : originAsset]) ||
        providerPrivacyPolicy.default)) ||
    '';
  const limitsAndCommisions =
    (providerLimits &&
      ((type && providerLimits[type === 'Deposit' ? destinationAsset : originAsset]) || providerLimits.default)) ||
    '';
  return {
    termsAndCond,
    privacyPolicies,
    limitsAndCommisions
  };
};

export const getGlobalProviderDisclaimers = () => {
  const checkout = CurrentCheckout();
  const consumerConfig = getConsumerConfig();
  if (!checkout || !consumerConfig) {
    return {};
  }
  const { type, originAsset, destinationAsset } = checkout;
  const { globalPrivacy, globalTerms, providerLimits } = consumerConfig;
  const termsAndCond =
    (globalTerms &&
      ((type && globalTerms[type === 'Deposit' ? destinationAsset : originAsset]) || globalTerms.default)) ||
    '';
  const privacyPolicies =
    (globalPrivacy &&
      ((type && globalPrivacy[type === 'Deposit' ? destinationAsset : originAsset]) || globalPrivacy.default)) ||
    '';
  const limitsAndCommisions =
    (providerLimits &&
      ((type && providerLimits[type === 'Deposit' ? destinationAsset : originAsset]) || providerLimits.default)) ||
    '';
  return {
    termsAndCond,
    privacyPolicies,
    limitsAndCommisions
  };
};

const ConsumerConfig = () => {
  const consumerConfig = useSelector(state => (state.auth ? state.auth.consumerConfig : {}));
  const storedConsumerConfig = JSON.parse(localStorage.getItem('adjustedTheme'));
  return consumerConfig || storedConsumerConfig;
};

const CurrentCheckout = () => {
  const checkout = useSelector(state => (state.user ? state.user.user : {}));
  return checkout || {};
};
