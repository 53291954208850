import React, { useState, useEffect } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import './styles.scss';

function CustomDropdown({
  classname,
  toggleClassname = '',
  options,
  size,
  handleItemOnChange,
  defaultSelected = null
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [itemSelected, setItemSelected] = useState(defaultSelected || options[0]);
  const toggle = () => setDropdownOpen(prevState => !prevState);

  useEffect(() => {
    handleItemOnChange(itemSelected);
  }, [itemSelected]);

  return (
    <Dropdown className={classname} isOpen={dropdownOpen} toggle={toggle} size={size}>
      <DropdownToggle caret color="white" className={`text ${toggleClassname}`} style={{ textTransform: 'capitalize' }}>
        {itemSelected}
      </DropdownToggle>
      <DropdownMenu>
        {options.map(option => (
          <DropdownItem className="text" key={option} onClick={() => setItemSelected(option)}>
            {option}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
}

export default CustomDropdown;
