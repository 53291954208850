import Decimal from 'decimal.js';

export const toFixed = (num, fixed) => {
  if (!num) return '0';

  const re = new RegExp(`^-?\\d+(?:.\\d{0,${fixed || -1}})?`);
  return Decimal(num)
    .toFixed()
    .match(re)[0];
};

export const divide = (a, b) => {
  const decimal = new Decimal(a);
  const result = decimal.dividedBy(b);
  return Number(result);
};
