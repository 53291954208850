// @Vendors
import React from 'react';

// @Components
import DepositSummaryBR from '../DepositSummaryBR/DepositSummaryBR';

const DepositPage = ({ history }) => {
  return <DepositSummaryBR {...{ history }} />;

  // if (provider.provider === 'fashcash') {
  //   history.push('/br/deposit-method');
  //   return <></>;
  // }
};

export default DepositPage;
