import React from 'react';
import { connect } from 'react-redux';

import FcDepositCashComponent from '../../../components/Fastcash/FcDepositCash';

import FastcashActions from '../../../redux/FastcashRedux';

const FcDepositCash = ({
  user,
  kyc,
  history,
  error,
  deposit,
  requesting,
  paymentMethod,
  subPaymentMethod,
  subPaymentMethods,
  setSubPaymentMethod,
  fcGetSubPaymentMethodsRequest,
  fcCreateDepositRequest,
  resetStoreRequest
}) => (
  <FcDepositCashComponent
    {...{
      user,
      kyc,
      history,
      error,
      deposit,
      requesting,
      paymentMethod,
      subPaymentMethod,
      subPaymentMethods,
      setSubPaymentMethod,
      fcGetSubPaymentMethodsRequest,
      fcCreateDepositRequest,
      resetStoreRequest
    }}
  />
);

const mapStateToProps = state => {
  return {
    user: state.user.user,
    kyc: state.user.kyc,
    error: state.fastcash.error,
    paymentMethod: state.fastcash.paymentMethod,
    subPaymentMethod: state.fastcash.subPaymentMethod,
    subPaymentMethods: state.fastcash.subPaymentMethods,
    deposit: state.fastcash.deposit,
    requesting: state.fastcash.requesting
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setSubPaymentMethod: subPaymentMethod => dispatch(FastcashActions.setSubPaymentMethod(subPaymentMethod)),
    fcCreateDepositRequest: data => dispatch(FastcashActions.fcCreateDepositRequest(data)),
    fcGetSubPaymentMethodsRequest: data => dispatch(FastcashActions.fcGetSubPaymentMethodsRequest(data)),
    resetStoreRequest: items => dispatch(FastcashActions.resetStoreRequest(items))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FcDepositCash);
