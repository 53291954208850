import React from 'react';
import { Col, Row } from 'reactstrap';
import CurrencyFormat from 'react-currency-format';

import CheckoutStep from '../Layout/CheckoutStep';

import HandMoneyDoc from '../../images/enforcement/hand-money-doc.svg';

import './style.scss';

const LimitsDetail = ({ limitEnforcement, t, checkoutAmount, feeAmount, originAsset, quote, type }) => {
  const {
    availableLimits: {
      daily: availableDaily,
      monthly: availableMonthly,
      biannual: availableBiannual,
      anual: availableAnual
    },
    usedLimits: { daily: usedDaily, monthly: usedMonthly, biannual: usedBiannual, anual: usedAnual }
  } = limitEnforcement;

  const diffAnual =
    availableAnual && availableAnual !== 0 ? (availableAnual - usedAnual).toFixed(2) : Number.MAX_SAFE_INTEGER;

  const diffBiannual =
    availableBiannual && availableBiannual !== 0
      ? Math.min(diffAnual, availableBiannual - usedBiannual).toFixed(2)
      : Number.MAX_SAFE_INTEGER;

  const diffMonthly =
    availableMonthly && availableMonthly !== 0
      ? Math.min(diffAnual, diffBiannual, availableMonthly - usedMonthly).toFixed(2)
      : Number.MAX_SAFE_INTEGER;

  const diffDaily =
    availableDaily && availableDaily !== 0
      ? Math.min(diffAnual, diffBiannual, diffMonthly, availableDaily - usedDaily).toFixed(2)
      : Number.MAX_SAFE_INTEGER;

  const w = window.innerWidth;
  const isMobile = w >= 320 && w < 480;

  return (
    <div className="transfer-detail limit-exceeded">
      {availableAnual && availableAnual !== 0 && (
        <>
          <Row>
            <Col xs="5">{t('limitEnforcement.limitExceeded.availableAnual')}:</Col>
            <Col xs="7">
              <b>
                <CurrencyFormat
                  value={diffAnual}
                  displayType="text"
                  thousandSeparator=","
                  decimalSeparator="."
                  fixedDecimalScale
                  decimalScale={2}
                  prefix="$ "
                />
              </b>{' '}
              / {isMobile ? <br /> : null}
              <CurrencyFormat
                className="light"
                value={availableAnual}
                displayType="text"
                thousandSeparator=","
                decimalSeparator="."
                fixedDecimalScale
                decimalScale={2}
                prefix="$ "
              />
            </Col>
          </Row>
          <div style={{ height: '5px' }} />
        </>
      )}
      {availableBiannual && availableBiannual !== 0 && (
        <>
          <Row>
            <Col xs="5">{t('limitEnforcement.limitExceeded.availableBiannual')}:</Col>
            <Col xs="7">
              <b>
                <CurrencyFormat
                  value={diffBiannual}
                  displayType="text"
                  thousandSeparator=","
                  decimalSeparator="."
                  fixedDecimalScale
                  decimalScale={2}
                  prefix="$ "
                />
              </b>{' '}
              / {isMobile ? <br /> : null}
              <CurrencyFormat
                className="light"
                value={availableBiannual}
                displayType="text"
                thousandSeparator=","
                decimalSeparator="."
                fixedDecimalScale
                decimalScale={2}
                prefix="$ "
              />
            </Col>
          </Row>
          <div style={{ height: '5px' }} />
        </>
      )}
      {availableMonthly && availableMonthly !== 0 && (
        <>
          <Row>
            <Col xs="5">{t('limitEnforcement.limitExceeded.availableMonthly')}:</Col>
            <Col xs="7">
              <b>
                <CurrencyFormat
                  value={diffMonthly}
                  displayType="text"
                  thousandSeparator=","
                  decimalSeparator="."
                  fixedDecimalScale
                  decimalScale={2}
                  prefix="$ "
                />
              </b>{' '}
              / {isMobile ? <br /> : null}
              <CurrencyFormat
                className="light"
                value={availableMonthly}
                displayType="text"
                thousandSeparator=","
                decimalSeparator="."
                fixedDecimalScale
                decimalScale={2}
                prefix="$ "
              />
            </Col>
          </Row>
          <div style={{ height: '5px' }} />
        </>
      )}
      {availableDaily && availableDaily !== 0 && (
        <>
          <Row>
            <Col xs="5">{t('limitEnforcement.limitExceeded.availableDaily')}:</Col>
            <Col xs="7">
              <b>
                <CurrencyFormat
                  value={diffDaily}
                  displayType="text"
                  thousandSeparator=","
                  decimalSeparator="."
                  fixedDecimalScale
                  decimalScale={2}
                  prefix="$ "
                />
              </b>{' '}
              / {isMobile ? <br /> : null}
              <CurrencyFormat
                className="light"
                value={availableDaily}
                displayType="text"
                thousandSeparator=","
                decimalSeparator="."
                fixedDecimalScale
                decimalScale={2}
                prefix="$ "
              />
            </Col>
          </Row>
          <div style={{ height: '5px' }} />
        </>
      )}
      <Row>
        <Col xs="5">{t('limitEnforcement.limitExceeded.yourOrder')}:</Col>
        <Col xs="7">
          <b>
            {type === 'Withdraw' && (
              <>
                {checkoutAmount + feeAmount} {originAsset} ≈{' '}
              </>
            )}
            <CurrencyFormat
              value={(checkoutAmount + feeAmount) * (type === 'Deposit' ? 1 : quote)}
              displayType="text"
              thousandSeparator=","
              decimalSeparator="."
              fixedDecimalScale
              decimalScale={2}
              prefix="$ "
            />
          </b>
        </Col>
      </Row>
    </div>
  );
};

const LimitExceeded = ({ user, limitEnforcement, history, t }) => (
  <div className="d-flex flex-column align-items-center">
    <CheckoutStep
      title={t('limitEnforcement.limitExceeded.title')}
      next={() => (window.location.href = user.callbackUrl)}
      prev={history.goBack}
      buttonContent={t('limitEnforcement.limitExceeded.minorOrderButton')}
      hideGoBack
      classNameContainer="margin-container-bottom-12"
    >
      <div className="text-center ">
        <img src={HandMoneyDoc} alt="limit exceeded" />
      </div>
      <p className="description mt-4 mb-5 desc-lh-20">{t('limitEnforcement.limitExceeded.description')}</p>
      <LimitsDetail
        checkoutAmount={user.checkoutAmount}
        feeAmount={user.feeAmount}
        type={user.type}
        quote={user.quote}
        originAsset={user.originAsset}
        {...{ limitEnforcement, t }}
      />
      <p className="description mb-5 mt-1">
        <button className="button-reset" onClick={() => history.push('limit-enforcement/expand-limit')}>
          <small>{t('limitEnforcement.limitExceeded.contactSupport')}</small>
        </button>
      </p>
    </CheckoutStep>
  </div>
);

export default LimitExceeded;
