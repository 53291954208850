import apisauce from 'apisauce';

const privateRequest = ({ api, method, endpoint, params, options, token, contentType = 'application/json' }) => {
  const headers = options ? options.headers : {};
  return api[method](endpoint, params, {
    ...options,
    headers: {
      ...headers,
      'Content-Type': contentType,
      Authorization: `Token ${token}`
    }
  });
};

const privatePost = (api, token, endpoint, params, options, contentType) =>
  privateRequest({ api, method: 'post', endpoint, params, options, token, contentType });

const create = (baseURL = '') => {
  const api = apisauce.create({
    baseURL,
    headers: {
      'Cache-Control': 'no-cache'
    },
    timeout: 1000000
  });

  const getKycVerificationUrlRequest = (token, userData) => privatePost(api, token, '/kyc/props', userData, {});

  return {
    getKycVerificationUrlRequest
  };
};

export default {
  create
};
