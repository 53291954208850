import React from 'react';
import { withTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import { Link } from '../BaseStyledComponents';

import i18n from '../../../i18n';

import { countryMapping } from '../../../constants/countryMap';

import './styles.scss';

import { getGlobalProviderDisclaimers } from '../../../utils/consumerConfig';

const PoweredBy = styled.span`
  color: ${props => props.theme.darkTextColor};
`;

const Footer = ({ t }) => {
  const providerDisclaimers = getGlobalProviderDisclaimers();

  return (
    <div className="container">
      <div className="footer">
        <div className="row">
          <div className="pl-0 pr-0 col-md-10 offset-md-1">
            {providerDisclaimers && providerDisclaimers.termsAndCond && (
              <span className="footer-item-left">
                <Link
                  href={`${providerDisclaimers.termsAndCond}/${countryMapping[i18n.language].toLowerCase()}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('commons.footer.terms-and-conditions')}
                </Link>
              </span>
            )}
            {providerDisclaimers && providerDisclaimers.privacyPolicies && (
              <span className="footer-item-left">
                <Link
                  href={`${providerDisclaimers.privacyPolicies}/${countryMapping[i18n.language].toLowerCase()}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('commons.footer.privacy-policy')}
                </Link>
              </span>
            )}
            {providerDisclaimers && providerDisclaimers.limitsAndCommisions && (
              <span className="footer-item-left">
                <Link
                  href={`${providerDisclaimers.limitsAndCommisions}/${countryMapping[i18n.language].toLowerCase()}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('commons.footer.limits')}
                </Link>
              </span>
            )}
            <span className="powered-by-settle">
              <PoweredBy>Powered by &nbsp;</PoweredBy>
              <Link href="https://settlenetwork.com/" target="_blank" rel="noopener noreferrer">
                Settle X
              </Link>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Footer);
