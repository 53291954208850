import React from 'react';
import { connect } from 'react-redux';

import FcDepositBankTransferComponent from '../../../components/Fastcash/FcDepositBankTransfer';

import FastcashActions from '../../../redux/FastcashRedux';

const FcDepositBankTransfer = ({
  history,
  user,
  kyc,
  deposit,
  error,
  requesting,
  paymentMethod,
  subPaymentMethod,
  subPaymentMethods,
  transferAgency,
  transferAccount,
  setSubPaymentMethod,
  setTransferAgency,
  setTransferAccount,
  fcGetSubPaymentMethodsRequest,
  fcCreateDepositRequest,
  resetStoreRequest
}) => (
  <FcDepositBankTransferComponent
    {...{
      history,
      user,
      kyc,
      error,
      deposit,
      requesting,
      paymentMethod,
      subPaymentMethod,
      subPaymentMethods,
      setSubPaymentMethod,
      transferAgency,
      setTransferAgency,
      transferAccount,
      setTransferAccount,
      fcGetSubPaymentMethodsRequest,
      fcCreateDepositRequest,
      resetStoreRequest
    }}
  />
);

const mapStateToProps = state => {
  return {
    user: state.user.user,
    kyc: state.user.kyc,
    error: state.fastcash.error,
    deposit: state.fastcash.deposit,
    requesting: state.fastcash.requesting,
    paymentMethod: state.fastcash.paymentMethod,
    subPaymentMethod: state.fastcash.subPaymentMethod,
    subPaymentMethods: state.fastcash.subPaymentMethods,
    transferAgency: state.fastcash.transferAgency,
    transferAccount: state.fastcash.transferAccount
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setSubPaymentMethod: subPaymentMethod => dispatch(FastcashActions.setSubPaymentMethod(subPaymentMethod)),
    setTransferAgency: agency => dispatch(FastcashActions.setTransferAgency(agency)),
    setTransferAccount: account => dispatch(FastcashActions.setTransferAccount(account)),
    fcGetSubPaymentMethodsRequest: data => dispatch(FastcashActions.fcGetSubPaymentMethodsRequest(data)),
    fcCreateDepositRequest: data => dispatch(FastcashActions.fcCreateDepositRequest(data)),
    resetStoreRequest: items => dispatch(FastcashActions.resetStoreRequest(items))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FcDepositBankTransfer);
