import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import AddDocsComponent from '../../components/LimitEnforcement/AddDocs';

import DepositActions from '../../redux/DepositRedux';

const LimitEnforcementDocs = ({ sendLimitEmail, token, status, error, requesting, history, t, match }) => {
  const handleSendEmail = () => {
    sendLimitEmail({ history });
  };
  return (
    <AddDocsComponent
      status={status}
      error={error}
      token={token}
      sendEmailNotif={handleSendEmail}
      requesting={requesting}
      langParam={match.params.lang}
      history={history}
      {...{ t }}
    />
  );
};

const mapStateToProps = ({ deposit: { error, requesting }, user: { user }, auth: { token } }) => {
  return {
    user,
    error,
    requesting,
    token
  };
};

const mapDispatchToProps = dispatch => {
  return {
    sendLimitEmail: data => dispatch(DepositActions.sendLimitEmail(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LimitEnforcementDocs));
