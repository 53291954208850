import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import DepositActions from '../../redux/DepositRedux';
import WelcomeScreen from '../../components/Welcome/WelcomeScreen';

const Summary = ({ history, user, quote, paymentMethod, getAvailablePaymentMethods }) => {
  useEffect(() => {
    if (user) {
      getAvailablePaymentMethods();
    }
  }, [user, getAvailablePaymentMethods]);

  if (!user) {
    return <Redirect to="/" />;
  }

  const nextPage = user.type === 'Deposit' ? '/check-your-info' : '/withdraw/input';

  return (
    <WelcomeScreen
      {...{
        user,
        quote,
        paymentMethodFee: paymentMethod && paymentMethod.fee
      }}
      onClick={() => history.push(nextPage)}
      history={history}
    />
  );
};

const mapStateToProps = state => {
  return {
    user: state.user.user,
    quote: state.deposit.quote,
    paymentMethod: state.deposit.paymentMethodSelected
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAvailablePaymentMethods: data => dispatch(DepositActions.getAvailablePaymentMethods(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Summary);
