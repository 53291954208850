import _ from 'lodash';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import CurrencyFormat from 'react-currency-format';
import CustomButton from '../Commons/CustomButton/CustomButton';
import cryptoImage from '../../images/icons/latamex-digital-asset@2x.png';
import successWithdrawIcon from '../../images/icons/success-withdraw-icon.svg';
import IconBank from '../../images/deposit/icon-bank-2.png';

import CbuService from '../../services/CBU';

import './ConfirmStyles.scss';

const txHashLinks = {
  BTC: 'https://www.blockchain.com/btc/tx/'
};

const ConfirmSuccessDepositWithHash = ({
  confirmResult,
  t,
  cryptoAmount,
  destinationWalletAddress,
  destinationAsset,
  callbackUrl
}) => {
  const { status, hash } = confirmResult;
  const [copied, setCopied] = useState(false);

  return (
    <>
      <h4 className="mb-4">{t('confirmPage.thanks')}</h4>
      <div className="mb-4">&nbsp;</div>
      <img src={cryptoImage} width="150" className="d-block" />
      <span className="mt-4 mb-4 text-center">
        <Trans i18nKey={`confirmPage.completedSuccess${status === true ? '' : `_${status}`}`}>
          <br />
          <strong>
            {{ destinationAsset }} {{ cryptoAmount }}
          </strong>
          <br />
          <br />
          <strong>{{ destinationWalletAddress }}</strong>
        </Trans>
        <br />
        <br />
        {t('confirmPage.completedSuccess.hash')}
        <br />
        {txHashLinks[destinationAsset] ? (
          <a href={`${txHashLinks[destinationAsset]}${hash}`} target="_blank" rel="noopener noreferrer">
            {hash}
          </a>
        ) : (
          <span>{hash}</span>
        )}
        <CopyToClipboard text={hash} onCopy={() => setCopied(true)}>
          <span className="copyButton">{copied ? t('instructions.copied') : t('instructions.copy')}</span>
        </CopyToClipboard>
      </span>
      <span className="mt-4 mb-4">{t('confirmPage.thanksOperatingWithUs')}</span>

      <p className="description mt-2 mb-2">
        <a href={callbackUrl}>{t('exit')}</a>
      </p>
      <p className="description mt-2 mb-2">
        <CustomButton onClick={() => (window.parent.location.href = callbackUrl)}>
          {t('confirmPage.btnNewOrder')}
        </CustomButton>
      </p>
    </>
  );
};

const ConfirmSuccessDeposit = ({
  confirmResult,
  t,
  cryptoAmount,
  destinationWalletAddress,
  destinationAsset,
  callbackUrl
}) => {
  return (
    <>
      <h4 className="mb-4">{t('confirmPage.thanks')}</h4>
      <div className="mb-4">&nbsp;</div>
      <img src={cryptoImage} width="150" className="d-block" />
      <span className="mt-4 mb-4 text-center">
        <Trans i18nKey={`confirmPage.completedSuccess${confirmResult === true ? '' : `_${confirmResult}`}`}>
          <br />
          <strong>
            {{ cryptoAmount }} {{ destinationAsset }}
          </strong>
          <br />
          <br />
          {{ destinationAsset }}
          <strong>{{ destinationWalletAddress }}</strong>
        </Trans>
      </span>
      <span className="mt-4 mb-4">{t('confirmPage.thanksOperatingWithUs')}</span>

      <p className="description mt-2 mb-2">
        <a href={callbackUrl}>{t('exit')}</a>
      </p>
      <p className="description mt-2 mb-2">
        <CustomButton onClick={() => (window.parent.location.href = callbackUrl)}>
          {t('confirmPage.btnNewOrder')}
        </CustomButton>
      </p>
    </>
  );
};

const ConfirmSuccessWithdraw = ({ t, destinationAsset, callbackUrl, confirm, rate }) => {
  return (
    <>
      <h4 className="mb-3">{t('confirmPage.thanks')}</h4>
      <div className="mb-3">&nbsp;</div>
      <img src={successWithdrawIcon} width="150" className="d-block" />
      <span className="mt-4 mb-4 text-center">
        {t('confirmPage.orderComplete')}
        <br />
        <b>
          {destinationAsset}{' '}
          <CurrencyFormat
            value={rate.fx * confirm.checkout.checkoutAmount}
            displayType="text"
            thousandSeparator=","
            decimalSeparator="."
            decimalScale={2}
          />
        </b>
        <br />
        {t('confirmPage.gonnaReceiveSoon')}
        <br />
      </span>

      <div className="bank-detail">
        <div className="icon">
          {confirm.countryCode === 'AR' ? (
            <img src={CbuService.getBankByCBU(confirm.accountHandle).icon} />
          ) : (
            <img className="confirmBankIcon" src={IconBank} />
          )}
        </div>
        <div className="my-auto">
          <small>{confirm.toBank}</small>
          <br />
          <small>
            {destinationAsset !== 'BRL' && (
              <>
                {confirm.accountHandleType}: {confirm.accountHandle}
              </>
            )}
            {destinationAsset === 'BRL' &&
              confirm.accountHandleType.split(' | ').map((brAccountPart, index) => (
                <>
                  {brAccountPart}: {confirm.accountHandle.split(' | ')[index]}
                  <br />
                </>
              ))}
          </small>
        </div>
      </div>

      <span className="mt-4 mb-4 text-center" style={{ width: '70%' }}>
        <small>{t('confirmPage.confirmDetail')}</small>
      </span>

      <p className="description mt-2 mb-2">
        <a href={callbackUrl}>{t('exit')}</a>
      </p>
    </>
  );
};

const ConfirmSuccess = ({
  confirmResult,
  t,
  cryptoAmount,
  destinationWalletAddress,
  destinationAsset,
  callbackUrl,
  checkoutType,
  confirm,
  rate
}) => {
  if (checkoutType === 'deposit') {
    if (_.isObject(confirmResult) && confirmResult.hash) {
      return (
        <ConfirmSuccessDepositWithHash
          {...{
            confirmResult,
            t,
            cryptoAmount,
            destinationWalletAddress,
            destinationAsset,
            callbackUrl
          }}
        />
      );
    }

    return (
      <ConfirmSuccessDeposit
        {...{
          confirmResult: _.isObject(confirmResult) ? confirmResult.status : confirmResult,
          t,
          cryptoAmount,
          destinationWalletAddress,
          destinationAsset,
          callbackUrl
        }}
      />
    );
  }

  if (confirm && checkoutType === 'withdraw') {
    return (
      <ConfirmSuccessWithdraw
        {...{
          t,
          destinationAsset,
          callbackUrl,
          confirm,
          rate
        }}
      />
    );
  }

  return <div />;
};

export default ({
  confirmResult,
  t,
  cryptoAmount,
  destinationWalletAddress,
  destinationAsset,
  callbackUrl,
  checkoutType,
  confirm,
  rate
}) => {
  return (
    <div className="d-flex justify-content-center align-items-center flex-column confirm-success">
      <ConfirmSuccess
        {...{
          confirmResult,
          t,
          cryptoAmount,
          destinationWalletAddress,
          destinationAsset,
          callbackUrl,
          checkoutType,
          confirm,
          rate
        }}
      />
    </div>
  );
};
