export default {
  'account.cancel': 'Cancelar',
  'account.changePasswordSuccess': 'La contraseña se ha modificado correctamente',
  'account.createAccountSuccess': '¡Cuenta creada con éxito!',
  'account.insertEmail.enterYourEmail': 'Ingresa tu correo electrónico',
  'account.insertEmail.error': 'Email ya está en uso',
  'account.insertEmail.invalidEmail': 'Email no válido',
  'account.insertEmail.login': '¿Ya tienes una cuenta en Settle ID? Iniciar sesión',
  'account.insertEmail.subtitle': 'Para crear tu Settle ID, vamos a necesitar verificar tu correo.',
  'account.insertEmail.title': 'Crea tu Settle ID para ingresar a {{companyName}}',
  'account.insertEmail.whatIsSettleId': '¿Qué es Settle ID?',
  'account.insertEmail.settleIdPopup':
    'Es una cuenta única para acceder a diferentes aplicaciones, como Latamex y Stablex, sin tener que volver a completar todos tus datos. Pronto podrás usarlo para ingresar en otras aplicaciones asociadas.<br /><br /> Ahora el acceso a tu cuenta Latamex y Stablex se realiza a través de Settle ID. No necesitas hacer nada nuevo, si ya tenías una cuenta, tu email y contraseña de Latamex o Stablex, pasan a ser tu Settle ID. Si no tenías una cuenta deberás registrarte, solo tomará unos pocos minutos.',
  'account.insertEmail.OK': 'Entendido',
  'account.createAnAccount': '¿No tienes Settle ID? Crear una cuenta',
  'account.insertPassword.checkPassword': 'Revisa tu contraseña',
  'account.insertPassword.createAPassword': 'Settle ID - Crea una contraseña',
  'account.insertPassword.enterPassword': 'Ingresar contraseña',
  'account.insertPassword.passwordRequirements':
    'Tu contraseña debe tener al menos 8 caracteres, una letra y un número',
  'account.insertPassword.passwordsDoNotMatch': 'Las contraseñas no coinciden',
  'account.insertPassword.repeatedPasswordError': 'La contraseña fue utilizada previamente',
  'account.insertPassword.repeatPassword': 'Repetir contraseña',
  'account.login.enterLatamex': 'Ingresar a {{companyName}}',
  'account.login.subtitle': 'Ingresa con tu Settle ID',
  'account.login.enterYourEmail': 'Ingresa tu email',
  'account.login.enterYourPassword': 'Ingresa tu contraseña',
  'account.login.forgetPassword': 'He olvidado mi contraseña',
  'account.login.invalidCredentials': 'Credenciales no válidas',
  'account.logout': 'Cerrar Sesión',
  'account.returnToAccount': 'Volver a mi cuenta',
  'account.continueAsAGuest': 'Continuar como invitado',
  'account.next': 'Siguiente',
  'account.passwordRecovery.error': 'Cuenta no encontrada',
  'account.passwordRecovery.login': 'Iniciar sesión',
  'account.passwordRecovery.subtitle':
    'Ingresa tu correo electrónico, recibirás un código para restablecer tu contraseña.',
  'account.passwordRecovery.title': 'Settle ID - Restablecer contraseña',
  'buttons.addAccount': '+ Agregar cuenta',
  'buttons.confirm': 'Confirmar',
  'buttons.deposit': 'Comprar',
  'buttons.done': 'Hecho',
  'buttons.next': 'Siguiente',
  'buttons.withdraw': 'Vender',
  'checkoutForm.buyButton': 'Comprar',
  'checkoutForm.sellButton': 'Vender',
  'checkoutForm.checkWalletAddress': 'Checa la dirección de tu billetera',
  'checkoutForm.checkWalletAddressFederation': 'Chequea tu dirección federada',
  'checkoutForm.doNotHaveWallet': 'No tienes una billetera?',
  'checkoutForm.enterHigherValue': 'El valor mínimo de compra es {{fiatAsset}} {{minAmount}}',
  'checkoutForm.enterLowerValue': 'Ingresa un valor menor',
  'checkoutForm.enterValidEmail': 'El email no es válido',
  'checkoutForm.enterYourAmount': 'Ingresa el Importe',
  'checkoutForm.enterYourEmail': 'Ingresa tu email',
  'checkoutForm.enterYourWallet': 'Ingresa tu billetera',
  'checkoutForm.includedFees': 'Fees incluídos',
  'checkoutForm.minAmountError': 'El valor mínimo de compra es {{fiatAsset}} {{minAmount}}',
  'checkoutForm.minAmountError.withdraw': 'El valor mínimo de venta es {{cryptoAsset}} {{minAmount}}',
  'checkoutForm.mustEnterValue': 'Debes ingresar algun valor',
  'checkoutForm.flatFee': ' + cargo fijo {{fiatAsset}} {{flatFee}}',
  'checkoutForm.minFee': ' (min {{fiatAsset}} {{minFee}})',
  'checkoutForm.feeAndTermsCondDisclaimer':
    'El precio no incluye los gastos del procesador de pagos desde {{fee}}%{{minFee}}{{flatFee}}. Al continuar estas aceptando los <1>términos y condiciones</1>.',
  'checkoutForm.termsCondDisclaimer': 'Al continuar estas aceptando los <1>términos y condiciones</1>.',
  'checkoutForm.tooltip':
    'Precio basado en la cotización actual.<br/>La cotización final puede variar una vez<br/> que se acredite su pago. Una vez <br/>recibido su pago, se le enviará un <br/>correo electrónico para  que confirme <br/>la transacción con la tarifa final.',
  'checkoutForm.wantToBuy': 'Quiero comprar',
  'checkoutForm.wantToSpend': 'Quiero gastar',
  'checkoutForm.wantToSell': 'Quiero vender',
  'checkoutForm.wantToReceive': 'Quiero recibir',
  'commons.footer.help': 'Necesitas ayuda?',
  'commons.footer.limits': 'Límites y Comisiones',
  'commons.footer.privacy-policy': 'Política de Privacidad',
  'commons.footer.rates': '',
  'commons.footer.terms-and-conditions': 'Términos y condiciones',
  'confirmErrorPage.checkStatus': 'Checa el estado del servicio',
  'confirmErrorPage.description':
    'Nuestro equipo ha sido notificado. Revisaremos tu orden y volveremos a enviarte el link de confirmación a tu correo.',

  'confirmErrorPage.dontWorry': '¡No te preocupes!',
  'confirmErrorPage.meantime': 'Mientras tanto puedes:',
  'confirmErrorPage.visitHelpCenter': 'Visitar nuestro centro de ayuda',
  'confirmPage.actualPriceLabel': 'Precio actual',
  'confirmPage.alreadyExecuted.deposit':
    'Tus <strong>{{destinationAssetError}}</strong> ya fueron enviados a tu billetera <strong>{{destinationWalletAddressError}}</strong>',
  'confirmPage.alreadyExecuted.withdraw':
    'Tus <strong>{{destinationAssetError}}</strong> ya fueron enviados a tu cuenta bancaria',
  'confirmPage.alreadyExecuted_transferPending.deposit':
    'Tus <strong>{{destinationAssetError}}</strong> serán enviados a tu dirección a la brevedad <strong>{{destinationWalletAddressError}}</strong>.',
  'confirmPage.alreadyExecuted_transferPending.withdraw':
    'Tus <strong>{{destinationAssetError}}</strong> serán enviados en breve a tu cuenta bancaria.',
  'confirmPage.btn': 'Confirmar',
  'confirmPage.btnNewOrder': 'Crear nueva orden',
  'confirmPage.completedError': 'Oops… no pudimos procesar tu transacción',
  'confirmPage.completedSuccess':
    'Tus<br /><strong>{{destinationAsset}} {{cryptoAmount}}</strong><br />Están en camino a la dirección<br /><strong>{{destinationWalletAddress}}</strong>',
  'confirmPage.completedSuccess_transferPending':
    'Hemos recibido tu confirmación.<br />Tus<br /><strong>{{cryptoAmount}} {{destinationAsset}}</strong><br />Serán enviados a tu dirección a la brevedad<br />{{destinationAsset}}<br /><strong>{{destinationWalletAddress}}</strong>',
  'confirmPage.completedSuccess_userConfirmed':
    'Hemos recibido tu confirmación.<br />Tus<br /><strong>{{cryptoAmount}} {{destinationAsset}}</strong><br />Serán enviados a tu dirección a la brevedad<br />{{destinationAsset}}<br /><strong>{{destinationWalletAddress}}</strong>',
  'confirmPage.completedSuccess.hash': 'Este es tu Tx ID:',
  'confirmPage.loading': 'Cargando...',
  'confirmPage.payStatusAccepted': 'acreditado',
  'confirmPage.payStatusLabel': 'Estado de tu pago:',
  'confirmPage.thanks': '¡Gracias!',
  'confirmPage.thanksOperatingWithUs': 'Gracias por operar con nosotros :)',
  'confirmPage.title': 'Confirma tu orden Nº {{confirmId}}',
  'confirmPage.updatingIn': 'Actualizando en',
  'confirmPage.yourCryptoAddress': 'Tu dirección {{crypto}}',
  'confirmPage.yourOrderLabel': 'Tu orden',
  'confirmPage.transferStatusLabel': 'Estado de tu transferencia',
  'confirmPage.transferStatusReceived': 'Recibida',
  'confirmPage.amountReceived': 'Cantidad recibida',
  'confirmPage.amountCashout': 'Monto a cobrar',
  'confirmPage.withdrawfeeInfo': 'Comisión de cobro informada por el procesador de pagos',
  'confirmPage.totalCashout': 'Total a recibir',
  consult: 'Consultar',
  'country.AR': 'Argentina',
  'country.BR': 'Brasil',
  'country.MX': 'México',
  'currencies.ARS': 'Argentinean Peso',
  'currencies.USD': 'US Dollar',
  'currency.country.AR': 'ARS',
  'currency.country.BR': 'BRL',
  'currency.country.MX': 'MXN',
  'deposit.addAccount.description': 'You can only add an account under the same name you completed in your KYC.',
  'deposit.addAccount.placeholder.accountCBU': 'Account CBU',
  'deposit.addAccount.placeholder.holderLastName': 'Account holder Last Name',
  'deposit.addAccount.placeholder.holderName': 'Account holder Name(s)',
  'deposit.addAccount.title': 'Add a bank account',
  'deposit.cardConfirmationStep.endingText': '{{cardBrand}} ending in {{last4Digits}}',
  'deposit.cardConfirmationStep.title': 'Credit card deposit',
  'deposit.cardInfoStep.placeholder.cardNumber': 'Card number',
  'deposit.cardInfoStep.placeholder.expirationMonth': 'Expiration Month',
  'deposit.cardInfoStep.placeholder.expirationYear': 'Expiration Year',
  'deposit.cardInfoStep.placeholder.holderName': 'First and Last Name',
  'deposit.cardInfoStep.placeholder.securityCode': 'Security Code',
  'deposit.cardInfoStep.title': 'Credit card details',
  'deposit.cashInfoStep.description':
    'Please go to any of the retail cash network locations and pay to the cashier using the followiing payment code.',
  'deposit.cashInfoStep.title': 'Cash deposit details',
  'deposit.cashSelectStep.pagofacil': 'Pago Fácil',
  'deposit.cashSelectStep.rapipago': 'Rapipago',
  'deposit.cashSelectStep.subtitle': 'Select the cash network you want to use.',
  'deposit.cashSelectStep.title': 'Cash deposit details',
  'deposit.finishStep.actionBtn': 'Finalizar',
  'deposit.finishStep.question': '',
  'deposit.finishStep.textFour':
    'Tu orden tendrá una validez de 48hs hábiles para ser pagada, una vez transcurrido ese tiempo sin recibir el pago, la orden se cancelará automáticamente.',
  'deposit.finishStep.textOne':
    'Los datos de tu orden fueron recibidos correctamente.<br />Te enviamos un detalle a tu correo.',
  'deposit.finishStep.textThree':
    'Los pagos recibidos fuera de horario bancario serán procesados cuando el mismo se reanude (Lunes a Viernes - 10hs a 15hs).',
  'deposit.finishStep.textTwo':
    'Al momento de verificar la acreditación de tu pago, te enviaremos un correo para que confirmes tu orden con el precio actual.',
  'deposit.finishStep.title': '¡Gracias {{name}}!',
  'deposit.identityStep.placeholder': 'CURP',
  'deposit.identityStep.subtitle': 'Please enter your full DNI number.',
  'deposit.identityStep.title': 'Credit card details',
  'deposit.stepFive.hasAccount':
    'Please confirm that you will be sending {{currency}} {{amount}} from your following account:',
  'deposit.stepFive.title': 'Deposit details',
  'deposit.stepFive.withoutAccount':
    'There is no linked bank account in your profile, please add at least one bank account, so we can identify your incoming transfers',
  'deposit.stepFour.subtitleOne': 'Please make one only transfer of',
  'deposit.stepFour.subtitleTwo': 'to the following account',
  'deposit.stepFour.title': 'Deposit Details',
  'deposit.stepOne.radioOne': 'Argentinean Peso',
  'deposit.stepOne.radioTwo': 'US Dollar',
  'deposit.stepOne.subtitle': 'Choose the currency you wish to deposit',
  'deposit.stepOne.title': 'FIAT Deposit',
  'deposit.stepSix.message':
    'Your account balance will be updated once we receive your payment and we will notify via email. You can always check your deposit status in your account.',
  'deposit.stepSix.subtitle': 'Your transfer details were successfully submited!',
  'deposit.stepSix.title': 'Thank you!',
  'deposit.stepThree.subtitle': 'Please enter the amount you want to fund',
  'deposit.stepThree.title': 'Amount to deposit',
  'deposit.stepTwo.radioOne': 'Bank Account',
  'deposit.stepTwo.radioThree': 'Credit card',
  'deposit.stepTwo.radioTwo': 'Cash deposit',
  'deposit.stepTwo.subtitle': 'Choose your payment method',
  'deposit.stepTwo.title': 'Buy ZARS Stable Coin.',
  'emailCode.cancel': 'Cancelar',
  'emailCode.correctCode': '¡Código correcto!',
  'emailCode.incorrectCode': '¡Código incorrecto!',
  'emailCode.next': 'Siguiente',
  'emailCode.onlyNumbersError': 'Error, solo puedes ingresar números!',
  'emailCode.reSendCode': 'Reenviar código',
  'emailCode.reSendCodeError': '¡Error en el envío del código!',
  'emailCode.reSendCodeSuccess': '¡El código se ha enviado correctamente!',
  'emailCode.text':
    'Hola <strong>{{email}}</strong>, para continuar por favor ingresa el código de 4 dígitos que te enviamos a tu correo. (Revisa tu spam por las dudas)',
  'emailCode.recoveryText':
    '<strong>{{email}}</strong> hemos recibido tu solicitud. Recibirás un código en caso de que el email ingresado se corresponda con una cuenta Settle ID. (Revisa tu spam por las dudas)',
  'emailCode.title': 'Settle ID - Verifica tu correo',
  'errorPage.DepositsExisting.contact': 'Contactar a soporte',
  'errorPage.DepositsExisting.description.deposit':
    'Para realizar una nueva orden deberás realizar el pago de tu orden pendiente.<br /><br />Si necesitas cancelarla <0>contáctate con soporte</0>.',
  'errorPage.DepositsExisting.description.withdraw':
    'Para realizar una nueva orden deberás realizar la transferencia de tu orden pendiente.',
  'errorPage.DepositsExisting.title.deposit': '¡Ya existe una orden pendiente a tu nombre!',
  'errorPage.DepositsExisting.title.withdraw': '¡Ya existe 1 orden pendiente a tu nombre!',
  'errorPage.AmountTooLow.title': 'El importe es demasiado bajo',
  'errorPage.AmountTooLow.description':
    'El importe que ingresaste está por debajo del mínimo permitido. Por favor crea una nueva orden por al menos <strong>{{fiat-asset}} {{fiat-minimum}}</strong>.',
  'errorPage.WrongAsset.title': 'Moneda incorrecta',
  'errorPage.WrongAsset.description':
    'La moneda que seleccionaste <strong>{{fiat-asset}}</strong>, no corresponde al país con el que realizaste tu verificación de identidad <strong>{{user-country}}</strong>.',
  'errorPage.SessionConflict.title': 'Error de sesión',
  'errorPage.SessionConflict.description':
    'Esta sesión ha encontrado un conflicto y no puede continuar. No te preocupes, si ya has creado una orden, recibirás los datos por email, sino intenta crear una nueva orden. En caso de que vuelvas a ver este error, contactate con soporte.',
  'errorPage.Cookies.title': 'Error en las cookies',
  'errorPage.Cookies.description':
    'Las cookies en tu navegador están bloqueadas o no están disponibles. Asegurate que estén habilitadas y vuelve a intentarlo. Si vuelves a ver este error contactate con soporte.',
  'errorPage.retryOrContact': 'Por favor reingresa tu orden o contactate con <0>soporte</0>',
  'errorPage.somethingWrong': 'Parece que algo salió mal',
  'errorPage.title': 'Oops!',
  'errorPage.return': 'Volver',
  exit: 'Salir',
  'fastcash.fcDepositBankTransfer.account.label': 'Informe a su cuenta, con dígitos.',
  'fastcash.fcDepositBankTransfer.agency.label': 'Informe a su agencia, sin cifras',
  'fastcash.fcDepositBankTransfer.bank.label': 'Elija su banco.',
  'fastcash.fcDepositBankTransfer.description':
    'Realice una transferencia a través de banca electrónica, teléfono o cajero automático.',
  'fastcash.fcDepositBankTransfer.error':
    '¡Ups! Algo salió mal. Por favor, vuelva a intentarlo y, si el problema persiste, póngase en contacto con el servicio de asistencia técnica.',
  'fastcash.fcDepositBankTransfer.nextButton': 'Siguiente',
  'fastcash.fcDepositBankTransfer.title': 'Transferencia bancaria',
  'fastcash.fcDepositCash.description':
    'Elija una ubicación para realizar el depósito, no es necesario ser titular de una cuenta. Elija la agencia o terminal de su elección.',
  'fastcash.fcDepositCash.error':
    '¡Ups! Algo salió mal. Por favor, vuelva a intentarlo y, si el problema persiste, póngase en contacto con el servicio de asistencia técnica.',
  'fastcash.fcDepositCash.nextButton': 'Siguiente',
  'fastcash.fcDepositCash.subPaymentMethod.label': 'Elija su ubicación',
  'fastcash.fcDepositCash.title': 'Depósito en efectivo',
  'fastcash.fcDepositDetails.account.label': 'Conta-Corrente',
  'fastcash.fcDepositDetails.accountHolder.label': 'Favorecido',
  'fastcash.fcDepositDetails.agency.label': 'Agência',
  'fastcash.fcDepositDetails.bank.label': 'Banco',
  'fastcash.fcDepositDetails.bankTransferDescription':
    'Para efetuar o pagamento realize uma transeferência pelo internet banking, celular, phone banking ou caixa eletrônico para:',
  'fastcash.fcDepositDetails.bankTransferTitle': 'Datos para la transferencia',
  'fastcash.fcDepositDetails.cashDescription':
    'Para efetuar o pagamento qualquer agência do banco Lotéricas e faça um depósito com os seguintes dados:',
  'fastcash.fcDepositDetails.cashTitle': 'Datos para el depósito',
  'fastcash.fcDepositDetails.copyButton.notPushed': 'copiar',
  'fastcash.fcDepositDetails.copyButton.pushed': 'copiado',
  'fastcash.fcDepositDetails.homeBankingLegend': 'Acesse seu internet banking',
  'fastcash.fcDepositDetails.nextButton': 'Siguiente',
  'fastcash.fcDepositDetails.total.label': 'Total: ',
  'fastcash.fcDepositFinish.description1':
    'Los detalles de su giro postal han sido recibidos correctamente. Su pedido será procesado tan pronto como recibamos la transferencia de fondos. Después de transferir el dinero, por favor, introduzca los detalles del vale.',
  'fastcash.fcDepositFinish.description2':
    'Enviaremos um e-mail para informá-lo sobre qualquer alteração de status em seu pedido.',
  'fastcash.fcDepositFinish.fee.label': 'Comisión e impuestos',
  'fastcash.fcDepositFinish.from.label': 'De',
  'fastcash.fcDepositFinish.idLabel': 'ID #',
  'fastcash.fcDepositFinish.nextButton': 'Verificar pago',
  'fastcash.fcDepositFinish.order.label': 'Número de pago',
  'fastcash.fcDepositFinish.title': '¡Gracias {{name}}!',
  'fastcash.fcDepositFinish.total.from': 'De',
  'fastcash.fcDepositFinish.total.label': 'Total',
  'fastcash.fcDepositMethod.bankTransfer': 'Transferencia bancaria',
  'fastcash.fcDepositMethod.cash': 'Efectivo',
  'fastcash.fcDepositMethod.description1': 'Pague ',
  'fastcash.fcDepositMethod.description2': ' para finalizar su pedido',
  'fastcash.fcDepositMethod.footerLegend':
    'Si tiene alguna pregunta sobre su pedido, póngase en contacto con el equipo de soporte',
  'fastcash.fcDepositMethod.nextButton': 'Siguiente',
  'fastcash.fcDepositMethod.termsAndConditionsCheck': 'Acepto los términos y condiciones',
  'fastcash.fcDepositMethod.title': 'Seleccione la forma de pago',
  'fastcash.fcDepositVerification.amount.label': 'importe',
  'fastcash.fcDepositVerification.description': 'Para comprovar o pagamento do seu pedido insira os dados abaixo',
  'fastcash.fcDepositVerification.error':
    '¡Ups! Algo salió mal. Por favor, vuelva a intentarlo y, si el problema persiste, póngase en contacto con el servicio de asistencia técnica.',
  'fastcash.fcDepositVerification.nextButton': 'Verificar pago',
  'fastcash.fcDepositVerification.orderNumber.label': 'Nº de Orden',
  'fastcash.fcDepositVerification.reference.help':
    'Puede encontrar su número de pedido en el correo que recibió cuando creó el pedido.',
  'fastcash.fcDepositVerification.reference.label': 'Referencia #',
  'fastcash.fcDepositVerification.supportContact':
    'Si tiene alguna pregunta sobre su pedido, póngase en contacto con el equipo de soporte',
  'fastcash.fcDepositVerification.title': 'Verificar pago',
  'fastcash.fcDepositVerificationSuccess.description1': 'Gracias por verificar',
  'fastcash.fcDepositVerificationSuccess.description2':
    'Usted recibirá un correo electrónico tan pronto como recibamos su compensación de pago del banco.',
  'fastcash.fcDepositVerificationSuccess.description3':
    'Advertencia: los depósitos por sobre se toman hasta el final del siguiente día hábil para ser compensados.',
  'fastcash.fcDepositVerificationSuccess.nextButton': 'Comprovar estado',
  'fastcash.fcDepositVerificationSuccess.supportContact':
    'Si tiene alguna pregunta sobre su pedido, póngase en contacto con el equipo de soporte',
  'fastcash.fcDepositVerificationSuccess.title': 'Gracias {{name}}!',
  finalizar: 'Finalizar',
  finish: 'Finalizar',
  'instructions.copied': 'copiado',
  'instructions.copy': 'copiar',
  'instructions.description.bank.bankTransfer':
    'Por favor realiza una transferencia bancaria desde una cuenta a tu nombre, <strong>{{name}} {{lastname}},</strong> por el <strong>importe exacto</strong> e incluye el <strong>número de referencia</strong>, si tu banco te permite esta opción, para que podamos identificar mejor tu pago. <br /> Si tu banco no te permite incluir el número de referencia, puedes ignorarlo.',
  'instructions.description.rapipago.cashNetwork':
    'Para efectuar el pago, acercate a cualquier sucursal de Rapipago e informale al cajero que queres realizar un pago con los siguientes datos (también te enviaremos esta información a tu email):',
  'instructions.details.CBU': 'CBU',
  'instructions.details.account': 'Nº de Cuenta',
  'instructions.details.agency': '',
  'instructions.details.alias': 'Alias',
  'instructions.details.refNumber': 'Nº Referencia',
  'instructions.details.amount': 'Total',
  'instructions.details.CLABE': 'CLABE',
  'instructions.details.bank': 'Banco',
  'instructions.details.banknumber': '',
  'instructions.details.cbu': '',
  'instructions.details.clabe': 'CLABE',
  'instructions.details.cnpj': '',
  'instructions.details.company': 'Empresa',
  'instructions.details.cuit': '',
  'instructions.details.recipient': 'Beneficiario',
  'instructions.details.refnumber': 'Concepto/Referencia',
  'instructions.details.rfc': 'RFC',
  'instructions.title.bank.bankTransfer': 'Datos para transferencia',
  'instructions.title.rapipago.cashNetwork': 'Datos para el pago en efectivo',
  'instructions.tooltip':
    'Ingresa este numero en cualquier campo de referencia que te permita tu banco. Si tu banco no lo permite, puedes ignorarlo.',
  kycTitle: 'Verificación de identidad',
  'limitEnforcement.addDocs.description':
    'Por favor adjunta los siguientes comprobantes según corresponda para poder aumentar tu límite y procesar tu órden:',
  'limitEnforcement.addDocs.fileRequestList':
    '- Recibo de nómina (últimos 2 meses)<br />- Estado de cuenta / cuenta inversiones (últimos 2 meses)<br />- Constancia de situación fiscal ante el SAT',
  'limitEnforcement.addDocs.title': 'Aumento de límite',
  'limitEnforcement.addDocs.uploadText': 'Seleccionar archivos o arrastrar aquí',
  'limitEnforcement.checkStatus.description':
    'Ingresa tu email para que te enviemos un detalle del estado de tus límites de operación.',
  'limitEnforcement.checkStatus.footer': 'Si tienes dudas ',
  'limitEnforcement.checkStatus.footer.link': 'ponte en contacto con el equipo de soporte',
  'limitEnforcement.checkStatus.title': 'Consultá el estado de tus límites',
  'limitEnforcement.limitExceeded.availableAnual': 'Límite anual restante',
  'limitEnforcement.limitExceeded.availableBiannual': 'Límite semestral restante',
  'limitEnforcement.limitExceeded.availableDaily': 'Límite diario restante',
  'limitEnforcement.limitExceeded.availableMonthly': 'Límite mensual restante',
  'limitEnforcement.limitExceeded.contactSupport': 'Contactarme para solicitar un aumento de límite',
  'limitEnforcement.limitExceeded.contactSupport.link': 'https://{{branding}}.zendesk.com/hc/es/requests/new',
  'limitEnforcement.limitExceeded.description':
    'Superaste tu límite semestral o diario de operación. Puedes crear una nueva orden por un importe menor o solicitar un aumento de límite con nuestro equipo de soporte',
  'limitEnforcement.limitExceeded.minorOrderButton': 'Crear orden por importe menor',
  'limitEnforcement.limitExceeded.title': 'Excediste tu límite',
  'limitEnforcement.limitExceeded.yourOrder': 'Tu orden',
  'limitEnforcement.orderStatus.executed': 'EJECUTADA',
  'limitEnforcement.orderStatus.expired': 'CADUCADA',
  'limitEnforcement.orderStatus.pending': 'PENDIENTE',
  'limitEnforcement.orderStatus.rejected': 'RECHAZADA',
  'limitEnforcement.uploadSuccess.button': 'Volver a {{companyName}}',
  'limitEnforcement.uploadSuccess.description':
    'Tu información fue enviada correctamente.<br />Nuestro equipo está comenzando el proceso de revisión necesario para aumentar tus límites de operación.<br /><br />Una vez que verifiquemos la documentación, te enviaremos un email informando sobre el resultado de tu solicitud.',
  'paymentMethod.bankTransfer': 'Transferencia bancaria',
  'paymentMethod.cash': 'Efectivo',
  'paymentMethod.description': 'Total a pagar',
  'paymentMethod.footerLegend':
    'Su orden será procesada por un procesador de pagos local. Antes de continuar debe aceptar los términos y condiciones y política de privacidad.',
  'paymentMethod.nextButton': 'Continuar',
  'paymentMethod.termsAndConditionsCheck': 'Acepto los ',
  'paymentMethod.termsAndConditionsCheckAnd': 'y',
  'paymentMethod.Rapipago': 'Efectivo - Rapipago',
  'welcome.welcomeScreen': 'Resumen de tu orden de pago',
  'welcome.description': 'Chequea todos los datos antes de continuar, ¡Sobre todo la dirección de tu billetera!',
  'welcome.feeWithMin': '(cargo de {{fee}}% o mínimo de {{currency}} {{minFee}})',
  'welcome.fee': '(cargo de {{fee}}%)',
  'paymentMethod.description1': 'Pague ',
  'paymentMethod.description2': ' para finalizar su pedido',
  'paymentMethod.escription1': '',
  'paymentMethod.escription2': '',
  'paymentMethod.title': 'Seleccione la forma de pago',
  'paymentMethod.notEnabled':
    'Para poder operar con {{name}}<br/> deberás crear una nueva orden <br />por un valor {{majorMinor}} a {{amount}} {{currency}}',
  'paymentMethod.notEnabled.major': 'mayor',
  'paymentMethod.notEnabled.minor': 'menor',

  'paymentMethod.notEnabled.limit':
    'Límite alcanzado.<br/> Límite Anual = {{annualRemaining}} / {{annualLimit}} <br/> Límite Diario = {{dailyRemaining}} / {{dailyLimit}}',

  send: 'Enviar',
  'status.checkStatus.Deposit': 'Depósito',
  'status.checkStatus.Withdraw': 'Extracción',
  'status.checkStatus.checkButton': 'Consultar',
  'status.checkStatus.description':
    'Ingresa el número de orden (chécalo en los mails que te enviamos) y tu CURP para poder consultar si tu orden está pendiente, completada o rechazada.',
  'status.checkStatus.documentId.label': 'CURP',
  'status.checkStatus.errorMessage': 'Por favor checa que tu número de orden y CURP sean correctos',
  'status.checkStatus.footer': 'Si tienes dudas sobre el estado de tu órden',
  'status.checkStatus.footer.link': 'Ponte en contacto con el equipo de soporte',
  'status.checkStatus.orderNumber.label': 'Nº de Orden',
  'status.checkStatus.status.title': 'Estado de la orden',
  'status.checkStatus.title': 'Consulta el estado de tu orden',
  'status.checkStatus.type': 'Tipo de Transacción',
  'warningInfo.title': 'Información muy importante sobre tu pago',
  'welcome.addressConfirm': 'Confirmo que los datos de mi billetera son correctos',
  'welcome.cancelAction': 'Cancelar la orden',
  'welcome.termsAndCondDesc':
    'Su orden será procesada por un procesador de pagos local.<br/>Antes de continuar debe aceptar los términos y condiciones.',
  'warningInfo.disclaimer':
    'En caso de no cumplir con todas estas indicaciones, tu pago no podrá ser identificado correctamente, pudiendo demorar, dificultar o extraviar tu transacción',
  'warningInfo.checkText': 'Confirmo que he leído y comprendo esta información',
  'welcome.description.Deposit': 'Checa todos los datos antes de continuar',
  'welcome.paymentOrder': 'Orden de pago',
  'welcome.processorCharge': 'Comisión',
  'welcome.flatFee': 'Cargo Fijo:',
  'welcome.varFee': 'Cargo variable:',
  'welcome.startBtn': 'Continuar',
  'welcome.termsAndCond': 'Acepto los <0>términos y condiciones</0> y <1>políticas de privacidad</1>',
  'welcome.title': 'Tu pedido de {{companyName}}',
  'welcome.total.Deposit': 'Total a pagar:',
  'welcome.total.Withdraw': 'Total a retirar:',
  'welcome.welcomeScreen.Deposit': 'Resumen de tu orden de pago',
  'welcome.welcomeScreen.Withdraw': 'Resumen de tu orden de venta',
  'welcome.order.Deposit': 'Orden de pago',
  'welcome.order.Withdraw': 'Total orden de venta',
  'welcome.description.Withdraw': 'Checa todos los datos antes de continuar!',
  'welcome.termsAndCondDesc.withdraw':
    'Su cobro será procesado por un procesador de pagos local.<br/>Antes de continuar debe aceptar los términos y condiciones.',
  'welcome.termsAndCond.withdraw':
    'Acepto los <0>términos y condiciones</0> y <1>políticas de privacidad</1> del procesador de pagos',
  'welcome.withdrawDisclaimer':
    'Comisión de cobro informada por el procesador de pagos local elegido en tu jurisdicción. El monto de la comisión será descontado del total a recibir',
  'welcome.estimatedFees': 'Cargos estimados',
  'withdraw.clabe': 'CLABE',
  'withdraw.orderNumber': 'Orden Nº',
  'withdraw.importantInfo':
    '<strong>Importante</strong>: Si tu billetera cobra un cargo adicional por retiro, asegurate de agregarlo para que recibamos el importe completo.<br /><br />Una vez que recibamos la transferencia en nuestra billetera, enviaremos un link a tu correo para que confirmes tu orden con la cotización final de ese momento.',
  'withdraw.etaTransfer': 'Tiempo de acreditación<br/>1 a 24 hs hábiles desde recibida la transferencia',
  'withdraw.amount': 'Importe',
  'withdraw.memo': 'Memo',
  'withdraw.network': 'Red',
  'withdraw.wallet': 'Billetera',
  'withdraw.address': 'Dirección',
  'withdraw.sendToWallet':
    'Por favor realiza una transferencia de <strong>{{cryptoAmount}} {{originAsset}}</strong> a la siguiente billetera.',
  'withdraw.termsAndCond.memo':
    'Entiendo que si no completo la transferencia a la <strong>dirección y MEMO</strong> correctos, mis fondos se perderán.',
  'confirmPage.orderComplete': '¡Tu orden fue completada!',
  'confirmPage.gonnaReceiveSoon': 'Pronto recibirás el pago en tu cuenta.',
  'confirmPage.confirmDetail':
    'Las órdenes de venta confirmadas fuera de días y horarios bancarios serán procesadas cuando los mismos se reanuden.',
  'walletAddress.title': 'Billetera o dirección de destino',
  'walletAddressDestination.title': 'Billetera o dirección de destino',
  'walletAddress.selectLabel': 'Seleccione Billetera',
  'walletAddress.labelPart1': 'Ingresa la dirección de tu billetera {{cryptoAsset}} correspondiente a la',
  'walletAddress.labelPart2': ' red principal ',
  'walletAddress.labelPart3':
    '<strong>({{network}})</strong>. Enviar cualquier criptomoneda hacia tu billetera utilizando una red que no soportamos resultará en una potencial pérdida de tus fondos.',
  'walletAddress.tooltipText': 'Red principal soportada por nuestra plataforma.',
  'walletAddress.addressLabel': 'Dirección de billetera',
  'walletAddress.memoLabel': 'Memo',
  'walletAddress.memoOptional': 'No requiere MEMO / TAG',
  'walletAddress.memoTooltip':
    'Muchas billeteras requieren que ingreses un Memo/Tag. En caso de que estés seguro de que no lo requiere tilda la opción “no requiere Memo/Tag“. Si completas esta opción erróneamente, tus fondos se pueden perder. Si tienes dudas puedes leer más sobre Memo/Tag en nuestro centro de ayuda.',
  'walletAddressFederation.label':
    'Por favor, ingresa la dirección de tu billetera o dirección federada de {{cryptoAsset}} donde quieres recibir tus criptomonedas.',
  'walletAddressFederation.title': 'Dirección federada',
  'walletAddressFederation.selection': 'Seleccione cartera',
  'walletAddressFederation.description':
    'La dirección federada es un identificador similar a un correo electrónico que representa tu dirección pública Stellar de forma amigable. Cada dirección federada esta formada por: nombre-de-usuario(*)dominio. Deberías poder encontrarla en el perfil o configuración de tu billetera.'
};
