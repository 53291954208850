import React, { useState, useRef } from 'react';
import { Col, Row } from 'reactstrap';
import { Trans } from 'react-i18next';
import { v1 as uuidv1 } from 'uuid';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';

import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileMetadata from 'filepond-plugin-file-metadata';

import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

import { FilePond, registerPlugin } from 'react-filepond';
import CheckoutStep from '../Layout/CheckoutStep';

import HandMoneyDoc from '../../images/enforcement/hand-money-doc.svg';

// Import React FilePond

// Register the plugins
registerPlugin(FilePondPluginImagePreview, FilePondPluginFileValidateSize, FilePondPluginFileMetadata);

const AddDocsComponent = ({ token, history, t, sendEmailNotif }) => {
  const uuid = uuidv1();

  const [fileCount, setFileCount] = useState(0);
  const [enabledButton, setEnabledButton] = useState(true);
  const pondRef = useRef();
  const handleUpdateFileList = fileItems => {
    const isError = fileItems.filter(fi => fi.status !== 2).length > 0;
    setEnabledButton(!isError);
    setFileCount(fileItems.length);
  };
  const handleInit = () => {
    pondRef.current._pond.setOptions({
      fileMetadataObject: {
        uploadId: uuid
      }
    });
  };

  const handleProcessFinish = () => {
    sendEmailNotif(uuid);
  };

  const handleInitUpload = () => {
    pondRef.current.processFiles();
    setEnabledButton(false);
  };
  const serverConf = {
    url: process.env.REACT_APP_API_URL,
    process: {
      url: '/kyc/file',
      method: 'POST',
      headers: {
        Authorization: `Token ${token}`
      }
    }
  };

  const uploadZone = `<i class="cloud-icon"></i>${t('limitEnforcement.addDocs.uploadText')}`;

  return (
    <div className="d-flex flex-column align-items-center">
      <CheckoutStep
        title={t('limitEnforcement.addDocs.title')}
        next={handleInitUpload}
        prev={history.goBack}
        buttonContent={t('send')}
        disabled={fileCount === 0 || !enabledButton}
      >
        <div className="text-center mt-4">
          <img src={HandMoneyDoc} alt="limit exceeded" />
        </div>
        <p className="description mt-4">{t('limitEnforcement.addDocs.description')}</p>
        <p className="description mb-4 mt-4 fz-14">
          <Trans i18nKey="limitEnforcement.addDocs.fileRequestList">
            text
            <br />
            text
            <br />
            text
          </Trans>
        </p>
        <Row>
          <Col md={{ size: 8, offset: 2 }}>
            <FilePond
              ref={pondRef}
              allowMultiple
              maxFiles={6}
              instantUpload={false}
              maxParallelUploads={1}
              name="file"
              server={serverConf}
              allowRevert={false}
              oninit={() => handleInit()}
              onupdatefiles={handleUpdateFileList}
              onprocessfiles={handleProcessFinish}
              maxFileSize="8MB"
              imagePreviewHeight={100}
              labelIdle={uploadZone}
              labelFileProcessing="Subiendo..."
              labelFileProcessingComplete="Listo"
              labelFileProcessingError="Error subiendo el archivo"
              labelTapToRetry=""
              labelTapToCancel=""
            />
          </Col>
        </Row>
      </CheckoutStep>
    </div>
  );
};

export default AddDocsComponent;
