import React from 'react';
import { connect } from 'react-redux';

import FcDepositVerificationSuccessComponent from '../../../components/Fastcash/FcDepositVerificationSuccess';

const FcDepositVerificationSuccess = ({ history, kyc }) => (
  <FcDepositVerificationSuccessComponent {...{ history, kyc }} />
);

const mapStateToProps = state => {
  return {
    user: state.user.user,
    kyc: state.user.kyc
  };
};

export default connect(mapStateToProps, null)(FcDepositVerificationSuccess);
