import React, { useState } from 'react';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { withTranslation } from 'react-i18next';
import CurrencyFormat from 'react-currency-format';
import CheckoutStep from '../../Layout/CheckoutStep';

const FcDepositDetailsComponent = ({ history, paymentMethod, deposit, providerResponse, t }) => {
  const [copied, setCopied] = useState('none');

  return (
    <div className="d-flex flex-column align-items-center">
      <CheckoutStep
        title={
          paymentMethod === 1
            ? t('fastcash.fcDepositDetails.cashTitle')
            : t('fastcash.fcDepositDetails.bankTransferTitle')
        }
        hideGoBack
        next={() => history.push('/br/deposit-finish')}
        buttonContent={t('fastcash.fcDepositDetails.nextButton')}
      >
        <div className="fc-step">
          <p className="description">
            {paymentMethod === 1 && t('fastcash.fcDepositDetails.cashDescription')}
            {paymentMethod === 2 && t('fastcash.fcDepositDetails.bankTransferDescription')}
          </p>

          <div className="details">
            <div className="detail">
              <span className="detail__text">
                {t('fastcash.fcDepositDetails.accountHolder.label')}: <b>{providerResponse.accountholder}</b>
              </span>
              <CopyToClipboard text="Fastcash Ltda" onCopy={() => setCopied('accountholder')}>
                <span className="detail__copy-btn">
                  {copied === 'accountholder'
                    ? t('fastcash.fcDepositDetails.copyButton.pushed')
                    : t('fastcash.fcDepositDetails.copyButton.notPushed')}
                </span>
              </CopyToClipboard>
            </div>

            <div className="detail">
              <span className="detail__text">
                {t('fastcash.fcDepositDetails.bank.label')}: <b>{providerResponse.bank}</b>
              </span>
              <CopyToClipboard text="Bradesco" onCopy={() => setCopied('bank')}>
                <span className="detail__copy-btn">
                  {copied === 'bank'
                    ? t('fastcash.fcDepositDetails.copyButton.pushed')
                    : t('fastcash.fcDepositDetails.copyButton.notPushed')}
                </span>
              </CopyToClipboard>
            </div>

            <div className="detail">
              <span className="detail__text">
                {t('fastcash.fcDepositDetails.agency.label')}: <b>{providerResponse.agency}</b>
              </span>
              <CopyToClipboard text="6681" onCopy={() => setCopied('agency')}>
                <span className="detail__copy-btn">
                  {copied === 'agency'
                    ? t('fastcash.fcDepositDetails.copyButton.pushed')
                    : t('fastcash.fcDepositDetails.copyButton.notPushed')}
                </span>
              </CopyToClipboard>
            </div>

            <div className="detail">
              <span className="detail__text">
                {t('fastcash.fcDepositDetails.account.label')}: <b>{providerResponse.account}</b>
              </span>
              <CopyToClipboard text="4992-1" onCopy={() => setCopied('account')}>
                <span className="detail__copy-btn">
                  {copied === 'account'
                    ? t('fastcash.fcDepositDetails.copyButton.pushed')
                    : t('fastcash.fcDepositDetails.copyButton.notPushed')}
                </span>
              </CopyToClipboard>
            </div>

            <div className="detail">
              <span className="detail__text">
                {t('fastcash.fcDepositDetails.total.label')}
                <b>
                  R
                  <CurrencyFormat
                    value={deposit.amount + deposit.feeAmount}
                    displayType="text"
                    thousandSeparator=","
                    decimalSeparator="."
                    fixedDecimalScale
                    decimalScale={2}
                    prefix="$ "
                  />
                </b>
              </span>
            </div>
          </div>

          {paymentMethod === 2 && <p>{t('fastcash.fcDepositDetails.homeBankingLegend')}</p>}
        </div>
      </CheckoutStep>
    </div>
  );
};

export default withTranslation()(FcDepositDetailsComponent);
