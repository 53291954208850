class HeapBridge {
  identify(id) {
    try {
      window.heap.identify(id);
    } catch (err) {
      console.error(err);
    }
  }

  addUserProperties(...args) {
    try {
      window.heap.addUserProperties({ ...args });
    } catch (err) {
      console.error(err);
    }
  }

  track({ event, ...args }) {
    try {
      window.heap.track(event, { ...args });
    } catch (err) {
      console.error(err);
    }
  }
}

export default new HeapBridge();
