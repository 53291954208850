/* eslint-disable no-unused-expressions */
import _ from 'lodash';
import querystring from 'query-string';
import React, { useState, useEffect } from 'react';
import { withTranslation, Trans } from 'react-i18next';

import { Row } from 'reactstrap';

import { connect } from 'react-redux';
import CurrencyFormat from 'react-currency-format';
import Countdown from 'react-countdown-now';

import './Confirm.scss';
import CheckoutActions from '../../redux/CheckoutRedux';
import AuthActions from '../../redux/AuthRedux';
import cryptoImage from '../../images/icons/latamex-digital-asset@2x.png';

import CustomButton from '../../components/Commons/CustomButton/CustomButton';
import CheckoutStep from '../../components/Layout/CheckoutStep';
import ConfirmError from './ConfirmError';

import ConfirmSuccess from '../../components/Confirm/ConfirmSuccess';

import { langMapping } from '../../constants/countryMap';
import { ZendeskWebApi } from '../../utils/zendesk';
import HeapBridge from '../../utils/heap';

import { ALREADY_EXECUTED } from '../../constants/errors';
import decimals from '../../constants/decimals';

import watchIcon from '../../images/icons/watch.png';

import i18n from '../../i18n';

const decimalScaleCrypto = {
  NEO: 0,
  ONT: 0,
  TRX: 6,
  BTC: 10,
  LTC: 10,
  ETH: 10,
  BNB: 10,
  XLM: 2,
  XRP: 2,
  EOS: 2,
  USDT: 2,
  TUSD: 2,
  DAI: 2,
  USDC: 2,
  ARST: 2,
  BRLT: 2
};

function toFixedDown(value, digits) {
  const re = new RegExp(`(\\d+\\.\\d{${digits}})(\\d)`);
  const m = value.toString().match(re);
  return m ? parseFloat(m[1]) : value.valueOf();
}

const calculateAmount = (digitalCurrency, newQuote, fiatAmount) => {
  const newAmount = fiatAmount / newQuote;

  if (decimals.crypto[digitalCurrency] !== undefined) {
    return toFixedDown(newAmount, decimals.crypto[digitalCurrency]);
  }

  return newAmount;
};

const Confirm = ({
  getRate,
  error,
  errorDetails,
  rate = {},
  confirm,
  getCheckoutConfirmInfo,
  match,
  tokenExchange,
  checkoutConfirm,
  requestingConfirm,
  requestingGetRate,
  confirmResult,
  t
}) => {
  const [countdownDate, setCountdownDate] = useState(Date.now() + 21 * 1000);
  const [rateFirstTime, setRateFirstTime] = useState(true);
  const [confirmed, setConfirmed] = useState(false);

  useEffect(() => {
    const query = querystring.parse(window.location.search);
    tokenExchange(query.token);
    getCheckoutConfirmInfo({ id: match.params.id, type: checkoutType });
  }, []);

  useEffect(() => {
    if (confirm && !requestingGetRate) {
      getRate();
    }
  }, [(confirm || {})._id]);

  useEffect(() => {
    if (confirm && confirm.checkout) {
      const { countryCode } = confirm.checkout;
      if (langMapping[countryCode] !== i18n.language) {
        i18n.changeLanguage(langMapping[countryCode]);
        ZendeskWebApi('webWidget', 'setLocale', langMapping[countryCode]);
      }
    }
  }, [confirm]);

  useEffect(() => {
    if (errorDetails && errorDetails.countryCode) {
      const { countryCode } = errorDetails;
      if (langMapping[countryCode] !== i18n.language) {
        i18n.changeLanguage(langMapping[countryCode]);
        ZendeskWebApi('webWidget', 'setLocale', langMapping[countryCode]);
      }
    }
  }, [errorDetails]);

  useEffect(() => {
    if (rate.fx && rateFirstTime) {
      setCountdownDate(Date.now() + 21 * 1000);
      setRateFirstTime(false);
    }
  }, [rate.fx]);

  const { destinationAsset, checkoutAmount, destinationWalletAddress, callbackUrl } = confirm ? confirm.checkout : {};

  const { destinationAsset: destinationAssetError, destinationWalletAddress: destinationWalletAddressError } =
    errorDetails || {};

  const checkoutType = match.params.type || 'deposit';

  if (confirmResult !== undefined || error) {
    return (
      <>
        {((_.isObject(confirmResult) ? confirmResult.status === false : confirmResult === false) ||
          (error && error !== ALREADY_EXECUTED)) && (
          <>
            <ConfirmError lang={i18n.language} {...{ t }} />
          </>
        )}
        <CheckoutStep hideGoBack hideNextButton>
          {!error && (_.isObject(confirmResult) ? confirmResult.status : confirmResult) ? (
            <ConfirmSuccess
              cryptoAmount={calculateAmount(destinationAsset, rate.fx, checkoutAmount)}
              {...{
                t,
                destinationWalletAddress,
                destinationAsset,
                callbackUrl,
                confirmResult,
                checkoutType,
                confirm,
                rate
              }}
            />
          ) : (
            <>
              <p
                className={`description mt-4 mb-4 ${error !== ALREADY_EXECUTED ? 'error' : ''}`}
                style={{ size: '2em' }}
              >
                {error === ALREADY_EXECUTED && (
                  <div className="d-flex justify-content-center align-items-center flex-column">
                    <img src={cryptoImage} width="150" alt="crypto" className="d-block mb-4" />
                    <span className="mt-4">
                      <Trans
                        i18nKey={
                          (checkoutType === 'deposit' &&
                            (errorDetails.fullyProcessed === 'transferPending' ||
                              errorDetails.fullyProcessed === 'userConfirmed' ||
                              errorDetails.fullyProcessed === 'pendingForwarding')) ||
                          (checkoutType === 'withdraw' && errorDetails.fullyProcessed === false)
                            ? `confirmPage.alreadyExecuted_transferPending.${checkoutType}`
                            : `confirmPage.alreadyExecuted.${checkoutType}`
                        }
                      >
                        your <strong>{{ destinationAssetError }}</strong> sent to
                        <strong>{{ destinationWalletAddressError }}</strong>
                      </Trans>
                    </span>
                  </div>
                )}
              </p>
            </>
          )}
        </CheckoutStep>
      </>
    );
  }

  if (!confirm || !rate.fx) {
    return (
      <CheckoutStep hideGoBack hideNextButton>
        <p className="description mt-4 mb-4">{t('confirmPage.loading')}</p>
      </CheckoutStep>
    );
  }

  const crypto = checkoutType === 'deposit' ? confirm.checkout.destinationAsset : confirm.checkout.originAsset;
  const money = checkoutType === 'deposit' ? confirm.checkout.originAsset : confirm.checkout.destinationAsset;
  const amount = confirm.checkout.checkoutAmount;
  const fee = confirm.checkout.feeAmount;

  const checkoutConfirmFn = () => {
    setConfirmed(true);
    HeapBridge.track({
      event: checkoutType === 'deposit' ? 'OrderConfirmedBuy' : 'OrderConfirmedSell',
      checkoutId: confirm._id
    });
    checkoutConfirm(confirm._id, rate, checkoutType);
  };

  return (
    <CheckoutStep hideGoBack hideNextButton title={t('confirmPage.title', { confirmId: confirm._id })}>
      <div className="description mb-4 price-desc">
        {`${t('confirmPage.actualPriceLabel')} 1 ${crypto} =`}
        <br />
        <span style={{ fontSize: 24, fontWeight: 'bold' }} className="crypto-price-title">
          {money}{' '}
          <CurrencyFormat
            value={rate.fx}
            displayType="text"
            thousandSeparator=","
            decimalSeparator="."
            decimalScale={2}
          />
        </span>
        {!confirmed && (
          <Row
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <img src={watchIcon} width="20" height="20" alt="watch-icon" style={{ marginRight: '5px' }} />
            {` ${t('confirmPage.updatingIn')} `}
            <Countdown
              date={countdownDate}
              renderer={({ seconds }) => {
                if (seconds === 1 && !requestingGetRate) {
                  setCountdownDate(Date.now() + 21 * 1000);
                  if (confirmResult === undefined && confirm) {
                    getRate();
                  }
                }
                return seconds - 1;
              }}
            />
            s...
          </Row>
        )}
      </div>

      <div className="transfer-detail price-volatility mt-2 mb-5" style={{ maxWidth: '350px', padding: '32px 47px' }}>
        <Row className="trns-det">
          {checkoutType === 'deposit' && (
            <>
              <p className="description  mb-3 lh-24" style={{ textAlign: 'left' }}>
                {t('confirmPage.payStatusLabel')} <br />
                <span style={{ color: '#0bb07b' }}>{t('confirmPage.payStatusAccepted')}</span>
              </p>
              <p className="description mb-3 lh-24" style={{ textAlign: 'left' }}>
                {t('confirmPage.yourOrderLabel')}
                {': '}
                {/* <br /> */}
                {money}{' '}
                <CurrencyFormat
                  value={amount + fee}
                  displayType="text"
                  thousandSeparator=","
                  decimalSeparator="."
                  decimalScale={2}
                />{' '}
                ={' '}
                <b>
                  <CurrencyFormat
                    className="currency"
                    displayType="text"
                    value={rate && rate.fx ? calculateAmount(crypto, rate.fx, amount) : ''}
                    decimalScale={decimalScaleCrypto[crypto]}
                    fixedDecimalScale={false}
                  />{' '}
                  {crypto}
                </b>
              </p>
              <p className="description mb-3 lh-24" style={{ textAlign: 'left' }}>
                {t('confirmPage.yourCryptoAddress', { crypto })}
                <br />
                {confirm && confirm.checkout && confirm.checkout.destinationWalletAddress}
              </p>
            </>
          )}
          {checkoutType === 'withdraw' && (
            <>
              <p className="description mb-3 lh-24" style={{ textAlign: 'left' }}>
                {t('confirmPage.transferStatusLabel')}
                <br />
                <span style={{ color: '#00d214' }}>{t('confirmPage.transferStatusReceived')}</span>
              </p>
              <p className="description mb-3 lh-24" style={{ textAlign: 'left' }}>
                TX ID
                <br />
                {confirm.txId}
              </p>

              <p className="description mb-3 lh-24" style={{ textAlign: 'left' }}>
                {t('confirmPage.amountReceived')}
                <br />
                <CurrencyFormat
                  value={confirm.checkout.checkoutAmount + confirm.checkout.feeAmount}
                  displayType="text"
                  decimalSeparator="."
                  decimalScale={8}
                  fixedDecimalScale
                />{' '}
                {confirm.checkout.originAsset}
              </p>

              <p className="description mb-3 lh-24" style={{ textAlign: 'left' }}>
                {t('confirmPage.amountCashout')}: {confirm.checkout.destinationAsset}{' '}
                <CurrencyFormat
                  value={rate.fx * (confirm.checkout.feeAmount + confirm.checkout.checkoutAmount)}
                  displayType="text"
                  thousandSeparator=","
                  decimalSeparator="."
                  decimalScale={2}
                />
              </p>
              <p className="description mb-3 lh-24" style={{ textAlign: 'left' }}>
                {t('confirmPage.withdrawfeeInfo')}: {confirm.checkout.destinationAsset}{' '}
                <CurrencyFormat
                  value={rate.fx * confirm.checkout.feeAmount}
                  displayType="text"
                  thousandSeparator=","
                  decimalSeparator="."
                  decimalScale={2}
                />
              </p>
              <hr />
              <p>
                <b>
                  {t('confirmPage.totalCashout')}: {confirm.checkout.destinationAsset}{' '}
                  <CurrencyFormat
                    value={rate.fx * confirm.checkout.checkoutAmount}
                    displayType="text"
                    thousandSeparator=","
                    decimalSeparator="."
                    decimalScale={2}
                  />
                </b>
              </p>
            </>
          )}
        </Row>
      </div>

      <p className="description pt-3 mb-2">
        <CustomButton
          width="350px"
          disabled={requestingConfirm || !rate.fx || !confirm._id}
          onClick={checkoutConfirmFn}
        >
          {t('confirmPage.btn')}
        </CustomButton>
      </p>
    </CheckoutStep>
  );
};

const mapStateToProps = state => {
  return {
    error: state.checkout.error,
    errorDetails: state.checkout.errorDetails,
    rate: state.checkout.rate,
    confirm: state.checkout.confirm,
    confirmResult: state.checkout.confirmResult,
    requestingConfirm: state.checkout.requestingConfirm,
    requestingGetRate: state.checkout.requestingGetRate,
    consumer: state.checkout.confirm ? state.checkout.confirm.checkout.consumer : ''
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getRate: () => dispatch(CheckoutActions.getRate()),
    getCheckoutConfirmInfo: data => dispatch(CheckoutActions.getCheckoutConfirmInfoRequest(data)),
    checkoutConfirm: (id, rate, checkoutType) =>
      dispatch(CheckoutActions.checkoutConfirmRequest(id, rate, checkoutType)),
    tokenExchange: token => dispatch(AuthActions.saveTokenHeader(token))
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Confirm));
