/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Row, CustomInput as Check, Tooltip } from 'reactstrap';
import { PropagateLoader } from 'react-spinners';
import { withTranslation, Trans } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import * as multiCoinValidator from 'multicoin-address-validator';
import walletAddressValidator from '@swyftx/api-crypto-address-validator/dist/wallet-address-validator.min';
import Select, { components } from 'react-select';
import ReactTooltip from 'react-tooltip';

import './styles.scss';
import CheckoutStep from '../../components/Layout/CheckoutStep';
import CustomInput from '../../components/Commons/CustomInput/CustomInput';

import CheckoutActions from '../../redux/CheckoutRedux';

import walletIcon from '../../images/icons/wallet-icon.svg';
import rifAddressValidator from '../../utils/addressValidator/rifAddressValidator';

import { networkMap } from './walletAddress.constants';

const customStylesSelect = {
  control: base => ({
    ...base,
    height: 48,
    minHeight: 48
  })
};

const STABLEX_CRYPTOS = ['ARST', 'BRLT'];

const RefTooltip = ({ t, textDetail }) => {
  return (
    <span className="tooltip-container-2">
      <a data-tip={t(textDetail)} className="question-mark">
        ?
      </a>
      <ReactTooltip effect="float" place="top" className="custom-tooptil" />
    </span>
  );
};

const { Option, ValueContainer } = components;

const IconOption = props => {
  return (
    <Option {...props}>
      <img src={props.data.icon} className="icon-option" />
      {props.data.label}
    </Option>
  );
};

const IconContainer = props => {
  const val = props.getValue()[0];
  return (
    <ValueContainer {...props}>
      {val && (
        <>
          <img src={val.icon} className="icon-option" />
          {val.label}
        </>
      )}
    </ValueContainer>
  );
};

const addressWalletList = [
  {
    wallet_name: 'vibrant',
    wallet_display_name: 'Vibrant',
    wallet_federated_suffix: process.env.REACT_APP_VIBRANT_SUFFIX,
    icon: require('../../images/icons/icon-vibrant.png')
  },
  {
    wallet_name: 'lobstr',
    wallet_display_name: 'Lobstr',
    wallet_federated_suffix: process.env.REACT_APP_LOBSTR_SUFFIX,
    icon: require('../../images/icons/icon-lobstr.png')
  }
];

const WalletAddressComp = ({
  t,
  checkout,
  cryptoCurrencies,
  history,
  getCurrencies,
  saveWalletAddress,
  loading,
  error
}) => {
  const [addressWallet, setAddressWallet] = useState(checkout.destinationWalletAddress || '');
  const [addressFederation, setAddressFederation] = useState('');
  const [memo, setMemo] = useState(checkout.destinationWalletAddressTag || '');
  const [validity, setValidity] = useState({
    address: false,
    memo: false
  });
  const [showMemoTag, setShowMemoTag] = useState(false);
  const [touched, setTouched] = useState({
    addressWallet: false,
    addressFederation: false,
    memo: false
  });
  const [typeWallet, setTypeWallet] = useState(1);
  const [walletSelected, setWalletSelected] = useState({
    label: addressWalletList[0].wallet_display_name,
    value: addressWalletList[0].wallet_federated_suffix,
    icon: addressWalletList[0].icon
  });

  const [memoRequired, setMemoRequired] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  const isAddressFieldAvailable = (consumer, asset, value) => {
    if (asset && value) {
      return !STABLEX_CRYPTOS.includes(asset);
    }

    return ['latamex', 'tsadc', 'stablex', 'bitcoin-mx'].includes(consumer);
  };

  const isBNBValidAddress = address => {
    const regexp = new RegExp('^(bnb)([a-z0-9]{39})$'); // bnb + 39 a-z0-9
    return regexp.exec(address) !== null;
  };

  const validateAddress = (_crypto, address) => {
    if (STABLEX_CRYPTOS.includes(_crypto)) {
      return !!address.length;
    }
    // temp testing
    if (process.env.REACT_APP_OVERRIDE_BTC_WALLET_VALIDATION === 'true' && _crypto === 'BTC') {
      return true;
    }

    const customValidators = {
      BNB: isBNBValidAddress,
      ONT: walletAddressValidator.validate,
      EOS: walletAddressValidator.validate,
      RIF: rifAddressValidator.validate,
      USDC: () => {
        return multiCoinValidator.validate(address, 'ETH');
      },
      USDT: () => {
        return multiCoinValidator.validate(address, 'ETH');
      }
    };

    return customValidators[_crypto]
      ? customValidators[_crypto](address, _crypto)
      : multiCoinValidator.validate(address, _crypto);
  };

  const onNext = () => {
    let customAddress = typeWallet === 1 ? addressWallet : addressFederation + walletSelected.value;
    saveWalletAddress(customAddress, memo, checkout.destinationAsset, history);
  };

  useEffect(() => {
    getCurrencies();
    sessionStorage.removeItem('checkVerificationCodeMode');
  }, []);

  useEffect(() => {
    if (cryptoCurrencies) {
      const selectedCrypto = cryptoCurrencies.filter(cc => cc.crypto === checkout.destinationAsset)[0];
      setShowMemoTag(!!selectedCrypto.hasMemoTag);
    }
  }, [cryptoCurrencies]);

  useEffect(() => {
    if (cryptoCurrencies) {
      setValidity(
        typeWallet === 1
          ? {
              walletName: true,
              address: validateAddress(checkout.destinationAsset, addressWallet),
              memo: showMemoTag ? memoRequired || memo !== '' : true
            }
          : {
              walletName: walletSelected.value,
              address: addressFederation,
              memo: true
            }
      );
    }
  }, [typeWallet, addressWallet, addressFederation, memoRequired, memo, walletSelected, cryptoCurrencies]);

  useEffect(() => {
    setValidity({
      address: !error
    });
  }, [error]);

  if (loading) {
    return (
      <div className="loader-container d-flex justify-content-center align-items-center">
        <PropagateLoader sizeUnit="px" size={10} color="#448aff" />
      </div>
    );
  }

  console.log('showMemoTag', showMemoTag);
  console.log('typeWallet', typeWallet);
  console.log('validity.walletName', validity.walletName);
  console.log('validity.address', validity.address);
  console.log('validity.memo', validity.memo);

  return (
    <CheckoutStep
      classNameContainer="margin-container-bottom-12"
      hideGoBack
      next={onNext}
      disabled={!validity.walletName || !validity.address || !validity.memo}
      buttonContent={t('welcome.startBtn')}
    >
      <Row>
        <Col md={{ size: 12, offset: 0 }}>
          <Row className="mb-4">
            <Col className="d-flex flex-column align-items-center">
              <span className="title">{t(showMemoTag ? 'walletAddressDestination.title' : 'walletAddress.title')}</span>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={{ size: 8, offset: 2 }} className="d-flex flex-column align-items-center">
              <img src={walletIcon} width="90" height="93" />
            </Col>
          </Row>
          <Row>
            <Col md={{ size: 12, offset: 0 }} className="d-flex flex-column align-items-center">
              <p
                className="description mb-2 mt-2 text-height-20"
                style={{ margin: '0px 10%', textAlign: 'left', lineHeight: '25px !important' }}
              >
                {showMemoTag && (
                  <p className="description mb-2 mt-2 text-height-20" style={{ maxWidth: '512px' }}>
                    {t('walletAddressFederation)', {
                      cryptoAsset: checkout.destinationAsset
                    })}
                  </p>
                )}

                {!showMemoTag && (
                  <>
                    <span>
                      {t('walletAddress.labelPart1', {
                        cryptoAsset: checkout.destinationAsset
                      })}
                    </span>
                    <span id="network" style={{ borderBottom: '2px solid black', borderColor: '#dadce0' }}>
                      {t('walletAddress.labelPart2')}
                    </span>
                    <Tooltip placement="top" isOpen={tooltipOpen} target="network" toggle={toggle}>
                      {t('walletAddress.tooltipText')}
                    </Tooltip>
                    <span>
                      <Trans i18nKey={'walletAddress.labelPart3'}>
                        <strong>{{ network: networkMap[checkout.destinationAsset] }}</strong>
                      </Trans>
                    </span>
                  </>
                )}
              </p>
            </Col>
          </Row>

          <Row className="mb-4 wallet-container-input">
            {showMemoTag && (
              <>
                <Col md="6" className="mt-4 d-flex flex-column align-items-center">
                  <label className="mb-0">
                    <input
                      type="radio"
                      value="1"
                      onClick={({ target: { value } }) => setTypeWallet(parseInt(value))}
                      defaultChecked
                      name="radio-group"
                      className="radio-input"
                    />
                    <span className={`radio-description ${typeWallet === 1 && `selected`}`}>
                      {t('walletAddress.addressLabel')}
                    </span>
                  </label>
                </Col>
                <Col md="6" className=" mt-4 d-flex flex-column align-items-center">
                  <label className="mb-0">
                    <input
                      type="radio"
                      value="2"
                      onClick={({ target: { value } }) => setTypeWallet(parseInt(value))}
                      name="radio-group"
                      className="radio-input"
                    />
                    <span className={`radio-description ${typeWallet === 2 && `selected`}`}>
                      {t('walletAddressFederation.title')}
                    </span>
                  </label>
                </Col>
              </>
            )}
            {typeWallet === 2 && (
              <Col md="12">
                <Row className="mt-4 mb-2">
                  <Col md="10">
                    <span>{t('walletAddressFederation.title')}</span>
                  </Col>
                  <Col className="al-right">
                    <RefTooltip t={t} textDetail="walletAddressFederation.description" />
                  </Col>
                </Row>
              </Col>
            )}
            <Col md="12" className="mb-1">
              {typeWallet === 1 ? (
                <>
                  <CustomInput
                    onChange={({ target }) => setAddressWallet(target.value)}
                    outline
                    type="text"
                    value={addressWallet}
                    label={!isAddressFieldAvailable(checkout.consumer) ? '' : t('walletAddress.addressLabel')}
                    onBlur={() => setTouched({ ...touched, addressWallet: true })}
                    disabled={!isAddressFieldAvailable(checkout.consumer)}
                    width="350px"
                  />
                  <div style={{ height: 25, textAlign: 'left' }} className="mt-neg-12">
                    <small className="error">
                      {touched.addressWallet && !validity.address && t('checkoutForm.checkWalletAddress')}
                    </small>
                  </div>
                </>
              ) : (
                <Select
                  defaultValue={walletSelected}
                  onChange={target => setWalletSelected(target)}
                  options={addressWalletList.map(({ wallet_display_name, wallet_federated_suffix, icon }) => {
                    return {
                      label: wallet_display_name,
                      value: wallet_federated_suffix,
                      icon: icon
                    };
                  })}
                  components={{ Option: IconOption, ValueContainer: IconContainer }}
                  styles={customStylesSelect}
                />
              )}
            </Col>
            {showMemoTag && (
              <Col md="12">
                {typeWallet === 1 ? (
                  <>
                    <Row className="mb-1">
                      <Col md="12" className="mt-neg-14">
                        <CustomInput
                          onChange={({ target }) => setMemo(target.value)}
                          outline
                          type="text"
                          value={memo}
                          label={!isAddressFieldAvailable(checkout.consumer) ? '' : t('walletAddress.memoLabel')}
                          onBlur={() => setTouched({ ...touched, memo: true })}
                          disabled={memoRequired}
                          width="161px"
                        />
                        <div style={{ position: 'absolute', top: '30px', right: '30px' }}>
                          <RefTooltip t={t} textDetail="walletAddress.memoTooltip" />
                        </div>
                      </Col>
                    </Row>
                    <div style={{ height: 25, textAlign: 'left' }} className="mt-neg-16">
                      <small className="error mt-neg-20">
                        {!memoRequired && touched.memo && !validity.memo && t('checkoutForm.mustEnterValue')}
                      </small>
                    </div>

                    <Row className="mt-1">
                      <Col md="12">
                        <label className="check-detail">
                          <Check
                            type="checkbox"
                            id="MemoOptional"
                            checked={memoRequired}
                            className="mt-12"
                            onChange={() => {
                              setMemoRequired(!memoRequired);
                            }}
                          />
                          <span className="terms-cond">{t('walletAddress.memoOptional')}</span>
                        </label>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <Row>
                    <Col md="7">
                      <CustomInput
                        onChange={({ target }) => setAddressFederation(target.value)}
                        outline
                        type="text"
                        value={addressFederation}
                        label={!isAddressFieldAvailable(checkout.consumer) ? '' : t('walletAddressFederation.title')}
                        onBlur={() => setTouched({ ...touched, addressFederation: true })}
                        disabled={!isAddressFieldAvailable(checkout.consumer)}
                      />
                    </Col>
                    <Col className="custom-col-detail">
                      <span>{walletSelected && walletSelected.value}</span>
                    </Col>
                    <Col md="12">
                      <div style={{ height: 25 }} className="mt-neg-20 mb-3">
                        <small className="error">
                          {touched.addressFederation &&
                            !validity.address &&
                            t('checkoutForm.checkWalletAddressFederation')}
                        </small>
                      </div>
                    </Col>
                  </Row>
                )}
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </CheckoutStep>
  );
};

const WalletAddress = ({ t, checkout, cryptoCurrencies, history, getCurrencies, saveWalletAddress, error }) => {
  return (
    <>
      {(!checkout || !checkout.countryCode) && <Redirect to="/" />}
      {checkout && checkout.orderType === 'cash-in' && <Redirect to="/payment-method" />}
      <WalletAddressComp {...{ t, checkout, cryptoCurrencies, history, getCurrencies, saveWalletAddress, error }} />
    </>
  );
};

const mapStateToProps = state => {
  return {
    cryptoCurrencies: state.checkout.cryptoCurrencies,
    loading: state.checkout.requesting,
    checkout: state.user.user || state.user,
    error: state.checkout.error
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getCurrencies: () => dispatch(CheckoutActions.getCurrencies()),
    saveWalletAddress: (address, memo, asset, history) =>
      dispatch(CheckoutActions.saveWalletAddressRequest(address, memo, asset, history))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(WalletAddress));
