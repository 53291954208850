export default [
  {
    value: 332,
    label: 'Acesso Soluções de Pagamento S.A.'
  },
  {
    value: 117,
    label: 'Advanced Corretora de Câmbio LTDA'
  },
  {
    value: 272,
    label: 'AGK Corretora de Cambio S.A.'
  },
  {
    value: 349,
    label: 'Amaggi S.A. - Crédito, Financiamiento e Investimento'
  },
  {
    value: 188,
    label: 'Ativa Investimentos S.A. Corretora de Títulos, Câmbio e Valores'
  },
  {
    value: 280,
    label: 'Avista S.A. Crédito, Financiamento e Investimento'
  },
  {
    value: 80,
    label: 'B&T Corretora de Cambio LTDA.'
  },
  {
    value: 654,
    label: 'Banco A.J.Renner S.A.'
  },
  {
    value: 246,
    label: 'Banco ABC Brasil S.A.'
  },
  {
    value: 75,
    label: 'Banco ABN AMRO S.A.'
  },
  {
    value: 121,
    label: 'Banco Agilabel S.A.'
  },
  {
    value: 25,
    label: 'Banco Alfa S.A.'
  },
  {
    value: 65,
    label: 'Banco Andlabel (Brasil) S.A.'
  },
  {
    value: 213,
    label: 'Banco Arbi S.A.'
  },
  {
    value: 96,
    label: 'Banco B3 S.A.'
  },
  {
    value: 24,
    label: 'Banco BANDEPE S.A.'
  },
  {
    value: 330,
    label: 'Banco Bari de Investimentos e Financiamentos S/A'
  },
  {
    value: 318,
    label: 'Banco BMG S.A.'
  },
  {
    value: 752,
    label: 'Banco BNP Paribas Brasil S.A.'
  },
  {
    value: 107,
    label: 'Banco BOCOM BBM S.A.'
  },
  {
    value: 63,
    label: 'Banco Bradescard S.A.'
  },
  {
    value: 36,
    label: 'Banco Bradesco BBI S.A.'
  },
  {
    value: 122,
    label: 'Banco Bradesco BERJ S.A.'
  },
  {
    value: 394,
    label: 'Banco Bradesco Financiamentos S.A.'
  },
  {
    value: 237,
    label: 'Banco Bradesco S.A.'
  },
  {
    value: 218,
    label: 'Banco BS2 S.A.'
  },
  {
    value: 208,
    label: 'Banco BTG Pactual S.A.'
  },
  {
    value: 336,
    label: 'Banco C6 S.A.'
  },
  {
    value: 473,
    label: 'Banco Caixa Geral - Brasil S.A.'
  },
  {
    value: 412,
    label: 'Banco Capital S.A.'
  },
  {
    value: 40,
    label: 'Banco Cargill S.A.'
  },
  {
    value: 739,
    label: 'Banco Cetelem S.A.'
  },
  {
    value: 233,
    label: 'Banco Cifra S.A.'
  },
  {
    value: 745,
    label: 'Banco Citilabel S.A.'
  },
  {
    value: 241,
    label: 'Banco Clássico S.A.'
  },
  {
    value: 756,
    label: 'Banco Cooperativo do Brasil S.A. - Bancoob'
  },
  {
    value: 748,
    label: 'Banco Cooperativo Sicredi S.A.'
  },
  {
    value: 222,
    label: 'Banco Credit Agricole Brasil S.A.'
  },
  {
    value: 505,
    label: 'Banco Credit Suisse (Brasil) S.A.'
  },
  {
    value: 69,
    label: 'Banco Crefisa S.A.'
  },
  {
    value: 266,
    label: 'Banco Cédula S.A.'
  },
  {
    value: 3,
    label: 'Banco da Amazônia S.A.'
  },
  {
    value: 83,
    label: 'Banco da China Brasil S.A.'
  },
  {
    value: 707,
    label: 'Banco Daycoval S.A.'
  },
  {
    value: 300,
    label: 'Banco de La Nacion Argentina'
  },
  {
    value: 495,
    label: 'Banco de La Provincia de Buenos Aires'
  },
  {
    value: 335,
    label: 'Banco Digio S.A.'
  },
  {
    value: 1,
    label: 'Banco do Brasil S.A.'
  },
  {
    value: 47,
    label: 'Banco do Estado de Sergipe S.A.'
  },
  {
    value: 37,
    label: 'Banco do Estado do Pará S.A.'
  },
  {
    value: 41,
    label: 'Banco do Estado do Rio Grande do Sul S.A.'
  },
  {
    value: 4,
    label: 'Banco do Nordeste do Brasil S.A.'
  },
  {
    value: 265,
    label: 'Banco Fator S.A.'
  },
  {
    value: 224,
    label: 'Banco Fibra S.A.'
  },
  {
    value: 626,
    label: 'Banco Ficsa S.A.'
  },
  {
    value: 94,
    label: 'Banco Finaxis S.A.'
  },
  {
    value: 612,
    label: 'Banco Guanabara S.A.'
  },
  {
    value: 12,
    label: 'Banco Inbursa S.A.'
  },
  {
    value: 604,
    label: 'Banco Industrial do Brasil S.A.'
  },
  {
    value: 653,
    label: 'Banco Indusval S.A.'
  },
  {
    value: 77,
    label: 'Banco Inter S.A.'
  },
  {
    value: 249,
    label: 'Banco Investcred Unibanco S.A.'
  },
  {
    value: 479,
    label: 'Banco Itaulabel S.A'
  },
  {
    value: 184,
    label: 'Banco Itaú BBA S.A.'
  },
  {
    value: 29,
    label: 'Banco Itaú Consignado S.A.'
  },
  {
    value: 376,
    label: 'Banco J. P. Morgan S.A.'
  },
  {
    value: 74,
    label: 'Banco J. Safra S.A.'
  },
  {
    value: 217,
    label: 'Banco John Deere S.A.'
  },
  {
    value: 76,
    label: 'Banco KDB S.A.'
  },
  {
    value: 757,
    label: 'Banco Keb Hana do Brasil S.A.'
  },
  {
    value: 600,
    label: 'Banco Luso Brasileiro S.A.'
  },
  {
    value: 389,
    label: 'Banco Mercantil do Brasil S.A.'
  },
  {
    value: 370,
    label: 'Banco Mizuho do Brasil S.A.'
  },
  {
    value: 746,
    label: 'Banco Modal S.A.'
  },
  {
    value: 66,
    label: 'Banco Morgan Stanley S.A.'
  },
  {
    value: 456,
    label: 'Banco MUFG Brasil S.A.'
  },
  {
    value: 243,
    label: 'Banco Máxima S.A.'
  },
  {
    value: 7,
    label: 'Banco Nacional de Desenvolvimento Econômico e Social - BNDES'
  },
  {
    value: 169,
    label: 'Banco Olé Bonsucesso Consignado S.A.'
  },
  {
    value: 79,
    label: 'Banco Original do Agronegócio S.A.'
  },
  {
    value: 212,
    label: 'Banco Original S.A.'
  },
  {
    value: 712,
    label: 'Banco Ourinvest S.A.'
  },
  {
    value: 623,
    label: 'Banco PAN S.A.'
  },
  {
    value: 611,
    label: 'Banco Paulista S.A.'
  },
  {
    value: 643,
    label: 'Banco Pine S.A.'
  },
  {
    value: 747,
    label: 'Banco Rabolabel International Brasil S.A.'
  },
  {
    value: 633,
    label: 'Banco Rendimento S.A.'
  },
  {
    value: 741,
    label: 'Banco Ribeirão Preto S.A.'
  },
  {
    value: 120,
    label: 'Banco Rodobens S.A.'
  },
  {
    value: 422,
    label: 'Banco Safra S.A.'
  },
  {
    value: 33,
    label: 'Banco Santander (Brasil) S.A.'
  },
  {
    value: 743,
    label: 'Banco Semear S.A.'
  },
  {
    value: 754,
    label: 'Banco Sistema S.A.'
  },
  {
    value: 630,
    label: 'Banco Smartlabel S.A.'
  },
  {
    value: 366,
    label: 'Banco Société Générale Brasil S.A.'
  },
  {
    value: 637,
    label: 'Banco Sofisa S.A.'
  },
  {
    value: 464,
    label: 'Banco Sumitomo Mitsui Brasileiro S.A.'
  },
  {
    value: 82,
    label: 'Banco Topázio S.A.'
  },
  {
    value: 18,
    label: 'Banco Tricury S.A.'
  },
  {
    value: 634,
    label: 'Banco Triângulo S.A.'
  },
  {
    value: 655,
    label: 'Banco Votorantim S.A.'
  },
  {
    value: 610,
    label: 'Banco VR S.A.'
  },
  {
    value: 119,
    label: 'Banco Western Union do Brasil S.A.'
  },
  {
    value: 124,
    label: 'Banco Woori label do Brasil S.A.'
  },
  {
    value: 348,
    label: 'Banco XP S.A.'
  },
  {
    value: 81,
    label: 'BancoSeguro S.A.'
  },
  {
    value: 21,
    label: 'Banestes S.A. Banco do Estado do Espírito Santo'
  },
  {
    value: 755,
    label: 'label of America Merrill Lynch Banco Múltiplo S.A.'
  },
  {
    value: 268,
    label: 'Bari Companhia Hipotecária'
  },
  {
    value: 250,
    label: 'BCV - Banco de Crédito e Varejo S.A.'
  },
  {
    value: 144,
    label: 'BEXS Banco de Câmbio S.A.'
  },
  {
    value: 253,
    label: 'Bexs Corretora de Câmbio S/A'
  },
  {
    value: 134,
    label: 'BGC Liquidez Distribuidora de Títulos e Valores Mobiliários LTDA'
  },
  {
    value: 17,
    label: 'BNY Mellon Banco S.A.'
  },
  {
    value: 301,
    label: 'BPP Instituição de Pagamento S.A.'
  },
  {
    value: 126,
    label: 'BR Partners Banco de Investimento S.A.'
  },
  {
    value: 70,
    label: 'BRB - Banco de Brasília S.A.'
  },
  {
    value: 92,
    label: 'Brickell S.A. Crédito'
  },
  {
    value: 173,
    label: 'BRL Trust Distribuidora de Títulos e Valores Mobiliários S.A.'
  },
  {
    value: 142,
    label: 'Broker Brasil Corretora de Câmbio Ltda.'
  },
  {
    value: 292,
    label: 'BS2 Distribuidora de Títulos e Valores Mobiliários S.A.'
  },
  {
    value: 104,
    label: 'Caixa Econômica Federal'
  },
  {
    value: 309,
    label: 'Cambionet Corretora de Câmbio LTDA.'
  },
  {
    value: 288,
    label: 'Carol Distribuidora de Titulos e Valores Mobiliarios LTDA.'
  },
  {
    value: 130,
    label: 'Caruana S.A. - Sociedade de Crédito, Financiamento e Investimento'
  },
  {
    value: 159,
    label: 'Casa do Crédito S.A. Sociedade de Crédito ao Microempreendedor'
  },
  {
    value: 114,
    label: 'Central Cooperativa de Crédito no Estado do Espírito Santo - CECOOP'
  },
  {
    value: 91,
    label: 'Central de Cooperativas de Economia e Crédito Mûtuo do Estado do Rio Grande do S'
  },
  {
    value: 320,
    label: 'China Construction label (Brasil) Banco Múltiplo S.A.'
  },
  {
    value: 477,
    label: 'Citilabel N.A.'
  },
  {
    value: 180,
    label: 'CM Capital Markets Corretora de Câmbio, Títulos e Valores Mpbiliários LTDA'
  },
  {
    value: 127,
    label: 'Valuepe Corretora de Valores e Câmbio S.A.'
  },
  {
    value: 163,
    label: 'Commerzlabel Brasil S.A. - Banco Múltiplo'
  },
  {
    value: 133,
    label: 'Confederacâo Nacional Das Cooperativas Centrais De Crédito e Economia Familiar E'
  },
  {
    value: 136,
    label: 'Confederacâo Nacional Das Cooperativas Centrais Unicred LTDA. - Unicred do Brasil'
  },
  {
    value: 60,
    label: 'Confidence Corretora de Câmbio S.A.'
  },
  {
    value: 85,
    label: 'Cooperativa Central de Crédito - AILOS'
  },
  {
    value: 97,
    label: 'Cooperativa Central de Crédito Noroeste Brasileiro Ltda.'
  },
  {
    value: 279,
    label: 'Cooperativa de Credito Rural de Primavera do Leste'
  },
  {
    value: 16,
    label: 'Cooperativa de Credito Mútuo dos Despachantes de Trânsito de Santa Catarina e RI'
  },
  {
    value: 281,
    label: 'Cooperativa de Crédito Rural Coopavel'
  },
  {
    value: 322,
    label: 'Cooperativa de Crédito Rural de Abelardo Luz - Sulcredi/Crediluz'
  },
  {
    value: 286,
    label: 'Cooperativa DE Crédito Rural de Ouro Sulcredi/OURO'
  },
  {
    value: 273,
    label: 'Cooperativa de Crédito Rural de São Miguel do Oeste - Sulcredi/São Miguel'
  },
  {
    value: 98,
    label: 'Credialiança Cooperativa de Crédito Rural'
  },
  {
    value: 10,
    label: 'Credicoamo Credito Rural Cooperativa'
  },
  {
    value: 89,
    label: 'Credisan Cooperativa de Crédito'
  },
  {
    value: 11,
    label: 'Credit Suisse Hedging-Griffo Corretora de Valores S.A'
  },
  {
    value: 342,
    label: 'Creditas Sociedade de Crédito Direto S.A.'
  },
  {
    value: 321,
    label: 'Crefaz Sociedade De Crédito ao Microempeendedor e a Empresa de Pequeno Porte LT'
  },
  {
    value: 289,
    label: 'Decyseo Corretora de Cambio LTDA.'
  },
  {
    value: 487,
    label: 'Deutsche label S.A. - Banco Alemão'
  },
  {
    value: 140,
    label: 'Easynvest - Título Corretora de Valores SA'
  },
  {
    value: 149,
    label: 'Facta Financeira S.A. - Crédito Financiamento e Investimento'
  },
  {
    value: 196,
    label: 'Fair Corretora de Cambio S.A.'
  },
  {
    value: 343,
    label: 'FFA Sociedade de Crédito ao Microempreendedor e a Empresa de Pequeno Porte LTDA.'
  },
  {
    value: 331,
    label: 'Fram Capital Distribuidora de Títulos e Valores Mobiliários S.A.'
  },
  {
    value: 285,
    label: 'Frente Corretora de Câmbio Ltda.'
  },
  {
    value: 278,
    label: 'Genial Investimentos Corretora de Valores Mobiliários S.A.'
  },
  {
    value: 364,
    label: 'Gerencianet Pagamanetos de Brasil LTDA'
  },
  {
    value: 138,
    label: 'Get Money Corretora de Câmbio S.A.'
  },
  {
    value: 64,
    label: 'Goldman Sachs do Brasil Banco Múltiplo S.A.'
  },
  {
    value: 177,
    label: 'Guide Investimentos S.A. Corretora de Valores'
  },
  {
    value: 146,
    label: 'Guitta Corretora de Cambio LTDA.'
  },
  {
    value: 78,
    label: 'Haitong Banco de Investimento do Brasil S.A.'
  },
  {
    value: 62,
    label: 'Hipercard Banco Múltiplo S.A.'
  },
  {
    value: 189,
    label: 'HS Financeira S/A Credito, Financiamento e Investimentos'
  },
  {
    value: 269,
    label: 'HSBC Brasil S.A. - Banco de Investimento'
  },
  {
    value: 271,
    label: 'IB Corretora de Câmbio, Títulos e Valores Mobiliários S.A.'
  },
  {
    value: 157,
    label: 'ICAP do Brasil Corretora de Títulos e Valores Mobiliários Ltda.'
  },
  {
    value: 132,
    label: 'ICBC do Brasil Banco Múltiplo S.A.'
  },
  {
    value: 492,
    label: 'ING label N.V.'
  },
  {
    value: 139,
    label: 'Intesa Sanpaolo Brasil S.A. - Banco Múltiplo'
  },
  {
    value: 652,
    label: 'Itaú Unibanco Holding S.A.'
  },
  {
    value: 341,
    label: 'Itaú Unibanco S.A.'
  },
  {
    value: 488,
    label: 'JPMorgan Chase label'
  },
  {
    value: 399,
    label: 'Kirton label S.A. - Banco Múltiplo'
  },
  {
    value: 293,
    label: 'Lastro RDV Distribuidora de Títulos e Valores Mobiliários Ltda.'
  },
  {
    value: 105,
    label: 'Lecca Crédito, Financiamento e Investimento S/A'
  },
  {
    value: 145,
    label: 'Levycam - Corretora de Cambio e Valores LTDA.'
  },
  {
    value: 113,
    label: 'Magliano S.A. Corretora de Cambio e Valores Mobiliarios'
  },
  {
    value: 323,
    label: 'Mercadopago.com Representacoes LTDA.'
  },
  {
    value: 274,
    label: 'Money Plus Sociedade de Crédito ao Microempreendedor e a Empresa de Pequeno Port'
  },
  {
    value: 259,
    label: 'Moneycorp Banco de Câmbio S.A.'
  },
  {
    value: 128,
    label: 'MS label S.A. Banco de Câmbio'
  },
  {
    value: 354,
    label: 'Necton Investimentos S.A. Corretora de Valores Mobiliarios e Commodities'
  },
  {
    value: 191,
    label: 'Nova Futura Corretora de Títulos e Valores Mobiliários Ltda.'
  },
  {
    value: 753,
    label: 'Novo Banco Continental S.A. - Banco Múltiplo'
  },
  {
    value: 260,
    label: 'Nu Pagamentos S.A.'
  },
  {
    value: 111,
    label: 'Oliveira Trust Distribuidora de Títulos e Valores Mobiliarios S.A.'
  },
  {
    value: 319,
    label: 'OM Distribuidora de Títulos e Valores Mobiliarios LTDA'
  },
  {
    value: 613,
    label: 'Omni Banco S.A.'
  },
  {
    value: 290,
    label: 'Pagseguro Internet S.A.'
  },
  {
    value: 254,
    label: 'Paraná Banco S.A.'
  },
  {
    value: 326,
    label: 'Parati - Credito, Financiamento e Investimento S.A.'
  },
  {
    value: 194,
    label: 'Parmetal Distribuidora de Títulos e Valores Mobiliarios LTDA'
  },
  {
    value: 174,
    label: 'Pernambucanas Financiadora S.A. - Crédito, Financiamento e Investimento'
  },
  {
    value: 315,
    label: 'PI Distribuidora de Títulos e Valores Mobiliários S.A.'
  },
  {
    value: 100,
    label: 'Planner Corretora de Valores S.A.'
  },
  {
    value: 125,
    label: 'Plural S.A. - Banco Múltiplo'
  },
  {
    value: 108,
    label: 'Portocred S.A. - Credito, Financiamiento e Investimento'
  },
  {
    value: 306,
    label: 'Portopar Distribuidora de Titulos e Valores Mobiliarios LTDA.'
  },
  {
    value: 93,
    label: 'Pólocred Sociedade de Crédito ao Microempreendedor e a Empresa de Pequeno Port'
  },
  {
    value: 329,
    label: 'QI Sociedade de Crédito Direto S.A.'
  },
  {
    value: 283,
    label: 'RB Capital Investimentos Distribuidora de Títulos e Valores Mobiliarios Limitada'
  },
  {
    value: 101,
    label: 'Renascenca Distribuidora de Títulos e Valores Mobiliarios LTDA'
  },
  {
    value: 270,
    label: 'Sagitur Corretora de Câmbio Ltda.'
  },
  {
    value: 751,
    label: 'Scotialabel Brasil S.A. Banco Múltiplo'
  },
  {
    value: 276,
    label: 'Senff S.A. - Crédito, Financiamento e Investimento'
  },
  {
    value: 545,
    label: 'Senso Corretora de Cambio e Valores Mobiliarios S.A'
  },
  {
    value: 190,
    label: 'Servicoop - Cooperativa de Crédito dos Servidores Públicos Estaduais do Rio Gran'
  },
  {
    value: 183,
    label: 'Socred S.A. - Sociedade de Crédito ao Microempreendedor e a Empresa de Pequeno P'
  },
  {
    value: 365,
    label: 'Solidus S.A. Corretora de Cambio e Valores Mobiliarios'
  },
  {
    value: 299,
    label: 'Sorocred Crédito, Financiamiento e Investimento S.A.'
  },
  {
    value: 14,
    label: 'State Street Brasil S.A. - Banco Comercial'
  },
  {
    value: 197,
    label: 'Stone Pagamentos S.A.'
  },
  {
    value: 340,
    label: 'Super Pagamentos e Administração de Meios Eletrônicos S.A.'
  },
  {
    value: 307,
    label: 'Terra Investimentos Distribuidora de Títulos e Valores Mobiliários Ltda.'
  },
  {
    value: 352,
    label: 'Toro Corretora de Títulos e Valores Mobiliarios LTDA'
  },
  {
    value: 95,
    label: 'Travelex Banco de Câmbio S.A.'
  },
  {
    value: 143,
    label: 'Treviso Corretora de Câmbio S.A.'
  },
  {
    value: 131,
    label: 'Tullet Prebon Brasil Corretora de Valores e Câmbio LTDA'
  },
  {
    value: 129,
    label: 'UBS Brasil Banco de Investimento S.A.'
  },
  {
    value: 15,
    label: 'UBS Brasil Corretora de Câmbio, Títulos e Valores Mobiliários S.A.'
  },
  {
    value: 99,
    label: 'Uniprime Central - Central Interestadual de CooperativaS de Credito LTDA.'
  },
  {
    value: 84,
    label: 'Uniprime Norte do Paraná - Coop de Economia e Crédito Mútuo dos Médicos'
  },
  {
    value: 373,
    label: 'UP.P Sociedade de Empréstimo entre Pessoas S.A.'
  },
  {
    value: 298,
    label: "Vip's Corretora de Câmbio Ltda."
  },
  {
    value: 296,
    label: 'Vision S.A. Corretora de Cambio'
  },
  {
    value: 367,
    label: 'Vitreo Distribuidora de Títulos e Valores Mobiliarios S.A.'
  },
  {
    value: 310,
    label: 'Vortx Distribuidora de Títulos e Valores Mobiliarios LTDA.'
  },
  {
    value: 102,
    label: 'XP Investimento Corretora de Câmbio, Titulos e Valores Mobiliarios S/A'
  },
  {
    value: 325,
    label: 'Órama Distribuidora de Títulos e Valores Mobiliários S.A.'
  },
  {
    value: 355,
    label: 'Ótimo Sociedade de Crédito Direto S.A.'
  }
];
