import { combineReducers } from 'redux';
import configureStore from './CreateStore';
import rootSaga from '../sagas';
import { reducer as depositReducer } from './DepositRedux';
import { reducer as withdrawReducer } from './WithdrawRedux';
import { reducer as authReducer } from './AuthRedux';
import { reducer as userReducer } from './UserRedux';
import { reducer as fastcashReducer } from './FastcashRedux';
import { reducer as checkoutReducer } from './CheckoutRedux';

export default () => {
  /* ------------- Assemble The Reducers ------------- */
  const appReducer = combineReducers({
    deposit: depositReducer,
    withdraw: withdrawReducer,
    auth: authReducer,
    user: userReducer,
    fastcash: fastcashReducer,
    checkout: checkoutReducer
  });

  const rootReducer = (state, action) => {
    return appReducer(state, action);
  };

  return configureStore(rootReducer, rootSaga);
};
