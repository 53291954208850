// @Vendors
import { put, call, select } from 'redux-saga/effects';
import DepositActions from '../redux/DepositRedux';

import HeapBridge from '../utils/heap';

export const getToken = state => state.auth.token;
export const getDepositIntentId = state => (state.auth.depositIntent ? state.auth.depositIntent._id : undefined);

export function* createDeposit(api, action) {
  const token = yield select(getToken);
  const depositIntentId = yield select(getDepositIntentId);
  const { data } = action;
  const response = yield call(api.createDeposit, token, {
    ...data,
    depositIntentId,
    origin: 'anchor',
    originId: 'zars:sep'
  });

  if (response.ok) {
    yield put(DepositActions.createDepositSuccess(response.data.data));
  } else {
    yield put(DepositActions.createDepositFailure(response));
  }
}

export function* getDeposits(api, action) {
  const { filter } = action;
  const token = yield select(getToken);
  const response = yield call(api.getDeposits, token, { filter });

  if (response.ok) {
    yield put(DepositActions.getDepositsSuccess(response.data.data, filter));
  } else {
    yield put(DepositActions.getDepositsFailure(response));
  }
}

export function* getDeposit(api, action) {
  const { id } = action;
  const token = yield select(getToken);
  const response = yield call(api.getDeposit, token, id);

  if (response.ok) {
    yield put(DepositActions.getDepositSuccess(response.data.data));
  } else {
    yield put(DepositActions.getDepositFailure(response));
  }
}

export function* updateDeposit(api, action) {
  const { id, data } = action;
  const token = yield select(getToken);
  const response = yield call(api.updateDeposit, token, id, data);

  if (response.ok) {
    yield put(DepositActions.updateDepositSuccess(response.data.data));
  } else {
    yield put(DepositActions.updateDepositFailure(response));
  }
}

export function* getBanks(api) {
  const token = yield select(getToken);
  const response = yield call(api.getBanks, token);

  if (response.ok) {
    yield put(DepositActions.getAvailableBanksSuccess(response.data.data));
  } else {
    yield put(DepositActions.getAvailableBanksFailure(response));
  }
}

export function* generateDepositTicket(api, action) {
  const { data } = action;
  const token = yield select(getToken);
  const response = yield call(api.createDeposit, token, data);

  if (response.ok) {
    HeapBridge.track({ event: 'Order Created', type: 'Deposit' });
    yield put(DepositActions.generateDepositTicketSuccess(response.data.data));
  } else {
    yield put(DepositActions.generateDepositTicketFailure(response));
  }
}

export function* getCheckoutStatus(api, action) {
  const { data } = action;
  const response = yield call(api.getCheckoutStatus, data);

  if (response.ok) {
    yield put(DepositActions.getCheckoutStatusSuccess(response.data));
  } else {
    yield put(DepositActions.getCheckoutStatusFailure(response));
  }
}

export function* getQuote(api, { fromAsset, toAsset, _type, pid }) {
  const response = yield call(api.getQuote, fromAsset, toAsset, _type, pid);

  if (response.ok) {
    yield put(DepositActions.getQuoteSuccess(response.data));
  } else {
    yield put(DepositActions.getQuoteFailure(response));
  }
}

export function* getLimits(api, action) {
  const { data } = action;
  const response = yield call(api.getLimits, data);

  if (response.ok) {
    yield put(DepositActions.getLimitsSuccess(response.data));
  } else {
    yield put(DepositActions.getLimitsFailure(response));
  }
}

export function* sendLimitEmail(api, { data: { history } }) {
  const token = yield select(getToken);
  const response = yield call(api.sendLimitEmail, token, {});

  if (response.ok) {
    yield put(DepositActions.sendLimitEmailSuccess());
    history.push('/limit-enforcement/doc-upload-success');
  } else {
    yield put(DepositActions.sendLimitEmailFailure(response));
  }
}

export function* getAvailablePaymentMethods(api) {
  const token = yield select(getToken);
  const response = yield call(api.getAvailablePaymentMethods, token);

  if (response.ok) {
    yield put(DepositActions.getAvailablePaymentMethodsSuccess(response.data));
  } else {
    yield put(DepositActions.getAvailablePaymentMethodsFailure(response));
  }
}
