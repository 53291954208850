/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { withTranslation, Trans } from 'react-i18next';
import queryString from 'query-string';
import { useSelector } from 'react-redux';

import i18n, { getLastLangFromLocalStorage } from '../../i18n';
import CheckoutStep from '../../components/Layout/CheckoutStep';
import CustomDropdown from '../../components/Commons/CustomDropdown';

import errorIcon from '../../images/icons/general-error.svg';
import withdrawExistingErrorIcon from '../../images/icons/withdraw-existing-error.svg';
import genericErrorIcon from '../../images/icons/errorConfirmIcon.png';
import languagesIcon from '../../images/icons/languages.png';

import { GetSupportUrl } from '../../utils/consumerConfig';

import './styles.scss';

const ErrorTemplate = ({
  supportUrl,
  type,
  callbackUrl,
  errorCode,
  title,
  depositImage,
  withdrawImage,
  description,
  retrn,
  showContactInfo,
  handleLanguageOnChange,
  defaultSelected,
  fixedWidth,
  contactText
}) => (
  <CheckoutStep hideGoBack hideNextButton classNameContainer="pl-5 pb-3">
    <Row style={{ marginLeft: '30px' }}>
      <Col md={{ size: 10, offset: 0 }}>
        <Row>
          <Col className="d-flex flex-column align-items-center">
            <span className="error-title">{title}</span>
            <span className="mt-3 error-code">Error {errorCode}</span>
            {type === 'deposit' && (
              <img
                className={`${fixedWidth ? '' : 'img-lg'} mt-3 mb-4`}
                width={fixedWidth ? '237' : ''}
                src={depositImage}
                alt="error"
              />
            )}
            {type === 'withdraw' && (
              <img
                className={`${fixedWidth ? '' : 'img-lg'} mt-3 mb-4`}
                width={fixedWidth ? '237' : ''}
                src={withdrawImage}
                alt="error"
              />
            )}
            <p className="error-description mb-4">{description}</p>
            <p className="mt-4 error-link" onClick={() => (window.parent.location.href = callbackUrl)}>
              {retrn}
            </p>
            {showContactInfo && (
              <a className="mt-2 mb-4 error-link" href={supportUrl} target="_blank" rel="noopener noreferrer">
                {contactText}
              </a>
            )}
          </Col>
        </Row>
      </Col>
      <Col md={{ size: 2, offset: 0 }}>
        <Row className="justify-content-center">
          <img width="20" height="20" src={languagesIcon} alt="languages" className="languages-icon" />
          <CustomDropdown
            size="sm"
            defaultSelected={defaultSelected || 'Es'}
            options={['Es', 'Pt', 'En']}
            toggleClassname="toggle"
            handleItemOnChange={handleLanguageOnChange}
          />
        </Row>
      </Col>
    </Row>
  </CheckoutStep>
);

const ErrorDepositsExisting = ({
  t,
  supportUrl,
  type = 'deposit',
  callbackUrl,
  handleLanguageOnChange,
  defaultSelected
}) => (
  <ErrorTemplate
    supportUrl={supportUrl}
    type={type}
    callbackUrl={callbackUrl}
    depositImage={withdrawExistingErrorIcon}
    withdrawImage={withdrawExistingErrorIcon}
    title={t(`errorPage.DepositsExisting.title.${type}`)}
    errorCode="005"
    description={
      // eslint-disable-next-line react/jsx-wrap-multilines
      <Trans i18nKey={`errorPage.DepositsExisting.description.${type}`}>
        <a href={supportUrl} target="_blank" rel="noopener noreferrer" />
      </Trans>
    }
    retrn={t('errorPage.return')}
    handleLanguageOnChange={handleLanguageOnChange}
    defaultSelected={defaultSelected}
    fixedWidth={false}
    contactText={t('errorPage.DepositsExisting.contact')}
  />
);

const ErrorCookies = ({ t, supportUrl, type = 'deposit', callbackUrl, handleLanguageOnChange, defaultSelected }) => (
  <ErrorTemplate
    supportUrl={supportUrl}
    type={type}
    callbackUrl={callbackUrl}
    depositImage={genericErrorIcon}
    withdrawImage={genericErrorIcon}
    title={t('errorPage.Cookies.title')}
    errorCode="004"
    description={t('errorPage.Cookies.description')}
    showContactInfo
    retrn={t('errorPage.return')}
    handleLanguageOnChange={handleLanguageOnChange}
    defaultSelected={defaultSelected}
    fixedWidth
    contactText={t('errorPage.DepositsExisting.contact')}
  />
);

const ErrorSessionConflict = ({
  t,
  supportUrl,
  type = 'deposit',
  callbackUrl,
  handleLanguageOnChange,
  defaultSelected
}) => (
  <ErrorTemplate
    supportUrl={supportUrl}
    type={type}
    callbackUrl={callbackUrl}
    depositImage={genericErrorIcon}
    withdrawImage={genericErrorIcon}
    title={t('errorPage.SessionConflict.title')}
    errorCode="003"
    description={t('errorPage.SessionConflict.description')}
    showContactInfo
    retrn={t('errorPage.return')}
    handleLanguageOnChange={handleLanguageOnChange}
    defaultSelected={defaultSelected}
    fixedWidth
    contactText={t('errorPage.DepositsExisting.contact')}
  />
);

const ErrorWrongAsset = ({
  t,
  type = 'deposit',
  callbackUrl,
  asset,
  country,
  handleLanguageOnChange,
  defaultSelected
}) => {
  const completeCountryName = t(`country.${country}`);
  const description = (
    <Trans i18nKey="errorPage.WrongAsset.description">
      <strong>
        {{ 'fiat-asset': asset }} {{ 'user-country': completeCountryName }}
      </strong>
    </Trans>
  );

  return (
    <ErrorTemplate
      type={type}
      callbackUrl={callbackUrl}
      depositImage={genericErrorIcon}
      withdrawImage={genericErrorIcon}
      title={t('errorPage.WrongAsset.title')}
      errorCode="002"
      description={description}
      showContactInfo={false}
      handleLanguageOnChange={handleLanguageOnChange}
      defaultSelected={defaultSelected}
      retrn={t('errorPage.return')}
      fixedWidth
    />
  );
};

const ErrorAmountTooLow = ({ t, type = 'deposit', callbackUrl, asset, min, handleLanguageOnChange }) => {
  const description = (
    <Trans i18nKey="errorPage.AmountTooLow.description">
      <strong>
        {{ 'fiat-asset': asset }} {{ 'fiat-minimum': min }}
      </strong>
    </Trans>
  );

  return (
    <ErrorTemplate
      type={type}
      callbackUrl={callbackUrl}
      depositImage={genericErrorIcon}
      withdrawImage={genericErrorIcon}
      title={t('errorPage.AmountTooLow.title')}
      errorCode="001"
      description={description}
      showContactInfo={false}
      handleLanguageOnChange={handleLanguageOnChange}
      retrn={t('errorPage.return')}
      fixedWidth
    />
  );
};

const ErrorDefault = ({ t, match, supportUrl }) => {
  const lastLangSaved = getLastLangFromLocalStorage();
  let flagChangeLanguajeOnce = false;
  if (!flagChangeLanguajeOnce && lastLangSaved) {
    flagChangeLanguajeOnce = true;
    i18n.changeLanguage(lastLangSaved);
  }

  return (
    <CheckoutStep hideGoBack hideNextButton>
      <Row>
        <Col md={{ size: 12, offset: 0 }}>
          <Row className="mb-5">
            <Col className="d-flex flex-column align-items-center">
              <span className="title">{t('errorPage.title')}</span>
              <img className="img-lg mt-4 mb-4" src={errorIcon} alt="error" />
              <p className="description mb-4">
                {t('errorPage.somethingWrong')}. <br />
                <Trans i18nKey="errorPage.retryOrContact">
                  <a href={supportUrl} target="_blank" rel="noopener noreferrer" />
                </Trans>
              </p>
              <p className="description">Error code: {match.params.description}</p>
            </Col>
          </Row>
        </Col>
      </Row>
    </CheckoutStep>
  );
};

const errorMaps = {
  '00001': ErrorAmountTooLow,
  '00002': ErrorWrongAsset,
  '00003': ErrorSessionConflict,
  '00004': ErrorCookies,
  '00005': ErrorDepositsExisting
};

const ErrorPage = ({ t, match, location }) => {
  const callbackUrl = useSelector(state => state.user.user && state.user.user.callbackUrl) || 'https://www.latamex.com';
  const { lang, type, asset, min, country } = queryString.parse(location.search.replace('?', ''));
  const [language, setLanguage] = useState();

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  const handleLanguageOnChange = language => {
    setLanguage(language.toLowerCase());
  };

  const supportLang = language; // The zendesk page is only distinguishing between es and pt for now.
  const supportUrl = GetSupportUrl(supportLang);

  const Comp = errorMaps[match.params.description] || ErrorDefault;
  return (
    <Comp
      {...{ t, match, supportUrl, type, callbackUrl, asset, min, country }}
      handleLanguageOnChange={handleLanguageOnChange}
      defaultSelected={lang}
    />
  );
};

export default withTranslation()(ErrorPage);
