/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Col, Row, CustomInput as Check } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

import CheckoutStep from '../../components/Layout/CheckoutStep';

const warnings = {
  'bank-transfer': {
    AR: [
      {
        title: 'Cuenta a tu nombre',
        desc: 'La transferencia debe realizarse únicamente desde una cuenta bancaria a tu nombre.'
      },
      {
        title: 'Incluir Nº de Referencia',
        desc:
          'Deberás incluir el número de referencia (provisto en la siguiente pantalla) en el campo de concepto/referencia al momento de realizar la transferencia.'
      },
      { title: 'Importe Exacto', desc: 'El importe a enviar debe ser el importe exacto por el cual creaste la orden.' }
    ],
    BR: [
      {
        title: 'Conta bancária em seu nome',
        desc: 'A transferência deve ser feita apenas a partir de uma conta bancária em seu nome.'
      },
      {
        title: 'Credenciamento de Transferência',
        desc: `TED (recomendado): é creditado no mesmo dia se for realizado durante o horário bancário ou no próximo dia útil, se for após o horário comercial.
         <br>DOC: pode levar de 2 a 3 dias úteis, dependendo do seu banco e da hora em que é realizado.`
      },
      { title: 'Valor exato', desc: 'O valor a ser recebido deve ser o valor exato para o qual você criou o pedido.' }
    ],
    MX: [
      {
        title: 'Cuenta a tu nombre',
        desc: 'La transferencia debe realizarse únicamente desde una cuenta bancaria a tu nombre.'
      },
      {
        title: 'Incluir Nº de Referencia',
        desc:
          'Deberás incluir el número de referencia (provisto en la siguiente pantalla) en el campo de concepto/referencia al momento de realizar la transferencia.'
      },
      { title: 'Importe Exacto', desc: 'El importe a enviar debe ser el importe exacto por el cual creaste la orden.' }
    ]
  },
  'cash-network': {
    AR: [],
    BR: [],
    MX: []
  }
};

const WarningInfoComp = ({ t, user, checked, setChecked, redirectNext, paymentMethodSelected }) => (
  <CheckoutStep
    classNameContainer="margin-container-bottom-12"
    hideGoBack
    next={redirectNext}
    disabled={!checked}
    buttonContent={t('welcome.startBtn')}
  >
    <Row>
      <Col md={{ size: 12, offset: 0 }}>
        <Row className="mb-4">
          <Col className="d-flex flex-column align-items-center">
            <span className="title">{t('warningInfo.title')}</span>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col md={{ size: 8, offset: 2 }} className="d-flex flex-column align-items-center">
            <ul className="warningList">
              {user &&
                user.countryCode &&
                warnings[paymentMethodSelected.method][user.countryCode].map(({ title, desc }) => (
                  <li key={title}>
                    <b>{title}</b>
                    <p dangerouslySetInnerHTML={{ __html: desc }} />
                  </li>
                ))}
            </ul>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col md={{ size: 12, offset: 0 }} className="d-flex flex-column align-items-center">
            <p className="description mb-4">{t('warningInfo.disclaimer')}.</p>
            <Check
              className="warning-check"
              type="checkbox"
              id="TermsAndCond"
              label={t('warningInfo.checkText')}
              onChange={event => setChecked(event.target.checked)}
              defaultChecked={checked}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  </CheckoutStep>
);

const WarningInfo = ({ t, user, history, paymentMethodSelected }) => {
  const [checked, setChecked] = useState(false);
  const redirectNext = () =>
    history.push(
      user.type === 'Deposit'
        ? `/deposit/summary/${paymentMethodSelected.provider}/${paymentMethodSelected.method}`
        : '/withdraw/finish'
    );

  if (
    user.type === 'Withdraw' ||
    (user.countryCode && paymentMethodSelected && warnings[paymentMethodSelected.method][user.countryCode].length === 0)
  ) {
    redirectNext();
  }
  return (
    <>
      {(!user || !user.countryCode) && <Redirect to="/" />}

      <WarningInfoComp {...{ t, user, checked, setChecked, redirectNext, paymentMethodSelected }} />
    </>
  );
};

const mapStateToProps = state => {
  return {
    user: state.user.user || state.user,
    paymentMethodSelected: state.deposit.paymentMethodSelected || null
  };
};

export default connect(mapStateToProps, null)(withTranslation()(WarningInfo));
