// @Vendors
import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  setPaymentMethod: ['paymentMethod'],
  setTermsAndConditions: ['agreed'],
  setSubPaymentMethod: ['subPaymentMethod'],
  setTransferAgency: ['agency'],
  setTransferAccount: ['account'],
  fcCreateDepositRequest: ['data'],
  createDepositSuccess: ['deposit'],
  createDepositFailure: ['error'],
  fcValidateDepositRequest: ['data'],
  validateDepositSuccess: ['deposit'],
  validateDepositFailure: ['error'],
  fcGetSubPaymentMethodsRequest: ['data'],
  getSubPaymentMethodsSuccess: ['subPaymentMethods'],
  getSubPaymentMethodsFailure: ['error'],
  resetStoreRequest: ['items'],
  reset: []
});

export const FastcashTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  requesting: false,
  error: undefined,
  deposit: undefined,
  validation: undefined,
  paymentMethod: undefined,
  termsAndConditions: false,
  subPaymentMethod: undefined,
  subPaymentMethods: undefined,
  transferAgency: undefined,
  transferAccount: undefined
});

/* ------------- Reducers ------------- */

export const setPaymentMethod = (state, { paymentMethod }) => {
  return state.merge({
    ...state,
    paymentMethod
  });
};

export const setTermsAndConditions = (state, { agreed }) => {
  return state.merge({
    ...state,
    termsAndConditions: agreed
  });
};

export const setSubPaymentMethod = (state, { subPaymentMethod }) => {
  return state.merge({
    ...state,
    subPaymentMethod
  });
};

export const setTransferAgency = (state, { agency }) => {
  return state.merge({
    ...state,
    transferAgency: agency
  });
};

export const setTransferAccount = (state, { account }) => {
  return state.merge({
    ...state,
    transferAccount: account
  });
};

export const createDepositRequest = state => {
  return state.merge({
    requesting: true,
    error: ''
  });
};

export const createDepositSuccess = (state, { deposit }) => {
  return state.merge({
    requesting: false,
    deposit
  });
};

export const validateDepositRequest = state => {
  return state.merge({
    requesting: true,
    error: ''
  });
};

export const validateDepositSuccess = (state, { deposit }) => {
  return state.merge({
    requesting: false,
    validation: deposit
  });
};

export const getSubPaymentMethodsRequest = state => {
  return state.merge({
    requesting: true,
    error: ''
  });
};

export const getSubPaymentMethodsSuccess = (state, { subPaymentMethods }) => {
  return state.merge({
    requesting: false,
    subPaymentMethods
  });
};

export const failure = (state, { error }) => {
  return state.merge({
    requesting: false,
    error: `Something went wrong. ${error}`
  });
};

export const resetStoreRequest = (state, { items }) => {
  let itemsToReset = {};
  items.forEach(item => {
    const itemObj = { [item]: INITIAL_STATE[item] };
    itemsToReset = { ...itemsToReset, ...itemObj };
  });
  return state.merge(itemsToReset);
};

export const reset = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.RESET]: reset,
  [Types.SET_PAYMENT_METHOD]: setPaymentMethod,
  [Types.SET_TERMS_AND_CONDITIONS]: setTermsAndConditions,
  [Types.SET_SUB_PAYMENT_METHOD]: setSubPaymentMethod,
  [Types.SET_TRANSFER_AGENCY]: setTransferAgency,
  [Types.SET_TRANSFER_ACCOUNT]: setTransferAccount,
  [Types.FC_CREATE_DEPOSIT_REQUEST]: createDepositRequest,
  [Types.CREATE_DEPOSIT_SUCCESS]: createDepositSuccess,
  [Types.CREATE_DEPOSIT_FAILURE]: failure,
  [Types.FC_VALIDATE_DEPOSIT_REQUEST]: validateDepositRequest,
  [Types.VALIDATE_DEPOSIT_SUCCESS]: validateDepositSuccess,
  [Types.VALIDATE_DEPOSIT_FAILURE]: failure,
  [Types.FC_GET_SUB_PAYMENT_METHODS_REQUEST]: getSubPaymentMethodsRequest,
  [Types.GET_SUB_PAYMENT_METHODS_SUCCESS]: getSubPaymentMethodsSuccess,
  [Types.GET_SUB_PAYMENT_METHODS_FAILURE]: failure,
  [Types.RESET_STORE_REQUEST]: resetStoreRequest
});
