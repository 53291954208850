// @Vendors
import { put, call, select, all } from 'redux-saga/effects';

import CheckoutActions from '../redux/CheckoutRedux';
import HeapBridge from '../utils/heap';

export const getToken = state => state.auth.token;

const STABLEX_CRYPTOS = ['ARST', 'BRLT'];

export function* saveWalletAddress(api, { address, memo, asset, history }) {
  if (STABLEX_CRYPTOS.includes(asset)) {
    const validationResponse = yield call(api.validateStablexWallet, asset, address);

    if (!validationResponse.ok) {
      yield put(CheckoutActions.saveWalletAddressFailure(validationResponse.data.message));
      return;
    }
  }

  const token = yield select(getToken);
  const response = yield call(api.saveWalletAddress, token, address, memo);

  if (response.ok) {
    yield put(CheckoutActions.saveWalletAddressSuccess(response.data));
    history.push('/payment-method');
  } else {
    yield put(CheckoutActions.saveWalletAddressFailure(response));
  }
}

export function* getCurrencies(api) {
  const [fiatCurrencies, cryptoCurrencies] = yield all([call(api.getFiatCurrencies), call(api.getCryptoCurrencies)]);

  if (fiatCurrencies.ok && cryptoCurrencies.ok) {
    yield put(CheckoutActions.getCurrenciesSuccess(fiatCurrencies.data, cryptoCurrencies.data));
  } else {
    yield put(CheckoutActions.getCurrenciesFailure({ ...fiatCurrencies, ...cryptoCurrencies }));
  }
}

export function* getRate(api) {
  const token = yield select(getToken);
  const response = yield call(api.getRate, token);

  if (response.ok) {
    yield put(CheckoutActions.getRateSuccess(response.data));
  } else {
    yield put(CheckoutActions.getRateFailure(response));
  }
}

export function* getCheckoutConfirmInfo(api, { data }) {
  const token = yield select(getToken);
  const { id, type } = data;
  const response = yield call(api.getCheckoutConfirmInfo, token, id, type);
  if (response.ok) {
    if (response.data && response.data.status === 'OUT_OF_SERVICE') {
      window.location.href = response.data.redirectUrl;
      return;
    }

    yield put(CheckoutActions.getCheckoutConfirmInfoSuccess(response.data));
  } else {
    yield put(CheckoutActions.getCheckoutConfirmInfoFailure(response.data));
  }
}

export function* checkoutConfirm(api, { id, rate, checkoutType }) {
  const token = yield select(getToken);
  const response = yield call(api.checkoutConfirm, token, id, rate.quoteHash, checkoutType);

  if (response.ok) {
    HeapBridge.track({ event: 'Order Confirmed', checkoutType, id });
    yield put(CheckoutActions.checkoutConfirmSuccess(response.data));
  } else {
    yield put(CheckoutActions.checkoutConfirmFailure(response.data));
  }
}

export function* checkoutProvider(api, { countryCode }) {
  const token = yield select(getToken);
  const response = yield call(api.checkoutProvider, token, countryCode);

  if (response.ok) {
    yield put(CheckoutActions.checkoutProviderSuccess(response.data));
  } else {
    yield put(CheckoutActions.checkoutProviderFailure(response.data));
  }
}

export function* validateCheckout(api, action) {
  const token = yield select(getToken);
  const response = yield call(api.validateCheckout, token);

  if (!response.ok) {
    const { history } = action;
    const { code, lang, type, asset, country } = JSON.parse(response.data.message);
    switch (code) {
      case 5:
        if (type === 'withdraw') {
          history.replace(`/error/00005?lang=${lang}&type=withdraw`);
        } else {
          history.replace(`/error/00005?lang=${lang}`);
        }
        break;
      case 2:
        history.replace(`/error/00002?asset=${asset}&country=${country}&lang=${lang}`);
        break;
      default:
        break;
    }
  }

  if (action.cb) {
    action.cb();
  }

  yield put(CheckoutActions.validateCheckoutFinish());
}
