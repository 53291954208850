import React from 'react';
import { Container, CardBody, CardGroup, Card, Col, Row } from 'reactstrap';
import { useTheme } from 'emotion-theming';

const Layout = ({ children, enabled }) => {
  const theme = useTheme();

  if (!enabled) return <>{children}</>;

  return (
    <div className="layout kyc">
      <Container>
        <Row>
          <Col md={{ size: 10, offset: 1 }}>
            <Row className="logo-container-welcome">
              {theme.partnerLogoImg && (
                <Col md="6" className="d-flex align-items-center justify-content-start">
                  {theme.partnerLogoImg && <img height="56" src={theme.partnerLogoImg} alt="partnerLogo" />}
                </Col>
              )}
              <Col
                md={theme.partnerLogoImg ? 6 : 12}
                className={`d-flex align-items-center ${
                  theme.partnerLogoImg ? 'justify-content-end' : 'justify-content-center'
                }`}
              >
                {theme.mainLogoImg && <img width="155" height="56" src={theme.mainLogoImg} alt="latamexLogo" />}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={{ size: 10, offset: 1 }}>
            <CardGroup>
              <Card>
                <CardBody>{children}</CardBody>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Layout;
