// @Vendors
import React, { useState, useCallback, useEffect } from 'react';
import { Form } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { PropagateLoader } from 'react-spinners';
import 'reactjs-popup/dist/index.css';

import './styles.scss';
import CustomInput from '../Commons/CustomInput/CustomInput';
import CustomButton from '../Commons/CustomButton/CustomButton';
import PopupWhatIsSettleId from '../SettleIDPopup';
import accountEmailLogo from '../../images/account/account-email@2x.png';
import passwordLogo from '../../images/account/password@2x.png';
import AuthActions from '../../redux/AuthRedux';

const VALID_EMAIL_REG_EXP = /^[A-Z0-9._+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

function InsertEmail({
  handleSignUpOnClick,
  handleContinueAsAGuestOnClick,
  existingEmail,
  loading,
  error,
  callbackUrl,
  t,
  checkVerificationCodeMode,
  userAlreadyExisted,
  consumer,
  initialLoading,
  iframeUrl
}) {
  const [email, setEmail] = useState('');
  const [emailFormatError, setEmailFormatError] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [pageName, setPageName] = useState('');

  const history = useHistory();

  const dispatch = useDispatch();
  const resetCheckAuth = useCallback(() => dispatch(AuthActions.resetCheckAuthRequesting()), [dispatch]);
  const resetCheckVerificationCodeMode = useCallback(() => dispatch(AuthActions.resetCheckVerificationCodeMode()), [
    dispatch
  ]);
  const cleanError = useCallback(() => dispatch(AuthActions.cleanError()), [dispatch]);

  useEffect(() => {
    if (!email) {
      setEmail(existingEmail);
      setButtonDisabled(!existingEmail);
    }
  }, [setEmail, setButtonDisabled, existingEmail]);

  useEffect(() => {
    if (checkVerificationCodeMode === 'sign-up' || !checkVerificationCodeMode) {
      setPageName('insertEmail');
    } else if (checkVerificationCodeMode === 'password-recovery') {
      setPageName('passwordRecovery');
    }
  }, [setPageName, checkVerificationCodeMode]);

  const handleEmailOnchange = useCallback(
    event => {
      const mail = event.target.value ? event.target.value.toLowerCase() : event.target.value;
      setEmail(mail);
      cleanError();

      if (!mail.match(VALID_EMAIL_REG_EXP)) {
        if (!emailFormatError) {
          setEmailFormatError(t('account.insertEmail.invalidEmail'));
        }
        if (!buttonDisabled) {
          setButtonDisabled(true);
        }
      } else {
        if (emailFormatError) {
          setEmailFormatError('');
        }
        if (buttonDisabled) {
          setButtonDisabled(false);
        }
      }
    },
    [setEmail, emailFormatError, setEmailFormatError, buttonDisabled, setButtonDisabled, cleanError]
  );

  const handleSignUp = useCallback(() => handleSignUpOnClick(email), [handleSignUpOnClick, email]);

  const continueAsAGuest = useCallback(() => handleContinueAsAGuestOnClick(email), [
    handleContinueAsAGuestOnClick,
    email
  ]);

  const goToLogin = useCallback(() => {
    resetCheckAuth();
    resetCheckVerificationCodeMode();
    cleanError();
    sessionStorage.removeItem('checkVerificationCodeMode');
    history.push('/auth/login');
  }, [resetCheckAuth, resetCheckVerificationCodeMode, cleanError, history]);

  const notAllowedToChangeEmail = consumer === 'latamex' || consumer === 'stablex';
  const isRecoveryPage = pageName === 'passwordRecovery';
  const emailInputDisabled = !isRecoveryPage && ((userAlreadyExisted && !!existingEmail) || notAllowedToChangeEmail);
  return (
    <div className="d-flex flex-column align-items-center">
      {initialLoading ? (
        <div
          style={{ minHeight: '642px' }}
          className="d-flex justify-content-center align-items-center kyc-iframe-container"
        >
          <iframe title="tokenIframe" src={iframeUrl} width="0" height="0" />
          <PropagateLoader sizeUnit="px" size={10} color="#448aff" />
        </div>
      ) : (
        <>
          <h1 className="title sign-up-title mt-4">{t(`account.${pageName}.title`)}</h1>
          {pageName === 'insertEmail' && (
            <img className="mt-4" height="80" src={accountEmailLogo} alt="accountEmailLogo" />
          )}
          {pageName === 'passwordRecovery' && (
            <img className="mt-4" height="80" src={passwordLogo} alt="passwordLogo" />
          )}
          <p className="mb-3 subtitle">{t(`account.${pageName}.subtitle`)}</p>
          {pageName === 'insertEmail' && (
            // POPUP SETTLE ID
            <PopupWhatIsSettleId />
          )}
          <Form>
            <CustomInput
              outline
              className="account-input"
              onChange={handleEmailOnchange}
              label={emailInputDisabled ? '' : t('account.insertEmail.enterYourEmail')}
              value={email || ''}
              disabled={emailInputDisabled}
              messageType={(emailFormatError || error) && 'error'}
              message={emailFormatError || (error && t(`account.${pageName}.error`))}
              style={{ borderRadius: '4px' }}
            />
          </Form>
          <div className="d-flex flex-column align-items-center">
            <CustomButton loading={loading} disabled={buttonDisabled} onClick={handleSignUp} width="350px">
              {t('account.next')}
            </CustomButton>
          </div>
          <p className="mt-4 action-label big" onClick={goToLogin}>
            {t(`account.${pageName}.login`)}
          </p>
          <p className="mt-3 action-label big2" onClick={continueAsAGuest}>
            {t(`account.continueAsAGuest`)}
          </p>
          <p className="mt-3 action-label big2" onClick={() => (window.parent.location.href = callbackUrl)}>
            {t('account.cancel')}
          </p>
        </>
      )}
    </div>
  );
}

export default withTranslation()(InsertEmail);
