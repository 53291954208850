// @Vendors
import React, { useState, useEffect } from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

// @Styles
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'mdbreact/dist/css/mdb.css';
import { ThemeProvider } from 'emotion-theming';
import styled from '@emotion/styled';
import Helmet from 'react-helmet';

import './styles/custom.scss';

import UserActions from './redux/UserRedux';

import Layout from './pages/Layout/Layout';

import { mainTheme } from './constants/themes';

// @Pages
import {
  DepositBridge,
  DepositBR,
  DepositSummaryPage,
  StatusCheck,
  FcDepositMethod,
  FcDepositBankTransfer,
  FcDepositCash,
  FcDepositDetails,
  FcDepositFinish,
  FcDepositVerification,
  FcDepositVerificationSuccess,
  TriggerForm,
  SummaryPage,
  LimitEnforcement,
  LimitEnforcementDocs,
  LimitEnforcementCheckStatus,
  LimitDocUploadSuccess,
  PaymentMethod,
  WithdrawSummaryPage,
  Confirm,
  Error,
  WarningInfo,
  WalletAddress,
  Auth,
  CloseApp
} from './pages';

import Footer from './components/Commons/Footer';
import PrivateRoute from './components/Commons/PrivateRoute';
import LocalStorageService from './services/LocalStorageService';
import LogoutButton from './components/Commons/LogoutButton';

import { TicketNotCreated } from './utils/RouteValidator';

import './App.scss';
import i18n from './i18n';

import { canUseDOM, loadZendeskScript } from './utils/zendesk';

const MainContainer = styled.div`
  background: ${props => props.theme.backgroundColor};
`;

const App = ({ consumerConfig, setBrokerCheckout, isBrokerCheckout }) => {
  const embedded = window.location.href.includes('embedded');

  const [theme] = useState(mainTheme);

  const [adjustedTheme, setAdjustedTheme] = useState({});
  const [zendeskLoaded, setZendeskLoaded] = useState(false);

  useEffect(() => {
    if (!consumerConfig || !consumerConfig.zendeskKey) {
      return;
    }
    if (adjustedTheme.zendeskWidgetColor && canUseDOM && !window.zE && !zendeskLoaded) {
      window.zESettings = {
        webWidget: {
          color: { theme: adjustedTheme.zendeskWidgetColor }
        }
      };
      loadZendeskScript(consumerConfig.zendeskKey);
      setZendeskLoaded(true);
    }
    if (window.zE) {
      window.zE('webWidget', 'updateSettings', {
        webWidget: {
          color: {
            theme: Object.keys(adjustedTheme).length > 0 ? adjustedTheme.zendeskWidgetColor : theme.zendeskWidgetColor
          }
        }
      });
    }
  }, [canUseDOM, adjustedTheme, window.zE, consumerConfig]);

  useEffect(() => {
    if (consumerConfig && consumerConfig.styles) {
      localStorage.setItem('adjustedTheme', JSON.stringify(consumerConfig));
      setAdjustedTheme({
        ...consumerConfig.styles,
        mainLogoImg: consumerConfig.mainLogoImg,
        partnerLogoImg: consumerConfig.partnerLogoImg
      });

      i18n.options.interpolation.defaultVariables = {
        companyName: consumerConfig.companyName,
        branding: consumerConfig.branding
      };
    }
  }, [consumerConfig]);

  useEffect(() => {
    const storedConsumerConfig = JSON.parse(localStorage.getItem('adjustedTheme'));
    if (storedConsumerConfig) {
      setAdjustedTheme({
        ...storedConsumerConfig.styles,
        mainLogoImg: storedConsumerConfig.mainLogoImg,
        partnerLogoImg: storedConsumerConfig.partnerLogoImg
      });

      i18n.options.interpolation.defaultVariables = {
        companyName: storedConsumerConfig.companyName
      };
    }
  }, []);

  const storedConsumerConfig = JSON.parse(localStorage.getItem('adjustedTheme'));

  const brandingName = consumerConfig
    ? consumerConfig.companyName
    : storedConsumerConfig
    ? storedConsumerConfig.companyName
    : '';

  return (
    <ThemeProvider theme={theme}>
      <ThemeProvider theme={adjustedTheme}>
        <Helmet>
          <title>{brandingName} Checkout</title>
          <link rel="icon" type="image/png" href={`favicon-${brandingName.toLowerCase()}.png`} sizes="16x16" />
        </Helmet>
        <MainContainer className={embedded ? 'transparent' : 'checkout-bck'}>
          <div className="logout-row">
            <LogoutButton isBrokerCheckout={isBrokerCheckout} />
          </div>
          <Layout enabled={!embedded}>
            <HashRouter>
              <Switch>
                <Route
                  path="/to"
                  name="redirect"
                  component={props => {
                    const isBrokerCheckout = props.history.location.search.includes('broker-checkout');
                    const data = JSON.parse(
                      atob(
                        props.history.location.search.replace(`${isBrokerCheckout ? '?broker-checkout' : ''}?data=`, '')
                      )
                    );

                    LocalStorageService.setToken(data.token);
                    setBrokerCheckout(isBrokerCheckout);
                    return <Redirect to="/" />;
                  }}
                />
                <Route exact path="/checkout-form/:country?" name="Checkout Form" component={TriggerForm} />
                <Route exact path="/auth/:type" name="Auth Page" component={Auth} />
                <Route exact path="/error/:description?" name="Error Page" component={Error} />
                <Route path="/confirm/:id/:type?" name="Checkout Confirm" component={Confirm} />
                <Route path="/status/check/:orderNumber?/:lang?" name="Check Deposit Status" component={StatusCheck} />
                <Route path="/application/close" name="Close Checkout Webapp" component={CloseApp} />

                <PrivateRoute
                  exact
                  path="/limit-enforcement/"
                  name="Limit Enforcement Check"
                  component={LimitEnforcement}
                />
                <PrivateRoute
                  exact
                  path="/limit-enforcement/expand-limit"
                  name="Limit Enforcement Add Documentation"
                  component={LimitEnforcementDocs}
                />
                <PrivateRoute
                  exact
                  path="/limit-enforcement/doc-upload-success"
                  name="Doc Upload Success"
                  component={LimitDocUploadSuccess}
                />
                <PrivateRoute
                  exact
                  path="/limit-enforcement/check-status"
                  name="Check Limit StatusCheck"
                  component={LimitEnforcementCheckStatus}
                />
                <PrivateRoute path="/br/deposit" name="Deposit Method" component={DepositBR} />
                <PrivateRoute path="/br/deposit-method" name="Deposit Method" component={FcDepositMethod} />
                <PrivateRoute
                  path="/br/deposit-bank-transfer"
                  name="Deposit Bank Transfer"
                  component={FcDepositBankTransfer}
                />
                <PrivateRoute path="/br/deposit-cash" name="Deposit Cash" component={FcDepositCash} />
                <PrivateRoute path="/br/deposit-details" name="Deposit Details" component={FcDepositDetails} />
                <PrivateRoute path="/br/deposit-finish" name="Deposit Finish" component={FcDepositFinish} />
                <PrivateRoute
                  path="/br/deposit-verification"
                  name="Deposit Verification"
                  component={FcDepositVerification}
                />
                <PrivateRoute
                  path="/br/deposit-verification-success"
                  name="Deposit Verification Success"
                  component={FcDepositVerificationSuccess}
                />

                {/* TICKET FLOW */}
                <PrivateRoute
                  exact
                  path="/wallet-address"
                  name="Wallet Address"
                  component={WalletAddress}
                  validators={[TicketNotCreated]}
                />
                <PrivateRoute
                  exact
                  path="/payment-method/"
                  name="Select Payment Method"
                  component={PaymentMethod}
                  validators={[TicketNotCreated]}
                />
                <PrivateRoute
                  exact
                  path="/general/summary"
                  name="Summary Checkout"
                  component={SummaryPage}
                  validators={[TicketNotCreated]}
                />
                <PrivateRoute
                  exact
                  path="/check-your-info"
                  name="Check Your Information"
                  component={WarningInfo}
                  validators={[TicketNotCreated]}
                />

                <PrivateRoute
                  path="/withdraw/:step"
                  name="Withdraw Summary"
                  component={WithdrawSummaryPage}
                  validators={[TicketNotCreated]}
                />

                <PrivateRoute
                  path="/deposit/summary/:provider/:paymentMethod"
                  name="Deposit Summary"
                  component={DepositSummaryPage}
                />

                <PrivateRoute path="/" name="Deposit" component={DepositBridge} />
              </Switch>
            </HashRouter>
          </Layout>
          {!embedded && <Footer />}
        </MainContainer>
      </ThemeProvider>
    </ThemeProvider>
  );
};

const mapStateToProps = state => {
  return {
    consumerConfig: state.auth.consumerConfig,
    isBrokerCheckout: state.user.isBrokerCheckout
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setBrokerCheckout: isBrokerCheckout => dispatch(UserActions.setBrokerCheckout(isBrokerCheckout))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
