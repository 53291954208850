// @Vendors
import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import _ from 'lodash';
import moment from 'moment';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  createWithdrawRequest: ['data'],
  createWithdrawSuccess: ['withdraw'],
  createWithdrawFailure: ['error'],

  getWithdrawsRequest: ['filter'],
  getWithdrawsSuccess: ['withdraws', 'filter'],
  getWithdrawsFailure: ['error'],

  getWithdrawRequest: ['id'],
  getWithdrawSuccess: ['withdraw'],
  getWithdrawFailure: ['error'],

  updateWithdrawRequest: ['id', 'data'],
  updateWithdrawSuccess: ['withdraw'],
  updateWithdrawFailure: ['error']
});

export const WithdrawTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  requesting: false,
  list: [],
  last: {},
  flagged: [],
  error: '',
  updateAction: ''
});

/* ------------- Reducers ------------- */

export const createRequest = state => {
  return state.merge({
    requesting: true,
    error: ''
  });
};

export const createSuccess = (state, { withdraw }) => {
  return state.merge({
    requesting: false,
    last: withdraw,
    list: [...state.list, withdraw]
  });
};

export const getWithdrawsRequest = state => {
  return state.merge({
    requesting: true,
    error: ''
  });
};

export const getWithdrawsSuccess = (state, { withdraws, filter }) => {
  const newState = {
    requesting: false
  };

  if (filter === 'flagged') {
    newState.flagged = _.orderBy(withdraws, withdraw => moment(withdraw.lastUpdateTimeStamp), ['desc']);
  } else {
    newState.list = _.orderBy(withdraws, withdraw => moment(withdraw.lastUpdateTimeStamp), ['desc']);
  }

  return state.merge(newState);
};

export const getWithdrawRequest = state => {
  return state.merge({
    requesting: true,
    error: ''
  });
};

export const getWithdrawSuccess = (state, { withdraw }) => {
  return state.merge({
    requesting: false,
    last: withdraw
  });
};

export const updateWithdrawRequest = state => {
  return state.merge({
    updating: true,
    error: ''
  });
};

export const updateWithdrawSuccess = (state, { withdraw }) => {
  const newList = [...state.list];
  const index = _.findIndex(newList, d => d._id === withdraw._id);
  newList[index] = withdraw;

  return state.merge({
    updating: false,
    list: newList,
    last: withdraw
  });
};

export const failure = (state, { error }) => {
  return state.merge({
    requesting: false,
    error: `Something went wrong. ${error}`
  });
};

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CREATE_WITHDRAW_REQUEST]: createRequest,
  [Types.CREATE_WITHDRAW_SUCCESS]: createSuccess,
  [Types.CREATE_WITHDRAW_FAILURE]: failure,

  [Types.GET_WITHDRAWS_REQUEST]: getWithdrawsRequest,
  [Types.GET_WITHDRAWS_SUCCESS]: getWithdrawsSuccess,
  [Types.GET_WITHDRAWS_FAILURE]: failure,

  [Types.GET_WITHDRAW_REQUEST]: getWithdrawRequest,
  [Types.GET_WITHDRAW_SUCCESS]: getWithdrawSuccess,
  [Types.GET_WITHDRAW_FAILURE]: failure,

  [Types.UPDATE_WITHDRAW_REQUEST]: updateWithdrawRequest,
  [Types.UPDATE_WITHDRAW_SUCCESS]: updateWithdrawSuccess,
  [Types.UPDATE_WITHDRAW_FAILURE]: failure
});
