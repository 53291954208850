// @Vendors
import React, { Component } from 'react';
import { Input } from 'mdbreact';
import classnames from 'classnames';

// @Styles
import './CustomInputStyles.scss';

export default class CustomInput extends Component {
  render() {
    const { message, messageType, reference, disabled, className, style = {}, ...props } = this.props;
    const typeClass = `custom-input--${messageType}`;
    const messageEl = message && <span className="custom-input__message">{message}</span>;

    return (
      <div className={classnames('custom-input', { [typeClass]: !!messageType })}>
        <Input
          style={disabled ? { ...style, backgroundColor: '#eaecef' } : style}
          className={disabled ? ` ${className} input-disabled` : className}
          disabled={disabled}
          {...props}
          ref={reference}
        />
        {messageEl}
      </div>
    );
  }
}
