/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import CurrencyFormat from 'react-currency-format';
import { withTranslation, Trans } from 'react-i18next';
import ReactTooltip from 'react-tooltip';

import CheckoutStep from '../Layout/CheckoutStep';
import stepIcon from '../../images/fastcash/order-icon@2x.png';
import transferBankIcon from '../../images/deposit/bank-icon.png';
import RapiPagoIcon from '../../images/deposit/money-icon.png';

import decimals from '../../constants/decimals';

import { getSpecificProviderDisclaimers } from '../../utils/consumerConfig';

import './index.scss';

const TooltipAmount = ({
  methodName: name,
  transactionLimitMax,
  transactionLimitMin,
  checkoutAmount,
  feeAmount,
  currency,
  t
}) => {
  const majorMinor =
    transactionLimitMin && checkoutAmount + feeAmount > transactionLimitMin
      ? t('paymentMethod.notEnabled.major')
      : t('paymentMethod.notEnabled.minor');

  const amount =
    transactionLimitMin && checkoutAmount + feeAmount > transactionLimitMin ? transactionLimitMin : transactionLimitMax;

  return (
    <>
      <a data-tip="disabled" data-for="explication" className="question-mark">
        ?
      </a>
      <ReactTooltip id="explication" effect="solid" place="bottom" multiline>
        <div className="tooltip-content payment-tooltip" onClick={e => e.stopPropagation()}>
          <Trans i18nKey="paymentMethod.notEnabled">
            {{ name }} <br />
            <br />
            {{ majorMinor }} {{ amount }} {{ currency }}
          </Trans>
        </div>
      </ReactTooltip>
    </>
  );
};

const formatCurrency = value => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
};

const TooltipLimit = ({ totalLimits, usedLimits }) => {
  const annualLimit = formatCurrency(totalLimits.anualLimit);
  const annualRemainingNumber = totalLimits.anualLimit - usedLimits.anual;
  const annualRemaining = formatCurrency(annualRemainingNumber);

  const dailyLimit = formatCurrency(totalLimits.dailyLimit);
  const dailyRemaining = formatCurrency(Math.min(annualRemainingNumber, totalLimits.dailyLimit - usedLimits.daily));

  return (
    <>
      <a data-tip="disabled" data-for="explication" className="question-mark">
        ?
      </a>
      <ReactTooltip id="explication" effect="solid" place="bottom" multiline>
        <div className="tooltip-content payment-tooltip" onClick={e => e.stopPropagation()}>
          <Trans i18nKey="paymentMethod.notEnabled.limit">
            <br />
            {{ annualRemaining }} {{ annualLimit }}
            <br />
            {{ dailyRemaining }} {{ dailyLimit }}
          </Trans>
        </div>
      </ReactTooltip>
    </>
  );
};

const RefTooltip = props => {
  if (!props.totalLimits) {
    return <TooltipAmount {...{ ...props }} />;
  }

  return <TooltipLimit {...{ ...props }} />;
};

const icons = {
  bankTransfer: transferBankIcon,
  Rapipago: RapiPagoIcon
};

const processFee = (amount, { fee, minFee, flatFee }, type = 'Deposit', asset = 'BTC') => {
  if (type === 'Deposit') {
    return Math.max(minFee, Number(((amount * fee) / 100).toFixed(2))) + flatFee;
  }

  return Number(((amount * fee) / 100).toFixed(decimals.crypto[asset] || 8));
};

const changeFee = (user, fee, setUser) => {
  const total = user.checkoutAmount + user.feeAmount;
  const feeAmount = processFee(total, fee, user.type, user.originAsset);
  const checkoutAmount = total - feeAmount;

  setUser({ ...user, feeAmount, checkoutAmount });
};

const PaymentMethodComponent = ({ history, user, paymentMethods, paymentMethod, setPaymentMethod, setUser, t }) => {
  const { checkoutAmount, feeAmount, destinationAsset, originAsset, countryCode, type } = user;
  const currency = type === 'Deposit' ? originAsset : destinationAsset;
  const providerDisclaimers = getSpecificProviderDisclaimers();

  const [termsAndConditions, setTermsAndConditions] = useState(null);
  const [nextStep, goToNexStep] = useState(false);

  useEffect(() => {
    if (paymentMethods && paymentMethods.length === 1) {
      setPaymentMethod(paymentMethods[0]);
      goToNexStep(true);
    }
  }, [paymentMethods]);

  useEffect(() => {
    if (paymentMethod && nextStep) {
      changeFee(user, paymentMethod.fee, setUser);
      history.push('/general/summary');
    }
  }, [paymentMethod, nextStep]);

  if (!paymentMethods || !paymentMethods.length || paymentMethods.length === 1) {
    return <div className="overlay-loading visible" />;
  }

  return (
    <div className="d-flex flex-column align-items-center">
      <CheckoutStep
        classNameContainer="margin-container-bottom-12"
        title={t('paymentMethod.title')}
        next={() => goToNexStep(true)}
        buttonContent={t('paymentMethod.nextButton')}
        hideGoBack
        disabled={!paymentMethod || !termsAndConditions}
      >
        <div className="fc-step payment-methods">
          <img className="img-title" src={stepIcon} width="63" height="80" />

          <p className="description total-to-pay">
            <b>
              {t('paymentMethod.description')}{' '}
              <CurrencyFormat
                value={checkoutAmount + feeAmount}
                displayType="text"
                thousandSeparator=","
                decimalSeparator="."
                fixedDecimalScale
                decimalScale={2}
                prefix={`${currency} `}
              />
            </b>
          </p>

          {paymentMethods.map(pm => {
            const selected = paymentMethod && paymentMethod.id === pm._id;
            return (
              <div
                key={pm._id}
                className={`${selected ? 'fc-option fc-option--active' : 'fc-option'} ${
                  !pm.enabled ? 'fc-disabled' : ''
                }`}
                onClick={() => pm.enabled && setPaymentMethod({ ...pm, id: pm._id, provider: pm.provider })}
              >
                <div className="row">
                  <div className="col-1 rad-button">
                    <input type="radio" checked={selected} onChange={() => {}} disabled={!pm.enabled} />
                    <span className="design" />
                  </div>

                  <div className="col-2 image">
                    <img
                      className={`fc-option__icon fc-option__left-space ${pm.methodName}`}
                      src={icons[pm.methodName]}
                    />
                  </div>

                  <div className={`text ${pm.enabled ? 'col-9' : 'col-7'}`}>
                    <div className="row">
                      <div className="col-12">
                        <b>
                          <span className="fc-option__text payment-method-name">
                            {t(`paymentMethod.${pm.methodName}`)}
                          </span>
                        </b>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 fee">
                        <small className="fc-option__text fee">
                          {t('welcome.processorCharge')} {`${currency} `}
                          {processFee(checkoutAmount + feeAmount, pm.fee)}
                          <br />
                          {pm.fee.flatFee > 0 ? `${t('welcome.flatFee')} ${currency} ${pm.fee.flatFee}` : ''}
                          {pm.fee.flatFee > 0 && pm.fee.fee > 0 ? ' + ' : ''}
                          {pm.fee.fee > 0 ? `${t('welcome.varFee')} ${pm.fee.fee}%` : ''}
                          {pm.fee.minFee > 0 ? ` (min ${currency} ${pm.fee.minFee + (pm.fee.flatFee || 0)})` : ''}{' '}
                        </small>
                      </div>
                    </div>
                  </div>

                  {!pm.enabled && (
                    <div className="col-1 tooltip-payment">
                      <RefTooltip {...{ checkoutAmount, feeAmount, ...pm, t }} />
                    </div>
                  )}
                </div>
              </div>
            );
          })}

          <p className="status-description">
            <Trans i18nKey="paymentMethod.footerLegend">
              <br />
            </Trans>
          </p>

          <label className="checkbox bounce">
            <input
              type="checkbox"
              onClick={() => setTermsAndConditions(!termsAndConditions)}
              checked={termsAndConditions}
            />
            <svg viewBox="0 0 21 21">
              <polyline points="5 10.75 8.5 14.25 16 6" />
            </svg>
            <span>
              {t('paymentMethod.termsAndConditionsCheck')}{' '}
              {providerDisclaimers && providerDisclaimers.termsAndCond && (
                <a href={`${providerDisclaimers.termsAndCond}/${countryCode.toLowerCase()}`} target="_blank">
                  {t('commons.footer.terms-and-conditions')}
                </a>
              )}{' '}
              {t('paymentMethod.termsAndConditionsCheckAnd')}{' '}
              {providerDisclaimers && providerDisclaimers.privacyPolicies && (
                <a href={`${providerDisclaimers.privacyPolicies}/${countryCode.toLowerCase()}`} target="_blank">
                  {t('commons.footer.privacy-policy')}
                </a>
              )}
            </span>
          </label>
        </div>
      </CheckoutStep>
    </div>
  );
};

export default withTranslation()(PaymentMethodComponent);
