import React, { useEffect } from 'react';

import classnames from 'classnames';
import { withTranslation } from 'react-i18next';
import CheckoutStep from '../../Layout/CheckoutStep';
import stepIcon from '../../../images/fastcash/bank-icon.png';

const FcDepositBankTransferComponent = ({
  error,
  user: { checkoutAmount },
  kyc: { nationalId },
  history,
  deposit,
  requesting,
  paymentMethod,
  subPaymentMethod,
  subPaymentMethods,
  transferAgency,
  transferAccount,
  setSubPaymentMethod,
  setTransferAgency,
  setTransferAccount,
  fcCreateDepositRequest,
  fcGetSubPaymentMethodsRequest,
  resetStoreRequest,
  t
}) => {
  useEffect(() => {
    fcGetSubPaymentMethodsRequest({ paymentMethod });
    return () => {
      resetStoreRequest(['subPaymentMethod']);
    };
  }, []);

  useEffect(() => {
    if (deposit) {
      history.push('/br/deposit-details');
    }
  });

  const handleOnNextButtonClicked = () => {
    fcCreateDepositRequest({
      method: 'bank-transfer',
      subPaymentMethod,
      cashPaymentMethodId: 'fastcash',
      amount: checkoutAmount,
      assetName: 'BRL',
      bankAgency: transferAgency,
      bankAccountNumber: transferAccount,
      nationalId
    });
  };

  return (
    <div className="d-flex flex-column align-items-center">
      <CheckoutStep
        title={t('fastcash.fcDepositBankTransfer.title')}
        prev={history.goBack}
        next={handleOnNextButtonClicked}
        buttonContent={t('fastcash.fcDepositBankTransfer.nextButton')}
        disabled={requesting || !subPaymentMethod || !transferAgency || !transferAccount}
      >
        <div className="fc-step">
          <img src={stepIcon} />
          <p className="description">{t('fastcash.fcDepositBankTransfer.description')}</p>

          <select
            className={classnames('fc-input', { 'not-selected': !subPaymentMethod })}
            onChange={e => setSubPaymentMethod(e.target.value)}
            defaultValue={subPaymentMethod || ''}
          >
            <option disabled value="">
              {t('fastcash.fcDepositBankTransfer.bank.label')}
            </option>
            {subPaymentMethods &&
              subPaymentMethods.map((subpayment, index) => (
                <option value={subpayment.id} key={index}>
                  {subpayment.name}
                </option>
              ))}
          </select>

          <input
            className="fc-input"
            type="text"
            placeholder={t('fastcash.fcDepositBankTransfer.agency.label')}
            onChange={e => setTransferAgency(e.target.value)}
            value={transferAgency}
          />

          <input
            className="fc-input"
            type="text"
            placeholder={t('fastcash.fcDepositBankTransfer.account.label')}
            onChange={e => setTransferAccount(e.target.value)}
            value={transferAccount}
          />

          {error && <p className="status-description error">{t('fastcash.fcDepositBankTransfer.error')}</p>}
        </div>
      </CheckoutStep>
    </div>
  );
};

export default withTranslation()(FcDepositBankTransferComponent);
