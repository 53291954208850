/* eslint-disable */
import React from 'react';

import CurrencyFormat from 'react-currency-format';
import { withTranslation } from 'react-i18next';
import CheckoutStep from '../../Layout/CheckoutStep';
import stepIcon from '../../../images/fastcash/order-icon@2x.png';
import transferBankIcon from '../../../images/deposit/icon-bank.png';
import cashIcon from '../../../images/deposit/icon-cash.png';

const FcDepositMethodComponent = ({
  history,
  user: { checkoutAmount },
  paymentMethod,
  termsAndConditions,
  setPaymentMethod,
  setTermsAndConditions,
  t
}) => {
  return (
    <div className="d-flex flex-column align-items-center">
      <CheckoutStep
        title={t('fastcash.fcDepositMethod.title')}
        next={() =>
          paymentMethod === 1 ? history.push('/br/deposit-cash') : history.push('/br/deposit-bank-transfer')
        }
        buttonContent={t('fastcash.fcDepositMethod.nextButton')}
        hideGoBack
        disabled={!paymentMethod || !termsAndConditions}
      >
        <div className="fc-step">
          <img src={stepIcon} />

          <p className="description">
            {t('fastcash.fcDepositMethod.description1')}
            <b>
              <CurrencyFormat
                value={checkoutAmount}
                displayType={'text'}
                thousandSeparator={','}
                decimalSeparator={'.'}
                fixedDecimalScale
                decimalScale={2}
                prefix={'$ '}
              />
            </b>
            {t('fastcash.fcDepositMethod.description2')}
          </p>

          <label className={paymentMethod === 2 ? 'fc-option fc-option--active' : 'fc-option'}>
            <input type="radio" onClick={() => setPaymentMethod(2)} checked={paymentMethod === 2} />
            <img className="fc-option__icon" src={transferBankIcon} />
            <span className="fc-option__text">{t('fastcash.fcDepositMethod.bankTransfer')}</span>
          </label>

          <label className={paymentMethod === 1 ? 'fc-option fc-option--active' : 'fc-option'}>
            <input type="radio" onClick={() => setPaymentMethod(1)} checked={paymentMethod === 1} />
            <img className="fc-option__icon" src={cashIcon} />
            <span className="fc-option__text">{t('fastcash.fcDepositMethod.cash')}</span>
          </label>

          <label className="terms-and-conditions">
            <input
              className="terms-and-conditions__checkbox"
              type="checkbox"
              onClick={() => setTermsAndConditions(!termsAndConditions)}
              checked={termsAndConditions}
            />
            {t('fastcash.fcDepositMethod.termsAndConditionsCheck')}
          </label>

          <p className="status-description">{t('fastcash.fcDepositMethod.footerLegend')}</p>
        </div>
      </CheckoutStep>
    </div>
  );
};

export default withTranslation()(FcDepositMethodComponent);
