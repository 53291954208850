// @Vendors
import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  addAccountRequest: ['holderName', 'holderLastName', 'CBU'],
  addAccountSuccess: ['response'],
  addAccountFailure: ['error'],
  setUser: ['user'],
  setBrokerCheckout: ['isBrokerCheckout'],
  updateUser: ['props'],
  setKyc: ['kyc'],
  getKycVerificationUrlRequest: ['userData'],
  getKycVerificationUrlSuccess: ['response'],
  getKycVerificationUrlFailure: ['error'],
  limitEnforcementData: ['limitEnforcementCheck']
});

export const UserTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  error: null,
  user: null,
  isBrokerCheckout: false,
  kyc: null,
  limitEnforcementCheck: null,
  iframeUrl: null
});

/* ------------- Reducers ------------- */

export const setUser = (state, { user }) => {
  const userMerge = { ...state.user, ...user, email: user.consumerUserEmail };

  return state.merge({
    user: { ...userMerge }
  });
};

export const setBrokerCheckout = (state, { isBrokerCheckout }) => {
  return state.merge({
    isBrokerCheckout
  });
};

export const updateUser = (state, { props }) => {
  return state.merge({
    user: { ...state.user, ...props }
  });
};

export const setKyc = (state, { kyc }) => {
  return state.merge({
    kyc
  });
};

export const addAccountSuccess = (state, { response }) => {
  return state.merge({
    user: response.data
  });
};

export const addAccountFailure = (state, { error }) => {
  return state.merge({
    error: `Something went wrong. ${error}`
  });
};

export const getKycVerificationUrlSuccess = (state, { response }) => {
  return state.merge({
    iframeUrl: response.redirect
  });
};

export const getKycVerificationUrlFailure = (state, { error }) => {
  return state.merge({
    error: `Something went wrong. ${error}`
  });
};

export const limitEnforcementData = (state, { limitEnforcementCheck }) => {
  return state.merge({
    requesting: false,
    limitEnforcementCheck
  });
};

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_USER]: setUser,
  [Types.SET_BROKER_CHECKOUT]: setBrokerCheckout,
  [Types.UPDATE_USER]: updateUser,
  [Types.SET_KYC]: setKyc,

  [Types.ADD_ACCOUNT_SUCCESS]: addAccountSuccess,
  [Types.ADD_ACCOUNT_FAILURE]: addAccountFailure,

  [Types.GET_KYC_VERIFICATION_URL_SUCCESS]: getKycVerificationUrlSuccess,
  [Types.GET_KYC_VERIFICATION_URL_FAILURE]: getKycVerificationUrlFailure,

  [Types.LIMIT_ENFORCEMENT_DATA]: limitEnforcementData
});
