import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';

import Select from 'react-select';
import CheckoutStep from '../Layout/CheckoutStep';
import CustomInput from '../Commons/CustomInput/CustomInput';

// @Images
import IconBank from '../../images/deposit/icon-bank-1.png';
// @Data
import data from '../../data/index';

import CbuService from '../../services/CBU';
import clabeService from '../../services/clabe';
import { justDigits } from '../../utils/inputFilters';

import './index.scss';

const ArBankAccountInput = (kyc, country, t, handleOnClick, history) => {
  const [cbu, setCbu] = useState('');
  const cbuInfo = CbuService.isValidCBU(cbu);
  const error = cbu.trim() !== '' && !cbuInfo.valid && t('withdraw.wrongCBU');
  return (
    <CheckoutStep
      title={t('instructions.title.bank.bankTransfer')}
      next={() =>
        handleOnClick({
          accountHandleType: 'CBU',
          accountHandle: cbu.replace(/[^0-9]/g, ''),
          toBank: CbuService.getBankNameByCBU(cbu.replace(/[^0-9]/g, ''))
        })
      }
      prev={history.goBack}
      buttonContent={t('buttons.next')}
      hideGoBack
      disabled={!!error || cbu.trim() === ''}
      classNameContainer="margin-container-bottom-12"
    >
      <p className="description mt-1">
        <img className="bank" src={IconBank} width="64" height="64" />
      </p>

      <p className="description mt-4 mb-2 lh-15">
        Para poder realizar una transferencia necesitamos que nos indiques tu número de <b>CBU</b>.
        <br />
        Recordá por favor que es indispensable que la cuenta asociada al <b>CBU</b> se encuentre a tu nombre{' '}
        <b>
          {kyc.name} {kyc.lastname}
        </b>
        .
      </p>
      <div className="mb-5">
        <Row>
          <Col md={{ size: 8, offset: 2 }}>
            <CustomInput label={t('withdraw.cbu')} outline onChange={e => setCbu(e.target.value)} value={cbu} />
            {error && <small className="error">{error}</small>}
          </Col>
        </Row>

        {(cbuInfo.bankData || cbuInfo.bankName) && (
          <Row>
            <Col md={{ size: 8, offset: 2 }}>
              <Row className="transfer-detail folded" style={{ width: 'auto' }}>
                <Col md={{ size: 4 }}>
                  {cbuInfo.bankData ? <img src={cbuInfo.bankData.icon} /> : <img width="64" src={IconBank} />}
                </Col>
                <Col md={{ size: 8 }} className="my-auto">
                  <small>{cbuInfo.bankName.name}</small>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </div>
    </CheckoutStep>
  );
};

const MxBankAccountInput = (kyc, country, t, handleOnClick, history) => {
  const [clabe, setClabe] = useState('');
  const clabeInfo = clabeService.validate(clabe);
  const error = clabe.trim() !== '' && !clabeInfo.ok && t('withdraw.wrongClabe');
  return (
    <CheckoutStep
      title={t('instructions.title.bank.bankTransfer')}
      next={() =>
        handleOnClick({
          accountHandleType: 'CLABE',
          accountHandle: clabe.replace(/[^0-9]/g, ''),
          toBank: clabeInfo.bank
        })
      }
      prev={history.goBack}
      buttonContent={t('buttons.next')}
      hideGoBack
      disabled={!!error || clabe.trim() === ''}
      classNameContainer="margin-container-bottom-12"
    >
      <p className="description mt-1">
        <img className="bank" src={IconBank} width="64" height="64" />
      </p>
      <p className="description mt-4 mb-2 lh-15">
        Para poder realizar una transferencia necesitamos que nos indiques tu número de <b>CLABE</b>.
        <br />
        Recuerda por favor que es indispensable que la cuenta asociada a la <b>CLABE</b> se encuentre a tu nombre{' '}
        <b>
          {kyc.name} {kyc.lastname}
        </b>
        .
      </p>
      <div className="mb-5">
        <Row>
          <Col md={{ size: 8, offset: 2 }}>
            <CustomInput label={t('withdraw.clabe')} outline onChange={e => setClabe(e.target.value)} value={clabe} />
            {error && <small className="error">{clabeInfo.message}</small>}
          </Col>
        </Row>

        {clabeInfo.bank && (
          <Row>
            <Col md={{ size: 8, offset: 2 }}>
              <Row className="transfer-detail folded" style={{ width: 'auto' }}>
                <Col md={{ size: 4 }}>
                  <img width="64" src={IconBank} />
                </Col>
                <Col md={{ size: 8 }} className="my-auto">
                  <small>{clabeInfo.bank}</small>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </div>
    </CheckoutStep>
  );
};

const BrBankAccountInput = (kyc, country, t, handleOnClick, history) => {
  const [bank, setBank] = useState({ label: '', value: null });
  const [agency, setAgency] = useState('');
  const [account, setAccount] = useState('');
  const [accountType, setAccountType] = useState('');
  const error =
    bank.label.trim() === '' ||
    agency.trim() === '' ||
    account.trim() === '' ||
    accountType.trim() === '' ||
    !bank.value;

  const onChange = ({ label, value }) => setBank({ label, value });

  const bankSelectCustomStyles = {
    control: styles => ({
      ...styles,
      marginTop: '24px',
      marginBottom: '25px',
      fontWeight: '500',
      fontFamily: 'RobotoRegular',
      height: '54px',
      border: '1.5px solid #E1E1E1',
      borderRadius: '2px'
    }),
    placeholder: () => ({
      color: '#989898',
      paddingTop: '8px',
      paddingLeft: '6px'
    })
  };

  return (
    <CheckoutStep
      title={t('instructions.title.bank.bankTransfer')}
      next={() =>
        handleOnClick({
          accountHandleType: 'Agência | Conta | Tipo de Conta | Código bancário',
          accountHandle: `${agency.trim()} | ${account.trim()} | ${accountType.trim()} | ${bank.value} `,
          toBank: bank.label
        })
      }
      prev={history.goBack}
      buttonContent="Seguinte"
      hideGoBack
      disabled={!!error}
      classNameContainer="margin-container-bottom-12"
    >
      <p className="description mt-1">
        <img className="bank" src={IconBank} width="64" height="64" />
      </p>
      <p className="description mt-4 mb-2 lh-15">
        Insira os detalhes de uma conta bancária da qual você é o proprietário para receber o pagamento pelo seu pedido
        de vendas.
        <br />
        <br />
        <b>Importante:</b> Não poderemos processar a transação se a conta não estiver em seu nome:{' '}
        <b>
          {kyc.name} {kyc.lastname}
        </b>
        .
      </p>
      <div className="">
        <Row>
          <Col md="6">
            <Select
              placeholder="Seu banco"
              noOptionsMessage={() => 'sem opções'}
              options={data.brlBanks}
              onChange={onChange}
              styles={bankSelectCustomStyles}
            />
          </Col>
          <Col>
            <CustomInput
              label="Sua agência, sem dígito"
              outline
              onChange={e => setAgency(justDigits(e.target.value))}
              value={agency}
            />
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <CustomInput
              label="Sua conta, com dígito"
              outline
              onChange={e => setAccount(justDigits(e.target.value))}
              value={account}
            />
          </Col>
          <Col>
            <CustomInput
              label="Tipo de conta"
              outline
              onChange={e => setAccountType(e.target.value)}
              value={accountType}
            />
          </Col>
        </Row>
      </div>
    </CheckoutStep>
  );
};

const renderDesc = (kyc, t, handleOnClick, history) => {
  const { country } = kyc;
  let accountInput;
  switch (country) {
    case 'AR':
      accountInput = ArBankAccountInput(kyc, country, t, handleOnClick, history);
      break;
    case 'BR':
      accountInput = BrBankAccountInput(kyc, country, t, handleOnClick, history);
      break;
    case 'MX':
      accountInput = MxBankAccountInput(kyc, country, t, handleOnClick, history);
      break;
    default:
      accountInput = <></>;
  }
  return accountInput;
};

const WithdrawInput = ({ onClick, history, kyc, t }) => {
  const handleOnClick = bankData => {
    onClick(bankData);
  };

  return <div className="d-flex flex-column align-items-center">{renderDesc(kyc, t, handleOnClick, history)}</div>;
};

export default withTranslation()(WithdrawInput);
