import React from 'react';
import { connect } from 'react-redux';

import FcDepositFinishComponent from '../../../components/Fastcash/FcDepositFinish';

const FcDepositFinish = ({ history, user, kyc, deposit }) => (
  <FcDepositFinishComponent
    {...{
      history,
      user,
      kyc,
      deposit
    }}
  />
);

const mapStateToProps = state => {
  return {
    user: state.user.user,
    kyc: state.user.kyc,
    deposit: state.fastcash.deposit.deposit
  };
};

export default connect(mapStateToProps, null)(FcDepositFinish);
