import React from 'react';
import { connect } from 'react-redux';

import FcDepositDetailsComponent from '../../../components/Fastcash/FcDepositDetails';

const FcDepositDetails = ({ history, paymentMethod, deposit, providerResponse }) => (
  <FcDepositDetailsComponent
    {...{
      history,
      paymentMethod,
      deposit,
      providerResponse
    }}
  />
);

const mapStateToProps = state => {
  return {
    user: state.user.user,
    paymentMethod: state.fastcash.paymentMethod,
    deposit: state.fastcash.deposit.deposit,
    providerResponse: state.fastcash.deposit.providerResponse
  };
};

export default connect(mapStateToProps, null)(FcDepositDetails);
