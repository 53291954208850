import React from 'react';
import _ from 'lodash';
import { Redirect } from 'react-router-dom';

export const TicketNotCreated = state => {
  const hasTicket = !!state.deposit.ticket || !!_.get(state, 'withdraw.last._id');

  if (hasTicket) {
    return {
      status: 'redirect',
      Redirect: <Redirect to="/status/check" push />
    };
  }
};
