import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import LimitExceeded from '../../components/LimitEnforcement/LimitExceeded';

const LimitEnforcement = ({ user, limitEnforcement, history, t }) => (
  <>
    {!limitEnforcement && <Redirect to="/#/checkout-form" />}
    {limitEnforcement && <LimitExceeded {...{ user, limitEnforcement, history, t }} />}
  </>
);

const mapStateToProps = state => {
  return {
    limitEnforcement: state.user.limitEnforcementCheck,
    user: state.user.user
  };
};

export default connect(mapStateToProps, null)(withTranslation()(LimitEnforcement));
