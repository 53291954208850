export const networkMap = {
  BTC: 'BTC',
  ETH: 'ERC20',
  DAI: 'ERC20',
  USDT: 'ERC20',
  USDC: 'ERC20',
  TUSD: 'ERC20',
  LTC: 'LTC',
  NEO: 'NEP5',
  ONT: 'ONT',
  TRX: 'TRC20',
  RIF: 'RSK'
};
