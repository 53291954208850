// @Vendors
import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  saveWalletAddressRequest: ['address', 'memo', 'asset', 'history'],
  saveWalletAddressSuccess: ['data'],
  saveWalletAddressFailure: ['error'],

  getCurrencies: ['data'],
  getCurrenciesSuccess: ['fiatCurrencies', 'cryptoCurrencies'],
  getCurrenciesFailure: ['error'],

  getRate: [],
  getRateSuccess: ['rate'],
  getRateFailure: ['error'],

  getCheckoutConfirmInfoRequest: ['data'],
  getCheckoutConfirmInfoSuccess: ['confirm'],
  getCheckoutConfirmInfoFailure: ['error'],

  checkoutConfirmRequest: ['id', 'rate', 'checkoutType'],
  checkoutConfirmSuccess: ['data'],
  checkoutConfirmFailure: ['error'],

  checkoutProviderRequest: ['countryCode'],
  checkoutProviderSuccess: ['data'],
  checkoutProviderFailure: ['error'],

  validateCheckoutRequest: ['history', 'cb'],
  validateCheckoutFinish: []
});

export const CheckoutTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({});

/* ------------- Reducers ------------- */

export const getCurrencies = state => {
  return state.merge({
    requesting: true,
    error: ''
  });
};

export const saveWalletAddressRequest = state => {
  return state.merge({
    requesting: true,
    error: ''
  });
};

export const saveAddressSuccess = state => {
  return state.merge({
    requesting: false,
    error: ''
  });
};

export const getCurrenciesSuccess = (state, { fiatCurrencies, cryptoCurrencies }) => {
  return state.merge({
    fiatCurrencies,
    cryptoCurrencies,
    requesting: false
  });
};

export const failure = (state, { error }) => {
  return state.merge({
    requesting: false,
    error: `Something went wrong. ${error}`
  });
};

export const getRate = state => {
  return state.merge({
    requestingGetRate: true,
    error: ''
  });
};

export const getRateSuccess = (state, { rate }) => {
  return state.merge({
    requestingGetRate: false,
    rate,
    error: ''
  });
};

export const getRateFailure = (state, { error }) => {
  return state.merge({
    requestingGetRate: false,
    rate: undefined,
    error: `Something went wrong. ${error.message || error}`
  });
};
export const getCheckoutConfirmInfoRequest = state => {
  return state.merge({
    requesting: true,
    error: '',
    errorDetails: undefined
  });
};

export const getCheckoutConfirmInfoSuccess = (state, { confirm }) => {
  return state.merge({
    requesting: false,
    confirm,
    error: '',
    errorDetails: undefined
  });
};

export const getCheckoutConfirmInfoFailure = (state, { error }) => {
  return state.merge({
    requesting: false,
    confirm: undefined,
    errorDetails: error.details || undefined,
    error: error.message || error
  });
};

export const checkoutConfirmRequest = state => {
  return state.merge({
    requestingConfirm: true,
    error: ''
  });
};

export const checkoutConfirmSuccess = (state, { data }) => {
  return state.merge({
    requestingConfirm: false,
    confirmResult: data.result,
    error: ''
  });
};

export const checkoutConfirmFailure = (state, { error }) => {
  return state.merge({
    requestingConfirm: false,
    confirmResult: false,
    error: `Something went wrong. ${error.message || error}`
  });
};

export const checkoutProviderRequest = state => {
  return state.merge({
    requesting: true,
    error: ''
  });
};

export const checkoutProviderSuccess = (state, { data }) => {
  return state.merge({
    requesting: false,
    provider: data,
    error: ''
  });
};

export const checkoutProviderFailure = (state, { error }) => {
  return state.merge({
    requesting: false,
    provider: undefined,
    error: `Something went wrong. ${error.message || error}`
  });
};

export const validateCheckoutRequest = state => {
  return state.merge({
    requesting: true
  });
};

export const validateCheckoutFinish = state => {
  return state.merge({
    requesting: false
  });
};

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SAVE_WALLET_ADDRESS_REQUEST]: saveWalletAddressRequest,
  [Types.SAVE_WALLET_ADDRESS_SUCCESS]: saveAddressSuccess,
  [Types.SAVE_WALLET_ADDRESS_FAILURE]: failure,

  [Types.GET_CURRENCIES]: getCurrencies,
  [Types.GET_CURRENCIES_SUCCESS]: getCurrenciesSuccess,
  [Types.GET_CURRENCIES_FAILURE]: failure,

  [Types.GET_RATE]: getRate,
  [Types.GET_RATE_SUCCESS]: getRateSuccess,
  [Types.GET_RATE_FAILURE]: getRateFailure,

  [Types.GET_CHECKOUT_CONFIRM_INFO_REQUEST]: getCheckoutConfirmInfoRequest,
  [Types.GET_CHECKOUT_CONFIRM_INFO_SUCCESS]: getCheckoutConfirmInfoSuccess,
  [Types.GET_CHECKOUT_CONFIRM_INFO_FAILURE]: getCheckoutConfirmInfoFailure,

  [Types.CHECKOUT_CONFIRM_REQUEST]: checkoutConfirmRequest,
  [Types.CHECKOUT_CONFIRM_SUCCESS]: checkoutConfirmSuccess,
  [Types.CHECKOUT_CONFIRM_FAILURE]: checkoutConfirmFailure,

  [Types.CHECKOUT_PROVIDER_REQUEST]: checkoutProviderRequest,
  [Types.CHECKOUT_PROVIDER_SUCCESS]: checkoutProviderSuccess,
  [Types.CHECKOUT_PROVIDER_FAILURE]: checkoutProviderFailure,

  [Types.VALIDATE_CHECKOUT_REQUEST]: validateCheckoutRequest,
  [Types.VALIDATE_CHECKOUT_FINISH]: validateCheckoutFinish
});
