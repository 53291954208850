export const canUseDOM = () => {
  if (typeof window === 'undefined' || !window.document || !window.document.createElement) {
    return false;
  }
  return true;
};

export const ZendeskWebApi = (...args) => {
  if (canUseDOM && window.zE) {
    window.zE.apply(null, args);
  } else {
    console.log('Zendesk is not initialized yet');
    const localStorage_zEQueue = localStorage.getItem('zEQueue');
    const zEQueue = localStorage_zEQueue ? JSON.parse(localStorage_zEQueue) : {};
    zEQueue[Date.now()] = args;
    localStorage.setItem('zEQueue', JSON.stringify(zEQueue));
  }
};

export const loadZendeskScript = zendeskKey => {
  localStorage.removeItem('zEQueue');
  const script = document.createElement('script');
  script.async = true;
  script.id = 'ze-snippet';
  script.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`;
  script.addEventListener(
    'load',
    function() {
      const zEQueue = localStorage.getItem('zEQueue');
      if (zEQueue) {
        const zEQueueObj = JSON.parse(zEQueue);
        Object.keys(zEQueueObj).map(function(key) {
          return window.zE.apply(null, zEQueueObj[key]);
        });
      }
    },
    false
  );
  document.body.appendChild(script);
};
