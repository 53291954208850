// @Vendors
import { put, call, select } from 'redux-saga/effects';
import WithdrawActions from '../redux/WithdrawRedux';

import HeapBridge from '../utils/heap';

export const getToken = state => state.auth.token;

export function* createWithdraw(api, action) {
  const token = yield select(getToken);
  const { data } = action;
  const response = yield call(api.createWithdraw, token, data);

  if (response.ok) {
    HeapBridge.track({ event: 'Order Created', type: 'Withdraw' });
    yield put(WithdrawActions.createWithdrawSuccess(response.data.data));
  } else {
    yield put(WithdrawActions.createWithdrawFailure(response.data));
  }
}

export function* getWithdraws(api, action) {
  const token = yield select(getToken);
  const { filter } = action;
  const response = yield call(api.getWithdraws, token, { filter });

  if (response.ok) {
    yield put(WithdrawActions.getWithdrawsSuccess(response.data.data, filter));
  } else {
    yield put(WithdrawActions.getWithdrawsFailure(response));
  }
}

export function* getWithdraw(api, action) {
  const { id } = action;
  const token = yield select(getToken);
  const response = yield call(api.getWithdraw, token, id);

  if (response.ok) {
    yield put(WithdrawActions.getWithdrawSuccess(response.data.data));
  } else {
    yield put(WithdrawActions.getWithdrawFailure(response));
  }
}

export function* updateWithdraw(api, action) {
  const { id, data } = action;
  const token = yield select(getToken);
  const response = yield call(api.updateWithdraw, token, id, data);

  if (response.ok) {
    yield put(WithdrawActions.updateWithdrawSuccess(response.data.data));
  } else {
    yield put(WithdrawActions.updateWithdrawFailure(response));
  }
}
