import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import CheckStatusComponent from '../../components/Status/CheckStatus';

import DepositActions from '../../redux/DepositRedux';

const StatusCheck = ({ checkoutRequest, status, error, requesting, t, match }) => (
  <CheckStatusComponent
    requestCheckoutStatus={checkoutRequest}
    status={status}
    error={error}
    requesting={requesting}
    orderNumberParam={match.params.orderNumber}
    langParam={match.params.lang}
    {...{ t }}
  />
);

const mapStateToProps = ({ deposit: { ticket, error, requesting }, user: { user } }) => {
  return {
    status: ticket ? ticket.status : null,
    user,
    error,
    requesting
  };
};

const mapDispatchToProps = dispatch => {
  return {
    checkoutRequest: data => dispatch(DepositActions.getCheckoutStatus(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(StatusCheck));
