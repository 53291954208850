import React, { useEffect, useState } from 'react';
import { Col, Row, CustomInput as Check } from 'reactstrap';
import QRCode from 'qrcode.react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CurrencyFormat from 'react-currency-format';
import { Trans } from 'react-i18next';
import { PropagateLoader } from 'react-spinners';

import './styles.scss';
import CheckoutStep from '../Layout/CheckoutStep';
import CashoutFinish from './CashoutFinish';
import clockImage from '../../images/icons/clock.svg';

const WithdrawFinish = ({
  onClick,
  history,
  t,
  user: { cryptoAmount, originAsset, quoteSource },
  user,
  cryptoCurrencies,
  ticket
}) => {
  const [copiedAddress, setCopiedAddress] = useState(false);
  const [copiedMemo, setCopiedMemo] = useState(false);
  const [termsAndCondCheck, setTermsAndCondCheck] = useState(false);
  const checkValue = val => val === undefined;
  const [disabledButton, setDisabledButton] = useState(checkValue(ticket.address));
  const showTermsAndCondMemo = originAsset === 'ARST' || originAsset === 'BRLT';
  const onTermsAndConditionCheck = event => setTermsAndCondCheck(event.target.checked);
  const transText = txt => {
    return (
      <small>
        <Trans i18nKey={txt}>
          text
          <strong>text</strong>
          text
        </Trans>
      </small>
    );
  };
  const handleCopy = e => {
    switch (e.currentTarget.getAttribute('value')) {
      case 'address':
        setCopiedAddress(true);
        setCopiedMemo(false);
        break;
      case 'memo':
        setCopiedAddress(false);
        setCopiedMemo(true);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (ticket.address && showTermsAndCondMemo) {
      setDisabledButton(!termsAndCondCheck);
    }

    if (ticket.address && !showTermsAndCondMemo) {
      setDisabledButton(false);
    }
  }, [termsAndCondCheck, ticket]);

  if (!ticket || !ticket._id) {
    return (
      <div className="d-flex flex-column align-items-center" style={{ minHeight: '600px', paddingTop: '300px' }}>
        <PropagateLoader sizeUnit="px" size={10} color="#448aff" />
      </div>
    );
  }

  if (ticket.orderType === 'cash-out') {
    return <CashoutFinish {...{ ticket, t, user }} />;
  }

  return (
    <div className="d-flex flex-column align-items-center">
      <CheckoutStep
        title={`${t('withdraw.orderNumber')} ${ticket && ticket._id ? ticket._id : ''} -  ${t(
          'instructions.title.bank.bankTransfer'
        )}`}
        next={onClick}
        prev={history.goBack}
        buttonContent={t('finish')}
        hideGoBack
        disabled={disabledButton}
        classNameContainer="margin-container-bottom-12"
      >
        <p className="description mb-3">
          <Trans i18nKey="withdraw.sendToWallet">
            text{' '}
            <strong>
              {{ cryptoAmount }} {{ originAsset }}
            </strong>{' '}
            text
          </Trans>
        </p>

        <div className="transfer-detail withdraw-detail">
          <Row>
            <Col md="2">
              <div>{ticket.address && <QRCode size={80} value={ticket.address || ' '} />}</div>
            </Col>
            <Col className="container">
              <div className="container-grid grid-small">
                <div className="column-title">{t('withdraw.wallet')}:</div>
                <div className="column-detail">
                  <b>{originAsset}</b>
                </div>
              </div>
              <div className="container-grid grid-small">
                <div className="column-title">{t('withdraw.network')}:</div>
                <div className="column-detail">
                  <b>{showTermsAndCondMemo ? 'STELLAR' : originAsset}</b>
                </div>
              </div>
              <div className="container-grid grid-small">
                <div className="column-title">{t('withdraw.amount')}:</div>
                <div className="column-detail">
                  <b>
                    <CurrencyFormat
                      value={cryptoAmount}
                      displayType="text"
                      decimalSeparator="."
                      decimalScale={cryptoCurrencies.find(({ crypto }) => crypto === originAsset).decimal}
                      fixedDecimalScale
                    />
                  </b>
                </div>
              </div>
            </Col>
          </Row>
          <div className="container-grid grid-large">
            <div className="column-title">{t('withdraw.address')}:</div>
            <div className="column-detail line-small">
              <b>{ticket.address}</b>
            </div>
            <div className="column-tools">
              <CopyToClipboard text={ticket.address}>
                <span className="copyButton" value="address" onClick={handleCopy}>
                  {copiedAddress ? t('instructions.copied') : t('instructions.copy')}
                </span>
              </CopyToClipboard>
            </div>
          </div>
          <div className="container-grid grid-large">
            <div className="column-title">{t('withdraw.memo')}:</div>
            <div className="column-detail line-small">{ticket.memo ? <b>{ticket.memo}</b> : '-'}</div>
            <div className="column-tools">
              <CopyToClipboard text={ticket.memo}>
                <span className="copyButton" value="memo" onClick={handleCopy}>
                  {copiedMemo ? t('instructions.copied') : t('instructions.copy')}
                </span>
              </CopyToClipboard>
            </div>
          </div>
        </div>
        {quoteSource && quoteSource !== 'stablex' && (
          <p className="description text-import mt-3">
            <Trans i18nKey="withdraw.importantInfo">
              <strong>text</strong> text
              <br />
              <br />
              text
            </Trans>
          </p>
        )}
        <p className="description mt-4 mb-4" style={{ lineHeight: 1.3 }}>
          <img src={clockImage} />
          <br />
          <small>
            <Trans i18nKey="withdraw.etaTransfer">
              text
              <br />
              text
            </Trans>
          </small>
        </p>
        {showTermsAndCondMemo && (
          <div className="description check-small">
            <Check
              type="checkbox"
              id="TermsAndCond"
              label={transText('withdraw.termsAndCond.memo')}
              onChange={onTermsAndConditionCheck}
              defaultChecked={termsAndCondCheck}
            />
          </div>
        )}
      </CheckoutStep>
    </div>
  );
};

export default WithdrawFinish;
