import React from 'react';
import { Trans } from 'react-i18next';

import CheckoutStep from '../Layout/CheckoutStep';
import SuccessIcon from '../../images/deposit/icon-card-success@2x.png';
import { GetSupportUrl } from '../../utils/consumerConfig';

const LimitDocUploadSuccess = ({ kyc, onClick, history, t }) => {
  const name = kyc ? kyc.name : '';
  const email = kyc ? kyc.email : '';
  const lang = kyc.country === 'BR' ? 'pt' : 'es'; // The zendesk page is only distinguishing between es and pt for now.
  const supportUrl = GetSupportUrl(lang);

  return (
    <div className="d-flex flex-column align-items-center">
      <CheckoutStep
        title={t('deposit.finishStep.title', { name })}
        next={onClick}
        prev={history.goBack}
        buttonContent={t('limitEnforcement.uploadSuccess.button')}
        hideGoBack
      >
        <div className="d-flex align-items-center">
          <img src={SuccessIcon} alt="success icon" className="logo" style={{ marginTop: '30px' }} />
        </div>

        <p className="description mt-4">
          <Trans i18nKey="limitEnforcement.uploadSuccess.description">
            <br />
            <br />
            <br />
            <strong>{{ email }}</strong>
          </Trans>
        </p>
        <p className="description mt-4 mb-4">
          <small>
            {t('limitEnforcement.checkStatus.footer')}{' '}
            <a href={supportUrl} target="_blank" rel="noopener noreferrer">
              {t('limitEnforcement.checkStatus.footer.link')}
            </a>
          </small>
        </p>
      </CheckoutStep>
    </div>
  );
};

export default LimitDocUploadSuccess;
