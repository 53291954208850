/* eslint-disable react/jsx-no-target-blank */
import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CurrencyFormat from 'react-currency-format';
import { Trans } from 'react-i18next';
import { PropagateLoader } from 'react-spinners';

import CheckoutStep from '../Layout/CheckoutStep';

// @Images
import IconBank from '../../images/deposit/icon-bank-1.png';
import RapiPagoIcon from '../../images/deposit/rapipago.png';
import PlaceMarker from '../../images/icons/place-24px.svg';

import './index.scss';

const DYNAMIC_FIELDS = {
  clabe: '{mx_user_clabe}'
};

const camelCase = input => {
  return input.toLowerCase().replace(/-(.)/g, function(match, group1) {
    return group1.toUpperCase();
  });
};

const RefTooltip = ({ visible, setVisible, t }) => {
  const openTooltip = event => {
    event.stopPropagation();
    setVisible(!visible);
  };
  return (
    <div className="tooltip-container">
      <a className="question-mark" onClick={openTooltip} style={{ bottom: '18px' }}>
        ?
      </a>
      {visible && (
        <div className="tooltip-content" onClick={e => e.stopPropagation()}>
          {t('instructions.tooltip')}
        </div>
      )}
    </div>
  );
};

const DepositSummary = ({
  history,
  kyc: { name, lastname, bankAccountId },
  user: { checkoutAmount, feeAmount, countryCode },
  ticket,
  setStep,
  provider,
  method,
  bank,
  t
}) => {
  const [copied, setCopied] = useState('none');
  const [visible, setVisible] = useState(false);
  const [bankMapped, setBankMapped] = useState();
  const [details] = useState({
    AR: {
      rapipago: {
        cashNetwork: [
          { id: 'company', value: 'Settle ARG S.A.S.' },
          {
            id: 'refNumber',
            value: '',
            isRefNumber: true
          },
          { id: 'amount', value: checkoutAmount + feeAmount, isCurrency: true }
        ]
      }
    }
  });

  const processValue = (id, value) => {
    if (DYNAMIC_FIELDS[id] === value) {
      value = bankAccountId;
    }

    return value;
  };

  useEffect(() => {
    if (bank) {
      const data = [
        { id: 'bank', value: bank.bankName },
        { id: 'recipient', value: countryCode === 'MX' ? `${name} ${lastname}` : bank.holderName }
      ];

      _.each(bank.data, (value, id) => {
        data.push({ id, value: processValue(id, value), hasCopyButton: true });
      });

      data.push(
        {
          id: 'refNumber',
          value: '',
          hasTooltip: true,
          hasCopyButton: true,
          isRefNumber: true
        },
        { id: 'amount', value: checkoutAmount + feeAmount, isCurrency: true }
      );

      setBankMapped(data);
    }
  }, [bank]);

  const providerData =
    camelCase(provider) === 'bank'
      ? camelCase(method) === 'bankTransfer'
        ? bankMapped
        : undefined
      : details[countryCode][camelCase(provider)][camelCase(method)];

  const totalAmount = checkoutAmount + feeAmount;
  const formatedTotalAmount = totalAmount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  const ticketId = ticket ? ticket._id : '';

  console.log(`providerData`, providerData);

  return (
    <div className="d-flex flex-column align-items-center">
      <CheckoutStep
        classNameContainer="margin-container-bottom-12"
        title={t(`instructions.title.${camelCase(provider)}.${camelCase(method)}`)}
        next={() => setStep('finish')}
        prev={history.goBack}
        buttonContent={t('buttons.next')}
        hideGoBack
      >
        <p className="description transfer-data mb-3">
          <Trans i18nKey={`instructions.description.${camelCase(provider)}.${camelCase(method)}`}>
            <strong>
              {{ name }} {{ lastname }}
            </strong>
            <strong>text {{ formatedTotalAmount }}</strong>
            <strong>text {{ ticketId }}</strong>
            <br />
          </Trans>
        </p>

        <div className="transfer-detail bank-instructions">
          <div className="bank-data">
            <img
              width="64"
              height="64"
              className={` ${provider}`}
              src={provider === 'bank' ? IconBank : RapiPagoIcon}
            />
            <div>
              {providerData &&
                providerData.map(detail => {
                  if (detail.value && ['bank', 'recipient', 'cuit'].includes(detail.id))
                    return (
                      <p key={detail.id}>
                        {t(`instructions.details.${detail.id.toLowerCase()}`)}: {detail.value}
                      </p>
                    );
                  return null;
                })}
            </div>
          </div>

          <div className="bank-data-transfer">
            {providerData ? (
              providerData.map(detail => {
                if ((detail.value || detail.isRefNumber) && !['bank', 'recipient', 'cuit'].includes(detail.id))
                  return (
                    <Row className="mb-2" key={detail.id}>
                      <Col md={detail.hasCopyButton ? 9 : 12} xs="12" className="align-left">
                        <span>
                          {t(`instructions.details.${detail.id.toLowerCase()}`)}:{' '}
                          {detail.isCurrency ? (
                            <CurrencyFormat
                              value={totalAmount}
                              displayType="text"
                              thousandSeparator=","
                              decimalSeparator="."
                              fixedDecimalScale
                              decimalScale={2}
                              prefix="$ "
                            />
                          ) : detail.isRefNumber && ticket ? (
                            ticket._id
                          ) : (
                            detail.value
                          )}
                        </span>
                        {detail.hasTooltip && countryCode !== 'MX' && <RefTooltip {...{ visible, setVisible, t }} />}
                      </Col>
                      {detail.hasCopyButton && (
                        <Col md="3" xs="12">
                          <CopyToClipboard
                            text={detail.isRefNumber && ticket ? ticket._id : detail.value}
                            onCopy={() => setCopied(detail.id)}
                          >
                            <span className="copyButton">
                              {copied === detail.id ? t('instructions.copied') : t('instructions.copy')}
                            </span>
                          </CopyToClipboard>
                        </Col>
                      )}
                    </Row>
                  );
                return null;
              })
            ) : (
              <div className="loading d-flex justify-content-center align-items-center">
                <PropagateLoader sizeUnit="px" size={10} color="#448aff" />
              </div>
            )}
          </div>
        </div>
        {provider === 'rapipago' && (
          <p className="description mb-4">
            <a target="_blank" href="https://www.google.com.ar/maps/search/rapipago" className="sucursales">
              <img src={PlaceMarker} /> Ver sucursales rapipago
            </a>
          </p>
        )}
      </CheckoutStep>
    </div>
  );
};

export default DepositSummary;
