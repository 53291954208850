import React from 'react';
import { withTranslation } from 'react-i18next';
import { PropagateLoader } from 'react-spinners';
import { Col, Container, Row, NavLink } from 'reactstrap';

import leftArrow from '../../images/left-arrow@2x.png';
import CustomButton from '../Commons/CustomButton/CustomButton';

const CheckoutStep = ({
  classNameContainer,
  title,
  children,
  prev,
  next,
  disabled,
  buttonContent,
  overrideTitleWith,
  hideGoBack,
  hideNextButton,
  loading,
  t,
  cancelActionBtn
}) => {
  if (loading) {
    return (
      <div className="loader-container d-flex justify-content-center align-items-center">
        <PropagateLoader sizeUnit="px" size={10} color="#448aff" />
      </div>
    );
  }

  return (
    <Container className={classNameContainer}>
      {!hideGoBack && (
        <NavLink onClick={prev} className="back-arrow">
          <img src={leftArrow} alt="left-arrow" width="25" height="25" />
        </NavLink>
      )}
      {(overrideTitleWith || title) && (
        <Row className="kyc-step-title align-content-start">
          <Col md={{ size: 10, offset: 1 }}>
            <Row className="mb-4">
              <Col className="d-flex flex-column align-items-center">
                <span className="title">{overrideTitleWith || title}</span>
              </Col>
            </Row>
          </Col>
        </Row>
      )}

      {children}
      {!hideNextButton && (
        <Row className="kyc-step-button-next">
          <Col className="d-flex flex-column align-items-center">
            <CustomButton disabled={disabled} onMouseUp={next} width="350px">
              {buttonContent || t('Next')}
            </CustomButton>
            {cancelActionBtn ? (
              <p
                style={{
                  color: '#448aff',
                  fontSize: '16px',
                  padding: '15px',
                  marginBottom: '0',
                  cursor: 'pointer'
                }}
                onMouseUp={cancelActionBtn}
              >
                {t('welcome.cancelAction')}
              </p>
            ) : null}
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default withTranslation()(CheckoutStep);
