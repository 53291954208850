/* eslint-disable */
// @Vendors
import React, { Component } from 'react';
import { MDBInput } from 'mdbreact';

import './RadioButtonStyles.scss';

const iconsMap = {
  bank: require('../../../images/deposit/icon-bank.png'),
  card: require('../../../images/deposit/icon-card.png'),
  cash: require('../../../images/deposit/icon-cash.png'),
  pagofacil: require('../../../images/deposit/icon-cash-pagofacil.png'),
  rapipago: require('../../../images/deposit/icon-cash-rapipago.png')
};

class RadioButton extends Component {
  render() {
    const { label, onClick, isChecked, id, icon, iconClass, disabled, className: propsClassName } = this.props;
    let className = 'radio-button';
    let baseIconClass = 'radio-icon';
    if (icon) {
      className += ' with-icon';

      if (baseIconClass) {
        baseIconClass = `${baseIconClass} ${iconClass}`;
      }
    }

    return (
      <div className={`radio-wrapper ${propsClassName || ''}`}>
        {icon && <img src={iconsMap[icon]} className={baseIconClass} alt="icon" />}

        <MDBInput
          disabled={disabled}
          className={className}
          onClick={onClick}
          checked={isChecked}
          label={label}
          type="radio"
          id={id}
        />
      </div>
    );
  }
}

export default RadioButton;
